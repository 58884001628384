import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ValidationsService } from '@services';
import { uniq } from 'lodash';
import { ICheckboxOption } from '@interfaces';

@Component({
  selector: 'app-ui-checkbox-dropdown',
  templateUrl: './ui-checkbox-dropdown.component.html',
  styleUrls: ['./ui-checkbox-dropdown.component.scss'],
})
export class UiCheckboxDropdownComponent implements OnInit, OnChanges {
  @Input()
  formGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  options: Array<ICheckboxOption>;
  @Input()
  isOptionsLoading = false;
  @Input()
  set selected(input: Array<string>) {
    this.selectedStorage = input;
  }
  get selected(): Array<string> {
    return this.selectedStorage;
  }
  @Input()
  placeholder: string;
  @Input()
  disabled = false;
  @Input()
  showSelected = false;
  @Input()
  width: string;
  @Input()
  style = {};
  @Input()
  resultValueType: string;
  @Input()
  needPixel: boolean; // for location
  @Input()
  tabindex: number;

  @Output()
  changeEvent: EventEmitter<Array<any>> = new EventEmitter();
  @Output()
  noPixelEvent: EventEmitter<boolean> = new EventEmitter();
  @Output()
  disabledClickEvent: EventEmitter<MouseEvent> = new EventEmitter();
  @Output()
  removeOption: EventEmitter<string | number> = new EventEmitter();
  @Output()
  addOption: EventEmitter<string | number> = new EventEmitter();

  @ViewChild('myDrop')
  myDrop: NgbDropdown;

  checkboxOptions: Array<ICheckboxOption>;
  displaySelected: Array<ICheckboxOption>;

  selectedStorage: Array<string>;
  constructor(private validationsService: ValidationsService) {}

  ngOnInit() {
    this._initForm();
    this._initData();
    this._initStyle();
  }

  ngOnChanges() {
    this._initData();
    this._initStyle();
  }

  private _initForm(): void {
    let formGroup = this.formGroup;
    let controlName = this.controlName;
    const defaultValue = this._selected;
    if (!formGroup || !controlName) {
      controlName = 'defaultControl';
      const control = {
        defaultControl: new FormControl(defaultValue, []),
      };
      formGroup = new FormGroup(control);
    }
    this.formGroup = formGroup;
    this.controlName = controlName;
  }

  private _initStyle(): void {
    const width = this.width;
    let style = this.style;
    if (!!width) {
      style = Object.assign({}, this.style, { width });
    }
    this.style = style;
  }

  private _initData(): void {
    let options = [...this.options] as Array<ICheckboxOption>;
    options = options.map((option) => {
      option.checked = this.isChecked(option);
      return option as ICheckboxOption;
    });
    this.displaySelected = options.filter((option) => option.checked);
    this.checkboxOptions = options;
  }

  clearAllChecked() {
    this.displaySelected = [];
    this.checkboxOptions.forEach((option) => {
      option.checked = false;
    });
    this._doChange();
  }

  doCheck(event: MatCheckboxChange, option: ICheckboxOption): void {
    const value = option.value;
    if (this.disabled) {
      return;
    }
    if (event.checked) {
      this._checkValue(value);
    } else {
      this.unCheckValue(value);
    }
    this._doChange();
  }

  checkPixel(event: MouseEvent, option: ICheckboxOption): void {
    if (this.needPixel && !option['hasPixel']) {
      event.preventDefault();
      event.stopPropagation();
      this._doNoPixel();
    }
  }

  doDisabledClick(event: MouseEvent, option: ICheckboxOption): void {
    event.preventDefault();
    event.stopPropagation();
    this.disabledClickEvent.emit(event);
  }

  private _checkValue(value: string | number): void {
    const control = this.formGroup.get(this.controlName);
    let selected = control.value as Array<string | number>;
    const options = this.checkboxOptions.map((option) => {
      if (option.value + '' === value + '') {
        option.checked = true;
        selected = [...selected];
        selected.push(option.value);
        selected = uniq(selected);
        control.setValue(selected);
      }
      return option;
    });
    this.addOption.emit(value);
    this.checkboxOptions = options;
    this.displaySelected = options.filter((option) => option.checked);
  }

  unCheckValue(value: string | number): void {
    const control = this.formGroup.get(this.controlName);
    let selected = control.value as Array<string>;
    const options = this.checkboxOptions.map((option) => {
      if (option.value + '' === value + '') {
        option.checked = false;
        selected = selected.filter((item) => item !== option.value);
        control.setValue(selected);
      }
      return option;
    });
    this.removeOption.emit(value);
    this.checkboxOptions = options;
    this.displaySelected = options.filter((option) => option.checked);
  }

  private _doChange(): void {
    this.changeEvent.emit(
      this.checkboxOptions
        .filter((option) => option.checked)
        .map((option) =>
          this.resultValueType === 'object' ? { value: option.value, text: option.text } : option.value,
        ),
    );
  }

  private _doNoPixel(): void {
    this.noPixelEvent.emit(true);
  }

  toggleDropdown(event: MouseEvent): void {
    const myDrop = this.myDrop;
    if (!!myDrop && !this.disabled && this.checkboxOptions && this.checkboxOptions.length > 0) {
      event.stopPropagation();
      const control = this.formGroup.get(this.controlName);
      if (!!control && !control.touched) {
        control.markAsTouched();
      }
      myDrop.toggle();
    }
  }

  get buttonText(): string {
    return this.placeholder || 'Please select...';
  }

  isChecked(option: ICheckboxOption): boolean {
    if (option['checked'] !== undefined) {
      return option['checked'];
    } else {
      return !!this._selected.find((item) => item + '' === option.value + '');
    }
  }

  get _selected(): Array<string> {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (this.selected) {
      return this.selected;
    }
    if (!!formGroup && !!controlName) {
      return this.formGroup.get(controlName).value;
    }
    return [];
  }

  get formControl(): AbstractControl {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName);
    }
    return null;
  }

  get errorMessage(): string {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return this.validationsService.getValidationErrorMessage(formGroup.get(controlName));
    }
    return '';
  }

  get isShowError(): boolean {
    const formControl = this.formControl;
    if (!!formControl) {
      return !!this.errorMessage && (formControl.dirty || formControl.touched);
    }
    return false;
  }
}
