export enum ChannelName {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  GOOGLE_ADS = 'google_ads',
  INSTAGRAM = 'instagram',
}

export enum MediaType {
  IMAGE = 'image',
  IMAGES = 'images',
  VIDEO = 'video',
}

export enum GenderType {
  BOTH = 'both',
  MALE = 'male',
  FEMALE = 'female',
}

export enum BiddingStrategyType {
  MANUAL_CPC = 'MANUAL_CPC',
  MANUAL_CPM = 'MANUAL_CPM',
  PAGE_ONE_PROMOTED = 'PAGE_ONE_PROMOTED',
  TARGET_SPEND = 'TARGET_SPEND',
  TARGET_CPA = 'TARGET_CPA',
  TARGET_ROAS = 'TARGET_ROAS',
  MAXIMIZE_CONVERSIONS = 'MAXIMIZE_CONVERSIONS',
  MAXIMIZE_CONVERSION_VALUE = 'MAXIMIZE_CONVERSION_VALUE',
  TARGET_OUTRANK_SHARE = 'TARGET_OUTRANK_SHARE',
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN',
}

export enum FacebookAdPosition {
  FEED = 'feed',
  FACEBOOK_REELS = 'facebook_reels',
  STORY = 'story',
  RIGHT_HAND_COLUMN = 'right_hand_column',
  MARKETPLACE = 'marketplace',
  VIDEO_FEEDS = 'video_feeds',
  SEARCH = 'search',
  INSTREAM_VIDEO = 'instream_video',
}

export enum InstagramAdPosition {
  STREAM = 'stream',
  REELS = 'reels',
  STORY = 'story',
  EXPLORE = 'explore',
  EXPLORE_HOME = 'explore_home',
  IG_SEARCH = 'ig_search',
}

export interface FacebookChannel {
  campaignChannelId?: string;
  campaignChannelNme: ChannelName.FACEBOOK;
  facebookChannelAdsetNme: string;
  facebookChannelAdTxt: string;
  facebookChannelSocialMedia: string;
  facebookChannelCta: string;
  facebookChannelDisplayLink: string;
  facebookChannelAdsetId?: string;
  facebookChannelMediaType: MediaType;
  facebookChannelFullscreenInd: boolean;
  facebookChannelDetailTargeting?: string;
  facebookAdsetRecommendations?: {
    title: string;
    message: string;
  }[];
  facebookChannelAdPositions: FacebookAdPosition[];
}

export interface InstagramChannel {
  campaignChannelId?: string;
  campaignChannelNme: ChannelName.INSTAGRAM;
  instagramChannelAdsetNme: string;
  instagramChannelTraffic: string;
  instagramChannelDetailTargeting?: string;
  instagramChannelAdTxt: string;
  instagramChannelSocialMedia: string;
  instagramChannelCta: string;
  instagramChannelDisplayLink: string;
  instagramChannelAdsetId?: string;
  instagramChannelMediaType: MediaType;
  instagramChannelFullscreenInd: boolean;
  instagramAdsetRecommendations?: {
    title: string;
    message: string;
  }[];
  instagramChannelAdPositions: InstagramAdPosition[];
}

export interface GoogleChannel {
  campaignChannelId?: string;
  campaignChannelNme: ChannelName.GOOGLE;
  googleChannelAdIds?: Array<string>;
  googleChannelAdGroupId?: string;
  googleChannelDsc: string;
  googleChannelDsc2: string;
  googleChannelAdGroupNme: string;
  googleChannelPathA: string;
  googleChannelPathB: string;
  googleChannelHeadline1: string;
  googleChannelHeadline2: string;
  googleChannelHeadline3: string;
  googleChannelFinalUrl: string;
  googleChannelNegativeKeywordType?: string;
  googleChannelBiddingStrategy: BiddingStrategyType;
  googleChannelExactKeywords: Array<string>;
  googleChannelBroadKeywords: Array<string>;
  googleChannelPhraseKeywords: Array<string>;
  googleChannelExactNegativeKeywords: Array<string>;
  googleChannelBroadNegativeKeywords: Array<string>;
  googleChannelPhraseNegativeKeywords: Array<string>;
  googleChannelAdserving: string;
  googleChannelAdGroupCpcBid: number;
}

export interface IChannel {
  campaignChannelId: string;
  campaignChannelNme: ChannelName;
}

export interface IChannelData {
  facebook?: FacebookChannel;
  instagram?: InstagramChannel;
  google?: GoogleChannel;
}

export interface IChannelDisplayLinks {
  facebook: string;
  instagram: string;
  googleAdWords: string;
}

export interface IChannelConnectivityStatus {
  facebook: number;
  instagram: number;
  google: number;
}

export enum CampaignType {
  PENDING = 'pending',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export enum CampaignStatusType {
  FAILED = 'failed',
  DISAPPROVED = 'disapproved',
  SUCCESS = 'success',
}

export enum CampaignOperationType {
  PAUSE = 'pause',
  STARTING = 'starting',
  CANCEL = 'cancel',
}
