import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ui-dialog-button',
  templateUrl: './ui-dialog-button.component.html',
  styleUrls: ['./ui-dialog-button.component.scss'],
})
export class UiDialogButtonComponent implements OnInit {
  @Input()
  link: string;
  @Input()
  disabled: boolean;
  @Input()
  error: boolean;
  @Input()
  dialogCloseValue: string | boolean | number;

  @Input()
  type = 'button';
  @Input()
  btnType = 'primary';
  @Input()
  style = 'square';
  @Input()
  position = 'center';

  constructor() {}

  ngOnInit() {}
}
