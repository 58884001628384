import { Injectable } from '@angular/core';
import { ApolloError } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { IReportingMetrics, LatestActivities } from '@interfaces';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as gql from './reporting.gql';
import { omitTypename } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(private apollo: Apollo) {}

  getTopPercentByCpc(campaignId: string): Observable<number> {
    return this.apollo
      .query<{ topPercentByCpc: number }>({
        fetchPolicy: 'network-only',
        query: gql.topPercentByCpc,
        variables: { campaignId },
      })
      .pipe(
        map(({ data }) => data.topPercentByCpc),
        catchError((error: ApolloError) => throwError(error)),
      );
  }

  getCampaignReportingMetrics(campaignId: string): Observable<IReportingMetrics> {
    return this.apollo
      .query<{ reportingMetrics: IReportingMetrics }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignReportingMetrics,
        variables: {
          campaignId,
        },
      })
      .pipe(
        map(({ data }) => data.reportingMetrics),
        catchError((error: ApolloError) => throwError(error)),
      );
  }

  getCampaignTemplateReportingMetrics({
    campaignTemplateId,
    startDte,
    endDte,
    locationIds,
    campaignStatuses,
  }: {
    campaignTemplateId: string;
    startDte?: string;
    endDte?: string;
    locationIds?: number[];
    campaignStatuses?: string[];
  }): Observable<IReportingMetrics> {
    return this.apollo
      .query<{ reportingMetrics: IReportingMetrics }>({
        fetchPolicy: 'network-only',
        query: gql.getCampaignTemplateReportingMetrics,
        variables: {
          campaignTemplateId,
          startDte,
          endDte,
          locationIds,
          campaignTypes: campaignStatuses,
        },
      })
      .pipe(
        map(({ data }) => data.reportingMetrics),
        catchError(() => of({} as IReportingMetrics)),
      );
  }

  getTotalMetricsByTemplate({
    startDte,
    endDte,
    locationIds,
  }: {
    startDte?: string;
    endDte?: string;
    locationIds?: number[];
  }): Observable<IReportingMetrics> {
    return this.apollo
      .query<{ reportingMetrics: IReportingMetrics }>({
        fetchPolicy: 'network-only',
        query: gql.getTotalMetricsByTemplate,
        variables: {
          startDte,
          endDte,
          locationIds,
        },
      })
      .pipe(
        map(({ data }) => data.reportingMetrics),
        catchError(() => of({} as IReportingMetrics)),
      );
  }

  getPerformanceOverviewMetrics({
    startDte,
    endDte,
  }: {
    startDte?: string;
    endDte?: string;
  }): Observable<IReportingMetrics> {
    return this.apollo
      .query<{ reportingMetrics: IReportingMetrics }>({
        fetchPolicy: 'network-only',
        query: gql.getPerformanceOverviewMetrics,
        variables: {
          startDte,
          endDte,
        },
      })
      .pipe(
        map(({ data }) => data.reportingMetrics),
        catchError(() => of({} as IReportingMetrics)),
      );
  }

  getSMBPerformanceOverviewMetrics({
    startDte,
    endDte,
  }: {
    startDte?: string;
    endDte?: string;
  }): Observable<IReportingMetrics> {
    return this.apollo
      .query<{ reportingMetrics: IReportingMetrics }>({
        fetchPolicy: 'network-only',
        query: gql.getSMBPerformanceOverviewMetrics,
        variables: {
          startDte,
          endDte,
        },
      })
      .pipe(
        map(({ data }) => data.reportingMetrics),
        catchError(() => of({} as IReportingMetrics)),
      );
  }

  getAggregatedMetrics({
    startDte,
    endDte,
    locationIds,
    channelNme,
  }: {
    startDte?: string;
    endDte?: string;
    locationIds?: number[];
    channelNme?: string;
  }): Observable<IReportingMetrics> {
    return this.apollo
      .query<{ reportingMetrics: IReportingMetrics }>({
        fetchPolicy: 'network-only',
        query: gql.getAggregatedMetrics,
        variables: {
          startDte,
          endDte,
          locationIds,
          channelNme,
        },
      })
      .pipe(
        map(({ data }) => data.reportingMetrics),
        catchError(() => of({} as IReportingMetrics)),
      );
  }

  getLatestActivities(): Observable<LatestActivities> {
    return this.apollo
      .watchQuery<{ latestActivities: LatestActivities }>({
        fetchPolicy: 'network-only',
        query: gql.getLatestActivities,
      })
      .valueChanges.pipe(
        map(({ data }) => omitTypename(data.latestActivities)),
        catchError(() => of(null)),
      );
  }
}
