import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '@utils';
import {
  IconType,
  MessagePromptDialogDataInterface as PromptDialogDataInterface,
  MessagePromptDialogActions as DialogActions,
} from '@interfaces';

@Component({
  selector: 'app-message-prompt-dialog',
  templateUrl: './message-prompt-dialog.component.html',
  styleUrls: ['../dialog-shared.scss', './message-prompt-dialog.component.scss'],
})
export class MessagePromptDialogComponent implements OnInit {
  prefix = 'promptDialog';
  closeIconPath: string;
  warningIconPath: string;

  constructor(
    private dialogRef: MatDialogRef<MessagePromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: PromptDialogDataInterface,
    private router: Router,
  ) {
    this.closeIconPath = Utils.GetIconPath(IconType.Outline, 'Close');
    this.warningIconPath = Utils.GetIconPath(IconType.Outline, 'Alert');
  }

  ngOnInit() {
    if (this.dialogData.isSucceed) {
      this.warningIconPath = Utils.GetIconPath(IconType.Outline, 'Confirmed_Active');
    } else if (this.dialogData.isEmotion) {
      this.warningIconPath = Utils.GetIconPath(IconType.Outline, 'emotion');
    }
  }

  /* tslint:disable */
  closeModal = (payload: any) => {
    this.dialogRef.close({ payload, action: DialogActions.CloseIcon });
  };

  btnClick = () => {
    if (this.dialogData.isRouterBtn) {
      this.closeModal({ payload: this.dialogData.payloadInClose, action: DialogActions.Router });
      this.router.navigate(this.dialogData.router);
    } else if (this.dialogData.isLinkBtn) {
      this.closeModal({ payload: this.dialogData.payloadInClose, action: DialogActions.Link });
      window.open(this.dialogData.link);
    } else {
      this.closeModal({ payload: this.dialogData.payloadInClose, action: DialogActions.Button });
    }
  };

  gotoRouter = (router) => {
    this.closeModal({ payload: this.dialogData.payloadInClose, action: DialogActions.SubRouter });
    this.router.navigate(router);
  };
  /* tslint:enable */
}
