<mat-dialog-content>
  <div class="loader">
    <div class="loading-anim">
      <div class="file-anim">
        <i class="material-icons file-icon">kitchen</i>
      </div>
    </div>
    <div class="icons">
      <app-ui-icon class="computer-icon" logo="computer" size="2"></app-ui-icon>
      <app-ui-icon class="facebook-icon" [logo]="currentChannel" size="2"></app-ui-icon>
    </div>
  </div>
  <div class="message-content">
    Please wait while we try to connect to {{ currentChannel }}. This can take up to five minutes.
  </div>
</mat-dialog-content>
