import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import {
  ChannelName,
  IGenderData,
  IDeviceData,
  IDeviceSummary,
  IReportingMetrics,
  IAudienceData,
  IAgeGenderSummary,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportingDataService {
  getAudienceData({
    reportingMetrics,
    potentialReach,
  }: {
    reportingMetrics: IReportingMetrics;
    potentialReach: number;
  }): IAudienceData {
    const { audienceOverview, total } = reportingMetrics;

    const audienceData = {
      totalActualReach: total.reach,
      totalPotentialReach: potentialReach,
      ageGenderSummary: this.getAgeGenderData(audienceOverview.ageGenderSummary || []),
      deviceSummary: this.getDeviceData(audienceOverview.deviceSummary || []),
      genderSummary: this.getGenderData(audienceOverview.genderSummary || []),
      regionSummary: this.getRegionData(audienceOverview.regionSummary || []),
    };

    return audienceData;
  }

  getAgeGenderData(ageGenderSummary: { channelNme: string; data: IAgeGenderSummary[] }[]): {
    [channelName: string]: { [age: string]: IGenderData<number> };
  } {
    const ageGenderData = ageGenderSummary.reduce((acc, { channelNme, data }) => {
      const ageGenderSummaryObj = {};
      const isGoogle = channelNme === ChannelName.GOOGLE;
      data.forEach((ageItem) => {
        const ageName = ageItem.age.replace('-', 'to');
        if (!ageGenderSummaryObj[ageName]) {
          ageGenderSummaryObj[ageName] = isGoogle ? { undetermined: 0 } : { male: 0, female: 0 };
        }
        const addValue = ageItem.impressions || 0;
        switch (ageItem.gender) {
          case 'male': {
            const maleImpressions = ageGenderSummaryObj[ageName].male || 0;
            ageGenderSummaryObj[ageName].male = maleImpressions + addValue;
            break;
          }
          case 'female': {
            const femaleImpressions = ageGenderSummaryObj[ageName].female || 0;
            ageGenderSummaryObj[ageName].female = femaleImpressions + addValue;
            break;
          }
          default:
            ageGenderSummaryObj[ageName]['undetermined'] =
              (ageGenderSummaryObj[ageName]['undetermined'] || 0) + addValue;
            break;
        }
      });
      return { ...acc, [channelNme]: ageGenderSummaryObj };
    }, {});

    return ageGenderData;
  }

  getDeviceData(deviceSummary: Array<IDeviceSummary>): {
    groupedByDevice: IDeviceData<number>;
    total: number;
  } {
    const defaultDeviceData: IDeviceData<number> = {
      desktop: { total: 0 },
      mobile: { ios: 0, android: 0, other: 0, total: 0 },
      tablet: { ios: 0, android: 0, total: 0 },
    };
    const deviceData: IDeviceData<number> = deviceSummary.reduce((prev, i) => {
      const acc = _.cloneDeep(prev);
      if (i.devicePlatform === 'desktop') {
        acc.desktop.total += i.impressions;
      } else if (i.devicePlatform === 'tablet') {
        acc.tablet.total += i.impressions;
      } else if (i.devicePlatform === 'mobile') {
        acc.mobile.total += i.impressions;
      } else if (i.impressionDevice === 'ipad') {
        acc.tablet.ios += i.impressions;
      } else if (i.impressionDevice === 'android_tablet') {
        acc.tablet.android += i.impressions;
      } else if (i.impressionDevice?.includes('ip') || i.impressionDevice?.includes('apple')) {
        acc.mobile.ios += i.impressions;
      } else if (i.impressionDevice?.includes('android') || i.impressionDevice?.includes('win')) {
        acc.mobile.android += i.impressions;
      } else {
        acc.mobile.other += i.impressions;
      }

      return acc;
    }, defaultDeviceData);

    let total = 0;
    Object.keys(deviceData).forEach((deviceType) => {
      const totalByDevice = _.sum(Object.values(deviceData[deviceType]));
      deviceData[deviceType].total = totalByDevice;
      total += totalByDevice;
    });

    return { groupedByDevice: deviceData, total };
  }

  getGenderData(genderSummary: Array<any>): IGenderData<number> {
    const result: IGenderData<number> = {
      male: 0,
      female: 0,
      undetermined: 0,
    };
    genderSummary.forEach((genderItem) => {
      let addValue = 0;
      const gender = _.lowerCase(genderItem.gender || '');
      try {
        addValue = parseInt(genderItem['impressions'], 10);
      } catch (error) {}
      switch (gender) {
        case 'male': {
          result.male = result.male + addValue;
          break;
        }
        case 'female': {
          result.female = result.female + addValue;
          break;
        }
        case 'undetermined':
        default: {
          result.undetermined = result.undetermined + addValue;
          break;
        }
      }
    });
    return result;
  }

  getRegionData(regionSummary: Array<any>): Array<{
    name: string;
    value: number;
    percentage: number;
  }> {
    let result = [];
    let totalRegion = 0;
    result = regionSummary.map((item) => {
      let value = 0;
      try {
        value = parseInt(item.impressions, 10);
      } catch (error) {}
      totalRegion = totalRegion + value;
      return {
        name: item.region,
        value,
        percentage: 0,
      };
    });
    result = result.map((item) => {
      item.percentage = (item.value / totalRegion).toFixed(4);
      return item;
    });
    return result;
  }
}
