<div
  class="flex"
  [class.flex-justify-center]="position === 'center'"
  [class.flex-justify-start]="['start', 'left'].includes(position)"
  [class.flex-justify-end]="['end', 'right'].includes(position)"
>
  <button 
    mat-button
    [ngClass]="{
      'error-btn':error,
      'primary-btn':btnType ==='primary',
      'secondary-btn':btnType==='secondary',
      'round-btn':style==='round'
    }"
    [disabled]="disabled"
    [type]="type"
    (click)="doClick($event)"
  >
    <span class="btn-text">
      <ng-content></ng-content>
    </span>
  </button>
</div>

