import { Component, OnInit, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-ui-tip',
  templateUrl: './ui-tip.component.html',
  styleUrls: ['./ui-tip.component.scss'],
})
export class UiTipComponent implements OnInit {
  private staticIconUrl = './assets/icons';

  @Input()
  tip: string;
  @Input()
  position: TooltipPosition;
  @Input()
  showDelay: number;
  @Input()
  hideDelay: number;
  @Input()
  size = 1;
  @Input()
  style: any;
  @Input()
  type = 'normal';

  constructor() {}

  ngOnInit() {}

  get iconUrl(): string {
    let result = '';
    const staticUrl = this.staticIconUrl;
    const iconName = 'Information';
    const fillOrOutline = 'outline';
    let infoType = '';
    switch (this.type) {
      case 'warning': {
        infoType = '_active';
        break;
      }
      case 'normal':
      default:
        break;
    }
    result = `${staticUrl}/${fillOrOutline}/${iconName}${infoType}.svg`;
    return result;
  }

  get iconStyle(): any {
    const normalSize = `${this.size}em`;
    let result = { width: normalSize, height: normalSize };
    const inputStyle = this.style;
    if (!!inputStyle) {
      result = Object.assign({}, result, inputStyle);
    }
    return result;
  }
}
