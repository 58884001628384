import { gql } from 'apollo-angular';

const notificationFragment = gql`
  fragment notification on NotificationType {
    notificationId
    notificationType
    notificationContent
    notificationIsRead
    notificationCreatedAt
    notificationUpdatedAt
  }
`;

export const notificationForCurrentUser = gql`
  query notificationForCurrentUser {
    notificationForCurrentUser {
      ...notification
    }
  }
  ${notificationFragment}
`;

export const checkTokenNotification = gql`
  query checkTokenNotification {
    checkTokenNotification {
      channel
      type
      count
      content
      failedAccountStatus
    }
  }
`;

export const readNotification = gql`
  query readNotification($notificationId: ID) {
    readNotification(notificationId: $notificationId)
  }
`;

export const networkActivities = gql`
  query networkActivities {
    networkActivities {
      newAds {
        campaignTemplateNme
        campaignId
        campaignChannelNme
        locationNme
        timestamp
      }
      firstAdPlaced {
        campaignTemplateNme
        campaignId
        campaignChannelNme
        locationNme
        timestamp
      }
      hit2kImpressions {
        campaignTemplateNme
        campaignId
        campaignChannelNme
        impressions
        locationNme
        timestamp
      }
      hit5kImpressions {
        campaignTemplateNme
        campaignId
        campaignChannelNme
        impressions
        locationNme
        timestamp
      }
      hit10kImpressions {
        campaignTemplateNme
        campaignId
        campaignChannelNme
        impressions
        locationNme
        timestamp
      }
      highestCtrAd {
        campaignTemplateNme
        campaignId
        campaignChannelNme
        locationNme
        ctr
        timestamp
      }
      adsConversion {
        campaignTemplateNme
        campaignId
        campaignChannelNme
        locationNme
        conversion
        timestamp
      }
      newUsers {
        userFirstNme
        userLastNme
        userMiddleNme
        locationNme
        timestamp
      }
    }
  }
`;

export const adSubscriptionNotifications = gql`
  query adSubscriptionNotifications {
    adSubscriptionNotifications {
      ...notification
    }
  }
  ${notificationFragment}
`;

export const getCampaignTemplateRequestById = gql`
  query getCampaignTemplateRequestById($campaignTemplateRequestId: ID!) {
    getCampaignTemplateRequestById(campaignTemplateRequestId: $campaignTemplateRequestId) {
      campaignTemplateRequestId
      campaignTemplateRequestChannel
      campaignTemplateRequestDetails
      campaignTemplateRequestMediaType
      campaignTemplateRequestImageUrls
      campaignTemplateRequestAcceptStatus
      campaignTemplateRequestNeedSendEmail
    }
  }
`;

export const campaignTemplateRequestNotification = gql`
  query campaignTemplateRequestNotification {
    campaignTemplateRequestNotification {
      ...notification
    }
  }
  ${notificationFragment}
`;
