/* tslint:disable */
export interface GraphqlError extends Error {}
/* tslint:enable */

export interface GraphqlErrorCtor {
  new (msg?: string): GraphqlError;
  data: any;
}

function GraphqlErrorImpl(this: any, msg: string) {
  Error.call(this);
  this.message = msg || 'graphql error occurred.';
  this.name = 'GraphqlError';
  return this;
}

GraphqlErrorImpl.prototype = Object.create(Error.prototype);

export const GraphqlError: GraphqlErrorCtor = GraphqlErrorImpl as any;
