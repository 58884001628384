import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent, SimpleLayoutComponent } from './core/layout';
import {
  AccountConnectionGuard,
  AdminGuard,
  GmbAccessGuard,
  ReadOnlyAccessGuard,
  RunAdAccessGuard,
  UserRouteGuard,
} from '@guards';
import { PoliciesEnum } from '@interfaces';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/callback/callback.module').then((m) => m.CallbackModule),
    canActivate: [],
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [],
  },
  {
    path: 'register',
    component: SimpleLayoutComponent,
    loadChildren: () => import('./pages/sign-up/sign-up.module').then((m) => m.SignUpModule),
  },
  {
    path: 'admin',
    component: SimpleLayoutComponent,
    loadChildren: () => import('./pages/admin-portal/admin-portal.module').then((m) => m.AdminPortalModule),
    canActivate: [AuthGuard, UserRouteGuard, AdminGuard],
    data: {
      policyIds: [PoliciesEnum.navAdminPortal],
    },
  },
  {
    path: 'agency',
    component: SimpleLayoutComponent,
    canActivate: [AuthGuard, UserRouteGuard],
    loadChildren: () => import('./pages/agency-portal/agency-portal.module').then((m) => m.AgencyPortalModule),
  },
  {
    path: 'app',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard, UserRouteGuard],
    children: [
      {
        path: 'performance-overview',
        canActivate: [AccountConnectionGuard],
        loadChildren: () =>
          import('./pages/performance-overview/performance-overview.module').then((m) => m.PerformanceOverviewModule),
        data: {
          policyIds: [PoliciesEnum.navZorPerformanceOverview, PoliciesEnum.navSMBPerformanceOverview],
        },
      },
      {
        path: 'create-campaign',
        loadChildren: () =>
          import('./pages/create-template/create-template.module').then((m) => m.CreateTemplateModule),
        canActivate: [AccountConnectionGuard],
        data: {
          policyIds: [PoliciesEnum.navZorCreateANewTemplate, PoliciesEnum.navSMBCreateANewTemplate],
        },
      },
      {
        path: 'create-campaign/:id',
        loadChildren: () =>
          import('./pages/create-template/create-template.module').then((m) => m.CreateTemplateModule),
        canActivate: [AccountConnectionGuard],
        data: {
          policyIds: [PoliciesEnum.navZorCreateANewTemplate, PoliciesEnum.navSMBCreateANewTemplate],
        },
      },
      {
        path: 'template-management',
        loadChildren: () =>
          import('./pages/template-management/template-management.module').then((m) => m.TemplateManagementModule),
        data: {
          policyIds: [PoliciesEnum.navManageAdTemplates],
        },
      },
      {
        path: 'ad-aggregated-results',
        loadChildren: () =>
          import('./pages/ad-aggregated-results/ad-aggregated-results.module').then((m) => m.AdAggregatedResultsModule),
        data: {
          policyIds: [PoliciesEnum.navAdResultsAggregatedMetrics],
        },
      },
      {
        path: 'location-management',
        loadChildren: () =>
          import('./pages/location-management/location-management.module').then((m) => m.LocationManagementModule),
        data: {
          policyIds: [PoliciesEnum.navLocationManagement],
        },
      },
      {
        path: 'group-management',
        loadChildren: () =>
          import('./pages/group-management/group-management.module').then((m) => m.GroupManagementModule),
        data: {
          policyIds: [PoliciesEnum.navManageTags],
        },
      },
      {
        path: 'home',
        canActivate: [AccountConnectionGuard],
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        data: {
          policyIds: [PoliciesEnum.navZeePerformanceOverview],
        },
      },
      {
        path: 'ad-results',
        loadChildren: () => import('./pages/ad-results/ad-results.module').then((m) => m.AdResultsModule),
        data: {
          policyIds: [PoliciesEnum.navZeeAdResults],
        },
      },
      // hide until subscriptions are reworked
      // {
      //   path: 'ad-subscription',
      //   loadChildren: () =>
      //     import('./pages/ad-subscription/ad-subscription.module').then((m) => m.AdSubscriptionModule),
      //   canActivateChild: [ReadOnlyAccessGuard],
      //   data: {
      //     policyIds: [PoliciesEnum.navManageSubscriptions],
      //   },
      // },
      // common
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
        data: {
          policyIds: [PoliciesEnum.navZorAccount, PoliciesEnum.navZeeAccount],
        },
      },
      {
        path: 'ad-spend',
        loadChildren: () => import('./pages/ad-spend/ad-spend.module').then((m) => m.AdSpendModule),
        data: {
          policyIds: [PoliciesEnum.navAdSpend],
        },
      },
      {
        path: 'ad-results-details/:id',
        loadChildren: () =>
          import('./pages/ad-results-details/ad-results-details.module').then((m) => m.AdResultsDetailsModule),
        data: {
          policyIds: [PoliciesEnum.navLocalAdResults],
        },
      },
      {
        path: 'google-my-business-management',
        loadChildren: () =>
          import('./pages/google-my-business/google-my-business.module').then((m) => m.GoogleMyBusinessModule),
        canActivate: [GmbAccessGuard],
        data: {
          defaultAccess: true,
        },
      },
      {
        path: 'run-ad',
        loadChildren: () => import('./pages/run-ad/run-ad.module').then((m) => m.RunAdModule),
        data: {
          policyIds: [PoliciesEnum.navZorRunANewAd, PoliciesEnum.navZeeRunANewAd],
        },
      },
      {
        path: 'run-ad-customization/:id',
        loadChildren: () =>
          import('./pages/run-ad-customization/run-ad-customization.module').then((m) => m.RunAdCustomizationModule),
        canActivate: [RunAdAccessGuard],
        data: {
          policyIds: [PoliciesEnum.runANewAd],
        },
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./pages/user-management/user-management.module').then((m) => m.UserManagementModule),
        data: {
          policyIds: [PoliciesEnum.navUserManagement],
        },
      },
    ],
  },
  {
    path: 'end-user-license-agreement',
    loadChildren: () =>
      import('./pages/end-user-license-agreement/end-user-license-agreement.module').then(
        (m) => m.EndUserLicenseAgreementModule,
      ),
  },
  {
    path: '**',
    component: MainLayoutComponent,
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
    pathMatch: 'full',
    data: {
      defaultAccess: true,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 0],
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
