import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocationService, ManageUserSocialAccountsService as Oauth, UserService } from '@services';
import { map } from 'rxjs/operators';
import { AccountConnectStatus, ILocation } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RunAdAccessGuard implements CanActivate {
  private previousRouterUrl: string;
  private runAdRoute = '/app/run-ad';
  private accountRoute = '/app/account';
  private channel: string;
  private isReplace: boolean;
  private locationRequest$: Observable<ILocation[]>;

  constructor(
    private router: Router,
    private oauthConnectService: Oauth,
    private locationService: LocationService,
    private userService: UserService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const channel = next && next.queryParamMap && next.queryParamMap.get('channel');
    const replace = next && next.queryParamMap && next.queryParamMap.get('replace');
    this.isReplace = replace === 'true';
    if (!replace) {
      this.previousRouterUrl = this.router.url;
    }
    return new Promise((resolve: (canActivate: boolean) => void) => {
      if (!channel) {
        this.handleError(resolve);
        return;
      }
      this.channel = channel;
      this.oauthConnectService.getAccountsConnectedStatus().subscribe((status: AccountConnectStatus) => {
        if (!Oauth.CheckAccountConnected(status)) {
          this.oauthConnectService.promptForConnect(this.accountRoute);
          this.handleError(resolve);
          return;
        }
        if (channel && !status[channel]) {
          this.oauthConnectService.promptForSpecificChannelConnect(this.accountRoute, channel);
          this.handleError(resolve);
          return;
        }
        if (this.userService.isZOWIView()) {
          this.locationRequest$ = this.locationService
            .getAllLocationsByViewType()
            .pipe(map((data: any) => data.locations));
        } else if (this.userService.isCOLOView() || this.userService.isZEWIView()) {
          const userProfile = this.userService.getCachedUserProfile();
          const orgId = userProfile.organization.organizationId;
          this.locationRequest$ = this.locationService.getLocationsByOrgId(orgId);
        } else {
          this.validateActions(resolve);
        }
        this.checkPermission(resolve);
      });
    });
  }

  private checkPermission(resolve: (canActivate: boolean) => void): void {
    if (this.locationRequest$) {
      this.locationRequest$.subscribe((locations: ILocation[]) => {
        if (!locations.length) {
          this.oauthConnectService.promptForNoLocationsInWideUser();
          this.handleError(resolve);
        } else {
          this.validateActions(resolve);
        }
      });
    }
  }

  private validateActions(resolve: (canActivate: boolean) => void): void {
    this.oauthConnectService.validationForCampaignActions(
      this.channel,
      this.accountRoute,
      () => {
        resolve(true);
      },
      () => {
        this.handleError(resolve);
      },
    );
  }

  private handleError(resolve: (canActivate: boolean) => void): void {
    resolve(false);
    if (this.isReplace) {
      this.router.navigate([this.previousRouterUrl === '/' ? this.runAdRoute : this.previousRouterUrl]);
    }
  }
}
