<div [attr.id]="prefix" class="dialog-container">
  <div [attr.id]="prefix + 'Close'" class="dialog-close-container interaction"
       *ngIf="dialogData.withClose" (click)="closeModal(dialogData.payloadInClose)">
    <img [src]=" closeIconPath " alt="close icon">
  </div>

  <div [attr.id]="prefix + 'Icon'" class="dialog-icon-container" *ngIf="dialogData.withIcon" >
    <img [src]=" warningIconPath " alt="warning icon">
  </div>

  <div [attr.id]="prefix + 'Content'" class="dialog-content-container">
    <ng-template [ngIf]="dialogData.content">
      <div [attr.id]="prefix + 'ContentSection' + pos" *ngFor="let section of dialogData.content; let pos=index">
        <ng-template ngFor let-ele [ngForOf]="section">
          <ng-template [ngIf]="ele.content">
            <ng-template [ngIf]="ele.isNormal">
              <span class="dialog-content">{{ ele.content }}</span>
            </ng-template>

            <ng-template [ngIf]="ele.isLink">
              <a class="dialog-content interaction" [href]="ele.href" target="_blank">{{ ele.content }}</a>
            </ng-template>

            <ng-template [ngIf]="ele.isRouter">
              <button class="dialog-content interaction" (click)="gotoRouter(ele.routerString)">{{ ele.content }}</button>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
  </div>

  <div [attr.id]="prefix + 'BtnContainer'" class="dialog-actions">
    <button type="button" class="app-primary-btn" (click)="btnClick()">
      <span class="btn-label">{{ dialogData.btnString || 'Dismiss'}}</span>
    </button>
  </div>
</div>
