<li class="list-item" *ngIf="listData">
  <div class="item-text">
    <ng-template [ngIf]="subject === 'campaignTemplateNme'">
      <div class="campaign-channel-title" *ngIf="subject === 'campaignTemplateNme'">
        <img *ngIf="!!channelNme" src="assets/icons/logo/{{channelNme}}.svg" [alt]="channelNme" class="channel-icon">
        <span>{{listData[subject]}}</span>
      </div>
      <div *ngIf="displayRate">
        <app-ui-star-rating [stars]="listData.averageRate" [reviews]="listData.rateCount" [templateId]="listData.campaignTemplateId"></app-ui-star-rating>
      </div>
    </ng-template>
    <ng-template [ngIf]="subject !== 'campaignTemplateNme' && subject !== 'notification'">
      <strong class="main-text">{{ listData[subject] }}
        <span *ngIf="value==='spend'&&locationNme">&nbsp;-&nbsp;{{locationNme}}</span>
      </strong>
    </ng-template>
  </div>
  <div class="item-value">
    <span *ngIf="value==='spend'">{{ listData[value] | currency:'USD':'symbol':'.0-2'}}</span>
    <span *ngIf="value==='averageRate'">{{listData[value]}}</span>
    <ng-template [ngIf]="value==='run'&&!isPending">
      <span class="kpi-value">
        <span class="text-style-value">
          {{listData[value]|number:'.0-2'}}x
        </span>
      </span>
      <span class="kpi-title">Run</span>
    </ng-template>
    <ng-template [ngIf]="value==='ctr'&&!isPending">
      <span class="kpi-value value-type-percentage">
        <span class="text-style-value">
          {{listData[value]|number:'.0-2'}}
        </span>
        <span class="text-style-symbol">%</span>
      </span>
      <span class="kpi-title">CTR</span>
    </ng-template>
  </div>
</li>
