import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

interface IDateObject {
  year: number;
  month: number;
  day: number;
}

@Injectable({
  providedIn: 'root',
})
export class DatepickerService {
  public static DateReg = /^\d{4}-(1|1[0-2]|[2-9]|0[1-9])-([1-3]|[4-9]|0[1-9]|[12][0-9]|3[01])$/;

  constructor() {}

  public static GetValidDate(year: number, month: number, date: number): number {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return date > 31 ? 31 : date;
      case 4:
      case 6:
      case 9:
      case 11:
        return date > 30 ? 30 : date;
      case 2:
        const max = 0 === year % 400 || (0 !== year % 100 && 0 === year % 4) ? 29 : 28;
        return date > max ? max : date;
    }
    return date;
  }

  public static ValidateStartDate(input: string, today: Date, minDate: IDateObject, maxDate: IDateObject) {
    const todayStr = DatepickerService.BuildDateString(today);
    let startDate: string | IDateObject = DatepickerService.DateReg.test(input) ? input : null;
    if (startDate) {
      startDate = DatepickerService.BuildStartDate(startDate, todayStr, minDate, maxDate);
    }
    if (startDate) {
      const tmp = startDate.split('-');
      const year = Number(tmp[0]);
      const month = Number(tmp[1]);
      const day = DatepickerService.GetValidDate(year, month, Number(tmp[2]));
      startDate = { year, month, day };
    }

    return startDate;
  }

  public static BuildDateString(date: Date): string {
    // date string format is `YYYY-MM-DD`
    const [year, month, day] = [date.getFullYear(), Number(date.getMonth()) + 1, date.getDate()];
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }

  public static BuildStartDate(input: string, todayStr: string, minDate: IDateObject, maxDate: IDateObject): string {
    const min = DatepickerService.BuildDateString(new Date(minDate.year, Number(minDate.month) - 1, minDate.day));
    const max = DatepickerService.BuildDateString(new Date(maxDate.year, Number(maxDate.month) - 1, maxDate.day));
    if (todayStr < min) {
      input = input < min ? min : input > max ? max : input;
    } else if (todayStr > max) {
      input = null;
    } else {
      input = input < todayStr ? todayStr : input > max ? max : input;
    }
    return input;
  }

  static ValidateEndDate(input: string, startDate: IDateObject, maxDate: IDateObject) {
    let endDate: string | IDateObject = DatepickerService.DateReg.test(input) ? input : null;
    if (endDate) {
      endDate = DatepickerService.BuildEndDate(endDate, startDate, maxDate);
    }
    if (endDate) {
      const tmp = endDate.split('-');
      const year = Number(tmp[0]);
      const month = Number(tmp[1]);
      const day = DatepickerService.GetValidDate(year, month, Number(tmp[2]));
      endDate = { year, month, day };
    }

    return endDate;
  }

  public static BuildEndDate(input: string, startDate: IDateObject, maxDate: IDateObject): string {
    const min = DatepickerService.BuildDateString(new Date(startDate.year, Number(startDate.month) - 1, startDate.day));
    const max = DatepickerService.BuildDateString(new Date(maxDate.year, Number(maxDate.month) - 1, maxDate.day));
    return input > max ? max : input < min ? min : input;
  }

  public static BuildDateObjectFromDateString(input: string): IDateObject {
    if ('string' === typeof input) {
      const tmp = input.split('-');
      return Array.isArray(tmp) && tmp.length === 3
        ? { year: Number(tmp[0]), month: Number(tmp[1]), day: Number(tmp[2]) }
        : null;
    }
    return null;
  }

  public getNgbDateFromDate(input: string | Date): NgbDate {
    if (!input) {
      return null;
    }
    const dateTime = new Date(input);
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth();
    let date = dateTime.getDate();
    if (dateTime.getUTCHours() === 0) {
      year = dateTime.getUTCFullYear();
      month = dateTime.getUTCMonth();
      date = dateTime.getUTCDate();
    }
    return new NgbDate(year, month + 1, date);
  }

  public getStringFromStrOrDate(input: string | Date): string {
    if (!input) {
      return null;
    }
    const dateTime = new Date(input);
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth();
    let date = dateTime.getDate();
    if (dateTime.getUTCHours() === 0) {
      year = dateTime.getUTCFullYear();
      month = dateTime.getUTCMonth();
      date = dateTime.getUTCDate();
    }
    return this.getDateStrFromNgbDate(new NgbDate(year, month + 1, date));
  }

  public getDateStrFromNgbDate(input: NgbDate): string {
    if (input) {
      const { year, month, day } = input;
      return moment({ year, month: month - 1, day }).format('YYYY-MM-DD');
    }
  }

  public newNgbDate(input: NgbDate): NgbDate {
    if (input) {
      const { year, month, day } = input;
      if ([year, month, day].every((value) => typeof value === 'number')) {
        return new NgbDate(year, month, day);
      }
    }
    return null;
  }

  public getAutoFullYear(year: string) {
    const date = new Date();
    const century = Math.floor(date.getUTCFullYear() / 100);
    switch (year.length) {
      case 1: {
        year = `${century}0${year}`;
        break;
      }
      case 2: {
        year = `${century}${year}`;
        break;
      }
      case 3: {
        year = `${Math.floor(century / 10)}${year}`;
        break;
      }
      default:
        break;
    }
    return year;
  }
}
