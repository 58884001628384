import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { combineLatest, of, Subject, Observable } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { LoadingStatusService, OrganizationService, PolicyService, UserContextService, UserService } from '@services';
import { INetworkInfo, IUser, NavigationMenu } from '@interfaces';

type NavigationMenus = {
  sidebar: NavigationMenu[];
  dropdown: NavigationMenu[];
};

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  userProfile?: IUser = null;

  get isZor(): boolean {
    return this.userService.isZORView();
  }

  get isOrg(): boolean {
    return this.userService.isOrgUser();
  }

  pageNavigationList: NavigationMenu[] = [];
  dropdownNavs: NavigationMenu[] = [];

  networkSizeNum = 0;
  ownedLocationNum = 0;

  isScroll = false;
  isOpen = false;

  isBottom: boolean;
  isFirstScroll: boolean;

  get currentUrl(): string {
    return this.router.url;
  }

  constructor(
    private userService: UserService,
    private router: Router,
    private organizationService: OrganizationService,
    private policyService: PolicyService,
    private loadingStatus: LoadingStatusService,
    private userContext: UserContextService,
  ) {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((event) => {
      this.handleNavigation(event);
    });
  }

  ngOnInit() {
    this.initializeDeviceInfo();

    const user$ = this.getUserInfo().pipe(takeUntil(this.ngUnsubscribe$), shareReplay());
    user$.subscribe({
      next: (user) => {
        this.userProfile = user;
      },
    });

    user$.pipe(switchMap((user) => this.getNavigationMenuItems(user))).subscribe({
      next: (navigationMenu) => {
        this.pageNavigationList = this.activateNavigationMenu(navigationMenu.sidebar);
        this.dropdownNavs = this.activateNavigationMenu(navigationMenu.dropdown);
      },
    });

    user$.pipe(switchMap((user) => this.getNetworkInfo(user))).subscribe({
      next: (networkInfo) => {
        this.setNetworkInfo(networkInfo);
      },
    });
  }

  private handleNavigation(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loadingStatus.isLoading$.next(true);
    }
    if (event instanceof NavigationError || event instanceof NavigationCancel) {
      this.loadingStatus.isLoading$.next(false);
    }
    if (event instanceof NavigationEnd) {
      this.loadingStatus.isLoading$.next(false);
      this.isOpen = false;
      this.isFirstScroll = true;

      this.pageNavigationList = this.activateNavigationMenu(this.pageNavigationList);
      this.dropdownNavs = this.activateNavigationMenu(this.dropdownNavs);
    }
  }

  private getUserInfo(): Observable<IUser | null> {
    return this.userService.currentUser$;
  }

  private getNavigationMenuItems(userProfile: IUser | null): Observable<NavigationMenus> {
    const emptyNavigationMenu: NavigationMenus = {
      sidebar: [],
      dropdown: [],
    };

    if (!userProfile || !userProfile.rbacAssignmentId) {
      return of(emptyNavigationMenu);
    }

    return combineLatest([of(userProfile), this.policyService.getRoleById(userProfile.rbacAssignmentId)]).pipe(
      map(([user, role]) => {
        if (user && role) {
          return {
            sidebar: this.policyService.getNavMenus(user, 'sidebar'),
            dropdown: this.policyService.getNavMenus(user, 'dropdown'),
          };
        }
        return emptyNavigationMenu;
      }),
    );
  }

  private getNetworkInfo(user: IUser | null): Observable<INetworkInfo | null> {
    const isZORView = user && this.userService.isZORView(user, false);
    if (!isZORView) {
      return of(null);
    }

    return this.organizationService.getNetworkInfo();
  }

  private setNetworkInfo(networkInfo: INetworkInfo): void {
    if (networkInfo) {
      this.networkSizeNum = networkInfo.networkSizeNum;
      this.ownedLocationNum = networkInfo.ownedLocationNum;
    } else {
      this.networkSizeNum = 0;
      this.ownedLocationNum = 0;
    }
  }

  private initializeDeviceInfo(): void {
    const localDeviceInfo = localStorage.getItem('deviceInfo');
    if (!localDeviceInfo) {
      const u = navigator.userAgent;
      const deviceInfo = {
        trident: u.indexOf('Trident') > -1,
        presto: u.indexOf('Presto') > -1,
        webKit: u.indexOf('AppleWebKit') > -1,
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1,
        mobile: !!u.match(/AppleWebKit.*Mobile.*/),
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
        iPhone: u.indexOf('iPhone') > -1,
        iPad: u.indexOf('iPad') > -1,
        webApp: u.indexOf('Safari') === -1,
        weixin: u.indexOf('MicroMessenger') > -1,
        qq: !!u.match(/\sQQ/i),
      };
      localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
    }
  }

  private activateNavigationMenu(navigationMenu: NavigationMenu[]): NavigationMenu[] {
    return navigationMenu.map((nav) => {
      return {
        ...nav,
        isActive: this.currentUrl.indexOf(nav.navPath) > -1,
      };
    });
  }

  onScrollChange(event) {
    let el;
    if (!!event.target.documentElement.scrollTop) {
      el = event.target.documentElement;
    }
    if (!!event.target.body.scrollTop) {
      el = event.target.body;
    }
    if (el) {
      this.updateElement(el);
    }
  }

  private updateElement(el): void {
    if (this.isFirstScroll && !!el.scrollTop) {
      el.scrollTop = 0;
      this.isFirstScroll = false;
    }
    // header scroll
    if (el.scrollTop < 200 && this.isScroll) {
      this.isScroll = false;
    }
    if (el.scrollTop >= 200 && !this.isScroll) {
      this.isScroll = true;
    }
    // adjust feedback button's position when hit the bottom of the page
    const axisYRemainValue = el.scrollHeight - el.clientHeight - el.scrollTop;
    this.isBottom = axisYRemainValue < 20;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onNavigationClick($event: NavigationMenu) {
    this.isOpen = false;

    if ($event.navPath === '/agency' || $event.navPath === '/admin') {
      this.userContext.remove();
    }
  }
}
