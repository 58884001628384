import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationsService {
  constructor() {}

  getValidationErrorMessage(formControl: AbstractControl, indexInArray?: number): string {
    let result = '';
    if (!!formControl) {
      const errorNames = !!formControl.errors ? Object.keys(formControl.errors) : [];
      if (errorNames && errorNames.length > 0) {
        const errorName = errorNames[0];
        const error = formControl.errors[errorName];
        switch (errorName) {
          case 'googlePathError':
            result = `Enter a value for the "pathA" field or use the "pathA" field only`;
            break;
          case 'maxlength':
          case 'maxLength': {
            const overLength = error.actualLength - error.requiredLength;
            result = `${overLength} character exceeded.`;
            break;
          }
          case 'excludedRadius': {
            result = `Can not be the same as included radius`;
            break;
          }
          case 'pattern': {
            result = `Invalid format`;
            break;
          }
          case 'phoneNumber': {
            result = 'The format should be (###)###-####';
            break;
          }
          case 'locationZip': {
            result = '5 digits and can not be 00000';
            break;
          }
          case 'locationWebsite': {
            result = 'Please make sure your URL begins with "http(s)://" and include your domain name';
            break;
          }
          case 'required': {
            result = `Required Field.`;
            break;
          }
          case 'headlinesRequired':
          case 'newsFeedlinkDscsRequired': {
            if (indexInArray === 0) {
              result = `Required Field.`;
            }
            break;
          }
          case 'max':
          case 'maxLengthArray': {
            result = `Up to ${error.max}.`;
            break;
          }
          case 'min':
          case 'minLengthArray': {
            result = `At least ${error.min}.`;
            break;
          }
          case 'minAgeError':
          case 'maxAgeError': {
            result = `Min or Max age Error.`;
            break;
          }
          case 'patternError': {
            result = `Please make sure your URL begins with "http(s)://" and include your domain name.`;
            break;
          }
          case 'dscError': {
            result = `Please make sure your description not include any URL.`;
            break;
          }
          case 'pathPatternError': {
            result = `There are illegal characters in the string.`;
            break;
          }
          case 'keywordPatternError': {
            result =
              'The keyword length should not exceed 80 characters and should only contain alphanumeric characters and spaces.';
            break;
          }
          case 'emailSameError':
          case 'emailDuplicated': {
            result = `Email is already taken.`;
            break;
          }
          case 'orgNameDuplicated': {
            result = `Organization already exists.`;
            break;
          }
          case 'headlineDuplicated': {
            result = 'Headlines are identical. Enter a different headline.';
            break;
          }
          case 'descriptionDuplicated': {
            result = 'Descriptions are identical. Enter a different description.';
            break;
          }
          case 'valueDuplicated': {
            result = 'Values are identical. Enter a different value.';
            break;
          }
          case 'inArrayMaxLength': {
            if (error.isArray) {
              const errorItem = error.errArray.find((item) => item.indexInArray === indexInArray);
              if (!!errorItem) {
                const overLength = errorItem.actualLength - errorItem.requiredLength;
                result = `${overLength} character exceeded.`;
              }
            } else {
              result = `Data error.`;
            }
            break;
          }
          case 'addressValidationError': {
            result = `Please input a correct address.`;
            break;
          }
          case 'budgetSetError': {
            result = `Your budget must be greater than current spend( $${error.currentSpend} ).`;
            break;
          }
          case 'checkLocationGroupExists': {
            result = 'Label name is already taken.';
            break;
          }
          case 'locationValidationError': {
            result = 'Location address not exists.';
            break;
          }
          case 'locationZipOrStNumberError': {
            result = 'Zip or street number is required.';
            break;
          }
          case 'locationApiError': {
            result = 'Google Map API is broken.';
            break;
          }
          case 'keywordDuplicateError': {
            result = `Keywords ${error.join(',')} are inputed already, please remove.`;
            break;
          }
          case 'scheduleValidError': {
            if (error === 'timeValidError') {
              result = `End time is earlier than start time`;
            } else if (error === 'overlappingTimeError') {
              result = `Times can't overlap. Please edit your schedule.`;
            } else {
              result = `Required time or days.`;
            }
            break;
          }
          case 'maxWordsCount': {
            result = `Keyword cannot contain more than ${error.maxCount} words.`;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    return result;
  }
}
