<form class="date-picker-container font-color-gray" [ngStyle]="{width: width, 'max-width': maxWidth}"
  [class.input-error]="validationError">
  <div class="date-input-container start-inupt-content">
    <div class="date-input-content">
      <input type="number" placeholder="MM" [value]="fromDate?.month | number:'2.0-0'"
        (focus)="startInput($event, 'fromDate')" (blur)="endInput($event, 'month')"
        (keydown)="checkInputValue($event)"/>
      /
      <input type="number" placeholder="DD" [value]="fromDate?.day | number:'2.0-0'"
        (focus)="startInput($event, 'fromDate')" (blur)="endInput($event, 'day')" (keydown)="checkInputValue($event)" />
      /
      <input type="number" placeholder="YY"
        [value]="fromDate && fromDate.year ? (fromDate.year % 100) : '' | number:'2.0-0'"
        (focus)="startInput($event, 'fromDate')" (blur)="endInput($event, 'year')"
        (keydown)="checkInputValue($event)" />
    </div>
    <div class="date-picker-toogle-icon">
      <app-ui-icon #toggleIcon
        [fillIcon]="isCalendarOpen && selectDateType === 'fromDate' ? 'Calendar_active' : 'calendar'"
        (iconClick)="toggleCalendar('fromDate')">
      </app-ui-icon>
    </div>
  </div>

  <div class="date-input-container start-inupt-content">
    <div class="date-input-content">
      <input type="number" placeholder="MM" [value]="toDate?.month | number:'2.0-0'"
        (focus)="startInput($event, 'toDate')" (blur)="endInput($event, 'month')" (keydown)="checkInputValue($event)" />
      /
      <input type="number" placeholder="DD" [value]="toDate?.day | number:'2.0-0'"
        (focus)="startInput($event, 'toDate')" (blur)="endInput($event, 'day')" (keydown)="checkInputValue($event)" />
      /
      <input type="number" placeholder="YY" [value]="toDate && toDate.year ? (toDate.year % 100) : '' | number:'2.0-0'"
        (focus)="startInput($event, 'toDate')" (blur)="endInput($event, 'year')" (keydown)="checkInputValue($event)" />
    </div>
    <div class="date-picker-toogle-icon">
      <app-ui-icon #toggleIcon
        [fillIcon]="isCalendarOpen && selectDateType === 'toDate' ? 'Calendar_active' : 'calendar'"
        (iconClick)="toggleCalendar('toDate')">
      </app-ui-icon>
    </div>
  </div>
</form>


<div class="date-picker-control-container">
  <form class="date-picker-control-content" [class.calender-top]="position === 'top'" [class.input-error]="activeValidation">
    <input class="date-picker-controller-input"
           ngbDatepicker name="dp" navigation="arrows" outsideDays="hidden"
           #datepicker="ngbDatepicker"
           [markDisabled]="isDisable.bind(this)"
           outsideDays="visible"
           [autoClose]="'outside'"
           [dayTemplate]="customDay"
           [startDate]="fromDate!"
           [displayMonths]="2"
           [disabled]="disabled"
           [restoreFocus]="toggleIcon"
           (closed)="doAfterClosed()"
           (dateSelect)="onDateSelection($event)"/>
  </form>
</div>
<p *ngIf="validationError" class="text-red text-s">{{ errorMessage }}</p>

<ng-template #customDay let-date let-selected="selected" let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.disable-date]="isDisable(date)"
    [class.range]="isRange(date)" [class.faded]="isInside(date)" (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
