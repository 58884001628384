import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as gql from './organization.gql';
import {
  IOrganization,
  INetworkInfo,
  IOrganizationUpdate,
  IOrganizationInput,
  IOrgAccountCreateInput,
  IOrgAccountUpdateInput,
  OrganizationType,
} from '@interfaces';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { sortBy, upperFirst } from 'lodash';
import { UtilsService } from '../utils.service';
import { UserService } from '../user/user.service';

interface INetworkInfoResponse {
  organization: {
    organizationId: string;
    organizationNme: string;
    parentOrg: {
      organizationNme: string;
    };
    locations: Array<{ locationId: string; organizationId: string }>;
  };
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private apollo: Apollo, private utilsService: UtilsService, private userService: UserService) {}

  getOrgSocialAccountInfoByUser(): Observable<IOrganization> {
    return this.apollo
      .watchQuery<{ organization: IOrganization }>({
        query: gql.orgSocialAccountInfo,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.organization),
        catchError(() => of(null)),
      );
  }

  createOrganization(input: IOrganizationInput): Observable<IOrganization> {
    return this.apollo
      .mutate<{ createOrganization: IOrganization }>({
        mutation: gql.createOrganization,
        variables: { input },
      })
      .pipe(
        map(({ data }) => data.createOrganization),
        catchError(() => of(null)),
      );
  }

  updateOrganization(id: string, input: IOrganizationUpdate): Observable<IOrganization> {
    const inputParam = { id, input };
    return this.apollo
      .mutate<{ updateOrganization: IOrganization }>({
        mutation: gql.updateOrganization,
        variables: inputParam,
      })
      .pipe(
        map(({ data }) => data.updateOrganization),
        catchError(() => of(null)),
      );
  }

  getNetworkInfo(): Observable<INetworkInfo> {
    const noData = {
      organizationId: null,
      ownedLocationNum: 0,
      networkSizeNum: 0,
    };
    return this.apollo
      .watchQuery<INetworkInfoResponse>({
        query: gql.getNetworkInfo,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const ownedLocation = data.organization.locations.filter(
            (location) => location.organizationId === data.organization.organizationId,
          );
          return {
            organizationId: data.organization.organizationId,
            ownedLocationNum: ownedLocation.length,
            networkSizeNum: data.organization.locations.length,
          };
        }),
        catchError(() => of(noData)),
      );
  }

  /*
   * For admin portal:
   * getBasicOrgInfo
   * getClientsWithBasicInfo
   * toggleOrgStatus
   * createOrgAccount
   * updateOrgAccount
   * toggleReadOnly (franchisor)
   * */

  getOrgsWithBasicInfo(): Observable<Array<IOrganization>> {
    return this.apollo
      .watchQuery<{ franchisors: Array<IOrganization> }>({
        query: gql.getOrgsWithBasicInfo,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => sortBy(data.franchisors, (organization) => organization.organizationNme.toLowerCase())),
        catchError(() => of([])),
      );
  }

  getFranchiseesWithBasicInfo(id: string): Observable<Array<IOrganization>> {
    return this.apollo
      .watchQuery<{ franchiseesByParentId: Array<IOrganization> }>({
        query: gql.getFranchiseesWithBasicInfo,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) =>
          sortBy(data.franchiseesByParentId || [], (organization) => organization.organizationNme.toLowerCase()),
        ),
        catchError(() => of([])),
      );
  }

  toggleOrgActiveStatus(input: { organizationID: string; organizationActive: boolean }): Observable<IOrganization> {
    return this.apollo
      .mutate<{ activeOrDeactiveOrg: IOrganization }>({
        mutation: gql.toggleActiveStatus,
        variables: { input },
      })
      .pipe(
        map(({ data }) => data.activeOrDeactiveOrg),
        catchError(() => of(null)),
      );
  }

  toggleReadOnlyStatus(input: { organizationId: string; readOnly: boolean }): Observable<IOrganization> {
    return this.apollo
      .mutate<{ readOnlyFranchisorAccount: IOrganization }>({
        mutation: gql.toggleReadOnlyStatus,
        variables: { input },
      })
      .pipe(
        map(({ data }) => data.readOnlyFranchisorAccount),
        catchError(() => of(null)),
      );
  }

  createFranchisorAccount(input: IOrgAccountCreateInput, isSMB: boolean = false): Observable<IOrganization> {
    return this.apollo
      .mutate<{ createFranchisorAccount: IOrganization }>({
        mutation: gql.createFranchisorAccount,
        variables: { input, isSMB },
      })
      .pipe(
        map(({ data }) => data.createFranchisorAccount),
        catchError(() => of(null)),
      );
  }

  updateFranchisorAccount(input: IOrgAccountUpdateInput): Observable<IOrganization> {
    return this.apollo
      .mutate<{ updateFranchisorAccount: IOrganization }>({
        mutation: gql.updateFranchisorAccount,
        variables: { input },
      })
      .pipe(
        map(({ data }) => data.updateFranchisorAccount),
        catchError(() => of(null)),
      );
  }

  // for agency portal
  getAgencyOrgAccounts(args: {
    nbr: number;
    size: number;
    orgType: OrganizationType;
    orderBy: string;
    asc: boolean;
    parentId?: string;
  }): Observable<{ organizations: Array<IOrganization>; count: number }> {
    return this.apollo
      .watchQuery<{ getOrgsByAgency: { organizations: Array<IOrganization>; count: number } }>({
        query: gql.getAgencyOrgAccounts,
        fetchPolicy: 'network-only',
        variables: args,
      })
      .valueChanges.pipe(
        map(({ data }) => data.getOrgsByAgency),
        catchError(() =>
          of({
            organizations: [],
            count: 0,
          }),
        ),
      );
  }

  getOrgsByIds(ids: Array<number>): Observable<Array<IOrganization>> {
    return this.apollo
      .watchQuery<{ organizations: Array<IOrganization> }>({
        fetchPolicy: 'network-only',
        query: gql.getOrgsByIds,
        variables: { ids },
      })
      .valueChanges.pipe(
        map(({ data }) => data.organizations || []),
        catchError(() => of([])),
      );
  }

  // for location management page
  getZorWithLocations(): Observable<IOrganization> {
    return this.apollo
      .watchQuery<{ organization: IOrganization }>({
        query: gql.getZorWithLocations,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const copyOrg = Object.assign({}, data.organization);
          copyOrg.locations = [...copyOrg.locations]
            .filter((location) => location.organizationId === data.organization.organizationId)
            .map((location) => Object.assign({}, location));
          return copyOrg;
        }),
        catchError(() => of(null)),
      );
  }

  getZeesWithLocations(parentId: string): Observable<Array<IOrganization>> {
    return this.apollo
      .watchQuery<{ franchiseesByParentId: Array<IOrganization> }>({
        query: gql.getZeesWithLocations,
        variables: {
          id: parentId,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return [...data.franchiseesByParentId].map((franchisee) => {
            const franchiseeCopy = Object.assign({}, franchisee);
            franchiseeCopy.locations = [...franchiseeCopy.locations].map((location) => Object.assign({}, location));
            return franchiseeCopy;
          });
        }),
        catchError(() => of([])),
      );
  }

  deleteFranchisee(orgId: string): Observable<{ organizationId: string }> {
    return this.apollo
      .mutate<{ deleteFranchisee: { organizationId: string } }>({
        mutation: gql.deleteFranchisee,
        variables: {
          id: orgId,
        },
      })
      .pipe(
        map(({ data }) => data.deleteFranchisee),
        catchError(() => of(null)),
      );
  }

  getOrganizationById(id: string): Observable<IOrganization> {
    return this.apollo
      .watchQuery<{ organizationInfoById: IOrganization }>({
        fetchPolicy: 'network-only',
        query: gql.organizationById,
        variables: { id },
      })
      .valueChanges.pipe(
        map(({ data }) => data.organizationInfoById),
        catchError(() => of(null)),
      );
  }

  isFeatureEnabled(featureName: 'gmb'): Observable<boolean> {
    const fieldName = `organization${upperFirst(featureName)}Enabled`;
    const user = this.userService.getCachedUserProfile();
    if (user) {
      const { organization, location } = user;
      if (organization) {
        return of(organization[fieldName]);
      } else {
        const orgId = location.organizationId;
        return this.getOrganizationById(orgId).pipe(map((org: IOrganization) => !!org && org[fieldName]));
      }
    }
    return of(false);
  }
}
