import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ui-search-input',
  templateUrl: './ui-search-input.component.html',
  styleUrls: ['./ui-search-input.component.scss'],
})
export class UiSearchInputComponent implements OnChanges, OnInit {
  @Input()
  fg: FormGroup;
  @Input()
  controlName: string;
  @Input()
  disabled = false;
  @Input()
  placeholder = 'Input for search';
  @Input()
  showSearchBtn = false;
  @Input()
  searchBtnText = 'Search';
  @Input()
  inputLimit: number;

  @Output()
  searchEvent: EventEmitter<string> = new EventEmitter();
  @Output()
  keyDownEvent: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output()
  inputEvent: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && !changes.disabled.firstChange) {
      const isDisabled = changes.disabled.currentValue;
      isDisabled ? this.formControl.disable() : this.formControl.enable();
    }
  }

  ngOnInit() {
    this._initForm();
  }

  private _initForm() {
    if (!this.controlName) {
      this.controlName = 'defaultSearchControl';
    }
    if (!this.fg) {
      this.fg = new FormGroup({
        [this.controlName]: new FormControl({ value: '', disabled: this.disabled }),
      });
    }
  }

  handleInput(value: string): void {
    this.setValue(value);
    this.inputEvent.emit(value);
  }

  handleKeyDown(event: KeyboardEvent): void {
    this.keyDownEvent.emit(event);
  }

  handleSearch(): void {
    this.searchEvent.emit(this.formValue);
  }

  handleClearInput(): void {
    this.setValue('');
    this.inputEvent.emit('');
  }

  private setValue(value: string): void {
    const formControl = this.formControl;

    if (formControl) {
      formControl.setValue(value);
    }
  }

  get formControl(): AbstractControl {
    if (this.fg && this.controlName) {
      return this.fg.get(this.controlName);
    }

    return null;
  }

  get formValue(): string {
    const formControl = this.formControl;
    if (formControl) {
      return formControl.value;
    }
    return '';
  }
}
