import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  CommonResponse,
  IUser,
  NavigationMenu,
  PoliciesEnum,
  PolicyList,
  QueryPolicyInput,
  Role,
  UpdatePolicyInput,
  ViewType,
} from '@interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as gql from './policy.gql';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { navigationMenus } from '../dictionary/dictionary-data';
import { AuthService } from '@services';
import { convertJsonToUrlQueryParams } from '../../../utils/utils';

interface GrantedPolicyId {
  accessIds: number[];
  naIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private accessPolicyIds: number[];
  private naPolicyIds: number[];

  private activeNavigationMenuItemsSubject: BehaviorSubject<NavigationMenu[]> = new BehaviorSubject<NavigationMenu[]>(
    navigationMenus,
  );

  get activeNavigationMenuItemsSnapshot(): NavigationMenu[] {
    return this.activeNavigationMenuItemsSubject.getValue();
  }

  set activeNavigationMenuItems(items: NavigationMenu[]) {
    this.activeNavigationMenuItemsSubject.next(items);
  }

  activeNavigationMenuItems$ = this.activeNavigationMenuItemsSubject.asObservable();

  constructor(private apollo: Apollo, private userService: UserService, private auth: AuthService) {
    this.auth.isAuthenticated().pipe(
      distinctUntilChanged(),
      tap((hasLoggedIn) => {
        if (!hasLoggedIn) {
          this.accessPolicyIds = null;
          this.naPolicyIds = null;
        }
      }),
    );
  }

  getRoleById(id: number): Observable<Role> {
    return this.apollo
      .watchQuery<{ role: Role }>({
        query: gql.role,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const accessPolicyIds = data.role.accessPolicyIds || [];
          const denyPolicyIds = data.role.denyPolicyIds || [];
          const naPolicyIds = data.role.naPolicyIds || [];
          this.accessPolicyIds = accessPolicyIds;
          this.naPolicyIds = naPolicyIds;
          return { ...data.role, accessPolicyIds, denyPolicyIds, naPolicyIds };
        }),
        catchError(() => of(null)),
      );
  }

  getRoles(): Observable<Role[]> {
    return this.apollo
      .watchQuery<{ roles: Role[] }>({
        query: gql.roles,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.roles.map((role) => {
            const accessPolicyIds = role.accessPolicyIds || [];
            const denyPolicyIds = role.denyPolicyIds || [];
            const naPolicyIds = role.naPolicyIds || [];
            return { ...role, accessPolicyIds, denyPolicyIds, naPolicyIds };
          });
        }),
        catchError(() => of([])),
      );
  }

  getPolicies({ pageNum, pageSize, text, asc, roleIds }: QueryPolicyInput): Observable<PolicyList> {
    return this.apollo
      .watchQuery<{ policies: PolicyList }>({
        query: gql.policies,
        variables: {
          pageNum,
          pageSize,
          text,
          asc, // order: rbacPolicyNme
          roleIds: roleIds.map((roleId) => parseInt(roleId, 10)),
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const { count, policies } = data.policies;
          const filteredPolicies = policies.map((policy) => {
            const accessRoles = policy.accessRoles || [];
            const denyRoles = policy.denyRoles || [];
            const naRoles = policy.naRoles || [];
            return { ...policy, accessRoles, denyRoles, naRoles };
          });
          return { count, policies: filteredPolicies };
        }),
        catchError(() => of({ count: 0, policies: [] })),
      );
  }

  updatePolicy({ policyId, accessRoleIds, denyRoleIds }: UpdatePolicyInput): Observable<CommonResponse> {
    return this.apollo
      .mutate<{ editPolicy: CommonResponse }>({
        mutation: gql.editPolicy,
        variables: {
          policyId: parseInt(policyId, 10),
          accessRoleIds: accessRoleIds.map((accessRoleId) => parseInt(accessRoleId, 10)),
          denyRoleIds: denyRoleIds.map((denyRoleId) => parseInt(denyRoleId, 10)),
        },
      })
      .pipe(
        map(({ data }) => data.editPolicy),
        catchError(() => of({ message: 'failed', success: false })),
      );
  }

  getPolicyAccess(policyId: number): Observable<boolean> {
    const user = this.userService.getCachedUserProfile();

    if (policyId) {
      let policyRequest: Observable<GrantedPolicyId>;
      if (user && user.rbacAssignmentId) {
        policyRequest = this.getAccessPolicies(user.rbacAssignmentId);
      } else {
        policyRequest = of({
          accessIds: [],
          naIds: [],
        });
      }
      return policyRequest.pipe(map((grantedPolicyId: GrantedPolicyId) => this.canAccess(grantedPolicyId, policyId)));
    }
    return of(false);
  }

  checkPolicyPermission(policyId: number): boolean {
    if (policyId && this.accessPolicyIds) {
      const grantedPolicyId = { accessIds: this.accessPolicyIds, naIds: this.naPolicyIds };
      return this.canAccess(grantedPolicyId, policyId);
    }
    return false;
  }

  getNavMenus(user: IUser, type?: string): NavigationMenu[] {
    const viewType = this.userService.isCOLOView() ? ViewType.COLO : user?.userCurView;
    const isAdmin = this.userService.isAdminRole();
    const isAgency = this.userService.isAgencyUser();
    const isOrgUser = this.userService.isOrgUser();
    const isSMBOrg = this.userService.isSMBOrg();
    const isReseller = this.userService.isReseller();
    const canRunAd = this.canRunAd();
    const navs = [...navigationMenus]
      .filter((nav: NavigationMenu) => {
        let res = true;
        if (!canRunAd) {
          res = nav.navId !== 'manage-subscription';
        }
        if (isSMBOrg) {
          res = nav.navId !== 'run-campaign';
        }
        if (nav.navId === 'user-management') {
          res = isOrgUser;
        }
        if (nav.navId === 'billing') {
          res = isOrgUser && !isReseller;
        }

        return res;
      })
      .map((nav: NavigationMenu) => {
        if (nav.navId !== 'logout') {
          const hasAccess =
            !nav.policyIds.length || nav.policyIds.some((policyId) => this.checkPolicyPermission(policyId));
          const isExcluded = nav.excludeViewTypes.includes(viewType);
          const hasAccessByAssignment = !nav.assignment || this.userService.isUserHasAssignment(nav.assignment);
          nav.isEnable = hasAccess && hasAccessByAssignment && !isExcluded;
          if (isAdmin && nav.navId === 'admin') {
            nav.isEnable = true;
          }
          if (isAgency && !isAdmin && nav.navId === 'agency') {
            nav.isEnable = true;
          }

          if (nav?.newApp) {
            if (user?.organizationId) {
              nav.queryParams = { ...nav.queryParams, organizationId: user.organizationId };

              if (Boolean(user?.locationId)) {
                nav.queryParams = { ...nav.queryParams, locationId: user.locationId };
              }

              const navBase = nav.navPath.split('?')[0];

              const newQueryString = convertJsonToUrlQueryParams(nav.queryParams);

              nav.navPath = navBase + newQueryString;
            }
          }
        }
        return nav;
      });

    if (!type) {
      const result = navs.filter((nav) => nav.isEnable);
      this.activeNavigationMenuItems = result;
      return result;
    } else {
      const result = navs.filter((nav) => nav.isEnable && nav.navType === type);
      this.activeNavigationMenuItems = result;
      return result;
    }
  }

  private getAccessPolicies(assignmentId: number): Observable<GrantedPolicyId> {
    return this.accessPolicyIds
      ? of({ accessIds: this.accessPolicyIds, naIds: this.naPolicyIds })
      : this.getRoleById(assignmentId).pipe(
          map((role: Role) => {
            let accessIds = [];
            let naIds = [];
            if (role) {
              accessIds = role.accessPolicyIds;
              naIds = role.naPolicyIds;
            }
            return { accessIds, naIds };
          }),
        );
  }

  private canAccess = (grantedPolicyId: GrantedPolicyId, policyId: number): boolean => {
    const { accessIds, naIds } = grantedPolicyId;
    return accessIds.includes(policyId) || naIds.includes(policyId);
    // tslint:disable-next-line:semicolon
  };

  private canRunAd(): boolean {
    const isReadOnly = this.userService.isReadOnlyAccess();
    const canRunAdWhenReadOnly = this.checkPolicyPermission(PoliciesEnum.readOnlyRunAnAd);
    return !isReadOnly || (isReadOnly && canRunAdWhenReadOnly);
  }
}
