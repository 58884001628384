import { AppConfigService } from './app-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APIVersion } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  private versionURL: string;
  private IPAddressURL: string;
  constructor(private http: HttpClient, private appConfigService: AppConfigService) {
    this.versionURL = appConfigService.versionURL;
    this.IPAddressURL = 'https://api.ipify.org?format=json';
  }

  public getBackendVersion(): Observable<APIVersion> {
    const noData = { version: null, branch: null, commit: null };
    return this.http.get(this.versionURL).pipe(
      map((res: APIVersion) => res || noData),
      catchError(() => of(noData)),
    );
  }

  public getIPAddress(): Observable<{ ip: string }> {
    return this.http.get(this.IPAddressURL).pipe(
      map((IPAddress: { ip: string }) => IPAddress),
      catchError(() => of({ ip: 'n/a' })),
    );
  }
}
