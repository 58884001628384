<ng-template [ngIf]="contentType === 'notifications'">
  <div id="notifications-container" class="item-container flex flex-justify-start"
       *ngFor="let notification of content;">
    <ng-container [ngSwitch]="notification.category">
      <div *ngSwitchCase="'templateRequest'">
        <div class="warning-notification-container">
          <app-ui-icon fillIcon="alert_red" size="1"></app-ui-icon>
          <span class="text-m text-medium">{{ notification.msg }}</span>
        </div>
        <div class="request-status text-m">
          <ng-template [ngIf]="notification.content.isFulfilled">
            Fulfilled
          </ng-template>
          <ng-template [ngIf]="notification.content.isFulfilled === null">
            <span class="cursor-pointer text-underline" (click)="previewRequest(notification)">
              Click here to create new template
            </span>
          </ng-template>
          <ng-template [ngIf]="notification.content.isFulfilled === false">
            Denied
          </ng-template>
        </div>
      </div>

      <div *ngSwitchCase="'campaign'">
        <div>
          <img *ngIf="notification.channelName" [src]="'assets/icons/logo/' + notification.channelName + '.svg'"
               [alt]="notification.channelName" class="inline-item notification-icon small-size">
          <p class="inline-item text-medium campaign-name">{{ notification.adName }}</p>
        </div>
        <div>
          <p class="text-m font-color-comment-gray">
            {{ notification.msg }}
            <ng-template [ngIf]="notification.adId && canNavToAdResult">
              <span class="link-text" (click)="gotoResultPage(notification.adId, 'Notification')">preview</span>.
            </ng-template>
          </p>
        </div>
      </div>

      <div *ngSwitchCase="'pausedAd'">
        <div class="operation-notification">
          <img *ngIf="notification.channelName" [src]="'assets/icons/logo/' + notification.channelName + '.svg'"
               [alt]="notification.channelName" class="inline-item notification-icon small-size">
          <div>
            <p><span>{{ notification.msg }}</span><span class="bold-noti-text">{{ notification.adName }}</span></p>
            <a (click)="gotoResultPage(notification.adId, 'Notification')"
               *ngIf="(!notification.isRead) && notification.content.campaignPublishStatus ==='pause' && canNavToAdResult">
              Click here to resume.
            </a>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'accountDisconnect'" class="warning-notification-container">
        <app-ui-icon fillIcon="alert_red" size="1"></app-ui-icon>
        <div>
          <p class="text-medium text-m">
            {{ notification.msg }}
          </p>
          <p *ngIf="canNavToAccount" class="text-bold text-m notification-operation" (click)="gotoAccountPage()">
            Click here to reconnect
          </p>
        </div>
      </div>

      <div *ngSwitchCase="'inactiveAd'" class="campaign-inactive-container warning-notification-container">
        <app-ui-icon fillIcon="alert_red" size="1"></app-ui-icon>
        <ng-container
          *ngTemplateOutlet="inactiveAdContainer; context:{ $implicit: notification }">
        </ng-container>
      </div>

      <div *ngSwitchCase="'paymentUnset'" class="warning-notification-container">
        <app-ui-icon fillIcon="alert_red" size="1"></app-ui-icon>
        <ng-container
          *ngTemplateOutlet="paymentUnsetContainer; context:{ $implicit: notification }">
        </ng-container>
      </div>

      <div *ngSwitchCase="'newAds'" class="full-width flex-row">
        <span class="main-text">
          <img *ngIf="notification.campaignChannelNme" [src]="'assets/icons/logo/' + notification.campaignChannelNme + '.svg'"
               [alt]="notification.campaignChannelNme" class="inline-item notification-icon small-size">
          <strong>{{notification.campaignTemplateNme}}</strong>
          <span>has been run by </span>
          <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
          <strong>{{notification.locationNme}}.</strong>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>

      <div *ngSwitchCase="'firstAdPlaced'" class="full-width flex-row">
        <span class="main-text">
          <img src="assets/icons/outline/Location.svg" alt="" class="location-icon">
          <strong>{{notification.locationNme}}</strong>
          <span>placed their first ad </span>
          <img *ngIf="notification.campaignChannelNme" [src]="'assets/icons/logo/' + notification.campaignChannelNme + '.svg'"
               [alt]="notification.campaignChannelNme" class="inline-item notification-icon small-size">
          <strong>{{notification.campaignTemplateNme}}</strong>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>

      <div *ngSwitchCase="'hit2kImpressions'" class="full-width flex-row">
        <span class="main-text">
          <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
          <strong>{{notification.locationNme}}'s</strong>
          <span>campaign </span>
          <img *ngIf="notification.campaignChannelNme" [src]="'assets/icons/logo/' + notification.campaignChannelNme + '.svg'"
               [alt]="notification.campaignChannelNme" class="inline-item notification-icon small-size">
          <strong>{{notification.campaignTemplateNme}}</strong>
          <span>reached</span>
          <span>
            {{2000|number}}
            impressions!
          </span>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>

      <div *ngSwitchCase="'hit5kImpressions'" class="full-width flex-row">
        <span class="main-text">
          <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
          <strong>{{notification.locationNme}}'s</strong>
          <span>campaign </span>
          <img *ngIf="notification.campaignChannelNme" [src]="'assets/icons/logo/' + notification.campaignChannelNme + '.svg'"
               [alt]="notification.campaignChannelNme" class="inline-item notification-icon small-size">
          <strong>{{notification.campaignTemplateNme}}</strong>
          <span>reached</span>
          <span>
            {{5000|number}}
            impressions!
          </span>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>

      <div *ngSwitchCase="'hit10kImpressions'" class="full-width flex-row">
        <span class="main-text">
          <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
          <strong>{{notification.locationNme}}'s</strong>
          <span>campaign </span>
          <img *ngIf="notification.campaignChannelNme" [src]="'assets/icons/logo/' + notification.campaignChannelNme + '.svg'"
               [alt]="notification.campaignChannelNme" class="inline-item notification-icon small-size">
          <strong>{{notification.campaignTemplateNme}}</strong>
          <span>reached</span>
          <span>
            {{10000|number}}
            impressions!
          </span>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>

      <div *ngSwitchCase="'adsExpiring'" class="full-width flex-row">
        <span class="main-text">
          <img *ngIf="notification.campaignChannelNme" [src]="'assets/icons/logo/' + notification.campaignChannelNme + '.svg'"
               [alt]="notification.campaignChannelNme" class="inline-item notification-icon small-size">
          <strong>{{notification.campaignTemplateNme}}</strong>
          <span>{{notification.expirationText}}. Time to create more campaign templates!</span>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>

      <div *ngSwitchCase="'bestPerformingAds'" class="full-width flex-row">
        <span class="main-text">
          <img *ngIf="notification.campaignChannelNme" [src]="'assets/icons/logo/' + notification.campaignChannelNme + '.svg'"
               [alt]="notification.campaignChannelNme" class="inline-item notification-icon small-size">
          <strong>{{notification.campaignTemplateNme}}</strong>
          <span>had the highest CTR this past week with a CTR of </span>
          <span>{{notification.ctr | number: '0.0-2'}}%!</span>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>

      <div *ngSwitchCase="'noAdsLocationList'" class="full-width flex-row">
        <span class="main-text">
          <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
          <strong>{{notification.locationNme}}</strong>
          <span>currently has no campaigns running.</span>
        </span>
        <span class="time-stamp">{{notification.hour}}</span>
      </div>
    </ng-container>
  </div>
</ng-template>


<ng-template #inactiveAdContainer let-notification>
  <p *ngIf="isLocationUser" class="text-m font-weight-half-bold">
    {{ notification.msg }}
  </p>
  <div *ngIf="!isLocationUser" class="campaign-inactive-content text-m">
    <p class="font-weight-half-bold" *ngIf="notification.content.disconnectFranchisees && !!notification.content.disconnectFranchisees.length">
      {{ notification.content.disconnectFranchisees.length }} Location accounts require attention -
      <span class="bold-noti-text">Tokens expired</span>
    </p>
    <div *ngIf="notification.content.showDetailStatus">
      <p class="franchise-name-detail font-weight-half-bold text-ellipsis"
         *ngFor="let franchiseName of notification.content.disconnectFranchisees">
        - {{ franchiseName }}
      </p>
    </div>
    <p *ngIf="notification.content.inActiveAds && !!notification.content.inActiveAds.length">
      <span class="bold-noti-text">·</span>
      {{ notification.content.inActiveAds.length }}
      Ads are not actively pulling results due to the expiration. Reconnect accounts to fix
      <span *ngIf="!notification.content.showDetailStatus" class="bold-noti-text notification-operation"
            (click)="toggleDisplayStatus(notification)">
        View All
      </span>
    </p>
    <div *ngIf="notification.content.showDetailStatus" class="campaign-detail-container">
      <p class="campaign-name-detail text-ellipsis" *ngFor="let adName of notification.content.inActiveAds">
        - {{ adName }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #paymentUnsetContainer let-notification>
  <div class="campaign-inactive-content text-m">
    <p class="font-weight-half-bold">
      {{ notification.msg }} Please follow these
        <a class="bold-noti-text notification-here-redirector" href="https://www.facebook.com/business/help/132073386867900" target="_blank">
          instructions
        </a>
        to get connected.
    </p>
    <div *ngIf="notification.content.showDetailStatus" class="campaign-detail-container">
      <p class="campaign-name-detail text-ellipsis" *ngFor="let account of notification.content.contentfailedAccountStatus">
        - {{ account.accountStatus.name || '' }}
      </p>
    </div>
  </div>
</ng-template>


<ng-template [ngIf]="contentType === 'activities'">
  <ul class="mt-s">
    <ng-container *ngFor="let activity of content">
      <li class="list-item">
        <div class="full-width">
          <div class="flex-row activity-item">
            <div>
              <div class="campaign-channel-title mb-xs" [class.cursor-pointer]="canNavToAdResult" *ngIf="activity.channelName"
                   (click)="gotoResultPage(activity.adId, 'Network Activity')">
                <img [src]="'assets/icons/logo/' + activity.channelName + '.svg'" [alt]="activity.channelName"
                     class="channel-icon">
                <span>{{ activity.adName }}</span>
              </div>
              <p class="activity-msg">
                <ng-template [ngIf]="activity.type !== 'newAds' && activity.type !== 'newUsers'">
                  <strong>{{ activity.ownerName }}'s</strong>
                  <span>{{activity.msg}}</span>
                </ng-template>
                <ng-template [ngIf]="activity.type === 'newUsers'">
                  <strong>{{ activity.ownerName }}</strong>
                  <span>{{activity.msg}}</span>
                </ng-template>
                <ng-template [ngIf]="activity.type === 'newAds'">
                  <strong>{{ activity.adName }}</strong>
                  <span>{{ activity.msg | titlecase }}</span>
                  <span>{{ activity.timestamp }} ago by</span>
                  <strong>{{ activity.ownerName }}.</strong>
                </ng-template>
              </p>
            </div>
            <span class="time-stamp pl-xs">{{activity.timestamp}}</span>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</ng-template>
