import { GoogleOAuthType } from './google-my-business.interface';

export interface ChannelOauthParamsInterface {
  google?: OauthParamsInterface;
  facebook?: OauthParamsInterface;
  instagram?: OauthParamsInterface;
}

export interface OauthParamsInterface {
  appId: string;
  scopes: string | string[];
  oauthUri: string;
}

export interface AccountConnectStatus {
  facebook?: boolean;
  instagram?: boolean;
  google?: boolean;
}
export interface TokenExpirationStatusInterface {
  channel?: string;
  content?: { [key: string]: string };
  failedAccountStatus?: Array<any>;
  type: string;
}

// Facebook Accounts Management Interfaces

export interface FacebookOAuthConfirmInterface {
  code: string;
  token: string;
}

export interface FacebookLocationPageInterface {
  adActId?: string;
  pageId?: string;
  locationId?: string;
  locationNme?: string;
}

export interface FacebookPageBindInputInterface {
  defaultAdActId?: string;
  defaultPageId?: string;
  defaultZeeAdActId?: string;
  defaultZeePageId?: string;
  pages: FacebookLocationPageInterface[];
}

export interface FacebookBusinessAccountInterface {
  id?: string;
  name?: string;
  pages?: FacebookPageInfoInterface[];
  hasNextPages?: boolean;
}

export interface FacebookPageInfoInterface {
  id?: string;
  name?: string;
}

export interface FacebookUserInfoInterface {
  id?: string;
  name?: string;
  adAccounts?: FacebookBusinessAccountInterface[];
  pages?: FacebookPageInfoInterface[];
  after?: string;
}

// Instagram Accounts Management Interfaces

export enum InstagramAccountType {
  BusinessAccount = 'BusinessAccount',
  InstagramAccount = 'InstagramAccount',
  BackedInstagramAccount = 'BackedInstagramAccount',
  None = 'None',
}

export interface InstagramAccountInfoInterface {
  type?: InstagramAccountType;
  accountId?: string;
  accountName?: string;
}

export interface GetDefaultInsAccountsInterface {
  zeeDefault: string;
  zorDefault: string;
  zeeDefaultAccountType: string;
  zorDefaultAccountType: string;
  zeeDefaultAccounts: Array<{ accountId: string; type: string; accountName: string }>;
  zorDefaultAccounts: Array<{ accountId: string; type: string; accountName: string }>;
}

export interface InstagramLocationPageInterface {
  locationId?: string;
  locationName?: string;
  pageId?: string;
  pageName?: string;
  adActId?: string;
  instagramAccount?: string;
  accountType?: InstagramAccountType;
  accounts?: InstagramAccountInfoInterface[];
}

export interface InstagramPageListInterface {
  id?: string;
  name?: string;
  locationPages?: InstagramLocationPageInterface[];
}

export interface InstagramAccountBindInterface {
  pageId?: string;
  locationId?: string;
  instagramAccount?: string;
  accountType?: string;
}

// Google Accounts Management Interfaces

export interface GoogleOAuthConfirmInterface {
  code: string;
  redirectUri: string;
  type?: GoogleOAuthType;
}

export interface LocationGoogleAccountInterface {
  customerId?: string;
  locationId?: string;
}

export interface GoogleAccountsBindInterface {
  defaultId?: string;
  locationAccountList?: LocationGoogleAccountInterface[];
}

export interface GoogleAccountInfoInterface {
  googleAdwordsCustomerNme?: string;
  googleAdwordsClientCustomerId?: string;
  googleAdwordsCurrencyCode?: string;
  googleAdwordsDateTimeZone?: string;
  googleAdwordsManagerCustomerId?: string;
  googleAdwordsCanManageClientsInd?: boolean;
}

export interface OrgGoogleAccountsInterface {
  organizationId?: string;
  total?: number | string;
  defaultAccountId?: string;
  accounts?: GoogleAccountInfoInterface[];
}
