import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@services';
import { OrganizationDetailWithLocations } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationApiClientService {
  constructor(private readonly appConfig: AppConfigService, private readonly httpClient: HttpClient) {}

  public getOrganizationDetail(
    organizationId: number | string,
    locationId?: number | string | null,
  ): Observable<OrganizationDetailWithLocations> {
    const endpoint = new URL(`${this.appConfig.organizationApiUrl}/${organizationId}`);
    if (locationId) {
      endpoint.searchParams.set('locationId', `${locationId}`);
    }

    return this.httpClient.get<OrganizationDetailWithLocations>(endpoint.toString());
  }
}
