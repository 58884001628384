import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-network-error-dialog',
  templateUrl: './network-error-dialog.component.html',
  styleUrls: ['./network-error-dialog.component.scss'],
})
export class NetworkErrorDialogComponent implements OnInit {
  dialogType: string;
  dialogText: string;
  dialogImage: string;
  constructor(
    private dialogRef: MatDialogRef<NetworkErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private dialogData: {
      dialogType: string;
      dialogText: string;
      dialogImage: string;
    },
  ) {}

  ngOnInit() {
    const { dialogType, dialogText, dialogImage } = this.dialogData;
    this.dialogType = dialogType;
    this.dialogText = dialogText;
    this.dialogImage = dialogImage;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
