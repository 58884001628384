import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DictionaryService } from '../dictionary/dictionary.service';
import { IUser, OrganizationType, UserRoleType } from '@interfaces';
import { VERSION } from '@version';

interface Pendo {
  track(eventCategory: string, metadata: object): any;
}
declare const ga: (action: string, type: string, value?: any) => void;
declare const pendo: Pendo;

// number: dimensionIndex, do not change it.
const customDimensions = {
  userType: 1,
  userId: 2,
  releaseVersion: 3,
  IPAddress: 4,
  accountType: 5,
  franchiseName: 6,
  networkSize: 7,
  userName: 8,
};

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router, private dictionaryService: DictionaryService) {
    router.events.subscribe((event) => {
      if (typeof ga === 'function') {
        if (event instanceof NavigationEnd) {
          this.navigationTrack(event.url, event.urlAfterRedirects);
        }
      }
    });
  }

  private navigationTrack(currentURL: string, nextURL: string): void {
    const allNavs = this.dictionaryService
      .getNavigations(null, null, true)
      .filter((nav) => nav.navId !== 'admin' && nav.navId !== 'logout');
    const matchedNav = allNavs.find((nav) => currentURL === nav.navPath);
    if (matchedNav) {
      let role = 'ZEE';
      if (matchedNav.navId.indexOf('zor') !== -1) {
        role = 'ZOR';
      }
      const label = matchedNav.navLabel || matchedNav.navName;
      this.eventTracking('Navigation', `Entered ${role} View`, label);
      ga('set', 'page', nextURL);
    } else if (currentURL.indexOf('subscription-list') !== -1) {
      this.eventTracking('Navigation', `Entered Zee View`, 'Subscriptions');
      ga('set', 'page', nextURL);
    }
  }

  public eventTracking(
    eventCategory: string,
    eventAction: string,
    eventLabel: string | number | Date | boolean = null,
    eventValue: number = null,
  ) {
    if (typeof ga === 'function') {
      ga('send', 'event', {
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
      });
    }
    if (typeof pendo === 'object' && pendo.hasOwnProperty('track') && typeof pendo.track === 'function') {
      pendo.track(eventCategory, {
        eventAction,
        eventLabel,
        eventValue,
      });
    }
  }

  setUserID(userId: string): void {
    if (typeof ga === 'function') {
      ga('set', 'userId', userId);
    }
  }

  setCustomDimensions(user: IUser, IPAddress: string): void {
    const emailType = user.userEmailAddress.includes('internal') ? 'Internal' : 'External';
    const org = user.organization;
    const isAdmin = user.rbacRoleId === UserRoleType.STAFF || user.rbacRoleId === UserRoleType.ROOT;
    let franchiseName = 'N/A';
    let networkSize = 'N/A';
    let accountType = isAdmin ? 'Admin' : 'N/A';
    if (!isAdmin && !!org) {
      franchiseName = org.organizationNme;
      networkSize = (org.locationTotal || 0).toString();
      accountType = org.organizationType === OrganizationType.ORGANIZATION ? 'Zor' : 'Zee';
    }

    const dimensions = [
      { name: 'userType', value: emailType },
      { name: 'userId', value: user.userId },
      { name: 'releaseVersion', value: VERSION.version },
      { name: 'IPAddress', value: IPAddress },
      { name: 'accountType', value: accountType },
      { name: 'franchiseName', value: franchiseName },
      { name: 'networkSize', value: networkSize },
      { name: 'userName', value: `${user.userFirstNme} ${user.userLastNme}` },
    ];

    for (const dimension of dimensions) {
      const dimensionIndex = `dimension${customDimensions[dimension.name]}`;
      if (dimensionIndex && typeof ga === 'function') {
        ga('set', dimensionIndex, dimension.value);
      }
    }
    // dimensions need to be set before pageview is sent
    if (typeof ga === 'function') {
      ga('send', 'pageview');
    }
  }
}
