import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appPageScroll]',
})
export class PageScrollDirective {
  @Output()
  scrollChange: any = new EventEmitter<Event>();

  constructor() {}

  @HostListener('window:scroll', ['$event'])
  onScroll($event: any) {
    this.scrollChange.next($event);
  }
}
