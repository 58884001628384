import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IUser, NavigationMenu, PoliciesEnum, ViewType } from '@interfaces';
import { GoogleAnalyticsService, NotificationService, UserService, PolicyService } from '@services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss'],
})
export class PageSidebarComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  @Input()
  userProfile: IUser;
  @Input()
  navigationList: NavigationMenu[] = [];
  @Input()
  elementId = 'sidebar';
  // emit the navigation info to the parent component if is need to do something.
  @Output()
  navigationClick: EventEmitter<NavigationMenu> = new EventEmitter(false);

  viewTypes = [
    { value: ViewType.ZOWI, text: 'All Locations' },
    { value: ViewType.ZOLO, text: 'Corporate Locations' },
  ];
  notificationCount: number;
  constructor(
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private notificationService: NotificationService,
    private policyService: PolicyService,
  ) {}

  ngOnInit() {
    this.notificationService.notificationCount
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((count) => (this.notificationCount = count));
  }

  navClick(currentNav: NavigationMenu) {
    this.navigationClick.emit(currentNav);
  }

  get canToggleViewType(): boolean {
    return (
      this.userProfile &&
      this.policyService.checkPolicyPermission(PoliciesEnum.viewAllAndToggleViewType) &&
      this.userService.isZORView(this.userProfile, false) &&
      !this.userService.isSMBOrg(this.userProfile, false)
    );
  }

  get currentViewType(): ViewType | undefined {
    return this.userProfile?.userCurView;
  }

  changeCurrentZorViewType(userViewType: ViewType): void {
    const userProfile = this.userProfile;
    if (userProfile) {
      const newUserProfile = { userId: userProfile.userId, userCurView: userViewType };
      const label = this.userService.isZOWIView(newUserProfile, false)
        ? 'View All Locations'
        : 'View Corporate Locations';
      this.userService
        .updateUserProfile(newUserProfile, null)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => {
          this.googleAnalyticsService.eventTracking('Franchisor View', 'Switch Franchisor View', label);
        });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
