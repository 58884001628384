import { ChannelName } from './channel.interface';

export enum NotificationType {
  impressions_10k = 'impressions_10k',
  impressions_20k = 'impressions_20k',
  highest_ctr = 'highest_ctr',
  highest_runs = 'highest_runs',
  highest_clicks = 'highest_clicks',
  highest_conversion = 'highest_conversion',
  zor_ran_on_zelo = 'zor_ran_on_zelo',
  ad_failed = 'ad_failed',
  account_disconnect = 'account_disconnect',
  ad_inactive_by_account_disconnect = 'ad_inactive_by_account_disconnect',
  campaign_operation = 'campaign_operation',
  funding_source_type_is_unset = 'funding_source_type_is_unset',
  campaign_channel_connected_state = 'campaign_channel_connected_state',
  conversion_campaign_template_for_ad_sub = 'conversion_campaign_template_for_ad_sub',
  campaign_template_request = 'campaign_template_request',
}

export interface Notification {
  notificationId?: string;
  notificationIsRead?: boolean;
  notificationCreatedAt?: Date;
  notificationUpdatedAt?: Date;
  notificationFromYear?: number;
  notificationFromMonth?: number;
  notificationType: NotificationType;
  notificationContent: {
    campaignTemplateNme?: string;
    campaignId?: string;
    campaignNme?: string;
    campaignChannelNme?: string;
    channelNme?: string;
    customerId?: string;
    locationId?: string;
    locationNme?: string;
    ctr?: string;
    clicks?: number;
    runTims?: number;
    budget?: number;
    campaignResultImpressions?: number;
    conversion?: number;
    campaignDurationStartDte?: string;
    campaignPublishStatus?: string;
    campaignDurationEndDte?: string;
    applicationUrl?: string;
    // ad subscriptions
    adSubscriptionNme?: string;
    disconnectedChannelNme?: string;
    // templateRequest
    userName?: string;
    isFulfilled?: boolean;
    requestToOrganizationId?: string;
    campaignTemplateRequestId?: string;
    // token status
    disconnectFranchisees?: string[];
    inActiveAds?: string[];
    failedAccountStatus?: { accountStatus: { name: string } }[];
  };
}

export enum TokenStatusType {
  TOKEN_EXPIRED = 'tokenExpired',
  ZEE_TOKEN_EXPIRED = 'zeeTokenExpired',
  CAN_NOT_PULL_AD_RESULT = 'cannotPullAdResult',
  NO_FOUNDING_SOURCE = 'fundingSourceTypeIsUnset',
}

export interface TokenStatusNotification {
  channel?: ChannelName;
  type: TokenStatusType;
  count?: number;
  content?: string[];
  failedAccountStatus?: { accountStatus: { name: string } }[];
}

export interface FormattedNotification {
  id: string;
  isRead: boolean;
  adId?: string;
  adName: string;
  adType?: string;
  channelName: string;
  category: string;
  msg: string;
  content: {
    campaignPublishStatus?: string;
    inActiveAds?: string[];
    failedAccountStatus?: { accountStatus: { name: string } }[];
    campaignId?: string;
    showDetailStatus?: boolean;
    campaignTemplateRequestId?: string;
  };
}

interface AdTypeInterface {
  campaignTemplateNme: string;
  campaignId: string;
  campaignChannelNme: string;
  locationNme: string;
  timestamp: string;
}

interface ImpressionsTypeInterface {
  campaignTemplateNme: string;
  campaignId: string;
  campaignChannelNme: string;
  impressions: number;
  locationNme: string;
  timestamp: string;
}

interface HighestCtrAdTypeInterface {
  campaignTemplateNme: string;
  campaignId: string;
  campaignChannelNme: string;
  locationNme: string;
  ctr: number;
  timestamp: string;
}

interface AdConversionInterface {
  campaignTemplateNme: string;
  campaignId: string;
  campaignChannelNme: string;
  conversion: number;
  locationNme: string;
  timestamp: string;
}

interface NewUserTypeInterface {
  userFirstNme: string;
  userLastNme: string;
  userMiddleNme: string;
  locationNme: string;
  timestamp: string;
}

export interface NetworkActivity {
  newAds: Array<AdTypeInterface>;
  firstAdPlaced: Array<AdTypeInterface>;
  hit2kImpressions: Array<ImpressionsTypeInterface>;
  hit5kImpressions: Array<ImpressionsTypeInterface>;
  hit10kImpressions: Array<ImpressionsTypeInterface>;
  highestCtrAd: Array<HighestCtrAdTypeInterface>;
  adsConversion: Array<AdConversionInterface>;
  newUsers: Array<NewUserTypeInterface>;
}

export interface FormattedNetworkActivity {
  type: string;
  msg: string;
  adName: string;
  adId: string;
  adType: string;
  channelName: string;
  ownerName: string;
  timestamp: string;
}

export interface CampaignTemplateRequestInterface {
  campaignTemplateRequestId?: string;
  campaignTemplateRequestChannel?: string;
  campaignTemplateRequestMediaType?: string;
  campaignTemplateRequestImageUrls?: string[] | string;
  campaignTemplateRequestDetails?: string;
  campaignTemplateRequestAcceptStatus?: boolean;
  campaignTemplateRequestNeedSendEmail?: boolean;
  user?: string;
  requestToOrganizationId?: string;
}

export interface NotificationMessage {
  type: string;
  category: string;
  msg?: string;
}
