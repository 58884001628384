import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChannelName, ICaretInfo, IChipObject, IconType, IScrollInfo, ITextareaStyle, MediaType } from '@interfaces';
import { ResizeObserver, Utils } from '@utils';
import { AdTextChipParseService, GoogleAnalyticsService, ValidationsService } from '@services';
import { TextareaWithChipDataHandleService as ChipHandle } from './textarea-with-chip-data-handle.service';
import { fromEvent, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { MatChip } from '@angular/material/chips';

@Component({
  selector: 'app-ui-textarea-with-chip',
  templateUrl: './ui-textarea-with-chip.component.html',
  styleUrls: ['./ui-textarea-with-chip.component.scss'],
})
export class UiTextareaWithChipComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  disabled = false;
  @Input()
  set mediaType(input: MediaType) {
    this.ownMediaType = input;
  }
  get mediaType(): MediaType {
    return this.ownMediaType;
  }
  @Input()
  sizeLimit: number;
  @Input()
  width = '100%';
  @Input()
  maxWidth = '100%';
  @Input()
  textValue: string;
  @Input()
  formGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  set formReset(flag: boolean) {
    if (flag && this.chipElementList && this.textareaEle && this.formControl) {
      ChipHandle.BuildChipStatus(this.formControl.value, this.adChipList);
      this.textareaStyles = ChipHandle.GetTextareaStyles(this.textareaEle);
      this._renderChips(this.chipElementList, this.textareaEle);
    }
  }
  @Input()
  channelName: ChannelName;
  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>(false);

  @ViewChildren(MatChip)
  chipContainer: QueryList<MatChip>;
  @ViewChild('textEle')
  set textareaElement(ele: ElementRef) {
    if (!ele) {
      return;
    }
    this.textareaEle = ele.nativeElement;
    this.textareaStyles = ChipHandle.GetTextareaStyles(this.textareaEle);
    this.subTextareaKeyUp = fromEvent(this.textareaEle, 'keydown')
      .pipe(auditTime(50))
      .subscribe(() => {
        setTimeout(() => {
          this._renderChips(this.chipElementList, this.textareaEle);
        }, 0);
      });

    this.subTextareaScroll = fromEvent(this.textareaEle, 'scroll')
      .pipe(auditTime(50))
      .subscribe((event: any) => {
        this.scrollInfo.newScrollTop = event.target['scrollTop'];
        this.scrollInfo.newScrollLeft = event.target['newScrollLeft'];
        this._renderChips(this.chipElementList, this.textareaEle);
      });

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(() => {
        this.textareaStyles = ChipHandle.GetTextareaStyles(this.textareaEle);
        this._renderChips(this.chipElementList, this.textareaEle);
      });
    });
    this.resizeObserver.observe(this.textareaEle);
  }

  removeIconPath: string;
  addIconPath: string;
  adChipList: IChipObject[];
  formControl: AbstractControl;

  private indexBackup: number;
  private textareaEle: any;
  private chipElementList: any;
  private textareaStyles: ITextareaStyle;

  private timerId: any;
  private readonly textCaretInfo: ICaretInfo;
  private readonly scrollInfo: IScrollInfo;

  private ownMediaType: MediaType;
  private subChipChange: Subscription;
  private subTextareaKeyUp: Subscription;
  private subTextareaScroll: Subscription;
  private resizeObserver: any;

  constructor(
    private chipParseService: AdTextChipParseService,
    private validation: ValidationsService,
    private googleAnalytic: GoogleAnalyticsService,
  ) {
    this.removeIconPath = Utils.GetIconPath(IconType.Fill, 'Circle_Delete');
    this.addIconPath = Utils.GetIconPath(IconType.Fill, 'Circle_Plus');
    this.textCaretInfo = {
      selectionStart: null,
      selectionEnd: null,
    };
    this.scrollInfo = {};
    this.scrollInfo.newScrollLeft = this.scrollInfo.newScrollTop = 0;
    this.scrollInfo.oldScrollLeft = this.scrollInfo.oldScrollTop = 0;
  }

  ngOnInit() {
    this.adChipList = this.chipParseService.buildChipObject(this.chipParseService.getAdTextChipList(this.channelName));
    this._initForm();
    ChipHandle.BuildChipStatus(this.controlValue, this.adChipList);
    this.textareaStyles = ChipHandle.GetTextareaStyles(this.textareaEle);
  }

  ngAfterViewInit() {
    if (this.chipContainer) {
      this.chipElementList = this.chipContainer;
      this._renderChips(this.chipElementList, this.textareaEle);
      this.subChipChange = this.chipContainer.changes.subscribe((elementList) => {
        this.chipElementList = elementList;
        this._renderChips(elementList, this.textareaEle);
      });
    }
  }

  ngOnDestroy(): void {
    Utils.CancelSubscribe(this.subChipChange, this.subTextareaKeyUp, this.subTextareaScroll);
  }
  // TODO refactor event.keyCode;
  captureKeyDownEvent(e: KeyboardEvent, textEle: any) {
    // do not put these to the observable, need to check the key every event
    // change the work of the key by condition to protect the placeholder string.
    if (e.key === 'Backspace' || e.key === 'Delete') {
      // delete or backspace
      if (textEle.selectionStart !== textEle.selectionEnd) {
        ChipHandle.CalculateDeleteRange(textEle, this.adChipList);
        this.indexBackup = textEle.selectionStart;
        this.formControl.setValue(
          textEle.value.slice(0, textEle.selectionStart) + textEle.value.slice(textEle.selectionEnd),
        );
        this._deleteKeyHandle();
        this._resetCaretIndex(textEle);
        e.preventDefault();
      } else {
        const set = new Set();
        for (const item of this.adChipList) {
          if (item.addToText && item.startIndex !== null) {
            for (let i = 1; i <= item.placeholderString.length; i++) {
              set.add(item.startIndex + i);
            }
          }
        }
        if (set.has(textEle.selectionStart)) {
          e.preventDefault();
          for (const item of this.adChipList) {
            if (item.addToText && item.startIndex !== null) {
              if (textEle.selectionStart === item.startIndex + item.placeholderString.length) {
                textEle.setSelectionRange(item.startIndex, item.startIndex);
                return;
              }
            }
          }
        } else {
          const newValue =
            textEle.value.slice(0, textEle.selectionStart - 1) + textEle.value.slice(textEle.selectionStart);
          ChipHandle.UpdateChipIndex(newValue, this.adChipList);
        }
      }
    } else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
      // left arrow
      setTimeout(() => {
        for (const item of this.adChipList) {
          if (item.addToText && item.startIndex !== null) {
            const delta = textEle.selectionStart - item.startIndex;
            if (delta > 0 && delta < item.placeholderString.length) {
              const position =
                e.key === 'ArrowRight' ? item.startIndex + item.placeholderString.length : item.startIndex;
              textEle.setSelectionRange(position, position);
            }
          }
        }
      }, 0);
    } else if (e.key === ' ') {
      // space key
      // allow, maybe do something later
    } else {
      const ctrlDown = e.ctrlKey || e.metaKey;
      if (ctrlDown && (e.key.toLowerCase() === 'c' || e.key.toLowerCase() === 'x')) {
        // x => 88, c => 67, v => 86
        // not allow the cut and copy by keyboard
        e.preventDefault();
        return;
      }
      setTimeout(() => {
        ChipHandle.UpdateChipIndex(this.formControl.value, this.adChipList);
      }, 100);
    }
    setTimeout(() => {
      this.emitValueChange();
    }, 0);
  }

  emitValueChange() {
    if (this.textareaEle.value !== this.formControl.value) {
      this.formControl.patchValue(this.textareaEle.value);
    }
    this.valueChange.emit({
      form: this.formGroup,
      controlName: this.controlName,
    });
  }

  addChipToText(chipData: IChipObject) {
    if (this.formGroup.controls.googleChannelDsc) {
      if (chipData.chipName === 'Location') {
        this.googleAnalytic.eventTracking(
          'Create Campaign',
          'Select Google Localized Address Chip',
          'Google Localized Address Chip',
        );
      } else if (chipData.chipName === 'City') {
        this.googleAnalytic.eventTracking(
          'Create Campaign',
          'Select Google Localized City Chip',
          'Google Localized City Chip',
        );
      }
    }

    if (this.disabled) {
      return;
    }
    if (this.textCaretInfo.selectionStart === null && this.textCaretInfo.selectionEnd === null) {
      this.textCaretInfo.selectionStart = this.textCaretInfo.selectionEnd = this.formControl.value.length;
    }
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
    this._deleteAdContent(this.textCaretInfo);

    const adText = this.formControl.value;
    this.formControl.setValue(
      adText.slice(0, this.textCaretInfo.selectionStart) +
        chipData.placeholderString +
        adText.slice(this.textCaretInfo.selectionStart),
    );
    this.textCaretInfo.selectionStart = null;
    this.textCaretInfo.selectionEnd = null;
    ChipHandle.BuildChipStatus(this.formControl.value, this.adChipList);
    this.emitValueChange();
  }

  removeChipFromText(chipData: IChipObject) {
    if (this.disabled) {
      return;
    }
    const adText = this.formControl.value;
    const index = adText.indexOf(chipData.placeholderString);
    if (index !== -1) {
      // remove 1 for space character at the each side of the placeholderString
      this.formControl.setValue(adText.slice(0, index) + adText.slice(index + chipData.placeholderString.length));
    }
    ChipHandle.BuildChipStatus(this.formControl.value, this.adChipList);
    this.emitValueChange();
  }

  notAllowCutAndCopy(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  loseFocus(e: any) {
    this.textCaretInfo.selectionStart = e.target.selectionStart;
    this.textCaretInfo.selectionEnd = e.target.selectionEnd;
    this.timerId = setTimeout(() => {
      this.textCaretInfo.selectionStart = null;
      this.textCaretInfo.selectionEnd = null;
    }, 2000);
  }

  captureMouseDown(e: MouseEvent) {
    setTimeout(() => {
      for (const item of this.adChipList) {
        if (item.addToText && item.startIndex !== null) {
          const delta = this.textareaEle.selectionStart - item.startIndex;
          if (delta > 0 && delta < item.placeholderString.length) {
            const position =
              delta < item.placeholderString.length / 2
                ? item.startIndex
                : item.startIndex + item.placeholderString.length;
            this.textareaEle.setSelectionRange(position, position);
          }
        }
      }
    }, 0);
  }

  private _initForm() {
    if (!this.formGroup) {
      this.formGroup = new FormGroup({});
    }
    if (!this.controlName) {
      this.controlName = 'chipTextDefault';
    }
    if (!this.textValue) {
      this.textValue = '';
    }
    if (!this.formGroup.get(this.controlName)) {
      this.formGroup.addControl(
        this.controlName,
        new FormControl(
          {
            value: this.textValue,
            disabled: this.disabled,
          },
          [Validators.required],
        ),
      );
    }
    this.formControl = this.formGroup.get(this.controlName);
  }

  private _resetCaretIndex(ele: any) {
    ChipHandle.UpdateChipIndex(this.formControl.value, this.adChipList);
    for (const item of this.adChipList) {
      if (
        item.addToText &&
        item.startIndex !== null &&
        this.indexBackup === item.startIndex + item.placeholderString.length
      ) {
        setTimeout(() => {
          ele.focus();
          ele.setSelectionRange(item.startIndex, item.startIndex);
        }, 0);
        return;
      }
    }
    setTimeout(() => {
      ele.focus();
      ele.setSelectionRange(this.indexBackup, this.indexBackup);
    }, 0);
  }

  private _deleteKeyHandle() {
    for (const item of this.adChipList) {
      if (item.needDelete) {
        item.needDelete = false;
        item.startIndex = null;
        item.addToText = false;
      }
    }
  }

  private _deleteAdContent(caretInfo: ICaretInfo) {
    if (caretInfo.selectionStart !== caretInfo.selectionEnd) {
      ChipHandle.CalculateDeleteRange(this.textCaretInfo, this.adChipList);
      const remainedStr =
        this.formControl.value.slice(0, caretInfo.selectionStart) +
        this.formControl.value.slice(caretInfo.selectionEnd);
      this._deleteKeyHandle();
      ChipHandle.UpdateChipIndex(remainedStr, this.adChipList);
      this.formControl.setValue(remainedStr);
    }
  }

  private _renderChips(elementList: any, element: any) {
    if (!Array.isArray(this.adChipList) || !element) {
      return;
    }
    for (const item of this.adChipList) {
      if (item.addToText) {
        for (const res of elementList['_results']) {
          const ele = res['_elementRef'].nativeElement;
          if (ele.innerHTML.indexOf(item.chipName) !== -1) {
            if (!this.textareaStyles.lineHeight) {
              this.textareaStyles = ChipHandle.GetTextareaStyles(element);
            }

            const [height, deltaWidth] = ChipHandle.GetChipPosition(
              item,
              this.formGroup.get(this.controlName).value,
              this.textareaStyles,
            );
            const lineHeight = parseInt(this.textareaStyles.lineHeight, 10);
            const paddingTop = this.textareaStyles.paddingTopWidth;
            const paddingRight = this.textareaStyles.paddingRightWidth;

            const shouldShow =
              height - this.scrollInfo.newScrollTop > 0 &&
              height - lineHeight - this.scrollInfo.newScrollTop <
                this.textareaStyles.availableHeight + this.textareaStyles.paddingTopWidth;
            if (shouldShow) {
              ele.style.opacity = 1;
              ele.style.visibility = 'visible';
              ele.style.zIndex = 8;

              ele.style.height = '1.6em';
              ele.style.top = height - lineHeight - this.scrollInfo.newScrollTop + paddingTop + 'px';
              let rightAppend = 0;
              switch (item.chipName) {
                case 'City':
                  rightAppend = -2;
                  break;
                default:
                  rightAppend = 0;
                  break;
              }
              ele.style.right = paddingRight + deltaWidth + rightAppend + 'px';
              let widthAppend = 0;
              switch (item.chipName) {
                case 'Location':
                  widthAppend = 14;
                  break;
                default:
                  widthAppend = 16;
                  break;
              }
              ele.style.width =
                ChipHandle.CalculateWordDimensions(item.placeholderString.trim(), this.textareaStyles) +
                widthAppend +
                'px';
            } else {
              ele.style.opacity = 0;
              ele.style.visibility = 'hidden';
              ele.style.zIndex = -1;
            }
            break;
          }
        }
      }
    }
    this.scrollInfo.oldScrollTop = this.scrollInfo.newScrollTop;
    this.scrollInfo.oldScrollLeft = this.scrollInfo.newScrollLeft;
  }

  get validationError() {
    return !!this.errorMessage && this.formControl.invalid && (this.formControl.dirty || this.formControl.touched);
  }

  get errorMessage() {
    return this.validation.getValidationErrorMessage(this.formControl);
  }

  get _formControl(): AbstractControl {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName);
    }
    return null;
  }

  get controlValue() {
    const control = this._formControl;
    if (!!control) {
      return control.value || '';
    }
    return '';
  }

  get getRealChipTextLength() {
    const controlValue = this.controlValue;
    return this.chipParseService.getRealChipTextLength(controlValue);
  }
}
