import { ChannelName, CheckFn, CampaignTemplate, IconType } from '@interfaces';
import { Subscription } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { isPlainObject, omit } from 'lodash';

export const Noop = (payload?: any) => void 0;

export function ChangeToUpperCase(source: any): string {
  if (typeof source === 'string' || typeof source === 'number' || typeof source === 'boolean') {
    return String(source).toString().toUpperCase();
  }
  return '';
}

export function CapitalizeFirstLetter(source: any): string {
  if ((typeof source === 'string' && source) || typeof source === 'number' || typeof source === 'boolean') {
    const tmp = String(source);
    return tmp[0].toUpperCase() + tmp.slice(1);
  }
  return '';
}

export function GetWebsiteURL(locations: any[], defaultUrl: string, urls: any[] = []) {
  if (Array.isArray(locations)) {
    if (locations.length === 1) {
      if (Array.isArray(urls) && urls.length) {
        const len = urls.length;
        for (let i = 0; i < len; i++) {
          if (locations[0].locationId === urls[i].locationId) {
            return urls[i].url || defaultUrl;
          }
        }
      }
    }
  }
  return defaultUrl;
}

export function GetIconPath(iconType: IconType, iconName: string): string {
  return `./assets/icons/${iconType}/${iconName}.svg`;
}

export function FindItemInArray<T, VT>(sourceArr: T[], compared: any, condition: CheckFn<VT, T>): [T, number] {
  if (Array.isArray(sourceArr) && sourceArr.length && condition) {
    for (let i = 0; i < sourceArr.length; i++) {
      if (condition(compared, sourceArr[i])) {
        return [sourceArr[i], i];
      }
    }
  }
  return [null, -1];
}

export function IsArrayEmpty(sourceArr: any[]): boolean {
  return !(Array.isArray(sourceArr) && sourceArr.length);
}

export function HasNullEmptyArrayFieldInArray(sourceArr: any[], field: string): boolean {
  return !(Array.isArray(sourceArr) && sourceArr.some((item) => Array.isArray(item[field]) && item[field].length));
}

export const SetTooltip = (displayFlag: boolean, timerId: any, cb = Noop, defaultTime = 200): [boolean, any] => {
  if (displayFlag) {
    if (timerId) {
      clearTimeout(timerId);
    }
    return [displayFlag, null];
  } else {
    const id = setTimeout(cb, defaultTime);
    return [!displayFlag, id];
  }
};

export function GetDetailedTargeting(template: CampaignTemplate, channel: ChannelName) {
  if (!template) {
    return '';
  }

  try {
    let detailedTargeting;
    switch (channel) {
      case ChannelName.FACEBOOK:
        detailedTargeting = JSON.parse(template.channel.facebook.facebookChannelDetailTargeting);
        break;
      case ChannelName.INSTAGRAM:
        detailedTargeting = JSON.parse(template.channel.instagram.instagramChannelDetailTargeting);
        break;
      default:
        return '';
    }
    return Array.isArray(detailedTargeting) ? detailedTargeting.map((item) => item.name).join(', ') : '';
  } catch (error) {
    return '';
  }
}

export function CancelSubscribe(...subs: Subscription[]) {
  if (Array.isArray(subs)) {
    subs.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }
}

export function GetElementStyle(oElm: any, strCssRule) {
  let strValue = '';
  if (document.defaultView && document.defaultView.getComputedStyle) {
    strValue = document.defaultView.getComputedStyle(oElm, '').getPropertyValue(strCssRule);
  } else if (oElm.currentStyle) {
    strCssRule = strCssRule.replace(/\-(\w)/g, (strMatch, p1) => {
      return p1.toUpperCase();
    });
    strValue = oElm.currentStyle[strCssRule];
  }
  return strValue;
}

export function FormatNumber(input: any, decimalLen: number = 2): number {
  const val = parseFloat(input);
  if (isNaN(val)) {
    return 0;
  }
  const tmp = String((val + 10e-10).toFixed(decimalLen)).split('.');
  return Number(tmp[0] + '.' + (tmp[1] ? tmp[1].slice(0, decimalLen) : '0'));
}

export function FormatDateComponentString(input: number | string): string {
  const val = parseInt(input + '', 10);
  return val < 10 ? '0' + val : val + '';
}

export function FormatFullYearString(year: string): string {
  const century = Math.floor(new Date().getFullYear() / 100);
  switch (year.length) {
    case 1: {
      return `${century}0${year}`;
    }
    case 2: {
      return `${century}${year}`;
    }
    case 3: {
      return `${Math.floor(century / 10)}${year}`;
    }
    default:
      return year;
  }
}

export function IsValidDate(date: any): boolean {
  return !isNaN(date) && date instanceof Date;
}

export function DateFromNgbDate(ngbDate: NgbDate): Date {
  return ngbDate ? new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day) : null;
}

export function FormatDateToNgbDate(dateValue: any): NgbDate {
  const date = new Date(dateValue);
  if (dateValue && IsValidDate(date)) {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }
  return null;
}

export function BuildNgbDate(date: any): NgbDate {
  return date && date.year && date.month && date.day ? new NgbDate(date.year, date.month, date.day) : null;
}

export function BuildDateString(dateValue: any): string {
  if (!dateValue) {
    return null;
  }
  return `${dateValue.year}-${FormatDateComponentString(dateValue.month)}-${FormatDateComponentString(dateValue.day)}`;
}

import * as moment from 'moment';
import { Params } from '@angular/router';
export function DateCompared(strOne: string, strTwo: string, type: any = 'days') {
  const one = moment(GetDateFromISODateString(strOne));
  const two = moment(GetDateFromISODateString(strTwo));
  return one.diff(two, type);
}

export function GetDateFromISODateString(input: any): Date {
  return new Date(String(input).slice(0, 10) + 'T00:00:00');
}
export function omitTypename(obj): any {
  const cleanObj = Object.assign({}, omit(obj, ['__typename']));
  for (const prop in cleanObj) {
    if (isPlainObject(cleanObj[prop])) {
      cleanObj[prop] = this.omitTypename(cleanObj[prop]);
    }
  }
  return cleanObj;
}

export function convertJsonToUrlQueryParams(json: Params) {
  return (
    '?' +
    Object.keys(json)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&')
  );
}
