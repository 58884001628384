<form *ngIf="!!formGroup && options && options.length > 0" [formGroup]="formGroup">
  <div class="btn-group-toggle radio-btn-group button-group" [class.group-radio]="!separate" ngbRadioGroup name="radioBasic"
       [ngStyle]="style" [style.grid-template-columns]="gridColumns" [formControlName]="controlName" [class.grid]="!!width">
    <label ngbButtonLabel *ngFor="let option of options" class="btn-primary radio-btn" [class.error-border]="isError"
           [class.disabled-btn]="disabled || !!option.disabled">
      <div class="flex-hor-center radio-btn-content text-medium">
        <app-ui-icon *ngIf="!!option.icon" [size]="iconSize" [icon]="option.icon"></app-ui-icon>
        <app-ui-icon *ngIf="!!option.fillIcon" [size]="iconSize" [fillIcon]="option.fillIcon"></app-ui-icon>
        <app-ui-icon *ngIf="!!option.logo" [size]="iconSize" [logo]="option.logo"></app-ui-icon>
        <input ngbButton type="radio" [value]="option.value" (click)="checkIsAble($event, option)">
        {{option.text}}
      </div>
    </label>
  </div>
</form>
