import { gql } from 'apollo-angular';

const roleFragment = gql`
  fragment role on RbacRoleType {
    rbacRoleId
    rbacRoleNme
    rbacRoleDsc
    accessPolicyIds
    denyPolicyIds
    naPolicyIds
  }
`;

const policyFragment = gql`
  fragment policy on RbacPolicyType {
    rbacPolicyId
    rbacPolicyNme
    rbacPolicyDsc
    accessRoles {
      ...role
    }
    denyRoles {
      ...role
    }
    naRoles {
      ...role
    }
  }
  ${roleFragment}
`;

export const role = gql`
  query role($id: Int!) {
    role(id: $id) {
      ...role
    }
  }
  ${roleFragment}
`;

export const roles = gql`
  query roles {
    roles {
      ...role
    }
  }
  ${roleFragment}
`;

export const policies = gql`
  query policies($pageNum: Int, $pageSize: Int, $text: String, $asc: Boolean, $roleIds: [Int]) {
    policies(pageNum: $pageNum, pageSize: $pageSize, text: $text, asc: $asc, roleIds: $roleIds) {
      policies {
        ...policy
      }
      count
    }
  }
  ${policyFragment}
`;

export const editPolicy = gql`
  mutation editPolicy($policyId: Int, $accessRoleIds: [Int], $denyRoleIds: [Int]) {
    editPolicy(policyId: $policyId, accessRoleIds: $accessRoleIds, denyRoleIds: $denyRoleIds) {
      success
      message
    }
  }
`;
