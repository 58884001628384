import { Injectable } from '@angular/core';
import * as gql from './targeting.gql';
import { Apollo } from 'apollo-angular';
import { Observable, of, Subject, combineLatest } from 'rxjs';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { DictionaryService } from '../dictionary/dictionary.service';

import {
  ITargetingSearch,
  TargetingBrowse,
  ITargetingSuggestion,
  Targeting,
  TargetingEstimate,
  DetailedTargetingInput,
  ITargetingLocation,
  SpecialAdCategoryType,
  ITargetingRegionDetail,
  TargetingInput,
} from '@interfaces';

// TODO replace all 'object' with specific types

@Injectable({
  providedIn: 'root',
})
export class TargetingService {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  defaultLocations: Array<ITargetingLocation> = [
    {
      excluded: false,
      key: 'US',
      type: 'country',
    },
  ];

  constructor(private apollo: Apollo, private dictionaryService: DictionaryService) {}

  getSearches(
    q: string,
    targetedUIDs: string[],
    variables?: {
      type?: string;
      campaignTemplateSpecialAdCategory?: string;
    },
  ): Observable<ITargetingSearch[]> {
    variables = variables || {};
    if (!q.trim()) {
      return of([]);
    }
    return this.apollo
      .watchQuery<{ targetingSearch: Array<ITargetingSearch> }>({
        fetchPolicy: 'network-only',
        query: gql.getTargetingSearch,
        variables: {
          q,
          campaignTemplateSpecialAdCategory: variables.campaignTemplateSpecialAdCategory || '',
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const targetingResult = data.targetingSearch.filter((f) => !targetedUIDs.includes(f.id + ':' + f.type));
          return [
            ...targetingResult.map((item) => {
              const result = Object.assign({}, item);
              if (!!result.path) {
                result.path = [...result.path];
              }
              return result;
            }),
          ];
        }),
        catchError(() => of([])),
      );
  }

  getSuggestions(
    targetingList: Array<ITargetingSuggestion>,
    campaignTemplateSpecialAdCategory = '',
  ): Observable<Array<ITargetingSuggestion>> {
    targetingList = !!targetingList ? targetingList : [];
    campaignTemplateSpecialAdCategory =
      campaignTemplateSpecialAdCategory === SpecialAdCategoryType.NONE ? '' : campaignTemplateSpecialAdCategory;
    return this.apollo
      .watchQuery<{ targetingSuggestions: Array<TargetingBrowse> }>({
        fetchPolicy: 'network-only',
        query: gql.getTargetingSuggestions,
        variables: {
          targetingList,
          campaignTemplateSpecialAdCategory,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return [
            ...data.targetingSuggestions.map((item) => {
              const result = Object.assign({}, item);
              if (!!result.path) {
                result.path = [...result.path];
              }
              return result;
            }),
          ];
        }),
        catchError(() => of([])),
      );
  }

  getBrowse(campaignTemplateSpecialAdCategory = ''): Observable<Array<TargetingBrowse>> {
    campaignTemplateSpecialAdCategory =
      campaignTemplateSpecialAdCategory === SpecialAdCategoryType.NONE ? '' : campaignTemplateSpecialAdCategory;
    return this.apollo
      .watchQuery<{ targetingBrowse: Array<TargetingBrowse> }>({
        fetchPolicy: 'network-only',
        query: gql.getTargetingBrowse,
        variables: {
          campaignTemplateSpecialAdCategory,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return [
            ...data.targetingBrowse.map((item) => {
              const result = Object.assign({}, item);
              if (!!result.path) {
                result.path = [...result.path];
              }
              return result;
            }),
          ];
        }),
        catchError(() => of([])),
      );
  }

  getDeliveryEstimate(detailedTargetingInput: DetailedTargetingInput): Observable<TargetingEstimate> {
    return this.apollo
      .watchQuery<{ targetingEstimate: TargetingEstimate }>({
        fetchPolicy: 'network-only',
        query: gql.getTargetingEstimate,
        variables: { detailedTargetingInput },
      })
      .valueChanges.pipe(
        map(({ data }) => data.targetingEstimate),
        catchError(() => of(null)),
      );
  }

  getRegionDetail(stateDetail: {
    abbreviation: string;
    state: string;
    key?: string;
  }): Observable<Array<ITargetingRegionDetail>> {
    return this.apollo
      .watchQuery<{ regionSearchDetail: Array<ITargetingRegionDetail> }>({
        fetchPolicy: 'network-only',
        query: gql.getRegionSearchDetail,
        variables: {
          state: stateDetail.state,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.regionSearchDetail.filter((item) => !!item && !!item.country_code && !!item.type);
        }),
        catchError(() => of(null)),
      );
  }

  getGroupedByType(targetingData: Targeting): Array<TargetingBrowse> {
    const result = [];
    const arr = {};
    targetingData.detailedTargeting.forEach((targeting) => {
      const type = targeting['type'];
      if (!arr[type]) {
        arr[type] = [];
      }
      if (type === 'education_statuses' || type === 'relationship_statuses') {
        arr[type].push(targeting['id']);
      } else {
        arr[type].push({ id: targeting['id'], name: encodeURIComponent(targeting['name']) });
      }
    });
    result.push(arr);
    return result;
  }

  getLocationState(targetingData: Targeting, cb?: () => void): void {
    const getState = [];
    const getStateKeys = [];
    let locations: Array<any> = this.defaultLocations;
    if (targetingData.locations && targetingData.locations.length > 0) {
      locations = targetingData.locations;
    }
    locations.forEach((location) => {
      if (location.locationState && location.locationState.length > 0) {
        location.locationState.forEach((state) => {
          if (!(getState || []).find((getStateItem) => getStateItem === state)) {
            const findState = this.dictionaryService.getState(state);
            if (!!findState && !!findState.state && !findState.key) {
              const stateAsync = this.getRegionDetail(findState);
              if (!!stateAsync) {
                getStateKeys.push(stateAsync);
              }
            }
            getState.push(state);
          }
        });
      }
    });

    if (getStateKeys && getStateKeys.length > 0) {
      combineLatest(getStateKeys)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((statesInfoArray: ITargetingRegionDetail[][]) => {
          if (!!statesInfoArray && statesInfoArray.length > 0) {
            statesInfoArray.forEach((statesInfo) => {
              const stateInfo = (statesInfo || []).find(
                (searchResult) => searchResult.country_code === 'US' && searchResult.type === 'region',
              );
              if (stateInfo) {
                this.dictionaryService.setState(stateInfo);
              }
            });
          }
          if (!!cb) {
            cb();
          }
        });
    } else if (!!cb) {
      cb();
    }
  }

  getGeoLocations(variables?: {
    locations?: Array<ITargetingLocation>;
    specialAdCategory?: SpecialAdCategoryType;
    templateRadius?: string;
  }): any {
    // TODO: add return type
    const result = {};
    variables = variables || {};
    let locations = variables.locations || [];
    const specialAdCategory = variables.specialAdCategory || '';
    const hasSpecialAdCategory = !(specialAdCategory === SpecialAdCategoryType.NONE || !specialAdCategory);

    if (!locations || locations.length === 0) {
      locations = this.defaultLocations;
    }
    if (!hasSpecialAdCategory) {
      locations.forEach((location) => {
        if (location['locationExcludedZips']) {
          const excludedZipsLocation = {
            excluded: true,
            locationZips: location['locationExcludedZips'],
          };
          locations.push(excludedZipsLocation);
        }
        if (location['locationExcludedState']) {
          const excludedStateLocation = {
            excluded: true,
            locationState: location['locationExcludedState'],
          };
          locations.push(excludedStateLocation);
        }
        if (location['locationExcludedRadius']) {
          const locationExcludedRadius = location['locationExcludedRadius'] as Array<string>;
          const excludedRadiusLocations = locationExcludedRadius
            .map((radius) => {
              if (!!radius) {
                return {
                  excluded: true,
                  locationRadius: radius,
                  locationAddress: location['locationAddress'],
                  locationAddressLat: location['locationAddressLat'],
                  locationAddressLng: location['locationAddressLng'],
                };
              }
              return null;
            })
            .filter((item) => !!item);
          locations.push(...excludedRadiusLocations);
        }
      });
    } else if (hasSpecialAdCategory && !!variables.templateRadius) {
      locations = locations.map((location) => {
        const res = Object.assign({}, location);
        if (!!location['locationRadius'] || (!!location['locationZips'] && location['locationZips'].length > 0)) {
          location['locationRadius'] = variables.templateRadius;
        }
        return res;
      });
    }
    locations.forEach((location) => {
      // rename the variables: o, key1, key2
      const key1 = location['excluded'] ? 'excluded_geo_locations' : 'geo_locations';
      let key2;

      if (!result[key1]) {
        result[key1] = {};
      }

      if (!!location['type']) {
        let o;
        if (location['type'] === 'country') {
          key2 = 'countries';
          o = location['key'];
        }
        if (!result[key1][key2]) {
          result[key1][key2] = [];
        }
        result[key1][key2].push(o);
      } else {
        if (!!location['locationRadius']) {
          key2 = 'custom_locations';
          if (!result[key1][key2]) {
            result[key1][key2] = [];
          }
          const radiusString = location['locationRadius'] + '';
          let radius = Number.parseInt(radiusString.split(' ')[0], 10);
          if (radius < 15 && hasSpecialAdCategory) {
            radius = 15;
          }
          const distanceUnit = radiusString.split(' ')[1] === 'mi' ? 'mile' : 'kilometer';
          result[key1][key2].push({
            address_string: location['locationAddress'],
            radius,
            latitude: location['locationAddressLat'],
            longitude: location['locationAddressLng'],
            distance_unit: distanceUnit,
          });
        } else if (!!location['locationState'] && location['locationState'].length > 0) {
          key2 = 'regions';
          let states = [];
          const statesWithNull = location['locationState'].map((state) => {
            const stateInfo = this.dictionaryService.getState(state);
            if (!!stateInfo) {
              return {
                key: stateInfo.key,
              };
            } else {
              return null;
            }
          });
          states = statesWithNull.filter((state) => state);
          if (!result[key1][key2]) {
            result[key1][key2] = [];
          }
          result[key1][key2].push(...states);
        } else if (!!location['locationZips'] && location['locationZips'].length > 0) {
          key2 = 'zips';
          const zips = [];
          location['locationZips'].forEach((zip) => {
            zips.push({ key: `US:${zip}` });
          });
          if (!result[key1][key2]) {
            result[key1][key2] = [];
          }
          result[key1][key2].push(...zips);
        }
      }
    });

    const geoLocations = result['geo_locations'];
    const excludedGeoLocations = result['excluded_geo_locations'];
    let hasTruthLocation = false;

    if (!!geoLocations && Object.keys(geoLocations).length > 0) {
      hasTruthLocation = true;
    }
    if (!!excludedGeoLocations && Object.keys(excludedGeoLocations).length > 0) {
      hasTruthLocation = true;
    }

    if (!hasTruthLocation) {
      return this.getGeoLocations();
    } else {
      return result;
    }
  }

  getPotentialReachByLocation(targeting: Targeting, geoLocations: any): Observable<TargetingEstimate> {
    const singleLocationTargetingInput: TargetingInput = {
      flexible_spec: this.getGroupedByType(targeting),
      age_min: targeting.age_min,
      age_max: targeting.age_max,
      genders: targeting.genders,
      geo_locations: geoLocations['geo_locations'] || null,
      excluded_geo_locations: geoLocations['excluded_geo_locations'] || null,
      publisher_platforms: [targeting.channelName],
    };
    return this.getDeliveryEstimate({
      optimizationGoal: targeting.optimizationGoal || 'REACH',
      targetingSpec: JSON.stringify(singleLocationTargetingInput),
    } as DetailedTargetingInput);
  }

  getDefaultLocations(): Array<ITargetingLocation> {
    return this.defaultLocations;
  }
}
