import { gql } from 'apollo-angular';

export const keywordFragment = gql`
  fragment keyword on KeywordGroupType {
    keywordGroupId
    keywordGroupNme
    keywordGroupContent
    keywordGroupCreatedAt
  }
`;

export const createKeywordGroup = gql`
  mutation createKeywordGroup($input: KeywordGroupInput!) {
    createKeywordGroup(input: $input) {
      ...keyword
    }
  }
  ${keywordFragment}
`;

export const updateKeywordGroup = gql`
  mutation updateKeywordGroup($id: ID!, $input: KeywordGroupInput!) {
    updateKeywordGroup(id: $id, input: $input) {
      ...keyword
    }
  }
  ${keywordFragment}
`;

export const findKeyWordGroupsByPage = gql`
  query findKeyWordGroupsByPage($pageInfo: KeywordGroupPageInput!) {
    findKeyWordGroupsByPage(pageInfo: $pageInfo) {
      count
      keywordGroups {
        ...keyword
      }
    }
  }
  ${keywordFragment}
`;

export const searchKeyWordGroupsByPages = gql`
  query searchKeyWordGroupsByPages($input: KeywordGroupPageInput!) {
    searchKeyWordGroupsByPages(input: $input) {
      count
      keywordGroups {
        ...keyword
      }
    }
  }
  ${keywordFragment}
`;

export const searchKeywordByPages = gql`
  query searchKeyWordByPages($input: SearchKeywordInput!) {
    searchKeyWordByPages(input: $input) {
      count
      keywordGroups {
        ...keyword
      }
    }
  }
  ${keywordFragment}
`;

export const currentKeywordGroupInfo = gql`
  query currentKeywordGroupInfo($id: ID!) {
    currentKeywordGroupInfo(id: $id) {
      keywordGroups {
        ...keyword
      }
    }
  }
  ${keywordFragment}
`;
