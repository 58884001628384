<div class="flex-row">
  <h2 mat-dialog-title class="page-title">{{listData.title}}</h2>
  <div class="sort-select">
    <div *ngIf="isNormal">
      <span class="main-text mr-xs">Sort by</span>
      <app-ui-dropdown [options]="sortOptions" [selected]="'desc'" (changeEvent)="sortList($event)">
      </app-ui-dropdown>
    </div>
    <span class="close-icon" (click)="closeDialog()">close</span>
  </div>
</div>

<div class="dialog-card-content">
  <ul class="mt-s" *ngIf="!!list.length">
    <mat-dialog-content *ngIf="isNormal">
      <app-ui-card-list-item
        *ngFor="let listItem of list;"
        [listData]="listItem"
        [subject]="listData.subject"
        [value]="listData.value"
        [displayRate]="false">
      </app-ui-card-list-item>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="isLatestActivity">
      <li class="list-item" *ngFor="let activity of list">
        <span class="main-text">
          <ng-template [ngIf]="activity.category === 'newAds'">
            <img src="assets/icons/logo/{{activity.campaignChannelNme}}.svg" class="channel-icon" alt="">
            <strong>{{activity.campaignTemplateNme}}</strong>
            <span>has been run by</span>
            <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
            <strong>{{activity.locationNme}}.</strong>
          </ng-template>

          <ng-template [ngIf]="activity.category==='firstAdPlaced'">
            <img src="assets/icons/outline/Location.svg" alt="" class="location-icon">
            <strong>{{activity.locationNme}}</strong>
            <span>placed their first ad</span>
            <img src="assets/icons/logo/{{activity.campaignChannelNme}}.svg" class="channel-icon" alt="">
            <strong>{{activity.campaignTemplateNme}}</strong>
          </ng-template>

          <ng-template [ngIf]="activity.category==='hit2kImpressions'">
            <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
            <strong>{{activity.locationNme}}'s</strong>
            <span>campaign</span>
            <img src="assets/icons/logo/{{activity.campaignChannelNme}}.svg" class="channel-icon" alt="">
            <strong>{{activity.campaignTemplateNme}}</strong>
            <span>reached</span>
            <span>
              {{2000|number}}
              impressions!
            </span>
          </ng-template>

          <ng-template [ngIf]="activity.category==='hit5kImpressions'">
            <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
            <strong>{{activity.locationNme}}'s</strong>
            <span>campaign</span>
            <img src="assets/icons/logo/{{activity.campaignChannelNme}}.svg" class="channel-icon" alt="">
            <strong>{{activity.campaignTemplateNme}}</strong>
            <span>reached</span>
            <span>
              {{5000|number}}
              impressions!
            </span>
          </ng-template>

          <ng-template [ngIf]="activity.category==='hit10kImpressions'">
            <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
            <strong>{{activity.locationNme}}'s</strong>
            <span>campaign</span>
            <img src="assets/icons/logo/{{activity.campaignChannelNme}}.svg" class="channel-icon" alt="">
            <strong>{{activity.campaignTemplateNme}}</strong>
            <span>reached</span>
            <span>
              {{10000|number}}
              impressions!
            </span>
          </ng-template>

          <ng-template [ngIf]="activity.category==='adsExpiring'">
            <img src="assets/icons/logo/{{activity.campaignChannelNme}}.svg" class="channel-icon" alt="">
            <strong>{{activity.campaignTemplateNme}}</strong>
            <span>will expire this week. Time to create more campaign templates!</span>
          </ng-template>

          <ng-template [ngIf]="activity.category==='bestPerformingAds'">
            <img src="assets/icons/logo/{{activity.campaignChannelNme}}.svg" alt="" class="channel-icon">
            <strong>{{activity.campaignTemplateNme}}</strong>
            <span>had the highest CTR this past week with a CTR of </span>
            <span>{{activity.ctr | number: '0.0-2'}}%!</span>
          </ng-template>

          <ng-template [ngIf]="activity.category==='noAdsLocationList'">
            <img src="assets/icons/outline/Location.svg" class="location-icon" alt="">
            <strong>{{activity.locationNme}}</strong>
            <span>currently has no campaigns running.</span>
          </ng-template>
        </span>
        <span class="time-stamp">{{activity.hour}}</span>
      </li>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="isAdReviews">
      <li class="list-item campaign-review" *ngFor="let adReview of list">
        <div class="campaign-channel-title">
          <img *ngIf="!!adReview.campaignChannelNme" src="assets/icons/logo/{{adReview.campaignChannelNme}}.svg"
            [alt]="adReview.campaignChannelNme" class="channel-icon">
          <span *ngIf="!!adReview.campaignTemplateNme">{{adReview.campaignTemplateNme}}</span>
        </div>
        <!--TODO review count-->
        <div>
          <app-ui-star-rating [stars]="adReview.rate" [isShowReview]="adReview.isShowReview"></app-ui-star-rating>
        </div>
        <p *ngIf="!!adReview.comment" class="main-text mt-s">
          {{adReview.comment}}
        </p>
        <div class="mt-xs">
          <app-ui-user-icon [name]="adReview.owner"></app-ui-user-icon>
          <span class="user-name">{{adReview.owner}}</span>
        </div>
      </li>
    </mat-dialog-content>
  </ul>
</div>

<mat-dialog-content class="no-data-msg" *ngIf="!list.length">
  No Data Available
</mat-dialog-content>
