import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingStatusService } from '@services';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page-loading',
  templateUrl: './page-loading.component.html',
  styleUrls: ['./page-loading.component.scss'],
})
export class PageLoadingComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe$ = new Subject<void>();

  isDisplay: boolean;
  message: string;

  constructor(private loadingStatusService: LoadingStatusService) {}

  ngOnInit() {
    combineLatest([this.loadingStatusService.isLoading$, this.loadingStatusService.loadingMessage$])
      .pipe(
        map((status: [boolean, string]) => {
          const isLoading = status['0'];
          const loadingMessage = status['1'];
          this.message = loadingMessage;
          return isLoading || !!loadingMessage;
        }),
      )
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((isLoading) => {
        this.isDisplay = isLoading;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
