import { ILocation } from './location.interface';
import { IUser } from './user.interface';

export enum OrganizationType {
  ORGANIZATION = 'ORGANIZATION',
  CLIENT = 'CLIENT',
}

export interface FacebookAccountBindInfoInterface {
  facebookAccountId?: string;
  organizationId?: string;
  facebookAccountUserId?: string;
  facebookAccountUserNme?: string;
  facebookAccountInstagramAct?: string;
  facebookAccountDefaultPageRealId?: string;
  facebookAccountZeeInstagramAct?: string;
  facebookAccountDefaultZeePageRealId?: string;
  facebookAccountZeeAdActId?: string;
  facebookAccountFacebookConnectedInd?: boolean;
  facebookAccountInstagramConnectedInd?: boolean;
  facebookAccountAdActId?: string;
}

export interface GoogleAccountBindInfoInterface {
  googleAccountConnectedInd?: string;
  googleAccountDefaultCustomerId?: string;
}

export interface IOrganization {
  organizationId: string;
  organizationType: OrganizationType;
  parentId?: string;
  parentOrg?: IOrganization;
  childrenOrg?: Array<IOrganization>;
  organizationNme?: string;
  organizationContactNme?: string;
  organizationContactEmail?: string;
  organizationAddress?: string;
  organizationState?: string;
  locations?: Array<ILocation>;
  orgLogoUrl?: string;
  facebookAccount?: FacebookAccountBindInfoInterface;
  googleAccount?: GoogleAccountBindInfoInterface;
  organizationActive?: boolean;
  rootUser?: IUser;
  organizationReadOnly?: boolean;
  organizationCanDelete?: boolean;
  organizationGmbEnabled?: boolean;
  organizationCanvaEnabled?: boolean;
  organizationIsSmb?: boolean;
  organizationCanActivate?: boolean;
}

export interface ILimitedOrg {
  parentId?: string;
  organizationId: string;
  organizationNme: string;
  organizationType: OrganizationType;
  organizationContactNme?: string;
  organizationContactEmail?: string;
  organizationState?: string;
  organizationAddress?: string;
  orgLogoUrl?: string;
  organizationActive?: boolean;
  parentName?: string;
  locationTotal?: number;
  organizationReadOnly?: boolean;
  organizationGmbEnabled?: boolean;
  organizationCanvaEnabled?: boolean;
  organizationIsSmb?: boolean;
  organizationCreatedAt?: string;
  reseller?: boolean;
}

export interface IOrganizationInput {
  parentId: string;
  organizationNme: string;
  organizationType: OrganizationType;
  orgLogoUrl?: string;
}

export interface IOrganizationUpdate {
  parentId?: string;
  organizationNme?: string;
  organizationType?: OrganizationType;
  orgLogoUrl?: string;
  organizationActive?: boolean;
  organizationContactNme?: string;
  organizationGmbEnabled?: boolean;
  organizationCanvaEnabled?: boolean;
}

export interface INetworkInfo {
  organizationId: string;
  ownedLocationNum: number;
  networkSizeNum: number;
}

export interface IOrgAccountCreateInput {
  franchisorName: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  organizationReadOnly: boolean;
}

export interface IOrgAccountUpdateInput {
  organizationId: string;
  franchisorName?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  organizationReadOnly?: boolean;
}

export interface OrganizationDetailWithLocations extends OrganizationDetail {
  locations: {
    id: number;
    name: string;
  }[];
}

interface OrganizationContact {
  name?: string;
  phoneNumber?: string;
  email?: string;
}

interface ChildOrganization {
  id: number;
  name: string;
  contact: OrganizationContact;
}

export interface OrganizationDetail {
  id: number;
  name: string;
  parentId: number;
  active: boolean;
  logoUrl: string;
  contact: OrganizationContact;
  createdAt: string;
  childOrganizations: ChildOrganization[];
  reseller: boolean;
}
