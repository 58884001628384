import { gql } from 'apollo-angular';

export const topPercentByCpc = gql`
  query topPercentByCpc($campaignId: ID!) {
    topPercentByCpc(campaignId: $campaignId)
  }
`;

const totalMetricsFragment = gql`
  fragment totalMetrics on TotalMetrics {
    spend
    run
    impressions
    clicks
    conversion
    ctr
    cvr
    cpc
    days
    videoViews
    reach
  }
`;

const totalMetricsFragmentByCampaign = gql`
  fragment totalMetricsByCampaign on TotalMetricsByCampaign {
    spend
    run
    impressions
    clicks
    conversion
    ctr
    cvr
    cpc
    days
    videoViews
    reach
    campaignNme
    locationNme
    campaignType
  }
`;

const totalMetricsFragmentByTemplate = gql`
  fragment totalMetricsByTemplate on TotalMetricsByTemplate {
    spend
    impressions
    clicks
    conversion
    cpc
    ctr
    cvr
    days
    run
    videoViews
    reach
    campaignTemplateId
    campaignTemplateNme
    campaignTemplateGoal
    channelNme
    averageRate
  }
`;

const audienceOverviewFragment = gql`
  fragment audienceOverview on ReportingAudienceOverview {
    genderSummary {
      impressions
      gender
    }
    ageGenderSummary {
      channelNme
      data {
        impressions
        age
        gender
      }
    }
    regionSummary {
      impressions
      region
    }
    deviceSummary {
      impressions
      devicePlatform
      impressionDevice
    }
  }
`;

const latestActivitiesFragment = gql`
  fragment latestActivities on LatestActivitiesType {
    newAds {
      campaignTemplateNme
      date
      locationNme
      campaignChannelNme
      hourNbr
    }
    firstAdPlaced {
      campaignTemplateNme
      date
      locationNme
      campaignChannelNme
      hourNbr
    }
    hit2kImpressions: hitImpressions(count: 2000) {
      campaignTemplateNme
      date
      impressions
      locationNme
      campaignChannelNme
      hourNbr
    }
    hit5kImpressions: hitImpressions(count: 5000) {
      campaignTemplateNme
      date
      impressions
      locationNme
      campaignChannelNme
      hourNbr
    }
    hit10kImpressions: hitImpressions(count: 10000) {
      campaignTemplateNme
      date
      impressions
      locationNme
      campaignChannelNme
      hourNbr
    }
    adsExpiring {
      campaignTemplateNme
      date
      locationNme
      campaignChannelNme
      hourNbr
    }
    bestPerformingAds {
      campaignTemplateNme
      date
      ctr
      campaignChannelNme
      hourNbr
    }
    noAdsLocationList {
      locationNme
      date
    }
  }
`;

export const getCampaignReportingMetrics = gql`
  query reportingMetrics($campaignId: ID) {
    reportingMetrics(campaignId: $campaignId) {
      total {
        ...totalMetrics
      }
      totalWeeks {
        ...totalMetrics
      }
      peakDay {
        dayOfWeek
        reach
      }
      peakHour {
        hour
        reach
      }
      actions {
        likes
        comments
      }
      audienceOverview {
        ...audienceOverview
      }
      adPerformanceTrends {
        peakHour
        date
        impressions
        hourlyImpressions
      }
    }
  }
  ${totalMetricsFragment}
  ${audienceOverviewFragment}
`;

export const getCampaignTemplateReportingMetrics = gql`
  query reportingMetrics(
    $campaignTemplateId: ID!
    $startDte: String
    $endDte: String
    $locationIds: [Int]
    $campaignTypes: [String]
  ) {
    reportingMetrics(
      campaignTemplateId: $campaignTemplateId
      startDte: $startDte
      endDte: $endDte
      locationIds: $locationIds
      campaignTypes: $campaignTypes
    ) {
      total {
        ...totalMetrics
      }
      totalCampaigns {
        ...totalMetricsByCampaign
      }
      peakDay {
        dayOfWeek
        reach
      }
      peakHour {
        hour
        reach
      }
      actions {
        likes
        comments
      }
      audienceOverview {
        ...audienceOverview
      }
      adPerformanceTrends {
        peakHour
        date
        impressions
        hourlyImpressions
      }
      potentialReach
    }
  }
  ${totalMetricsFragment}
  ${totalMetricsFragmentByCampaign}
  ${audienceOverviewFragment}
`;

export const getTotalMetricsByTemplate = gql`
  query reportingMetrics($startDte: String, $endDte: String, $locationIds: [Int]) {
    reportingMetrics(startDte: $startDte, endDte: $endDte, locationIds: $locationIds) {
      totalTemplates {
        ...totalMetricsByTemplate
      }
    }
  }
  ${totalMetricsFragmentByTemplate}
`;

export const getPerformanceOverviewMetrics = gql`
  query reportingMetrics($startDte: String, $endDte: String) {
    reportingMetrics(startDte: $startDte, endDte: $endDte) {
      totalChannels {
        spend
        impressions
        cpc
        ctr
        cvr
        channelNme
      }
      targetSpendChannels {
        channelNme
        targetSpend
      }
      spendLocations {
        spend
        locationNme
      }
      mostRunTemplates(limit: 1) {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        imageUrls
        mediaType
        averageRate
        rateCount
        run
      }
      highestCtrTemplates(limit: 1) {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        imageUrls
        mediaType
        averageRate
        rateCount
        ctr
      }
      highestSpendTemplates(limit: 1) {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        imageUrls
        mediaType
        averageRate
        rateCount
        spend
      }
      highestImpressionsTemplates(limit: 1) {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        imageUrls
        mediaType
        averageRate
        rateCount
        impressions
      }
      highestClicksTemplates(limit: 1) {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        imageUrls
        mediaType
        averageRate
        rateCount
        clicks
      }
    }
  }
`;

export const getSMBPerformanceOverviewMetrics = gql`
  query reportingMetrics($startDte: String, $endDte: String) {
    reportingMetrics(startDte: $startDte, endDte: $endDte) {
      total {
        spend
        impressions
        ctr
      }
      totalChannels {
        spend
        impressions
        cpc
        ctr
        cvr
        channelNme
      }
      targetSpendChannels {
        channelNme
        targetSpend
      }
      spendTemplates {
        spend
        campaignTemplateId
        campaignTemplateNme
      }
      highestCtrTemplates(limit: 1) {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        imageUrls
        mediaType
        averageRate
        rateCount
        ctr
      }
      highestSpendTemplates(limit: 1) {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        imageUrls
        mediaType
        averageRate
        rateCount
        spend
      }
    }
  }
`;

export const getAggregatedMetrics = gql`
  query reportingMetrics($startDte: String, $endDte: String, $locationIds: [Int], $channelNme: String) {
    reportingMetrics(startDte: $startDte, endDte: $endDte, locationIds: $locationIds, channelNme: $channelNme) {
      total {
        ...totalMetrics
      }
      peakDay {
        dayOfWeek
      }
      actions {
        likes
      }
      audienceOverview {
        ...audienceOverview
      }
      potentialReach
      channelMostRun {
        channelNme
        channelCount
      }
      campaignsByType {
        campaignType
        campaignTypeCount
      }
      mostRunTemplates {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        objectives
        run
        imageUrls
        adDsc
        mediaType
        averageRate
        rateCount
      }
      highestCtrTemplates {
        campaignTemplateId
        campaignTemplateNme
        channelNme
        objectives
        ctr
        imageUrls
        adDsc
        mediaType
        averageRate
        rateCount
      }
    }
  }
  ${totalMetricsFragment}
  ${audienceOverviewFragment}
`;

export const getLatestActivities = gql`
  query getLatestActivities {
    latestActivities {
      ...latestActivities
    }
  }
  ${latestActivitiesFragment}
`;
