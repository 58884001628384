import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AppConfigService,
  GoogleAnalyticsService,
  AppVersionService,
  UserService,
  OauthConnectService,
  AuthService,
} from '@services';
import { IUser, OrganizationType, UserRoleType } from '@interfaces';
import { Utils } from '@utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
declare var dT_;
import { map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  private sub: Subscription = new Subscription();

  private static GetAccountObjectByUserProfile(userInfo: IUser): { id: string; name: string } {
    const isAdmin = AppComponent.isAdmin(userInfo);
    if (isAdmin) {
      return {
        id: 'Admin',
        name: 'Admin',
      };
    } else if (userInfo.organization) {
      switch (userInfo.organization.organizationType) {
        case OrganizationType.ORGANIZATION:
          return {
            id: `Organization_${userInfo.organization.organizationId}`,
            name: userInfo.organization.organizationNme || `Organization_${userInfo.organization.organizationId}`,
          };
        case OrganizationType.CLIENT:
          return {
            id: `Client_${userInfo.organization.organizationId}`,
            name: userInfo.organization.organizationNme || `Client_${userInfo.organization.organizationId}`,
          };
      }
    } else if (userInfo.location) {
      return {
        id: `Location_${userInfo.location.locationId}`,
        name: userInfo.location.locationNme || `Location_${userInfo.location.locationId}`,
      };
    } else {
      return {
        id: 'Unknown',
        name: 'Unknown',
      };
    }
  }

  private static AppendPendoSnippet(apiKey: string, userInfo: IUser, env: string) {
    try {
      const script = document.createElement('script');
      const account = AppComponent.GetAccountObjectByUserProfile(userInfo);
      const isAdmin = AppComponent.isAdmin(userInfo);
      const userRoleName = AppComponent.getUserRoleName(userInfo.rbacRoleId);
      const visitor = {
        id: `${env}-${userInfo.userId}`,
        role: isAdmin ? 'Admin' : userRoleName,
        name:
          Utils.CapitalizeFirstLetter(userInfo.userFirstNme) + ' ' + Utils.CapitalizeFirstLetter(userInfo.userLastNme),
        email: userInfo.userEmailAddress,
        env,
        orgId: isAdmin ? null : userInfo.organization ? userInfo.organization.organizationId : null,
        orgName: isAdmin ? null : userInfo.organization ? userInfo.organization.organizationNme : null,
        locId: isAdmin ? null : userInfo.location ? userInfo.location.locationId : null,
        locName: isAdmin ? null : userInfo.location ? userInfo.location.locationNme : null,
        parent: isAdmin
          ? null
          : userInfo.location
          ? userInfo.location.organizationId
          : userInfo.organization
          ? userInfo.organization.parentId
          : null,
      };
      if (isAdmin) {
        visitor['userAdminType'] = isAdmin;
      }
      /* tslint:disable */
      script.innerHTML = `
       (function(apiKey){
       (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
       v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){ o[m]=o[m]||function()
       {o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));}
       ;})(v[w]);
       y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
       z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
       pendo.initialize(JSON.parse('${JSON.stringify({ visitor, account }).replace(/[']/g, "\\'")}'));
       })('${apiKey}');
      `;
      document.head.appendChild(script);
      /* tslint:enable */
    } catch (ex) {}
  }

  private static IsStageOrProdEnv(pageHost: string): boolean {
    const searchStrings = [
      'https://stage.adcornera4.com',
      'https://prod.adcornera4.com',
      'https://app.adcorner.io',
      // 'http://localhost:8080'
    ];
    return searchStrings.indexOf(pageHost) > -1;
  }

  private static isAdmin(userInfo: IUser): boolean {
    return userInfo.rbacRoleId === UserRoleType.STAFF || userInfo.rbacRoleId === UserRoleType.ROOT;
  }

  private static getUserRoleName(roleId: number): boolean {
    const roleMap = {
      1: 'ZOWI',
      2: 'ZOLO',
      3: 'ZEWI',
      4: 'ZELO',
      5: 'AGENCY',
      6: 'STAFF',
      7: 'ROOT',
      8: 'SILO',
    };
    return roleMap[roleId];
  }

  constructor(
    private userService: UserService,
    private appConfig: AppConfigService,
    private googleAnalytics: GoogleAnalyticsService,
    private versionService: AppVersionService,
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.handleGoogleAccountConnection();
    this.clearUselessInfo();
    // this.appendGaTrackingCode();
    // this.invokeDynatrace(http);
    this.setPendoAndCustomDimensions();
  }

  private invokeDynatrace(http: HttpClient) {
    setTimeout(() => {
      if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
        dT_.initAngularNg(http, HttpHeaders);
      }
    }, 5000);
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML =
        `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', '` +
        this.appConfig.googleUAId +
        `', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {}
  }

  private setPendoAndCustomDimensions(): void {
    this.sub.add(
      combineLatest([this.getCurrentUser(), this.versionService.getIPAddress()])
        .pipe(
          map((res) => {
            return {
              user: res[0],
              IPAddress: (res[1] as { ip: string }).ip,
            };
          }),
        )
        .subscribe(({ user, IPAddress }) => {
          if (user && user.userId) {
            const pageHost = window.location.hostname || window.location.host;
            if (AppComponent.IsStageOrProdEnv(this.appConfig.apiServerURL)) {
              const env = pageHost.indexOf('stage') > -1 || pageHost.indexOf('localhost') > -1 ? 'STAGE' : 'PROD';
              AppComponent.AppendPendoSnippet(this.appConfig.pendoAPIKey, user, env);
            }
            this.googleAnalytics.setUserID(user.userId);
            this.googleAnalytics.setCustomDimensions(user, IPAddress);
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private clearUselessInfo(): void {
    if (!!localStorage.getItem('profileCedar')) {
      ['profileCedar', 'profile', 'access_token', 'expires_at'].forEach((el) => localStorage.removeItem(el));
    }
    ['lastAction', 'auth_profile', 'current_user'].forEach((el) => localStorage.removeItem(el));
    sessionStorage.clear();
  }

  private handleGoogleAccountConnection(): void {
    const searchObject = OauthConnectService.UrlDataParse(window.location.search);

    if (searchObject.state) {
      try {
        searchObject.state = JSON.parse(searchObject.state);
        if (searchObject.state.channel === 'google') {
          setTimeout(() => {
            this.router.navigateByUrl(
              this.router.createUrlTree(['/app/account'], {
                queryParams: { googleOauth: JSON.stringify(searchObject) },
              }),
              {},
            );
          }, 0);
        }
      } catch (e) {
        // Not doing anything at this point, just running the code as usual.
        // console.log(e);
      }
    }
  }

  private getCurrentUser(): Observable<IUser> {
    return this.auth.getUserProfile().pipe(
      tap((user) => {
        if (user) {
          this.googleAnalytics.eventTracking('User Profile', 'Logged in', user.userId);
        }
      }),
    );
  }
}
