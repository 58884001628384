import { Injectable } from '@angular/core';
import * as gql from './user.gql';
import { Apollo } from 'apollo-angular';
import {
  IAddUserInput,
  IUser,
  IUserWithPermissions,
  OrganizationType,
  QueryUserAccountsParams,
  RegisterUserInput,
  UpdateUserInput,
  UserAccounts,
  UserRoleType,
  ViewType,
} from '@interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { omit, sortBy } from 'lodash';
import { ApolloQueryResult, createHttpLink } from '@apollo/client/core';
import { environment } from '../../../../environments/environment';
import { AppConfigService, UserContextService } from '@services';
import { HttpClient } from '@angular/common/http';
import { Assignment } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userProfile: IUser | null;
  public currentUser$: BehaviorSubject<IUser> = new BehaviorSubject(null);
  private currentUserViewType: ViewType;
  private userViewChange: BehaviorSubject<ViewType> = new BehaviorSubject(null);
  private orgLogoChange: BehaviorSubject<string> = new BehaviorSubject(null);
  private userWithPermissions: IUserWithPermissions;

  constructor(
    private apollo: Apollo,
    private userContext: UserContextService,
    private readonly appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
  ) {}

  // current user
  getUserProfile(): Observable<IUser> {
    const { organizationId, locationId, userCurView } = this.userContext.getContext();

    if ((organizationId || locationId) && userCurView) {
      return this.updateUserProfile({ organizationId, locationId, userCurView }, null);
    }

    return this.apollo
      .watchQuery<{ user: IUser }>({
        fetchPolicy: 'network-only', // Change here
        query: gql.userProfileQuery,
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const accessibleLocations = data.user.accessibleLocations || [];
          const accessibleOrganizations = data.user.accessibleOrganizations || [];
          const user = {
            ...data.user,
            accessibleLocations,
            accessibleOrganizations,
          };
          this.changeUserViewType(user);
          // HACK: Ensure that the user's org and location ID is attached to headers after page refresh
          this.updateUserContext(user);
          this.userProfile = user;
          this.currentUser$.next(user);
          this.getUserPermissions();
          return user;
        }),
        catchError(() => of(null)),
      );
  }

  // current user
  updateUserProfile(user: UpdateUserInput, file?: File): Observable<IUser> {
    delete user.userId;
    const inputParam = { user };
    if (file) {
      inputParam['file'] = file;
    }

    // Set/Change organization and location only when new values provided
    if (user.locationId || user.organizationId) {
      this.updateUserContext(user);
    }

    return this.apollo
      .mutate<{ updateRequestingUser: IUser }>({
        mutation: gql.updateUserProfile,
        variables: inputParam,
        update: (cache, { data: { updateRequestingUser } }) => {
          cache.writeQuery({
            query: gql.userProfileQuery,
            data: { user: updateRequestingUser },
          });
        },
      })
      .pipe(
        map(({ data }) => {
          const updatedUser = data.updateRequestingUser;
          this.userProfile = { ...this.userProfile, ...updatedUser };
          this.orgLogoChange.next(updatedUser.orgLogoUrl);
          this.currentUser$.next({ ...this.userProfile, ...updatedUser });
          this.changeUserViewType(this.userProfile);
          this.getUserPermissions();
          return updatedUser;
        }),
        catchError(({ message }) => of(null)),
      );
  }

  getCachedUserProfile(): IUser | null {
    return this.userProfile;
  }

  changeUserViewType(user: IUser): void {
    if (user && user.userCurView && (!this.currentUserViewType || this.currentUserViewType !== user.userCurView)) {
      this.currentUserViewType = user.userCurView;
      this.userViewChange.next(user.userCurView);
    }
  }

  getUserViewTypeListener(): BehaviorSubject<ViewType> {
    return this.userViewChange;
  }

  updateOrgLogo(url: string): void {
    this.userProfile = {
      ...this.userProfile,
      organization: {
        ...this.userProfile.organization,
        orgLogoUrl: url,
      },
      orgLogoUrl: url,
    };
    this.orgLogoChange.next(url);
    this.currentUser$.next(this.userProfile);
  }

  onOrgLogoChange(): BehaviorSubject<string> {
    return this.orgLogoChange;
  }

  // user management

  getUsersByRequestingUser(): Observable<Array<IUser>> {
    return this.apollo
      .watchQuery<{ usersByRquestingUser: IUser[] }>({
        fetchPolicy: 'network-only',
        query: gql.usersByRequestingUserQuery,
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const users = (data.usersByRquestingUser || [])
            .filter((user) => !this.isAdminRole(user, false))
            .map((user) => {
              const accessibleLocations = user.accessibleLocations || [];
              const accessibleOrganizations = user.accessibleOrganizations || [];
              return {
                ...user,
                accessibleLocations,
                accessibleOrganizations,
              };
            });
          return sortBy(users, (user) => user.userFirstNme.toLowerCase());
        }),
        catchError(() => of([])),
      );
  }

  // other users
  getUserProfileByEmail(email: string): Observable<IUser> {
    return this.apollo
      .watchQuery<{ userByEmail: IUser }>({
        fetchPolicy: 'network-only',
        query: gql.userByEmail,
        variables: {
          email,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.userByEmail),
        catchError(() => of(null)),
      );
  }

  canManageSocialAccounts(): boolean {
    return this.isOrgUser();
  }

  isLocUser(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && !!user.location;
  }

  isOrgUser(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && !!user.organization;
  }

  isSMBOrg(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && !!user.organization && user.organization.organizationIsSmb;
  }

  isReseller(): boolean {
    const user = this.userProfile;
    return !!user && !!user.organization && user.organization.reseller;
  }

  // role SILO = SMB
  isOrgRole(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return (
      !!user &&
      !![UserRoleType.ZOWI, UserRoleType.ZEWI, UserRoleType.SILO].find((userRole) => userRole === user.rbacRoleId)
    );
  }

  isLocRole(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && (user.rbacRoleId === UserRoleType.ZELO || user.rbacRoleId === UserRoleType.ZOLO);
  }

  isAgencyRole(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && user.rbacRoleId === UserRoleType.AGENCY;
  }

  isAgencyUser(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && (user.accessibleOrganizations?.length || 0) + (user.accessibleLocations?.length || 0) > 1;
  }

  isRootRole(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && user.rbacRoleId === UserRoleType.ROOT;
  }

  isAdminRole(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && (user.rbacRoleId === UserRoleType.STAFF || user.rbacRoleId === UserRoleType.ROOT);
  }

  // view type
  isZOWIView(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && user.userCurView === ViewType.ZOWI;
  }

  // ZOWI Corporate Locations
  isCOLOView(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && user.userCurView === ViewType.ZOLO && !!user.organization;
  }

  isZEWIView(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && user.userCurView === ViewType.ZEWI;
  }

  isZELOView(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && user.userCurView === ViewType.ZELO;
  }

  isLocView(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && (user.userCurView === ViewType.ZELO || (user.userCurView === ViewType.ZOLO && !!user.location));
  }

  isZORView(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return (
      !!user && (user.userCurView === ViewType.ZOWI || (user.userCurView === ViewType.ZOLO && !!user.organization))
    );
  }

  isZEEView(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return (
      !!user &&
      (user.userCurView === ViewType.ZEWI ||
        user.userCurView === ViewType.ZELO ||
        (user.userCurView === ViewType.ZOLO && !!user.location))
    );
  }

  isReadOnlyAccess(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    if (!!user) {
      const userReadOnly = user.userReadOnly;
      const locationReadOnly = !!user.location && user.location.locationReadOnly;
      const organizationReadOnly = !!user.organization && user.organization.organizationReadOnly;
      const isAgencyAccountReadOnly = this.isAgencyAccountReadOnly(user);
      return userReadOnly || locationReadOnly || organizationReadOnly || isAgencyAccountReadOnly;
    }
    return false;
  }

  isClientOrg(userProfile?: IUser, isCurrentUser: boolean = true): boolean {
    const user = isCurrentUser ? this.userProfile : userProfile;
    return !!user && !!user.organization && user.organization.organizationType === OrganizationType.CLIENT;
  }

  // root and staff
  getAdminUsers(): Observable<IUser[]> {
    return this.apollo
      .watchQuery<{ adminUsers: IUser[] }>({
        fetchPolicy: 'network-only',
        query: gql.getAdminUsers,
      })
      .valueChanges.pipe(
        map(({ data }) => sortBy(data.adminUsers || [], (user) => user.userFirstNme.toLowerCase())),
        take(1),
        catchError(() => of([])),
      );
  }

  // For admin portal

  // exclude admin(root and staff) users
  getUserAccounts(params: QueryUserAccountsParams): Observable<UserAccounts> {
    const noData = {
      count: 0,
      users: [],
    };
    const roleIds = params.search.roleIds.map((roleId) => Number(roleId));
    const search = !roleIds.length ? omit(params.search, ['roleIds']) : { ...params.search, roleIds };
    const input = { ...params, search };
    return this.apollo
      .watchQuery<{ userAccounts: UserAccounts }>({
        fetchPolicy: 'network-only',
        query: gql.getUserAccounts,
        variables: { input },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const users = data.userAccounts.users.map((user) => {
            const accessibleLocations = user.accessibleLocations || [];
            const accessibleOrganizations = user.accessibleOrganizations || [];
            return { ...user, accessibleLocations, accessibleOrganizations };
          });
          const { count } = data.userAccounts;
          return { count, users };
        }),
        take(1),
        catchError(() => of(noData)),
      );
  }

  checkEmailExisted(userEmail: string): Observable<boolean> {
    userEmail = userEmail || '';
    return this.apollo
      .watchQuery<{ checkEmailExisted: boolean }>({
        fetchPolicy: 'network-only',
        query: gql.checkEmailExisted,
        variables: {
          userEmail,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.checkEmailExisted),
        catchError(() => of(false)),
      );
  }

  // register as a root user by default
  registerUser(userInput: RegisterUserInput): Observable<{ message: string; status: string }> {
    return this.apollo
      .mutate<{ registerUser: { message: string; status: string } }>({
        mutation: gql.registerUser,
        variables: { user: userInput },
      })
      .pipe(
        map(({ data }) => data.registerUser),
        catchError((err) =>
          of({
            status: '-1',
            message: 'The registration has failed for unknown reasons. Please try again later.',
          }),
        ),
      );
  }

  authenticate(email: string): Observable<ApolloQueryResult<{ authenticate: { token: string } }>> {
    return this.apollo.watchQuery<{ authenticate: { token: string } }>({
      fetchPolicy: 'network-only',
      query: gql.authenticate,
      variables: { email },
    }).valueChanges;
  }

  createUsers(userInserts: IAddUserInput[]): Observable<IUser[]> {
    return this.apollo
      .mutate<{ addUsers: IUser[] }>({
        mutation: gql.addUsers,
        variables: { users: userInserts },
      })
      .pipe(
        take(1),
        map(({ data }) => data.addUsers),
        catchError(() => of(null)),
      );
  }

  updateUser(id: string, userInput: UpdateUserInput): Observable<IUser> {
    return this.apollo
      .mutate<{ updateUser: IUser }>({
        mutation: gql.updateUser,
        variables: {
          id,
          user: userInput,
        },
      })
      .pipe(
        map(({ data }) => {
          const { userId } = data.updateUser;
          if (userId === this.userProfile.userId) {
            this.userProfile = data.updateUser;
          }
          return data.updateUser;
        }),
        catchError(() => of(null)),
      );
  }

  getUserPermissions() {
    const endpoint = new URL(this.appConfig.userApiUrl);
    endpoint.searchParams.set('showPermissions', 'true');

    return this.httpClient.get<IUserWithPermissions>(endpoint.toString()).subscribe((userWithPermissions) => {
      this.userWithPermissions = userWithPermissions;
      return userWithPermissions;
    });
  }

  isUserHasAssignment(assignment: Assignment): boolean {
    const organizationId = Number(this.userProfile.organizationId);
    const locationId = Number(this.userProfile.locationId);

    if (!this.userWithPermissions) {
      return false;
    }

    // system admin has all assignments by default
    if (this.userWithPermissions.systemAdmin) {
      return true;
    }

    const isOrganizationUser = !!organizationId && !locationId;
    const isLocationUser = !!locationId;

    if (isOrganizationUser) {
      const hasAssignment =
        this.userWithPermissions.organizationPermissions?.some(
          (permission) => permission.organizationId === organizationId && permission.assignments?.includes(assignment),
        ) ?? false;

      return hasAssignment;
    }

    if (isLocationUser) {
      const hasAssignment =
        this.userWithPermissions.locationPermissions?.some(
          (permission) => permission.locationId === locationId && permission.assignments?.includes(assignment),
        ) ?? false;

      return hasAssignment;
    }

    return false;
  }

  // is agency user in read-only mode on single org/location
  private isAgencyAccountReadOnly = (user: IUser): boolean => {
    if (user.rbacRoleId !== UserRoleType.AGENCY) {
      return false;
    }
    const { userReadOnlyOrgIds, userReadOnlyLocIds, organization, location } = user;
    const isCurrentOrgAccountReadOnly = userReadOnlyOrgIds.some(
      (readOnlyOrgId) => !!organization && Number(organization.organizationId) === readOnlyOrgId,
    );
    const isCurrentLocAccountReadOnly = userReadOnlyLocIds.some(
      (readOnlyLocId) => !!location && Number(location.locationId) === readOnlyLocId,
    );
    return isCurrentOrgAccountReadOnly || isCurrentLocAccountReadOnly;
    // tslint:disable-next-line:semicolon
  };

  private updateUserContext(user: IUser) {
    this.userContext.setContext({
      organizationId: user.organizationId,
      locationId: user.locationId,
      userCurView: user.userCurView,
    });
  }
}
