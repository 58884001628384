<div [class.date-picker-container]="!iconOnly">
  <form class="date-input-container font-color-gray" *ngIf="!iconOnly">
    <input type="number" placeholder="MM" [value]="date?.month | number:'2.0-0'" (focus)="startInput($event)"
      (blur)="endInput($event, 'month')" (keydown)="checkInputValue($event)" />
    /
    <input type="number" placeholder="DD" [value]="date?.day | number:'2.0-0'" (focus)="startInput($event)"
      (blur)="endInput($event, 'day')" (keydown)="checkInputValue($event)" />
    /
    <input type="number" placeholder="YY" [value]="date ? (date.year % 100) : '' | number:'2.0-0'"
      (focus)="startInput($event)" (blur)="endInput($event, 'year')" (keydown)="checkInputValue($event)" />
  </form>
  <div class="date-picker-toggle-icon">
    <app-ui-icon
      #toggleIcon
      [fillIcon]="iconName ? iconName : isCalendarOpen ? 'Calendar_active' : 'calendar'"
      [isPointer]="true"
      (iconClick)="toggleCalendar()">
    </app-ui-icon>
  </div>
</div>

<div class="single-date-picker-control-container">
  <form class="date-picker-control-content" [class.calender-top]="position === 'top'">
    <input class="date-picker-controller-input"
           name="dp"
           ngbDatepicker
           navigation="arrows"
           outsideDays="visible"
           placement="bottom"
           #datepicker="ngbDatepicker"
           [markDisabled]="isDisable.bind({ limitDate: this.limitDate })"
           [value]="date"
           [dayTemplate]="customDay"
           [restoreFocus]="toggleIcon"
           (closed)="doAfterClosed()"
           (dateSelect)="doAfterSelected($event)"
           
    />
  </form>
</div>

<ng-template #customDay let-date let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.disable-date]="isDisable(date)"
    [class.selected]="isSelected(date)">
    {{ date.day }}
  </span>
</ng-template>
