import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@services';
import { BillingStatus } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingApiClientService {
  constructor(private readonly httpClient: HttpClient, private readonly appConfig: AppConfigService) {}

  public getStatus(organizationId: number | string, locationId?: number | string | null): Observable<BillingStatus> {
    const endpoint = new URL(`${this.appConfig.billingApiUrl}/status`);
    endpoint.searchParams.set('organizationId', `${organizationId}`);
    if (locationId) {
      endpoint.searchParams.set('locationId', `${locationId}`);
    }

    return this.httpClient.get<BillingStatus>(endpoint.toString());
  }
}
