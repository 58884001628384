import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ui-card-list-item',
  templateUrl: './ui-card-list-item.component.html',
  styleUrls: ['./ui-card-list-item.component.scss'],
})
export class UiCardListItemComponent implements OnInit {
  // TODO: refactor
  @Input()
  isFirst: boolean;
  @Input()
  listData: any;
  @Input()
  subject: string;
  @Input()
  value: string;
  @Input()
  displayRate = true;

  locationNme: string;
  channelNme: string;
  isPending = false;

  constructor() {}

  ngOnInit() {
    if (this.listData) {
      this.locationNme = this.listData.locationNme;
      if (!this.listData.description) {
        this.channelNme = this.listData.campaignChannelNme || this.listData.channelNme;
      }
      if (this.listData.campaignTemplateNme) {
        this.isPending = this.listData.campaignTemplateNme.toLowerCase() === 'result pending...';
      }
    }
  }
}
