import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss'],
})
export class UiIconComponent implements OnInit {
  private staticIconUrl = './assets/icons';

  @Input()
  icon: string;
  @Input()
  logo: string;
  @Input()
  fillIcon: string;
  @Input()
  kindName = 'svg';
  @Input()
  tip: string;
  @Input()
  size = 1; // em
  @Input()
  style: any;
  @Input()
  alt = '';
  @Input()
  disabled = false;
  @Input()
  isPointer = false;

  @Output()
  iconClick: EventEmitter<Event> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  get iconUrl(): string {
    let result = '';
    const staticUrl = this.staticIconUrl;
    const iconName = !!this.icon ? this.icon : this.logo ? this.logo : this.fillIcon;
    const parentPath = !!this.icon ? 'outline' : this.logo ? 'logo' : 'fill';
    if (!!iconName) {
      result = `${staticUrl}/${parentPath}/${iconName}.${this.kindName}`;
    }
    return result;
  }

  get iconStyle(): any {
    const normalSize = `${this.size}`.replace(/([^em])$/, '$1em');
    let result = { width: normalSize, height: normalSize };
    const inputStyle = this.style;
    if (!!inputStyle) {
      result = Object.assign({}, result, inputStyle);
    }
    return result;
  }

  clickEvent(event: any): void {
    if (!this.disabled) {
      this.iconClick.emit(event);
    }
  }
}
