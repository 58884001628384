import { Directive, ElementRef, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CampaignService } from '@services';

@Directive({
  selector: '[appSignedUrl]',
})
export class SignedUrlDirective implements OnChanges, OnDestroy {
  private ngUnSubscribe$: Subject<any> = new Subject();
  constructor(private el: ElementRef, private campaignService: CampaignService) {}
  @Input('appSignedUrl')
  signedUrl: string;

  ngOnChanges() {
    this.getURLs();
  }

  ngOnDestroy() {
    this.ngUnSubscribe$.next();
    this.ngUnSubscribe$.complete();
  }

  private getURLs() {
    this.unShow();
    this.campaignService
      .getSignedUrlsByUrls([this.signedUrl])
      .pipe(takeUntil(this.ngUnSubscribe$))
      .subscribe(
        (res) => {
          this.el.nativeElement.src = res[0] || this.signedUrl;
          this.show();
        },
        () => {
          this.el.nativeElement.src = this.signedUrl;
          this.show();
        },
      );
  }

  private unShow(): void {
    this.el.nativeElement.style.opacity = '0';
  }

  private show(): void {
    this.el.nativeElement.style.opacity = '1';
  }
}
