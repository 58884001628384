import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Utils } from '@utils';
import { UserService } from '../../../core/services/user/user.service';
import { IconType } from '@interfaces';

@Component({
  selector: 'app-social-account-reminder-dialog',
  templateUrl: './social-account-reminder-dialog.component.html',
  styleUrls: ['../dialog-shared.scss', './social-account-reminder-dialog.component.scss'],
})
export class SocialAccountReminderDialogComponent implements OnInit {
  idPrefix = 'socialConnectStatus';
  closeIconPath: string;
  warningIconPath: string;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<SocialAccountReminderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private router: Router,
    private user: UserService,
  ) {
    this.closeIconPath = Utils.GetIconPath(IconType.Outline, 'Close');
    this.warningIconPath = Utils.GetIconPath(IconType.Outline, 'Alert');
  }

  ngOnInit() {}

  closeModal() {
    if (this.dialogData.updateUser) {
      this.user
        .updateUserProfile({ userChannelsConnectWarnInd: false })
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((res) => {
          this._close();
        });
    } else {
      this._close();
    }
  }

  private _close() {
    this.dialogRef.close();
  }

  gotoRouter() {
    if (this.dialogData.updateUser) {
      this.user
        .updateUserProfile({ userChannelsConnectWarnInd: false })
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((res) => {
          this._close();
          this.router.navigateByUrl(this.dialogData.router);
        });
    } else {
      this._close();
      this.router.navigateByUrl(this.dialogData.router);
    }
  }
}
