import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as gql from './keyword.gql';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IKeywordGroup, IKeywordGroupInput, IKeywordGroupListType, ISearchKeywordInput } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class KeywordService {
  constructor(private apollo: Apollo) {}

  createKeywordGroup(input: IKeywordGroupInput): Observable<IKeywordGroup> {
    return this.apollo
      .mutate<{ createKeywordGroup: IKeywordGroup }>({
        mutation: gql.createKeywordGroup,
        variables: {
          input,
        },
      })
      .pipe(
        map(({ data }) => data.createKeywordGroup),
        catchError(() => of(null)),
      );
  }

  updateKeywordGroup(id: string, input: IKeywordGroupInput): Observable<IKeywordGroup> {
    return this.apollo
      .mutate<{ updateKeywordGroup: IKeywordGroup }>({
        mutation: gql.updateKeywordGroup,
        variables: {
          id,
          input,
        },
      })
      .pipe(
        map(({ data }) => data.updateKeywordGroup),
        catchError(() => of(null)),
      );
  }

  searchKeywordByPages(input: ISearchKeywordInput): Observable<IKeywordGroupListType> {
    const noData = {
      count: 0,
      keywordGroups: [],
    };
    return this.apollo
      .watchQuery<{ searchKeyWordByPages: IKeywordGroupListType }>({
        query: gql.searchKeywordByPages,
        variables: { input },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.searchKeyWordByPages;
        }),
        catchError(() => of(noData)),
      );
  }
}
