import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormattedNetworkActivity, FormattedNotification, PoliciesEnum } from '@interfaces';
import { UserService, NotificationService, GoogleAnalyticsService, PolicyService } from '@services';
@Component({
  selector: 'app-content-item',
  templateUrl: './content-item.component.html',
  styleUrls: ['./content-item.component.scss'],
})
export class ContentItemComponent implements OnInit {
  @Input()
  contentType: string;
  @Input()
  content: FormattedNotification[] | FormattedNetworkActivity[] = [];

  isLocationUser = true;
  canNavToAdResult: boolean;
  canNavToAccount: boolean;
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
    private googleAnalytics: GoogleAnalyticsService,
    private policyService: PolicyService,
  ) {}

  ngOnInit() {
    this.isLocationUser = this.userService.isLocUser();
    this.canNavToAdResult = this.policyService.checkPolicyPermission(PoliciesEnum.navLocalAdResults);
    if (this.userService.isZEEView()) {
      this.canNavToAccount = this.policyService.checkPolicyPermission(PoliciesEnum.navZeeAccount);
    } else {
      this.canNavToAccount = this.policyService.checkPolicyPermission(PoliciesEnum.navZorAccount);
    }
  }

  previewRequest(notification: FormattedNotification): void {
    this.notificationService.closeAllDialog();
    const requestId = notification.content.campaignTemplateRequestId;
    this.notificationService.openPreviewDialog$.next(requestId);
  }

  gotoResultPage(campaignId: string, type: string): void {
    this.eventTrack(`${type} Preview Ad`);
    this.redirect(`/app/ad-results-details/${campaignId}`);
  }

  gotoAccountPage(): void {
    this.redirect('/app/account');
  }

  toggleDisplayStatus(notification: FormattedNotification): FormattedNotification {
    notification.content.showDetailStatus = !notification.content.showDetailStatus;
    return notification;
  }

  private eventTrack(label: string): void {
    this.googleAnalytics.eventTracking('Zee Landing Page', 'Entered View', label);
  }

  private redirect(url: string): void {
    this.notificationService.closeAllDialog();
    this.router.navigateByUrl(url);
  }
}
