import { ILimitedOrg, IOrganization, IOrganizationUpdate } from './organization.interface';
import { ILimitedLocation, ILocationUpdateInput } from './location.interface';
import { DropdownOption } from './general.interface';
import { UserRoleType } from './policy.interface';
import { Assignment } from '@constants';

export enum ViewType {
  ZOWI = 'ZOWI',
  ZOLO = 'ZOLO',
  ZEWI = 'ZEWI',
  ZELO = 'ZELO',
  COLO = 'COLO', // ZOWI Corporate Locations,
}

export interface IUser {
  userId?: string;
  userFirstNme?: string;
  userMiddleNme?: string;
  userLastNme?: string;
  userEmailAddress?: string;
  userHelpVideoUrl?: string;
  locationId?: string;
  organizationId?: string;
  location?: ILimitedLocation;
  organization?: ILimitedOrg;
  locationOrganization?: ILimitedOrg;
  accessibleLocations?: ILimitedLocation[];
  accessibleOrganizations?: ILimitedOrg[];
  userAddress?: string;
  userWebsite?: string;
  userCurView?: ViewType;
  userDefaultView?: ViewType;
  userUpdatedAt?: string;
  userActive?: boolean;
  userChannelsConnectWarnInd?: boolean;
  orgLogoUrl?: string;
  userAgencyOrgIds?: number[];
  userAgencyLocIds?: number[];
  rbacRoleId?: number;
  rbacAssignmentId?: number;
  userReadOnly?: boolean;
  userExpireAt?: string;
  userLoginLastDte?: string;
  userReadOnlyEditRoleId?: number;
  userInactiveLocIds?: number[];
  userInactiveOrgIds?: number[];
  userReadOnlyLocIds?: number[];
  userReadOnlyOrgIds?: number[];
}

export interface UpdateUserInput {
  userId?: string;
  userFirstNme?: string;
  userMiddleNme?: string;
  userLastNme?: string;
  userEmailAddress?: string;
  locationId?: string;
  organizationId?: string;
  userAddress?: string;
  userWebsite?: string;
  userCurView?: ViewType;
  userDefaultView?: ViewType;
  userActive?: boolean;
  userChannelsConnectWarnInd?: boolean;
  userAgencyOrgIds?: number[];
  userAgencyLocIds?: number[];
  rbacRoleId?: number;
  userReadOnly?: boolean;
  userExpireAt?: string;
  userInactiveLocIds?: number[];
  userInactiveOrgIds?: number[];
  userReadOnlyLocIds?: number[];
  userReadOnlyOrgIds?: number[];
}

export interface IAddUserInput {
  rbacRoleId: number;
  userFirstNme: string;
  userMiddleNme?: string;
  userLastNme: string;
  userEmailAddress: string;
  locationId?: string;
  organizationId?: string;
  userAddress?: string;
  userWebsite?: string;
  userCurView?: ViewType;
  userDefaultView?: ViewType;
  userActive?: boolean;
  userAgencyOrgIds?: number[];
  userAgencyLocIds?: number[];
}

export interface SignUpQueryParams {
  email?: string;
}

export interface RegisterUserInput {
  userLastNme: string;
  userFirstNme: string;
  userEmailAddress: string;
}

// for user management
export interface AssignedLocation {
  locationId: string;
  locationName: string;
  locationGeoTarget: string;
  locationActive: boolean;
}

export interface UserInformation {
  userId: string;
  userName: string;
  userEmailAddress: string;
  userTypeText: string;
  userRole: UserRoleType;
  assignedLocations: AssignedLocation[];
  assignedOrganizations: IOrganization[];
  isAgencyUser: boolean;
  userAgencyOrgIds: number[];
  userAgencyLocIds: number[];
  orgOrLocId: string;
  active: boolean;
  readOnly: boolean;
  canNotEditReadOnly: boolean;
  updateDate: string;
  userInactiveLocIds: number[];
  userInactiveOrgIds: number[];
  userReadOnlyLocIds: number[];
  userReadOnlyOrgIds: number[];
  isExtensible: boolean;
}

export interface AddAccountDialogInput {
  orgOpts: Array<DropdownOption>;
  clientOpts: Array<DropdownOption>;
  account: { accountInput: any; accountType: string };
}

export interface AccountDialogType {
  value: string;
  label: string;
  isDisabled: boolean;
}

export interface UpdateAccountInput {
  userInput?: UpdateUserInput;
  orgInput?: IOrganizationUpdate;
  locationInput?: ILocationUpdateInput;
  orgLogo?: File;
}

export interface UserAccounts {
  count: number;
  users: IUser[];
}

export interface UserAccountsPage {
  pageSize: number;
  pageNum: number;
}

export enum UserAccountsType {
  READONLY = 'READONLY',
  ACTIVE = 'ACTIVE',
  STANDARD = 'STANDARD',
}

export enum UserAccountsOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface UserAccountsSearch {
  searchInput?: string;
  roleIds?: string[];
  userTypes?: UserAccountsType[];
}

export interface UserAccountsOrder {
  name?: UserAccountsOrderDirection;
  id?: UserAccountsOrderDirection;
  role?: UserAccountsOrderDirection;
  readOnly?: UserAccountsOrderDirection;
  active?: UserAccountsOrderDirection;
  expires?: UserAccountsOrderDirection;
  lastLogin?: UserAccountsOrderDirection;
}

export interface QueryUserAccountsParams {
  pageInfo: UserAccountsPage;
  search?: UserAccountsSearch;
  order?: UserAccountsOrder;
}

export interface OrganizationPermission {
  organizationId: number;
  assignments: Assignment[];
}

export interface LocationPermission {
  organizationId: number;
  locationId: number;
  assignments?: Assignment[];
}

export interface IUserWithPermissions {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  systemAdmin: boolean;
  active: boolean;
  organizationPermissions?: OrganizationPermission[] | null;
  locationPermissions?: LocationPermission[];
}
