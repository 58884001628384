import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ValidationsService } from '@services';
import { includes, trim } from 'lodash';

@Component({
  selector: 'app-ui-chip-input',
  templateUrl: './ui-chip-input.component.html',
  styleUrls: ['./ui-chip-input.component.scss'],
})
export class UiChipInputComponent implements OnInit {
  @Input()
  type = 'text';
  @Input()
  inputLimit: number;
  @Input()
  placeholder = '';
  @Input()
  formGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  inputDescription: string;
  @Input()
  disabled = false;
  @Input()
  chipLengthLimit: number;
  @Input()
  prefix: string;
  @Input()
  prefixTip: string;
  @Input()
  suffix = false;
  @Input()
  allowMuti = true;

  @Output()
  changeEvent: EventEmitter<Array<string>> = new EventEmitter();
  @Output()
  suffixClick: EventEmitter<Array<string>> = new EventEmitter();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];

  constructor(private validationsService: ValidationsService) {}

  ngOnInit() {
    this._initFormGroup();
  }

  private _initFormGroup(): void {
    let formGroup = this.formGroup;
    let controlName = this.controlName;
    if (!formGroup || !controlName) {
      const control = { defaultControl: new FormControl([], []) };
      formGroup = new FormGroup(control);
      controlName = 'defaultControl';
    }

    this.formGroup = formGroup;
    this.controlName = controlName;
  }

  private _setValue(value: Array<string>): void {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      const control = formGroup.get(controlName);
      this.changeEvent.emit(value);
      control.setValue(value);
      control.markAsDirty();
    }
  }

  get chips(): Array<string> {
    if (Array.isArray(this.control.value) && !!this.control) {
      return this.control.value;
    }
    return [];
  }

  get control(): AbstractControl {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName);
    }
    return null;
  }

  doChipListFocus(inputElement: any) {
    inputElement.focus();
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      formGroup.get(controlName).markAsTouched();
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const chip = event.value;
    const chipValue = trim(this.chipLengthLimit ? chip.substr(0, this.chipLengthLimit) : chip);

    if (chipValue && (this.allowMuti || (!this.allowMuti && !includes(this.chips, chipValue)))) {
      this._setValue([...this.chips, chipValue]);
    }

    if (input) {
      input.value = '';
    }
  }

  remove(chip: any): void {
    const chips = [...this.chips];
    const index = chips.indexOf(chip);

    if (index >= 0) {
      chips.splice(index, 1);
    }
    this._setValue(chips);
  }

  clickSuffix() {
    this.suffixClick.emit(this.controlValue);
  }

  get controlValue() {
    return this.formGroup.get(this.controlName).value;
  }

  get validationError() {
    const formControl = this.control;
    if (!!formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched);
    }
    return false;
  }

  get errorMessage() {
    return this.validationsService.getValidationErrorMessage(this.control);
  }
}
