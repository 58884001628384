import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ui-user-icon',
  templateUrl: './ui-user-icon.component.html',
  styleUrls: ['./ui-user-icon.component.scss'],
})
export class UiUserIconComponent implements OnInit {
  @Input()
  name: string;
  nameInitials: string;

  constructor() {}

  ngOnInit() {
    this.getInitials();
  }

  private getInitials() {
    if (this.name) {
      const nameArray = this.name.split(' ');
      const firstName = nameArray[0].split('')[0] || '';
      const lastName = nameArray[nameArray.length - 1].split('')[0] || '';
      this.nameInitials = firstName + lastName;
    }
  }
}
