import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-ui-toggle-switch',
  templateUrl: './ui-toggle-switch.component.html',
  styleUrls: ['./ui-toggle-switch.component.scss'],
})
export class UiToggleSwitchComponent implements OnInit {
  @Input()
  formGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  disabled = false;
  @Input()
  set checked(flag: boolean) {
    this.isChecked = flag;
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      formGroup.get(controlName).setValue(flag);
    }
  }
  get checked() {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName).value;
    }
    return this.isChecked;
  }

  @Output()
  checkEvent: EventEmitter<boolean> = new EventEmitter();

  private isChecked: boolean;

  constructor() {}

  ngOnInit() {
    this._initForm();
  }

  private _initForm() {
    let formGroup = this.formGroup;
    let controlName = this.controlName;
    const defaultValue = this.checked;

    if (!formGroup || !controlName) {
      const defaultControl = {
        defaultControl: new FormControl(defaultValue, []),
      };
      formGroup = new FormGroup(defaultControl);
      controlName = 'defaultControl';
    }

    this.formGroup = formGroup;
    this.controlName = controlName;
  }

  doChange(event: MatSlideToggleChange): void {
    const control = this.formControl;
    if (control) {
      control.setValue(event.checked);
      this.checkEvent.emit(event.checked);
    }
  }

  get formControl(): AbstractControl {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    return formGroup.get(controlName);
  }
}
