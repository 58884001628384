<div class="dialog-card-title flex-row">
  <h2 class="page-title">Filter Ads</h2>
  <span class="close-icon" (click)="closeDialog()"></span>
</div>
<div class="dialog-card-content">
  <p *ngIf="isNoFilters" class="mb-m text-center">No Available Filters</p>
  <div *ngIf="locationOptions?.length > 0">
    <span class="filter-label">Locations</span>
    <div class="item-container location-selection">
      <app-ui-checkbox-dropdown #dropdown [width]="'100%'" [showSelected]="true" [selected]="selectedLocationIds"
                                [options]="locationOptions" [resultValueType]="'object'" (changeEvent)="locationSelected($event)">
      </app-ui-checkbox-dropdown>
    </div>
  </div>

  <div *ngIf="objectives?.length > 0" class="section-row">
    <span class="filter-label">Goals</span>
    <div class="flex-hor-start wrap">
      <span *ngFor="let objective of objectives" class="filter-item" [class.active]="isActive(objective, 'objectives')"
            (click)="handleClick(objective, 'objectives')">
        {{ objective.objectiveNme || objective}}
      </span>
    </div>
  </div>

  <div *ngIf="channels?.length > 0" class="section-row">
    <span class="filter-label">Channel</span>
    <div class="flex-hor-start wrap">
      <span *ngFor="let channel of channels" class="filter-item" [class.active]="isActive(channel, 'channels')"
            (click)="handleClick(channel, 'channels')">
        <img *ngIf="channel" src="./assets/icons/logo/{{ channel }}.svg" [alt]="channel" class="img-icon">
      </span>
    </div>
  </div>

  <div *ngIf="genders?.length > 0" class="section-row">
    <span class="filter-label">Gender</span>
    <div class="flex-hor-start wrap">
      <span *ngFor="let gender of genders" class="filter-item" [class.active]="isActive(gender, 'genders')"
            (click)="handleClick(gender, 'genders')">
        {{gender | titlecase}}
      </span>
    </div>
  </div>

  <div *ngIf="!isNoFilters" class="section-row flex-hor-center">
    <app-ui-button class="mr-s" btnType="secondary" (clickEvent)="handleClear()">Clear</app-ui-button>
    <app-ui-button class="ml-s" (clickEvent)="closeDialog(true)">Apply</app-ui-button>
  </div>
</div>
