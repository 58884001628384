import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageSidebarComponent } from './page-sidebar/page-sidebar.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SimpleLayoutComponent } from './simple-layout/simple-layout.component';
import { SharedModule } from '@shared';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [
    MainLayoutComponent,
    PageSidebarComponent,
    PageHeaderComponent,
    PageLoadingComponent,
    PageFooterComponent,
    SimpleLayoutComponent,
  ],
  exports: [PageLoadingComponent],
})
export class LayoutModule {}
