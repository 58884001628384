import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { ICampaignTemplateFeedback } from '@interfaces';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as gql from './feedback.gql';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private apollo: Apollo) {}

  getAvailableReviews(): Observable<ICampaignTemplateFeedback[]> {
    return this.apollo
      .query<{ availableReviews: ICampaignTemplateFeedback[] }>({
        fetchPolicy: 'network-only',
        query: gql.getAvailableReviews,
      })
      .pipe(
        map(({ data }) => data.availableReviews),
        catchError(() => of([] as ICampaignTemplateFeedback[])),
      );
  }
}
