<div
  ngbDropdown
  #myDrop="ngbDropdown"
  *ngIf="!!showOptions"
  [ngStyle]="style"
  class="d-inline-block ui-dropdown"
  [class.error-dropdown]="isShowError"
  [class.no-select-icon]="noSelectIcon"
>
  <div
    class="dropdown-button-container"
    [class.invalid]="invalid"
    [ngClass]="{ 'dropdown-container-disabled': _disabled }"
    [class.no-selected]="!hasSelected"
  >
    <button
      [class]="'btn dropdown-toggle text-' + textAlign"
      ngbDropdownToggle
      [disabled]="_disabled"
      [matTooltip]="buttonTip"
      matTooltipClass="mat-information-tooltip"
      [tabindex]="tabindex"
    >
      {{ buttonText }}
    </button>
    <div class="toggle-button-icon" (click)="toggleDropdown($event)"></div>
  </div>
  <div *ngIf="!(isMobile && showOptions.length > 5)" ngbDropdownMenu aria-labelledby="dropdown">
    <button
      *ngFor="let option of showOptions"
      class="dropdown-item"
      [class.selected]="isSelected(option.value)"
      [disabled]="_disabled"
      [value]="option.value"
      (click)="doSelect($event.target.value)"
      [matTooltip]="option.tip || option.text"
      matTooltipClass="mat-information-tooltip"
    >
      {{ option.text }}
    </button>
  </div>
  <div *ngIf="isMobile && showOptions.length > 5" ngbDropdownMenu aria-labelledby="dropdown">
    <div class="mobile-dropdown-height-content">
      <div
        #optionsContainer
        class="options-container"
        [ngStyle]="{ top: -moveTop + 'px' }"
        (touchstart)="startMobileTouch($event)"
        (touchmove)="startMobileMove($event)"
      >
        <button
          *ngFor="let option of showOptions"
          class="dropdown-item"
          [disabled]="_disabled"
          [value]="option.value"
          (click)="doSelect($event.target.value)"
          [matTooltip]="option.tip || option.text"
          matTooltipClass="mat-information-tooltip"
        >
          {{ option.text }}
        </button>
      </div>
    </div>
  </div>
</div>
