import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { isString, split, range } from 'lodash';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ILimitedLocation, ILocation, ITableColumn, MediaType } from '@interfaces';
import { Observable, fromEvent, BehaviorSubject } from 'rxjs';
import { startWith, debounceTime, map } from 'rxjs/operators';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /********************** ********************** DOWN IS NEW ********************** **********************/
  isScreenSmall$;
  isScreenMiddle$;

  /********************** ********************** UP IS NEW ********************** **********************/
  isShowFAQ: BehaviorSubject<boolean> = new BehaviorSubject(false);
  URLPattern = /^https?:\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&\/~+#]*[\w\-@?^=%&\/~+#])?$/;
  cityNamePattern = /^([A-Za-z]+\s?)*[A-Za-z]$/;

  constructor() {
    this._listenWindowsResize();
  }

  static validateInLimit(value: any, min: any = 0, max: any = Infinity): boolean {
    if (!('number' === typeof value || ('string' === typeof value && !Number.isNaN(Number(value))))) {
      return false;
    }
    min = Number.isNaN(Number(min)) ? 0 : Number(min);
    max = Number.isNaN(Number(max)) || max === null ? Infinity : Number(max);
    return Number(value) >= min && Number(value) <= max;
  }

  static validateMp4FileByPromise(file: File, options: any = {}): Promise<boolean | any> {
    return new Promise((resolve) => {
      const videoEle = document.createElement('video');
      const _URL = window.URL || window['webkitURL'];

      videoEle.onloadedmetadata = () => {
        const res: { [key: string]: any } = {};
        const duration = Number(videoEle.duration);
        const videoWidth = Number(videoEle.videoWidth);
        const videoHeight = Number(videoEle.videoHeight);
        if (options.checkDuration) {
          res.durationValid = UtilsService.validateInLimit(duration, options.durationMin, options.durationMax);
        }
        if (options.checkDimensionWidth) {
          res.dimensionWidthValid = UtilsService.validateInLimit(
            videoWidth,
            options.dimensionWidthMin,
            options.dimensionWidthMax,
          );
        }
        if (options.checkDimensionHeight) {
          res.dimensionHeightValid = UtilsService.validateInLimit(
            videoHeight,
            options.dimensionHeightMin,
            options.dimensionHeightMax,
          );
        }
        if (options.checkRatio) {
          res.ratioValid = UtilsService.validateInLimit(videoWidth / videoHeight, options.ratioMin, options.ratioMax);
        }
        resolve(res);
      };

      videoEle.onerror = () => {
        resolve(false);
      };

      videoEle.src = _URL.createObjectURL(file);
    });
  }

  static validateImageFileByPromise(file: File, acceptedTypes: string[]): Promise<boolean> {
    if (file.type && acceptedTypes.indexOf(file.type) < 0) {
      return new Promise((resolve) => {
        resolve(false);
      });
    } else {
      return new Promise((resolve) => {
        const _URL = window.URL || window['webkitURL'];
        const image = new Image();

        // Validate the File Height and Width.
        image.onload = () => {
          const height = image.naturalHeight || image.height;
          const width = image.naturalWidth || image.width;
          resolve(height * 9 <= width * 16 && height * 16 >= width * 9);
        };

        image.onerror = () => {
          resolve(false);
        };

        // Set the Base64 string return from FileReader as source.
        image.src = _URL.createObjectURL(file);
      });
    }
  }

  /********************** ********************** DOWN IS NEW ********************** **********************/

  getBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  getLimitFileUrl(file: File, channelName: string, mediaType: string): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (mediaType) {
        case MediaType.IMAGES:
        case MediaType.IMAGE: {
          this._checkImageLimit(file, mediaType, channelName)
            .then((res) => {
              return resolve(res);
            })
            .catch((errorMessage) => {
              return reject(errorMessage);
            });
          break;
        }
        case MediaType.VIDEO: {
          this._checkVideoLimit(file, channelName)
            .then((res) => {
              return resolve(res);
            })
            .catch((errorMessage) => {
              return reject(errorMessage);
            });

          break;
        }
        default: {
          break;
        }
      }
    });
  }

  getMediaLimit(mediaFormat = MediaType.IMAGE, channel = 'facebook'): string {
    let result = '';
    switch (mediaFormat) {
      case MediaType.IMAGE:
      case MediaType.IMAGES: {
        result = ['image/png', 'image/jpeg'].join(',');
        break;
      }
      case MediaType.VIDEO: {
        if (channel === 'facebook') {
          result = [
            '3g2',
            '3gp',
            '3gpp',
            'asf',
            'avi',
            'dat',
            'divx',
            'dv',
            'f4v',
            'flv',
            'm2ts',
            'm4v',
            'mkv',
            'mod',
            'mov',
            'mp4',
            'mpe',
            'mpe',
            'mpeg4',
            'mpg',
            'mts',
            'nsv',
            'ogm',
            'ogm',
            'qt',
            'tod',
            'ts',
            'vob',
            'wmv',
          ]
            .map((type) => {
              return `video/${type}`;
            })
            .join(',');
        } else {
          result = 'video/mp4';
        }
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }

  private async _checkImageLimit(file: File, mediaType: MediaType, channelName: string) {
    let errorMessage = '';
    let imageMinWidth = 500;
    let imageMinHeight = 262;
    const isFacebook = channelName === 'facebook';
    return new Promise((resolve, reject) => {
      const image = new Image();
      const _URL = window.URL || window['webkitURL'];
      const result = _URL.createObjectURL(file);
      image.src = result;
      image.onload = () => {
        const imageWidth = image.width;
        const imageHeight = image.height;
        const aspectRatio = imageWidth / imageHeight;
        if (mediaType === MediaType.IMAGES) {
          imageMinWidth = 600;
          imageMinHeight = 600;
        }

        if (imageWidth <= imageMinWidth) {
          errorMessage = `File could not be uploaded. Minimum width for image is ${imageMinWidth}px.`;
        } else if (imageHeight <= imageMinHeight) {
          errorMessage = `File could not be uploaded. Minimum height for image is ${imageMinHeight}px.`;
        }

        if (!!errorMessage) {
          return reject(errorMessage);
        } else {
          return resolve(result);
        }
      };
    });
  }

  private async _checkVideoLimit(file: File, channelName: string) {
    return new Promise((resolve, reject) => {
      let errorMessage = '';
      const size = file.size;
      const isFacebook = channelName === 'facebook';
      let durationMax = 240 * 60;
      const dimensionWidthMin = 500;
      const dimensionHeightMin = 262;
      if (!isFacebook) {
        durationMax = 120;
      }
      const limitSize = 100 * 1024 * 1024;
      const video = document.createElement('video');
      const _URL = window.URL || window['webkitURL'];
      const result = _URL.createObjectURL(file);
      video.src = result;
      video.onloadedmetadata = () => {
        const duration = video.duration;
        if (size > limitSize) {
          errorMessage = `File could not be uploaded. Maximum size for video is 100MB.`;
        } else if (video.videoWidth <= dimensionWidthMin) {
          errorMessage = `File could not be uploaded. Minimum width for video is ${dimensionWidthMin}px.`;
        } else if (video.videoHeight <= dimensionHeightMin) {
          errorMessage = `File could not be uploaded. Minimum height for video is ${dimensionHeightMin}px.`;
        } else if (duration > durationMax) {
          errorMessage = `File could not be uploaded. Maximum duration for video is ${durationMax}s.`;
        } else {
          let radioError = false;
          const videoRadio = video.videoWidth / video.videoHeight;
          if (!isFacebook) {
            if (videoRadio < (4 / 5) * 0.99 || videoRadio > 1.91 * 1.01) {
              radioError = true;
            }
          } else {
            if (videoRadio < (9 / 16) * 0.97 || videoRadio > (16 / 9) * 1.03) {
              radioError = true;
            }
          }
          if (radioError) {
            errorMessage = `File could not be uploaded
            . Please upload a file that meets the specific check file type and aspect ratio requirements.`;
          }
        }
        if (!!errorMessage) {
          return reject(errorMessage);
        } else {
          return resolve(result);
        }
      };
    });
  }

  private checkScreenSize() {
    const width = document.body.offsetWidth;
    if (1024 <= width) {
      return 'pc';
    } else if (768 <= width && width < 1024) {
      return 'tablet';
    } else {
      return 'mobile';
    }
  }

  private _listenWindowsResize(): void {
    const screenSizeChanged$ = fromEvent(window, 'resize').pipe(map(this.checkScreenSize));
    this.isScreenSmall$ = screenSizeChanged$.pipe(
      debounceTime(10),
      map((event) => event === 'mobile'),
    );
    this.isScreenMiddle$ = screenSizeChanged$.pipe(
      debounceTime(10),
      map((event) => event === 'tablet' || event === 'mobile'),
    );
  }

  _getResizeListener(): Observable<boolean> {
    return fromEvent(window, 'resize').pipe(
      startWith(true),
      map(() => true),
    );
  }

  _getSmallSizeListener(): Observable<boolean> {
    const nowScreen = this.checkScreenSize();
    return this.isScreenSmall$.pipe(startWith(nowScreen === 'mobile'));
  }

  _getMiddleSizeListener(): Observable<boolean> {
    const nowScreen = this.checkScreenSize();
    return this.isScreenMiddle$.pipe(startWith(nowScreen === 'tablet' || nowScreen === 'mobile'));
  }

  public getNgbDateFromDate(input: string | Date): NgbDate {
    if (!input) {
      return null;
    }
    let dateTime = new Date(input);
    if (!dateTime) {
      dateTime = new Date();
    }

    let year = dateTime.getFullYear();
    let month = dateTime.getMonth();
    let date = dateTime.getDate();
    if (dateTime.getUTCHours() === 0) {
      year = dateTime.getUTCFullYear();
      month = dateTime.getUTCMonth();
      date = dateTime.getUTCDate();
    }
    return new NgbDate(year, month + 1, date);
  }

  public getNgbDateToday(): NgbDate {
    const dateTime = new Date();

    let year = dateTime.getFullYear();
    let month = dateTime.getMonth();
    let date = dateTime.getDate();
    if (dateTime.getUTCHours() === 0) {
      year = dateTime.getUTCFullYear();
      month = dateTime.getUTCMonth();
      date = dateTime.getUTCDate();
    }
    return new NgbDate(year, month + 1, date);
  }

  parseURL(url) {
    const a = document.createElement('a');
    a.href = url;
    const path = a.pathname.replace(/^([^\/])/, '/$1');
    if (!!url) {
      return {
        source: url,
        protocol: a.protocol.replace(':', ''),
        host: a.hostname,
        port: a.port,
        query: a.search,
        params: (() => {
          const params = {};
          const seg = a.search.replace(/^\?/, '').split('&');
          const len = seg.length;
          let p = [];
          for (let i = 0; i < len; i++) {
            if (seg[i]) {
              p = seg[i].split('=');
              params[p[0]] = p[1];
            }
          }
          return params;
        })(),
        hash: a.hash.replace('#', ''),
        path,
        basename: path.split('/').slice(-1)[0],
      };
    }
    return null;
  }

  /********************** ********************** UP IS NEW ********************** **********************/

  buildWindowUrl(url: string): string {
    if (typeof url === 'string' && url) {
      url = url.replace(/^[^a-zA-Z0-9]*/, '');

      if (url.toLowerCase().indexOf('https://') === -1 && url.toLowerCase().indexOf('http://') === -1) {
        url = 'http://' + url;
      } else {
        if (url.toLowerCase().indexOf('https://') !== -1) {
          url = url.slice(url.toLowerCase().indexOf('https://'));
        } else if (url.toLowerCase().indexOf('http://') !== -1) {
          url = url.slice(url.toLowerCase().indexOf('http://'));
        }
      }
      return url;
    }
    return '';
  }

  joinArray(array: Array<string>): string {
    if (array) {
      return array.join(', ');
    }
    return '';
  }

  convertAge(age: number): number | string {
    let resultAge = Math.abs(age) + '';
    if (Math.abs(age) === 65) {
      resultAge = '65+';
    }
    return resultAge;
  }

  getMediaLink(socialMedia: string, urls: Array<string>): string {
    if (socialMedia && socialMedia.length > 0 && urls.length > 0) {
      if (socialMedia === 'youtube') {
        const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
        const isMatched = urls[0].match(pattern);
        if (isMatched && isMatched.length >= 2) {
          return `https://www.youtube.com/embed/${isMatched[2]}`;
        }
      } else {
        /* tslint:disable-next-line */
        const pattern =
          /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
        const isMatched = urls[0].match(pattern);
        if (isMatched && isMatched.length >= 2) {
          return `https://player.vimeo.com/video/${isMatched[3]}`;
        }
      }
    }
    return null;
  }

  autoCloseDatePicker(event: any, datePickers: Array<NgbInputDatepicker>): void {
    let isDatePicker = false;
    if (event.path && event.path.length > 0) {
      isDatePicker = !!event.path.find((eleParent) => {
        if (eleParent.tagName) {
          return eleParent.tagName.toLowerCase() === 'ngb-datepicker';
        }
        return false;
      });
    }
    if (!isDatePicker) {
      datePickers.forEach((datePicker) => {
        if (!!datePicker) {
          datePicker.close();
        }
      });
    }
  }

  dateFormat(date: { year: number; month: number; day: number }): Date {
    return new Date(`${date.year}/${date.month}/${date.day}`);
  }

  dateCompare(dateA: Date, dateB: Date): number {
    // A > B return 1;
    let result = 0;
    const formatDateA = moment(dateA).startOf('date');
    const formatDateB = moment(dateB).startOf('date');
    if (formatDateA.diff(formatDateB) > 0) {
      result = 1;
    } else if (formatDateA.diff(formatDateB) < 0) {
      result = -1;
    }
    return result;
  }

  dateFormatUTC(date: Date) {
    const year = date.getFullYear();
    const month = Math.floor((date.getMonth() + 1) / 10) > 0 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    const day = Math.floor(date.getDate() / 10) > 0 ? date.getDate() : '0' + date.getDate();
    return moment(`${year}-${month}-${day}T00:00:00Z`).tz('Africa/Abidjan');
  }

  getSimpleUrl(url: string) {
    if (!url) {
      return '';
    }
    if (url.includes('://')) {
      const temporaryUrlParts = split(url, '://');
      const Ref = /\/|\:/;
      const urlParts = [temporaryUrlParts[0], split(temporaryUrlParts[1], Ref)[0]];
      return urlParts.join('://');
    }
    return split(url, '/')[0];
  }

  getURLPath(url: string): string {
    if (!url) {
      return '';
    }
    const reg = /http(?:s?):\/\/[^\/]*(\/.*)/;
    return url.match(reg) ? url.match(reg)[1].replace('/', '') : '';
  }

  public showFAQListener(): BehaviorSubject<boolean> {
    return this.isShowFAQ;
  }

  public isURLValid(url: string): boolean {
    if (!url) {
      return false;
    }
    return this.URLPattern.test(url);
  }

  public isCityNameValid(name: string): boolean {
    if (!name) {
      return false;
    }
    return this.cityNamePattern.test(name.trim());
  }

  getGeoTarget(location: ILocation | ILimitedLocation): string {
    let geoTarget = '';
    if (location) {
      // need to display information: [radius], [states], [zips]
      // if with radius(show ==> address [city], [selfState] + radius), no radius(show '')
      if (location.locationRadius) {
        geoTarget += location.locationStreetAddress || '';
        geoTarget += location.locationCity ? ' ' + location.locationCity : '';
        geoTarget += location.locationSelfState ? ', ' + location.locationSelfState + ' + ' : ', + ';
        geoTarget += location.locationRadius;
      }
      if (location.locationState && location.locationState.length) {
        geoTarget += location.locationRadius ? ', ' : '';
        geoTarget += location.locationState.join(', ');
      }
      if (location.locationZips && location.locationZips.length) {
        geoTarget += location.locationRadius || (location.locationState && location.locationState.length) ? ', ' : '';
        geoTarget += location.locationZips.join(', ');
      }
    }
    return geoTarget;
  }

  getGenderOptions(): any {
    return [
      { value: 'both', text: 'All' },
      { value: 'male', text: 'Male' },
      { value: 'female', text: 'Female' },
    ];
  }

  getAgeOptions(): any {
    return range(18, 66).map((age) => {
      const value = age.toString();
      return {
        value,
        text: value === '65' ? '65+' : value,
      };
    });
  }

  toggleHeaderText(columns: Array<ITableColumn>, index: number): ITableColumn {
    columns[index].isHover = !columns[index].isHover;
    if (columns[index].isHover && columns[index].icon) {
      columns[index].icon = columns[index].icon.replace('-gray', '');
    } else {
      columns[index].icon += '-gray';
    }
    return columns[index];
  }

  getFileNameFromBEUrl(backendUrl: string): string {
    const reg = /^((uid_[0-9]*\/)?[0-9]*\-){1}/;
    return backendUrl.replace(reg, '');
  }

  truncateDescriptionStr(str, n, useWordBoundary: true) {
    if (str && isString(str)) {
      const tooLong = str.length > n;
      let temStr = tooLong ? this.trimDescriptionStr(str.substr(0, n - 1)) : str;
      temStr = useWordBoundary && tooLong ? temStr.substr(0, temStr.lastIndexOf(' ')) : temStr;

      if (tooLong) {
        while (/[^A-Z]$/i.test(temStr)) {
          temStr = temStr.substr(0, temStr.length - 1);
        }
      }
      return tooLong ? temStr + '...' : temStr;
    } else {
      return '';
    }
  }

  trimDescriptionStr(str) {
    let sString = str;
    if (typeof sString === 'undefined' || !sString) {
      return '';
    }

    while (sString.substring(0, 1) === ' ' || sString.substring(0, 1) === '\r' || sString.substring(0, 1) === '\n') {
      sString = sString.substring(1, sString.length);
    }

    while (
      sString.substring(sString.length - 1, sString.length) === ' ' ||
      sString.substring(sString.length - 1, sString.length) === '\r' ||
      sString.substring(sString.length - 1, sString.length) === '\n'
    ) {
      sString = sString.substring(0, sString.length - 1);
    }

    return sString;
  }
}
