import { Assignment } from '@constants';
import { IKPMItem, NotificationMessage, NotificationType, PoliciesEnum, ViewType } from '@interfaces';

/*
 * For main layout route navigations
 * 1. Zor side menu and dropdown menu
 * 2. Zee side menu and dropdown menu
 * */

export const zorNavigations = [
  {
    navId: 'admin',
    navName: 'Admin',
    navPath: '/admin',
    navType: 'sidebar',
    isActive: false,
  },
  {
    navId: 'zor-agency',
    navName: 'Agency View',
    navPath: '/agency',
    navType: 'sidebar',
    navLabel: 'Agency Home Page',
    isActive: false,
  },
  {
    navId: 'zor-performance-performance-overview',
    navName: 'Performance Overview',
    navPath: '/app/performance-overview',
    navType: 'sidebar',
    isActive: false,
  },
  {
    navId: 'zor-create-template',
    navName: 'Create A New Ad Template',
    navPath: '/app/create-campaign',
    navType: 'sidebar',
    navLabel: 'Create Campaign',
    isActive: false,
  },
  {
    navId: 'zor-manage-template',
    navName: 'Manage Ad Templates',
    navPath: '/app/template-management',
    navType: 'sidebar',
    navLabel: 'Campaign Management',
    isActive: false,
  },
  {
    navId: 'zor-run-campaign',
    navName: 'Run A New Ad',
    navPath: '/app/run-ad',
    navType: 'sidebar',
    isActive: false,
  },
  {
    navId: 'zor-campaign-results',
    navName: 'Ad Results',
    navPath: '/app/ad-aggregated-results',
    navType: 'sidebar',
    navLabel: 'View Ad Results',
    isActive: false,
  },
  {
    navId: 'zor-ad-spend',
    navName: 'Ad Spend',
    navPath: '/app/ad-spend',
    navType: 'dropdown',
    isActive: false,
  },
  {
    navId: 'zor-account',
    navName: 'Account Info',
    navPath: '/app/account',
    navType: 'dropdown',
    navLabel: 'View Account Info',
    isActive: false,
  },
  {
    navId: 'zor-user-management',
    navName: 'User Management',
    navPath: '/app/user-management',
    navType: 'dropdown',
    isActive: false,
  },
  {
    navId: 'zor-group-management',
    navName: 'Manage Tags/Labels',
    navPath: '/app/group-management',
    navType: 'dropdown',
    isActive: false,
  },
  {
    navId: 'logout',
    navName: 'Log Out',
    navType: 'dropdown',
    navPath: null,
    isActive: false,
  },
];

export const zeeNavigations = [
  {
    navId: 'admin',
    navName: 'Admin',
    navPath: '/admin',
    navType: 'sidebar',
    isActive: false,
  },
  {
    navId: 'zee-agency',
    navName: 'Agency View',
    navPath: '/agency',
    navType: 'sidebar',
    navLabel: 'Agency Home Page',
    isActive: false,
  },
  {
    navId: 'zee-home',
    navName: 'Home',
    navPath: '/app/home',
    navType: 'sidebar',
    navLabel: 'Zee Landing Page',
    isActive: false,
  },
  {
    navId: 'zee-run-campaign',
    navName: 'Run A New Ad',
    navPath: '/app/run-ad',
    navType: 'sidebar',
    isActive: false,
  },
  {
    navId: 'zee-campaign-results',
    navName: 'View Ad Results',
    navPath: '/app/ad-results',
    navType: 'sidebar',
    isActive: false,
  },
  // hide until subscriptions are reworked
  // {
  //   navId: 'zee-manage-subscription',
  //   navName: 'Manage Subscriptions',
  //   navPath: '/app/ad-subscription',
  //   navType: 'sidebar',
  //   isActive: false,
  // },
  {
    navId: 'zee-ad-spend',
    navName: 'Ad Spend',
    navPath: '/app/ad-spend',
    navType: 'dropdown',
    isActive: false,
  },
  {
    navId: 'zee-account-info',
    navName: 'Account Info',
    navPath: '/app/account',
    navType: 'dropdown',
    navLabel: 'View Account Info',
    isActive: false,
  },
  {
    navId: 'zee-user-management',
    navName: 'User Management',
    navPath: '/app/user-management',
    navType: 'dropdown',
    isActive: false,
  },
  {
    navId: 'logout',
    navName: 'Log Out',
    navPath: null,
    navType: 'dropdown',
    isActive: null,
  },
];

export const navigationMenus = [
  {
    navId: 'admin',
    policyIds: [PoliciesEnum.navAdminPortal],
    navName: 'Admin',
    navPath: '/admin',
    navType: 'sidebar',
    excludeViewTypes: [],
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'agency',
    policyIds: [PoliciesEnum.navAgencyPortal],
    navName: 'Customer Portal',
    navPath: '/agency',
    navType: 'sidebar',
    excludeViewTypes: [],
    navLabel: 'Agency Home Page',
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'performance-performance-overview',
    policyIds: [PoliciesEnum.navZorPerformanceOverview, PoliciesEnum.navSMBPerformanceOverview],
    navName: 'Performance Overview',
    navPath: '/app/performance-overview',
    navType: 'sidebar',
    excludeViewTypes: [ViewType.ZEWI, ViewType.ZELO, ViewType.ZOLO],
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'home',
    policyIds: [PoliciesEnum.navZeePerformanceOverview],
    navName: 'Home',
    navPath: '/app/home',
    navType: 'sidebar',
    navLabel: 'Zee Landing Page',
    excludeViewTypes: [ViewType.ZOWI, ViewType.COLO],
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'create-template',
    policyIds: [PoliciesEnum.navZorCreateANewTemplate, PoliciesEnum.navSMBCreateANewTemplate],
    navName: 'Create A New Ad Template',
    navPath: '/app/create-campaign',
    navType: 'sidebar',
    navLabel: 'Create Campaign',
    excludeViewTypes: [ViewType.ZEWI, ViewType.ZELO, ViewType.ZOLO],
    isActive: false,
    isEnable: false,
    assignment: Assignment.TEMPLATE_CREATE,
  },
  {
    navId: 'manage-template',
    policyIds: [PoliciesEnum.navManageAdTemplates],
    navName: 'Manage Ad Templates',
    navPath: '/app/template-management',
    navType: 'sidebar',
    navLabel: 'Campaign Management',
    excludeViewTypes: [ViewType.ZEWI, ViewType.ZELO, ViewType.ZOLO],
    isActive: false,
    isEnable: false,
    assignment: Assignment.TEMPLATE_UPDATE,
  },
  {
    navId: 'run-campaign',
    policyIds: [PoliciesEnum.navZorRunANewAd, PoliciesEnum.navZeeRunANewAd],
    navName: 'Run A New Ad',
    navPath: '/app/run-ad',
    navType: 'sidebar',
    excludeViewTypes: [],
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'aggregated-ad-results',
    policyIds: [PoliciesEnum.navAdResultsAggregatedMetrics],
    navName: 'Ad Results',
    navPath: '/app/ad-aggregated-results',
    navType: 'sidebar',
    navLabel: 'View Ad Results',
    excludeViewTypes: [ViewType.ZEWI, ViewType.ZELO, ViewType.ZOLO],
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'location-management',
    policyIds: [],
    navName: 'Location Management',
    navPath: '/ui/management/locations',
    navType: 'sidebar',
    excludeViewTypes: [ViewType.ZELO, ViewType.ZOLO],
    isActive: false,
    isEnable: false,
    newApp: true,
  },
  {
    navId: 'ad-results',
    policyIds: [PoliciesEnum.navZeeAdResults],
    navName: 'View Ad Results',
    navPath: '/app/ad-results',
    navType: 'sidebar',
    excludeViewTypes: [ViewType.ZOWI, ViewType.COLO],
    isActive: false,
    isEnable: false,
  },
  // hide until subscriptions are reworked
  // {
  //   navId: 'manage-subscription',
  //   policyIds: [PoliciesEnum.navManageSubscriptions],
  //   navName: 'Manage Subscriptions',
  //   navPath: '/app/ad-subscription',
  //   navType: 'sidebar',
  //   excludeViewTypes: [ViewType.ZOWI, ViewType.COLO],
  //   isActive: false,
  //   isEnable: false,
  // },
  {
    navId: 'ad-spend',
    policyIds: [PoliciesEnum.navAdSpend],
    navName: 'Ad Spend',
    navPath: '/app/ad-spend',
    navType: 'dropdown',
    excludeViewTypes: [],
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'account',
    navName: 'Account Info',
    policyIds: [PoliciesEnum.navZorAccount, PoliciesEnum.navZeeAccount],
    navPath: '/app/account',
    navType: 'dropdown',
    navLabel: 'View Account Info',
    excludeViewTypes: [],
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'billing',
    policyIds: [],
    navName: 'Billing',
    navPath: '/ui/management/billing',
    excludeViewTypes: [ViewType.ZELO, ViewType.ZOLO],
    navType: 'dropdown',
    isActive: false,
    isEnable: false,
    newApp: true,
  },
  {
    navId: 'user-management',
    policyIds: [PoliciesEnum.navUserManagement],
    navName: 'User Management',
    navPath: '/ui/management/permissions/users',
    excludeViewTypes: [],
    navType: 'dropdown',
    isActive: false,
    isEnable: false,
    newApp: true,
  },
  {
    navId: 'group-management',
    policyIds: [PoliciesEnum.navManageTags],
    navName: 'Manage Tags/Labels',
    navPath: '/app/group-management',
    excludeViewTypes: [ViewType.ZELO, ViewType.ZOLO],
    navType: 'dropdown',
    isActive: false,
    isEnable: false,
  },
  {
    navId: 'logout',
    policyIds: [],
    navName: 'Log Out',
    navType: 'dropdown',
    excludeViewTypes: [],
    navPath: null,
    isActive: false,
    isEnable: true,
  },
];

/*
 * For zee home(landing) page
 * 1. Notifications
 * 2. Network activities
 * */

// [value] is the placeholder which will be replaced by the actual value.
export const notificationsMessages: Array<NotificationMessage> = [
  {
    type: NotificationType.impressions_10k,
    msg: 'This ad has just reached 10,000 impressions.',
    category: 'campaign',
  },
  {
    type: NotificationType.impressions_20k,
    msg: 'This ad has just reached 20,000 impressions.',
    category: 'campaign',
  },
  {
    type: NotificationType.highest_conversion,
    msg: 'This ad has produced the highest number of conversions so far.',
    category: 'campaign',
  },
  {
    type: NotificationType.highest_ctr,
    msg: 'This ad is performing well with a CTR of [value].',
    category: 'campaign',
  },
  {
    type: NotificationType.highest_runs,
    msg: 'This ad is being run by the most people in your organization network.',
    category: 'campaign',
  },
  {
    type: NotificationType.highest_clicks,
    msg: `This ad is gaining traction with [value] clicks.`,
    category: 'campaign',
  },
  {
    type: NotificationType.ad_failed,
    msg:
      'Your recently placed ad [adName] ' +
      'set to run on [channelName] scheduled to start on [startDate] failed to run.',
    category: 'campaign',
  },
  {
    type: NotificationType.zor_ran_on_zelo,
    msg: 'Your Franchisor paid for and ran an ad on your behalf. Click to ',
    category: 'campaign',
  },
  {
    type: NotificationType.campaign_channel_connected_state,
    msg:
      /* tslint:disable-next-line */
      'Due to [disconnectedChannelName] disconnection the ad for your subscription, [adSubscriptionName], was replaced with [templateName] on [channelName].',
    category: 'campaign',
  },
  {
    type: NotificationType.conversion_campaign_template_for_ad_sub,
    msg:
      /* tslint:disable-next-line */
      'For the next subscription period no add is available for auto selection. Go to Manage subscription if you would like to select a conversion ad for your subscription.',
    category: 'campaign',
  },
  {
    type: NotificationType.campaign_operation,
    msg: 'You have a paused ad: ',
    category: 'pausedAd',
  },
  {
    type: NotificationType.account_disconnect,
    msg: 'Your [channelName] authorization token has expired.',
    category: 'accountDisconnect',
  },
  {
    type: NotificationType.ad_inactive_by_account_disconnect,
    // tslint:disable-next-line:max-line-length
    msg: `Your Franchisor's Facebook/Instagram authorization token has expired. Your results may be affected until your franchisor renews their token.`,
    category: 'inactiveAd',
  },
  {
    type: NotificationType.funding_source_type_is_unset,
    category: 'paymentUnset',
    msg: 'You do not have up-to-date billing information in your Facebook Ad Manager.',
  },
  {
    type: NotificationType.campaign_template_request,
    msg: 'A new template is being requested by [zeeNme]',
    category: 'templateRequest',
  },
];

// [value] is the placeholder which will be replaced by the actual value.
export const zeeActivitiesMessages = [
  {
    type: 'newAds',
    msg: 'launched',
  },
  {
    type: 'firstAdPlaced',
    msg: 'just placed their first ad.',
  },
  {
    type: 'hit2kImpressions',
    msg: 'campaign has reached 2,000 impressions!',
  },
  {
    type: 'hit5kImpressions',
    msg: 'campaign has reached 5,000 impressions!',
  },
  {
    type: 'hit10kImpressions',
    msg: 'campaign has reached 10,000 impressions!',
  },
  {
    type: 'highestCtrAd',
    msg: 'campaign had the campaign with the highest CTR of [value] in the last 7 days.',
  },
  {
    type: 'adsConversion',
    msg: 'just received a conversion through this ad.',
  },
  {
    type: 'newUsers',
    msg: `just joined the network.`,
  },
];

/*
 * For zee ad subscription page
 * 1. Input fields
 * 2. Select Options
 * */

export const adSubscriptionTypeFields = [
  {
    fieldLabel: 'Channel',
    fieldName: 'adSubscriptionChannel',
    fieldType: 'radioGroup',
  },
  {
    fieldLabel: 'Goal',
    fieldName: 'adSubscriptionGoal',
    fieldType: 'radioGroup',
  },
  {
    fieldLabel: '',
    fieldName: 'adSubscriptionIsByMetrics',
    fieldType: 'radioGroup',
  },
  {
    fieldLabel: 'Best Performance Metric',
    fieldName: 'adSubscriptionPerformMetric',
    fieldType: 'radioGroup',
    fieldWidth: '100%',
    fieldToolTip: 'We will recommend an ad to subscribe to based on the metric you prefer',
  },
];

export const adSubscriptionDetailFields = [
  {
    fieldLabel: 'Weekly Budget',
    fieldName: 'adSubscriptionWeeklyBudget',
    fieldType: 'input',
    fieldDefaultValue: null,
    fieldInputType: 'number',
    fieldToolTip: 'The $ amount you would like to spend weekly on the ad you are subscribing to',
    fieldWidth: '100%',
    fieldPreText: '$',
    fieldEndText: 'PER WEEK',
  },
  {
    fieldLabel: 'Ad Run Time',
    fieldName: 'adSubscriptionRunTims',
    fieldType: 'input',
    fieldDefaultValue: null,
    fieldInputType: 'number',
    fieldToolTip: 'The number of weeks you would like to subscribe to the ad',
    fieldWidth: '100%',
    fieldEndText: 'WEEK',
  },
  {
    fieldLabel: 'Name of Subscription',
    fieldName: 'adSubscriptionNme',
    fieldType: 'input',
    fieldInputType: 'text',
    fieldDefaultValue: '',
    fieldToolTip: 'Please give a specific name to this subscription',
    fieldWidth: '100%',
    fieldLimit: 120,
  },
  {
    fieldLabel: 'Assigned Location(s)',
    fieldName: 'adSubscriptionAssignedLocations',
    fieldType: 'dropdown',
    fieldDefaultValue: [],
    fieldToolTip: '',
    fieldWidth: '75%',
  },
  {
    fieldLabel: 'Start Date',
    fieldName: 'adSubscriptionStartDte',
    fieldDefaultValue: null,
    fieldType: 'datepicker',
    fieldToolTip: 'The date you would like the subscription to start',
    fieldWidth: '75%',
  },
];

export const adSubscriptionTypeOptions = {
  adSubscriptionChannel: [
    {
      value: 'facebook',
      isDisabled: false,
    },
    {
      value: 'instagram',
      isDisabled: false,
    },
    {
      value: 'google',
      isDisabled: false,
    },
  ],
  adSubscriptionGoal: [
    {
      value: 'awareness',
      isDisabled: false,
    },
    {
      value: 'traffic',
      isDisabled: false,
    },
    {
      value: 'leads',
      isDisabled: false,
    },
    {
      value: 'sales',
      isDisabled: false,
    },
  ],
  adSubscriptionIsByMetrics: [
    {
      value: 'Best Ad By Performance Metrics',
      isDisabled: false,
    },
  ],
  adSubscriptionPerformMetric: [
    {
      text: 'Impressions',
      value: 'impressions',
      isDisabled: false,
    },
    {
      text: 'Click-Through Rate',
      value: 'ctr',
      isDisabled: false,
    },
  ],
};

/*
 * For zor franchisee management page
 * 1. Input fields
 * 2. Bulk upload template
 * 3. Invalid Input messages
 * */

export const franchiseeLocationFields = {
  basic: [
    {
      fieldLabel: 'Franchise Name',
      fieldName: 'locationFranchiseNme',
      fieldType: 'input',
      inputType: 'text',
      isRequired: true,
    },
    {
      fieldLabel: 'Contact Name',
      fieldName: 'locationContactNme',
      fieldType: 'input',
      inputType: 'text',
      isRequired: true,
    },
    {
      fieldLabel: 'Franchise Location Name',
      fieldName: 'locationNme',
      fieldType: 'input',
      inputType: 'text',
      isRequired: true,
    },
    {
      fieldLabel: 'Phone',
      fieldName: 'locationPhoneNbr',
      fieldType: 'input',
      inputType: 'text',
      isRequired: true,
    },
    {
      fieldLabel: 'Address',
      fieldName: 'locationAddress',
      fieldType: 'input',
      inputType: 'text',
      isRequired: true,
    },
  ],
  selfGeo: [
    {
      fieldLabel: 'Website',
      fieldName: 'locationWebsite',
      fieldType: 'input',
      inputType: 'text',
      isRequired: false,
    },
  ],
  targetingGeo: [
    {
      fieldLabel: 'Zip Codes',
      fieldName: 'targetingZips',
      fieldType: 'input',
      inputType: 'number',
      isRequired: false,
    },
    {
      fieldLabel: 'Radius',
      fieldName: 'targetingRadius',
      fieldType: 'input',
      inputType: 'number',
      isRequired: false,
    },
    {
      fieldLabel: 'States',
      fieldName: 'targetingStates',
      fieldType: 'dropdown',
      isRequired: false,
    },
  ],
  excludedGeo: [
    {
      fieldLabel: 'Excluded Zip Codes',
      fieldName: 'excludedZips',
      fieldType: 'input',
      inputType: 'number',
      isRequired: false,
    },
    {
      fieldLabel: 'Excluded Radius',
      fieldName: 'excludedRadius',
      fieldType: 'input',
      inputType: 'number',
      isRequired: false,
    },
  ],
};

export const bulkUploadTemplate = [
  ['Template Rules'],
  ['1. All fields are required except for Address 2 and Website. Those fields are optional'],
  ['2. Format for Phone must be (###) ###-####'],
  ['Included Geotargeting Rules (Assign only one)'],
  [
    '1.Only one radius can be assigned. The radius is the distance from the physical location provided in the address(es) column.' +
      'Radius must include mi (e.g. 3 mi) and be between 1 and 50',
  ],
  ['2.Multiple zip codes can be included and must be separated by a comma'],
  ['3.Multiple states can be included and must be separated by a comma'],
  ['Excluded Geotargeting Rules - Will only affect Facebook Ads'],
  [
    '1.Only one radius can be assigned. The radius is the distance from the physical location provided in the address(es) column.' +
      'Radius must include mi (e.g. 3 mi) and be between 1 and 50',
  ],
  ['2.Multiple zip codes can be included and must be separated by a comma'],
  [],
  ['Franchise Location Information', 'Geotargeting (assign only one)', 'Excluded Geotargeting (assign only one)'],
  [
    'Franchise Name',
    'Franchise Location Name',
    'Contact Name',
    'Phone (###) ###-####',
    'Address 1',
    'Address 2 (optional)',
    'City',
    'State',
    'Zip',
    'Radius (mi)',
    'Zip Code(s)',
    'State(s)',
    'Excluded Radius (mi)',
    'Excluded Zip Code(s)',
  ],
];

// For date dropdown menu
export const timePeriods = [
  { text: 'Year to Date', value: 'Year to Date' },
  { text: 'Month to Date', value: 'Month to Date' },
  { text: 'Last 12 Months', value: 'Last 12 Months' },
  { text: 'To Date', value: 'To Date' },
];

// For performance-overview page "Ad Performance";
export const adPerformanceIndicators = [
  {
    title: 'Highest CTR',
    value: 'highestCtrTemplates',
    label: 'CTR',
    sortProp: 'ctr',
    color: 'red',
  },
  {
    title: 'Most Run',
    value: 'mostRunTemplates',
    label: 'Run Times',
    color: 'orange',
    sortProp: 'run',
    isExcludedForSMB: true,
  },
  {
    title: 'Most Impressions',
    value: 'highestImpressionsTemplates',
    label: 'Impressions',
    sortProp: 'impressions',
    color: 'green',
  },
  {
    title: 'Most Clicks',
    value: 'highestClicksTemplates',
    label: 'Clicks',
    color: 'red',
    sortProp: 'clicks',
  },
];

export const adPerformanceIndicatorsSMB = [
  {
    title: 'Highest Spend Template',
    value: 'highestSpendTemplates',
    label: 'Spend',
    sortProp: 'spend',
    color: 'green',
  },
  {
    title: 'Highest CTR Template',
    value: 'highestCtrTemplates',
    label: 'CTR',
    sortProp: 'ctr',
    color: 'red',
  },
];

// For create template page "Google Analytics Event";
export const googleAnalyticsEvents = [
  {
    field: 'campaignTemplateGoal',
    label: 'Goal',
  },
  {
    field: 'campaignChannelNme',
    label: 'Channel',
  },
  {
    field: 'campaignTemplateAudienceGender',
    label: 'Gender',
  },
  {
    field: 'campaignTemplateObjectives',
    label: 'Objectives',
  },
  {
    field: 'campaignTemplateAvailableEndDte',
    label: 'Available End Date',
  },
  {
    field: 'campaignTemplateOptimizationGoal',
    label: 'Optimization Goal',
  },
  {
    field: 'campaignTemplateGoal',
    label: 'Goal',
  },
  {
    field: 'campaignTemplateMaxAge',
    label: 'Max Age',
  },
  {
    field: 'campaignTemplateMinAge',
    label: 'Min Age',
  },
  {
    field: 'facebookChannelMediaType',
    label: 'Media Type',
  },
  {
    field: 'instagramChannelMediaType',
    label: 'Media Type',
  },
  {
    field: 'facebookChannelCta',
    label: 'CTA',
  },
  {
    field: 'instagramChannelCta',
    label: 'CTA',
  },
  {
    field: 'detailedTargeting',
    label: 'Detailed Targeting',
  },
  {
    field: 'googleChannelBiddingStrategy',
    label: 'Bidding Strategy',
  },
  {
    field: 'googleChannelNegativeKeywordType',
    label: 'Negative Keyword Type',
  },
  {
    field: 'facebookChannelFullscreenInd',
    label: 'Full Screen',
  },
  {
    field: 'instagramChannelFullscreenInd',
    label: 'Full Screen',
  },
  {
    field: 'facebookChannelDisplayLink',
    label: 'Display Link',
  },
  {
    field: 'instagramChannelDisplayLink',
    label: 'Display Link',
  },
  {
    field: 'campaignTemplateOptOutInd',
    label: 'Subscription Toggle',
  },
];

// For ad list of zee run ad page, zee ad results page and zor run ad page

export const adMetricTypes = [
  {
    text: 'Relevance',
    value: 'relevance',
  },
  {
    text: 'Rating',
    value: 'rated',
    field: 'campaignTemplateRateFeedback.averageRate',
    color: 'orange',
    recommendedLabel: 'Highest Rated',
  },
  {
    /*
     * campaignTemplateRunTims: Total campaigns under this template
     * */
    text: 'Times Run',
    value: 'run',
    field: 'campaignTemplateRunTims',
    type: 'times',
    icon: 'assets/icons/fill/audience-primary.svg',
    color: 'orange',
    recommendedLabel: 'Most Run',
  },
  {
    text: 'Impressions',
    value: 'impressions',
    field: 'campaignTemplateMetrics.impressions',
    icon: 'assets/icons/fill/impressions-solid.svg',
    color: 'red',
    recommendedLabel: 'Most Impressions',
  },
  {
    text: 'CTR',
    value: 'ctr',
    field: 'campaignTemplateMetrics.ctr',
    type: 'percent',
    icon: 'assets/icons/fill/clicks-solid.svg',
    color: 'red',
    recommendedLabel: 'Highest CTR',
  },
  {
    text: 'CPC',
    value: 'cpc',
    field: 'campaignTemplateMetrics.cpc',
    type: 'currency',
    icon: 'assets/icons/fill/cpc-solid.svg',
    color: 'red',
  },
  {
    text: 'CVR',
    value: 'cvr',
    field: 'campaignTemplateMetrics.cvr',
    type: 'percent',
    icon: 'assets/icons/fill/cvr-solid.svg',
    color: 'red',
  },
];

export const conversionEvents = [
  'Add Payment Info',
  'Add to Cart',
  'Add to Wishlist',
  'Complete Registration',
  'Contact',
  'Customize Product',
  'Donate',
  'Find Location',
  'Initiate Checkout',
  'Lead',
  'Page View',
  'Schedule',
  'Search',
  'Start Trial',
  'Submit Application',
  'Subscribe',
  'View Content',
];

export const states: Array<{ abbreviation: string; state: string }> = [
  { abbreviation: 'AL', state: 'Alabama' },
  { abbreviation: 'AK', state: 'Alaska' },
  { abbreviation: 'AZ', state: 'Arizona' },
  { abbreviation: 'AR', state: 'Arkansas' },
  { abbreviation: 'CA', state: 'California' },
  { abbreviation: 'CO', state: 'Colorado' },
  { abbreviation: 'CT', state: 'Connecticut' },
  { abbreviation: 'DE', state: 'Delaware' },
  { abbreviation: 'DC', state: 'District of Columbia' },
  { abbreviation: 'FL', state: 'Florida' },
  { abbreviation: 'GA', state: 'Georgia' },
  { abbreviation: 'HI', state: 'Hawaii' },
  { abbreviation: 'ID', state: 'Idaho' },
  { abbreviation: 'IL', state: 'Illinois' },
  { abbreviation: 'IN', state: 'Indiana' },
  { abbreviation: 'IA', state: 'Iowa' },
  { abbreviation: 'KY', state: 'Kentucky' },
  { abbreviation: 'ME', state: 'Maine' },
  { abbreviation: 'MD', state: 'Maryland' },
  { abbreviation: 'MA', state: 'Massachusetts' },
  { abbreviation: 'MI', state: 'Michigan' },
  { abbreviation: 'MN', state: 'Minnesota' },
  { abbreviation: 'MS', state: 'Mississippi' },
  { abbreviation: 'MO', state: 'Missouri' },
  { abbreviation: 'MT', state: 'Montana' },
  { abbreviation: 'NE', state: 'Nebraska' },
  { abbreviation: 'NV', state: 'Nevada' },
  { abbreviation: 'NH', state: 'New Hampshire' },
  { abbreviation: 'NJ', state: 'New Jersey' },
  { abbreviation: 'NM', state: 'New Mexico' },
  { abbreviation: 'NY', state: 'New York' },
  { abbreviation: 'NC', state: 'North Carolina' },
  { abbreviation: 'ND', state: 'North Dakota' },
  { abbreviation: 'OH', state: 'Ohio' },
  { abbreviation: 'OK', state: 'Oklahoma' },
  { abbreviation: 'OR', state: 'Oregon' },
  { abbreviation: 'PA', state: 'Pennsylvania' },
  { abbreviation: 'PR', state: 'Puerto Rico' },
  { abbreviation: 'RI', state: 'Rhode Island' },
  { abbreviation: 'SC', state: 'South Carolina' },
  { abbreviation: 'SD', state: 'South Dakota' },
  { abbreviation: 'TN', state: 'Tennessee' },
  { abbreviation: 'TX', state: 'Texas' },
  { abbreviation: 'UT', state: 'Utah' },
  { abbreviation: 'VT', state: 'Vermont' },
  { abbreviation: 'VA', state: 'Virginia' },
  { abbreviation: 'VI', state: 'Virgin Islands' },
  { abbreviation: 'WA', state: 'Washington' },
  { abbreviation: 'WV', state: 'West Virginia' },
  { abbreviation: 'WI', state: 'Wisconsin' },
  { abbreviation: 'WY', state: 'Wyoming' },
];

export const KPMList: Array<IKPMItem> = [
  {
    label: 'Impressions',
    key: 'impressions',
    abbr: 'Impr.',
    name: '',
    defaultValue: 0,
    type: 'number',
    tooltip: 'The number of times your ad were on screen',
  },
  {
    label: 'Clicks',
    key: 'clicks',
    abbr: null,
    name: '',
    defaultValue: 0,
    type: 'number',
    tooltip: 'The number of clicks on your ad',
  },
  {
    label: 'CTR',
    key: 'ctr',
    abbr: null,
    name: '',
    defaultValue: 0,
    type: 'percentage',
    tooltip: 'The percentage of times people saw your ad and performed a click',
  },
  {
    label: 'CVR',
    key: 'cvr',
    abbr: null,
    name: '',
    defaultValue: 0,
    type: 'percentage',
    tooltip: 'The percentage of conversions you received out of all the clicks of your ads',
  },
  {
    label: 'CPC',
    key: 'cpc',
    abbr: null,
    name: '',
    defaultValue: 0,
    type: 'currency',
    tooltip: 'The average cost for each click',
  },
  {
    label: 'Channel Most Run',
    key: 'topRunChannelName',
    abbr: null,
    name: '',
    defaultValue: 0,
    type: 'number',
    tooltip: 'The channel of the campaign template most run by your franchise network',
  },
];

export const additionalKPMList: Array<IKPMItem> = [
  {
    label: 'Likes',
    key: 'likes',
    abbr: null,
    name: '',
    defaultValue: 0,
    type: 'number',
    tooltip: 'The number of likes your ad received',
  },
  {
    label: 'Video Avg. Play Time (sec)',
    key: 'videoViews',
    abbr: 'Cmts.',
    name: '',
    defaultValue: 0,
    type: 'number',
    tooltip: 'The number of seconds your video ad was played for',
  },
  {
    label: 'Peak Day',
    key: 'peakDay',
    abbr: 'Views',
    name: '',
    defaultValue: 'N/A',
    type: 'text',
    tooltip: 'The day of the week you have gotten the most impressions on your ad',
  },
];
