import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { RadioButtonOption } from '@interfaces';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

// TODO Option
interface Option {
  value: string;
  disabled: boolean;
}

@Component({
  selector: 'app-ui-radio-button',
  templateUrl: './ui-radio-button.component.html',
  styleUrls: ['./ui-radio-button.component.scss'],
})
export class UiRadioButtonComponent implements OnInit, OnChanges {
  @Input()
  formGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  options: Array<RadioButtonOption> = [];
  @Input()
  selected: string;
  @Input()
  separate = false;
  @Input()
  width: string;
  @Input()
  disabled = false;
  @Input()
  iconSize = 1;

  @Output()
  changeEvent: EventEmitter<string | boolean> = new EventEmitter();
  @Output()
  disabledClickEvent: EventEmitter<string | number> = new EventEmitter();

  style: any = {};
  gridColumns: SafeStyle;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.initForm();
    this.listenValueChange();
    this.initStyle();
  }

  ngOnChanges() {
    this._setValueByOptions();
    this.initStyle();
  }

  private initForm(): void {
    let radioGroupForm = this.formGroup;
    let controlName = this.controlName;
    const options = this.options;
    let defaultValue = this._selected;
    if (
      !!options &&
      options.length > 0 &&
      !options.find((option) => !!defaultValue && option.value + '' === defaultValue)
    ) {
      defaultValue = options[0].value + '';
    }
    if (!!radioGroupForm && !!controlName) {
      this.selected = defaultValue;
    } else {
      controlName = 'default';
      radioGroupForm = new FormGroup({
        default: new FormControl(defaultValue, [Validators.required]),
      });
    }

    this.formGroup = radioGroupForm;
    this.controlName = controlName;
  }

  private _setValueByOptions(): void {
    const radioGroupForm = this.formGroup;
    const controlName = this.controlName;
    const options = this.options;
    if (!!radioGroupForm && !!controlName) {
      const valueInOption = options.find((option) => option.value + '' === radioGroupForm.get(controlName).value);
      if (!valueInOption) {
        this._setValue('');
      }
    }
  }

  private listenValueChange() {
    const radioGroupForm = this.formGroup;
    const controlName = this.controlName;
    if (radioGroupForm && controlName) {
      radioGroupForm.get(controlName).valueChanges.subscribe((value) => {
        this.changeEvent.emit(value);
      });
    }
  }

  private initStyle() {
    const width = this.width;
    const options = this.options;
    let style = {};
    if (!!width && !!options) {
      this.gridColumns = this.sanitizer.bypassSecurityTrustStyle(`repeat(${options.length} , 1fr)`);
      style = { width };
    }
    this.style = style;
  }

  checkIsAble(event: MouseEvent, option: Option): void {
    if (option.disabled || this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      this.disabledClickEvent.emit(option.value);
    }
  }

  private _setValue(value: string) {
    setTimeout(() => {
      const radioGroupForm = this.formGroup;
      const controlName = this.controlName;
      this.selected = value;
      radioGroupForm.get(controlName).setValue(value);
    });
  }

  get _selected(): string {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName).value;
    }
    return this.selected + '' || '';
  }

  get formControl(): AbstractControl {
    return this.formGroup.get(this.controlName);
  }

  get isError(): boolean {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      const errors = formGroup.get(controlName).errors;
      const dirty = formGroup.get(controlName).dirty;
      const touched = formGroup.get(controlName).touched;
      return !!errors && (dirty || touched);
    }
    return false;
  }
}
