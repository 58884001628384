import { gql } from 'apollo-angular';

export const channelDataFragment = gql`
  fragment channel on ChannelUnion {
    ... on FacebookChannel {
      campaignChannelNme
      facebookChannelAdsetNme
      facebookChannelDetailTargeting
      facebookChannelAdTxt
      facebookChannelMediaType
      facebookChannelCta
      facebookChannelFullscreenInd
      facebookChannelSocialMedia
      facebookChannelDisplayLink
      facebookChannelAdPositions
    }
    ... on InstagramChannel {
      campaignChannelNme
      instagramChannelAdsetNme
      instagramChannelDetailTargeting
      instagramChannelAdTxt
      instagramChannelMediaType
      instagramChannelCta
      instagramChannelFullscreenInd
      instagramChannelSocialMedia
      instagramChannelDisplayLink
      instagramChannelAdPositions
    }
    ... on GoogleChannel {
      campaignChannelNme
      googleChannelDailyBudget
      googleChannelAdGroupNme
      googleChannelHeadline1
      googleChannelHeadline2
      googleChannelHeadline3
      googleChannelDsc
      googleChannelDsc2
      googleChannelPathA
      googleChannelPathB
      googleChannelExactKeywords
      googleChannelBroadKeywords
      googleChannelPhraseKeywords
      googleChannelExactNegativeKeywords
      googleChannelBroadNegativeKeywords
      googleChannelPhraseNegativeKeywords
      googleChannelBiddingStrategy
      googleChannelAdserving
      googleChannelFinalUrl
      googleChannelAdGroupCpcBid
    }
  }
`;

export const getChannelConnectivityStatus = gql`
  query channelConnectivity {
    channelConnectivity {
      facebook
      instagram
      google
    }
  }
`;
