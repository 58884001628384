<form #f [formGroup]="formGroup" *ngIf="!!formGroup && !isInArray">
  <div *ngIf="!ownFormArrayName" class="input-container" [class.input-error]="validationError"
    [class.longest-input]="type === 'text'" [class.auto-width-input]="autoWidth"
    [class.textarea-container]="type === 'textarea'" [ngStyle]="{width: width, maxWidth: maxWidth}">
    <div class="input-content flex-row" [class.disabled]="disabled">
      <p *ngIf="!!preText" class="pre-text"> {{ preText }} </p>
      <input *ngIf="type !== 'textarea'" [class.text-center]="preText && endText" [type]="getInputType()"
        [name]="controlName" [placeholder]="placeholder" [tabindex]="tabindex" [formControl]="_formControl"
        (input)="doChange($event.target.value)" (keyup)="keyUpEvent($event.target.value)"
        (keydown)="keyDownEvent($event)" (blur)="blurEvent($event.target.value)" (focus)="focusEvent($event)"
        (focusout)="focusOutEvent($event)">
      <textarea *ngIf="type === 'textarea'" [placeholder]="placeholder" [formControl]="_formControl"
        [name]=[controlName] (input)="doChange($event.target.value)">
    </textarea>
      <p *ngIf="!!endText" class="end-text text-xs">{{ endText }}</p>
      <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="grid notation-container" *ngIf="!!validationError || !!inputDescription || !!inputLimit">
      <p *ngIf="!!validationError" class="text-red text-s">{{ errorMessage }}</p>
      <p *ngIf="!!inputDescription && !validationError" class="text-xs text-italic">
        {{ inputDescription }}
      </p>
      <p *ngIf="!!inputLimit && !validationError" class="text-xs text-right">
        {{ inputLimit - controlValue.length }} / {{ inputLimit }}
      </p>
    </div>
  </div>

  <div *ngIf="!!ownFormArrayName" [formArrayName]="ownFormArrayName" class="input-container"
    [class.input-error]="validationError" [class.longest-input]="type === 'text'" [class.auto-width-input]="autoWidth"
    [class.textarea-container]="type === 'textarea'" [ngStyle]="{width: width, maxWidth: maxWidth}">
    <div class="input-content flex-row" [class.disabled]="disabled">
      <p *ngIf="!!preText" class="pre-text"> {{ preText }} </p>
      <input *ngIf="type !== 'textarea'" [class.text-center]="preText && endText" [type]="getInputType()"
        [placeholder]="placeholder" [formControlName]="controlName" [tabindex]="tabindex"
        (input)="doChange($event.target.value)" (keyup)="keyUpEvent($event.target.value)"
        (keydown)="keyDownEvent($event)" (blur)="blurEvent($event.target.value)"
        (focus)="focusEvent($event)" (focusout)="focusOutEvent($event)">
      <textarea *ngIf="type === 'textarea'" [placeholder]="placeholder"
                [tabindex]="tabindex" [formControlName]="controlName"
                (input)="doChange($event.target.value)">
      </textarea>
      <p *ngIf="!!endText" class="end-text text-xs">{{ endText }}</p>
      <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="grid notation-container" *ngIf="!!validationError || !!inputDescription || !!inputLimit">
      <p *ngIf="!!validationError" class="text-red text-s">{{ errorMessage }}</p>
      <p *ngIf="!!inputDescription && !validationError" class="text-xs text-italic">
        {{ inputDescription }}
      </p>
      <p *ngIf="!!inputLimit && !validationError" class="text-xs text-right">
        {{ inputLimit - controlValue.length }} / {{ inputLimit }}
      </p>
    </div>
  </div>
</form>


<!-- use for which value in Array-->
<form #f *ngIf="!!formGroup && isInArray">
  <div class="input-container" [class.input-error]="validationError" [class.longest-input]="type === 'text'"
    [class.textarea-container]="type === 'textarea'" [ngStyle]="{width: width, maxWidth: maxWidth}">
    <div class="input-content flex-row" [class.disabled]="disabled">
      <p *ngIf="!!preText" class="pre-text">{{ preText }}</p>
      <input *ngIf="type !== 'textarea'" [class.text-center]="preText && endText" [type]="getInputType()"
        [name]="controlName" [placeholder]="placeholder" [value]="valueInArray" [tabindex]="tabindex"
        (input)="doChange($event.target.value)" (keyup)="keyUpEvent($event.target.value)"
        (keydown)="keyDownEvent($event)" (blur)="blurEvent($event.target.value)">
      <textarea *ngIf="type === 'textarea'" [placeholder]="placeholder" [name]="controlName"
                [tabindex]="tabindex" [value]="valueInArray"
                (input)="doChange($event.target.value)">
      </textarea>
      <p *ngIf="!!endText" class="end-text text-xs">{{ endText }}</p>
    </div>
    <div class="grid notation-container" *ngIf="!!errorMessage || !!inputDescription || !!inputLimit">
      <p *ngIf="!!validationError" class="text-red text-s">{{ errorMessage }}</p>
      <p *ngIf="!!inputDescription && !validationError" class="text-xs text-italic">
        {{ inputDescription }}
      </p>
      <p *ngIf="!!inputLimit && !validationError" class="text-xs text-right">
        {{ inputLimit - valueInArray.length }} / {{ inputLimit }}
      </p>
    </div>
  </div>
</form>
