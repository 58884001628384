import { gql } from 'apollo-angular';

export const getAvailableReviews = gql`
  query availableReviews {
    availableReviews {
      id
      campaignTemplateId
      campaignTemplateNme
      rate
      comment
      userName
      channelNme
    }
  }
`;
