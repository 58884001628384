import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { createUploadLink } from 'apollo-upload-client';
import { GoogleAnalyticsService, AuthService, AppConfigService, UserService, UserContextService } from '@services';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkErrorDialogComponent } from '@dialogs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { defaultDataIdFromObject, ApolloLink } from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/cache';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';

interface TimeoutError extends Error {
  statusCode: number;
}

let failedImgBase64: string = null;

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class GraphQLModule {
  private isDialogOpen: boolean;
  private retryCount: number;
  private defaultTimeout = 5000;
  private retryError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private inRetryOperations = {};
  private customizedTimeoutMessage = 'Timeout exceeded'; // from 'ApolloLinkTimeout'
  private dialogRef: MatDialogRef<NetworkErrorDialogComponent>;
  private excludedOperations = ['addCampaign'];

  private static preLoadFailedImage(): void {
    const src = 'assets/icons/fill/load-failed.png';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = '*';
    img.onload = () => {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      failedImgBase64 = canvas.toDataURL('image/png');
    };
    img.src = src;
  }

  constructor(
    private apollo: Apollo,
    private appConfig: AppConfigService,
    private authService: AuthService,
    private googleAnalytics: GoogleAnalyticsService,
    private dialog: MatDialog,
    private httpLink: HttpLink,
    private userService: UserService,
    private userContext: UserContextService,
  ) {
    GraphQLModule.preLoadFailedImage();
    this.createApolloLink();
    this.catchRetryError();
  }

  // customized timeout error
  private catchRetryError(): void {
    merge(
      this.retryError$,
      fromEvent(window, 'error').pipe(
        map(({ message }: ErrorEvent) => message.includes(this.customizedTimeoutMessage)),
      ),
    ).subscribe((isRetryError) => {
      if (isRetryError) {
        this.openErrorDialog();
      }
    });
  }

  private createApolloLink(): void {
    const agencyMiddleware = this.createUserContextMiddleware();
    // const retryLink = this.setRetryLink();
    // const timeoutLink = new ApolloLinkTimeout(this.defaultTimeout, 504);
    const completeLink = this.setCompleteLink();
    const httpLink = agencyMiddleware.concat(this.setHttpLink());
    // TODO add retryLink, timeoutLink, completeLink back
    // temporarily remove retryLink, timeoutLink, completeLink
    const link = ApolloLink.from([httpLink]);
    this.apollo.create({
      link,
      cache: new InMemoryCache({
        dataIdFromObject: (object: any) => {
          if (object.__typename === 'Targeting') {
            return object.uniqueId;
          }
          return defaultDataIdFromObject(object);
        },
        possibleTypes: {
          ChannelUnion: ['GoogleChannel', 'FacebookChannel', 'InstagramChannel'],
        },
        typePolicies: {
          OrganizationType: {
            keyFields: ['organizationId'],
          },
        },
      }),
    });
  }

  private setRetryLink(): RetryLink {
    return new RetryLink({
      attempts: (count, operation, error) => {
        const isExcludedOperation = !!this.excludedOperations.find(
          (excludedOperation) => excludedOperation === operation.operationName,
        );
        if (isExcludedOperation) {
          return false;
        } else {
          this.retryCount = count;
          const isTimeoutError = this.isTimeoutError(error);
          const isServerError = error.message.toLowerCase().includes('failed to fetch');
          const isNeedRetry = isTimeoutError || isServerError;
          const isValidCount = count < 3;
          if (isNeedRetry && count === 1) {
            this.openDialog('loading');
          }
          // create unique key for each operation
          const key = `${operation.operationName}_${operation.variables.id}`;
          if (!this.inRetryOperations[key]) {
            this.inRetryOperations[key] = operation;
          }
          if (isNeedRetry && !isValidCount) {
            this.retryError$.next(true);
          }
          // overwrite default timout for retry
          operation.setContext((context) => ({ ...context, timeout: 3000 }));
          return isNeedRetry && isValidCount;
        }
      },
      delay: (count, operation, error) => {
        const isTimeoutError = this.isTimeoutError(error);
        let timout = 5000;
        if (count === 2) {
          timout = 3000;
        }
        if (isTimeoutError) {
          timout = 0;
        }
        return timout;
      },
    });
  }

  private isTimeoutError(error: TimeoutError): boolean {
    return (
      error &&
      (error.message.includes(this.customizedTimeoutMessage) || (error.statusCode || '').toString().startsWith('5', 0))
    );
  }

  private setCompleteLink(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      const key = `${operation.operationName}_${operation.variables.id}`;
      return forward(operation).map((response) => {
        if (!!this.inRetryOperations[key]) {
          delete this.inRetryOperations[key];
          if (!Object.keys(this.inRetryOperations).length) {
            this.closeDialog();
          }
        }
        return response;
      });
    });
  }

  private setHttpLink(): ApolloLink {
    const uploadLink = this.httpLink.create({ uri: this.appConfig.graphqlApiURL, withCredentials: true });
    const errorLink: ApolloLink = onError((error) => {
      this.handleLinkError(error);
    });
    return errorLink.concat(uploadLink);
  }

  private handleLinkError(error: ErrorResponse) {
    const { graphQLErrors, networkError } = error;
    let action: string;
    let label: string;
    if (networkError) {
      action = 'Network error';
      label = networkError.message;
      if (networkError['statusCode'] === 401) {
        action = 'Authentication';

        const user = this.userService.getCachedUserProfile();
        if (user) {
          label = user.userFirstNme + ' ' + user.userLastNme;
        }
        // TODO: Was disabled due to new auth0 process
        // this.authService.logout();
      } else if (networkError['statusCode'] === 400) {
        graphQLErrors.forEach(({ message }) => {
          action = 'Endpoint error';
          label = message;
        });
      }
      if (this.isDialogOpen && this.retryCount === 2) {
        this.retryError$.next(true);
      }
    } else if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        action = 'Endpoint error';
        label = `<Operator - ${path}>`;
      });
    }
    this.googleAnalytics.eventTracking('Error Reporting', action, label);
  }

  private createUserContextMiddleware(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      const userContext = this.userContext.getContext();

      if (userContext.organizationId || userContext.locationId) {
        operation.setContext({
          headers: new HttpHeaders()
            .set('x-organization-id', userContext.organizationId ?? 'null')
            .set('x-location-id', userContext.locationId ?? 'null'),
        });
      }

      return forward(operation);
    });
  }

  private openDialog(dialogType: string): void {
    if (!this.isDialogOpen) {
      this.dialogRef = this.dialog.open(NetworkErrorDialogComponent, {
        width: '90%',
        maxWidth: '30em',
        maxHeight: '80vh',
        disableClose: false,
        data: { dialogType, dialogImage: failedImgBase64 },
      });
    }
    this.isDialogOpen = true;
  }

  private closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.isDialogOpen = false;
  }

  private openErrorDialog(): void {
    this.closeDialog();
    this.openDialog('error');
  }
}
