import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, UserService } from '@services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getUserProfile().pipe(
      map((user) => {
        const isAdmin = this.userService.isAdminRole(user);
        if (!isAdmin) {
          this.redirectUser();
        }
        return isAdmin;
      }),
    );
  }

  private redirectUser() {
    this.router.navigateByUrl('/');
  }
}
