<header class="page-header" [class.header-scroll]="isScroll">
  <div class="header-content">
    <div class="header-left flex flex-align-center" id="headerLeft">
      <div class="header-branding flex-row">
        <img class="logo" src="assets/icons/logo/adcorner_logo_primary.svg"  alt="AdCorner Logo"/>
      </div>
    </div>
    <div class="header-right" *ngIf="!!userName">
      <app-ui-user-icon [name]="userName" [matMenuTriggerFor]="menu" class="cursor-pointer"></app-ui-user-icon>
      <mat-menu #menu="matMenu" [overlapTrigger]="false" class="user-menu box-shadow" xPosition="before">
        <div>
          <a mat-menu-item (click)="logout()">
            Log out
          </a>
        </div>
      </mat-menu>
    </div>
  </div>
</header>

<main class="main" appPageScroll (scrollChange)="onScrollChange($event)">
  <div class="main-container">
    <section class="main-content">
      <router-outlet></router-outlet>
    </section>
  </div>
</main>

<app-page-loading></app-page-loading>

