import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { OrganizationService, UserService } from '@services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GmbAccessGuard implements CanActivate {
  constructor(private orgService: OrganizationService, private userService: UserService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isGMBSEnabled();
  }

  private isGMBSEnabled(): Observable<boolean> {
    return this.orgService.isFeatureEnabled('gmb').pipe(
      map((isGMBSEnabled) => {
        if (!isGMBSEnabled) {
          const isZor = this.userService.isZORView();
          this.router.navigateByUrl(isZor ? '/app/performance-overview' : '/app/home');
        }
        return isGMBSEnabled;
      }),
    );
  }
}
