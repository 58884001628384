import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampaignFeedbackDialogComponent } from '@dialogs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ui-star-rating',
  templateUrl: './ui-star-rating.component.html',
  styleUrls: ['./ui-star-rating.component.scss'],
})
export class UiStarRatingComponent implements OnInit {
  @Input()
  stars = 0;
  @Input()
  reviews = 0;
  @Input()
  templateId: number;
  @Input()
  readonly = true;
  @Input()
  isShowReview = true;
  @Output()
  rate: EventEmitter<any> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  showTemplateReviews() {
    if (this.templateId && this.reviews) {
      this.dialog.open(CampaignFeedbackDialogComponent, {
        width: '90%',
        maxWidth: '50em',
        maxHeight: '80vh',
        data: {
          templateId: this.templateId,
        },
      });
    }
  }

  changeRate(rate: number): void {
    if (!this.readonly) {
      this.rate.emit(rate);
    }
  }
}
