export const timeFormat = 'HH:mm';

export const timeDisplayFormat = 'hh:mm a';

export enum GMBDayOfWeekEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface GMBLocation {
  name: string;
  locationName: string;
}

export interface GMBLocationBind {
  locationId: number;
  gmbLocation: string;
  extensionEnabled?: boolean;
}

export interface GMBDate {
  year: number;
  month: number;
  day: number;
}

export interface GMBTimePeriod {
  openDay: string;
  openTime: string;
  closeDay: string;
  closeTime: string;
}

export interface GMBSpecialHourPeriod {
  startDate: GMBDate;
  openTime?: string;
  endDate?: GMBDate;
  closeTime?: string;
  isClosed: boolean;
}

export interface GMBAddress {
  revision?: number;
  regionCode?: string;
  languageCode?: string;
  postalCode?: string;
  sortingCode?: string;
  administrativeArea?: string;
  locality?: string;
  sublocality?: string;
  addressLines?: Array<string>;
  recipients?: Array<string>;
  organization?: string;
}

export interface GMBLocationState {
  isGoogleUpdated?: boolean;
  isDuplicate?: boolean;
  isSuspended?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  isVerified?: boolean;
  needsReverification?: boolean;
  isPendingReview?: boolean;
  isDisabled?: boolean;
  isPublished?: boolean;
  isDisconnected?: boolean;
  isLocalPostApiDisabled?: boolean;
  hasPendingEdits?: boolean;
  hasPendingVerification?: boolean;
}

export interface GMBLocationDetail {
  name: string;
  locationName: string;
  languageCode?: string;
  storeCode?: string;
  primaryPhone: string;
  additionalPhones?: Array<string>;
  address: GMBAddress;
  primaryCategory?: any;
  additionalCategories?: Array<any>;
  websiteUrl: string;
  regularHours?: { periods: Array<GMBTimePeriod> };
  specialHours?: { specialHourPeriods: Array<GMBSpecialHourPeriod> };
  serviceArea?: any;
  locationKey?: any;
  labels?: Array<string>;
  adWordsLocationExtensions?: any;
  latlng?: any;
  openInfo?: any;
  locationState?: GMBLocationState;
  attributes?: Array<any>;
  metadata?: any;
  priceLists?: Array<any>;
  profile?: any;
  relationshipData?: any;
}

export enum GoogleOAuthType {
  MY_BUSINESS = 'MY_BUSINESS',
  AD_WORDS = 'AD_WORDS',
}
