import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-bar-dialog',
  templateUrl: './progress-bar-dialog.component.html',
  styleUrls: ['./progress-bar-dialog.component.scss'],
})
export class ProgressBarDialogComponent implements OnInit {
  msg = 'Update in progress...';

  constructor(
    public dialogRef: MatDialogRef<ProgressBarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: string,
  ) {}

  ngOnInit() {
    this.msg = this.dialogData;
  }
}
