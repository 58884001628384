import { Injectable } from '@angular/core';
import { ChannelName, ChannelOauthParamsInterface } from '@interfaces';

interface ChannelOauthResultInterface {
  error?: string;
  error_reason?: string;
  state?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OauthConnectService {
  constructor() {}

  static BuildFbIgOauthUrl(
    baseUrl: string,
    clientId: string,
    redirectUri: string,
    state: string,
    scope: string,
    requestDeniedRights = false,
  ): string {
    const queryString = [];
    queryString.push(OauthConnectService.BuildQueryStrings('client_id', clientId));
    queryString.push(OauthConnectService.BuildQueryStrings('redirect_uri', redirectUri));
    queryString.push(OauthConnectService.BuildQueryStrings('state', state));
    queryString.push(OauthConnectService.BuildQueryStrings('scope', scope));
    queryString.push(OauthConnectService.BuildQueryStrings('locale', 'en_US'));
    queryString.push(OauthConnectService.BuildQueryStrings('response_type', 'granted_scopes,code,token'));
    if (requestDeniedRights) {
      queryString.push(OauthConnectService.BuildQueryStrings('auth_type', 'rerequest'));
    }
    return baseUrl + (baseUrl.indexOf('?') === -1 ? '?' : '') + queryString.join('&');
  }

  static BuildGoogleOauthLoginUrl(
    loginBaseUrl: string,
    clientId: string,
    redirectUri: string,
    state: string,
    scope: any,
  ): string {
    const queryString = [];
    queryString.push(OauthConnectService.BuildQueryStrings('client_id', clientId));
    queryString.push(OauthConnectService.BuildQueryStrings('redirect_uri', redirectUri));
    queryString.push(OauthConnectService.BuildQueryStrings('state', state));
    scope = Array.isArray(scope) ? scope.join(' ') : scope;
    queryString.push(OauthConnectService.BuildQueryStrings('scope', scope));
    queryString.push(OauthConnectService.BuildQueryStrings('response_type', 'code'));
    queryString.push(OauthConnectService.BuildQueryStrings('access_type', 'offline'));
    queryString.push(OauthConnectService.BuildQueryStrings('include_granted_scopes', 'false'));
    return loginBaseUrl + (loginBaseUrl.indexOf('?') === -1 ? '?' : '') + queryString.join('&');
  }

  static BuildQueryStrings(key: string, value: string | number): string {
    return `${key.toString()}=${encodeURIComponent(value.toString())}`;
  }

  static UrlDataParse(input: string): any {
    const result = {};
    if (input) {
      const list = ['?', '#'].indexOf(input[0]) !== -1 ? input.slice(1).split('&') : input.split('&');
      for (const item of list) {
        const [key, value] = item.split('=');
        result[key] = decodeURIComponent(value);
      }
    }
    return result;
  }

  static CancelFbIgLogin(result: ChannelOauthResultInterface, channel: string): boolean {
    return (
      'access_denied' === result.error &&
      'user_denied' === result.error_reason &&
      (channel === ChannelName.FACEBOOK || channel === ChannelName.INSTAGRAM)
    );
  }

  static CancelGoogleLogin(result: any, channel: string): boolean {
    return 'access_denied' === result.error && channel === ChannelName.GOOGLE;
  }

  static CheckFbIgOauthMetadataIsEnough(input: ChannelOauthParamsInterface, field: string): boolean {
    return !(!input || !input[field] || !input[field].oauthUri || !input[field].appId || !input[field].scopes);
  }

  static CheckGoogleOauthMetadataIsEnough(input: ChannelOauthParamsInterface, field: string): boolean {
    return !(
      !input ||
      !input[field] ||
      !input[field].oauthUri ||
      !input[field].appId ||
      !Array.isArray(input[field].scopes) ||
      !input[field].scopes.length
    );
  }

  static ValidateGoogleAuthorizationResult(input: any, oauthRequire: any): boolean {
    const received = input.scope ? input.scope.split(/[\+\s]/) : [];
    if (Array.isArray(oauthRequire)) {
      const required = JSON.parse(JSON.stringify(oauthRequire));
      return OauthConnectService.CheckAllRightGet(required, received);
    } else if (typeof oauthRequire === 'string') {
      const required = JSON.parse(JSON.stringify(oauthRequire.split(',')));
      return OauthConnectService.CheckAllRightGet(required, received);
    } else {
      return false;
    }
  }

  static CheckAllRightGet(required: string[], received: string[]): boolean {
    for (const right of required) {
      if (received.indexOf(right) === -1) {
        return false;
      }
    }
    return true;
  }
}
