import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { ValidationsService } from '@services';
import { replace } from 'lodash';

@Component({
  selector: 'app-ui-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.scss'],
})
export class UiInputComponent implements OnInit, OnChanges {
  @Input()
  formGroup: FormGroup;
  @Input()
  ownFormArrayName: string;
  @Input()
  controlName: string;
  @Input()
  value: string;
  @Input()
  type = 'text';
  @Input()
  inputLimit: number;
  @Input()
  decimalPoint = false;
  @Input()
  indexInArray = 0;
  @Input()
  isInArray = false;
  @Input()
  disabled = false;
  @Input()
  width: string;
  @Input()
  maxWidth: string;
  @Input()
  autoWidth = false;
  @Input()
  isLoading = false;
  @Input()
  tabindex: number;

  @Input()
  placeholder = '';
  @Input()
  preText: string;
  @Input()
  endText: string;
  @Input()
  inputDescription: string;
  @Input()
  set statusValue(flag: boolean) {
    if (this.formGroup && this.controlName) {
      if (flag) {
        this.formGroup.get(this.controlName).disable();
      } else {
        this.formGroup.get(this.controlName).enable();
      }
    }
  }

  @Output()
  changeEvent: EventEmitter<{ form: FormGroup; controlName: string }> = new EventEmitter();
  @Output()
  inputBlur: EventEmitter<{ form: FormGroup; controlName: string }> = new EventEmitter();
  @Output()
  inputFocus: EventEmitter<FocusEvent> = new EventEmitter();
  @Output()
  inputFocusOut: EventEmitter<FocusEvent> = new EventEmitter();

  constructor(private validationsService: ValidationsService) {}

  ngOnInit() {
    this._initForm();
  }

  ngOnChanges() {
    this._initForm();
  }

  private _initForm() {
    let formGroup = this.formGroup;
    let controlName = this.controlName;
    const inputType = this.type;
    const validators = [];

    let defaultValue = this.value;
    if (!defaultValue) {
      switch (inputType) {
        case 'number': {
          if (!!this.decimalPoint) {
            defaultValue = '0.00';
          }
          defaultValue = '0';
          break;
        }
        case 'text':
        case 'textarea':
        default: {
          defaultValue = '';
          break;
        }
      }
    }

    if (!!this.inputLimit) {
      validators.push(Validators.maxLength(this.inputLimit));
    }
    let patternStr = /^[1-9]\d*$/;
    if (inputType === 'number' || inputType === 'zip') {
      if (this.decimalPoint) {
        patternStr = /^(0|[1-9]\d*)(\.\d{0,2})?$/;
      }
      validators.push(Validators.pattern(patternStr));
    }

    if (!formGroup || !controlName) {
      const defaultControl = {
        defaultControl: new FormControl(defaultValue, validators),
      };
      formGroup = new FormGroup(defaultControl);
      controlName = 'defaultControl';
    }
    if (this.disabled) {
      formGroup.get(controlName).disable();
    }

    this.formGroup = formGroup;
    this.controlName = controlName;
  }

  get _formControl(): AbstractControl {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName);
    }
    return null;
  }

  get controlValue() {
    const control = this._formControl;
    if (!!control) {
      return control.value || '';
    }
    return '';
  }

  get valueInArray() {
    const formControl = this._formControl;
    return formControl.value[this.indexInArray] || '';
  }

  get validationError() {
    const formControl = this._formControl;
    return !!this.errorMessage && formControl.invalid && (formControl.dirty || formControl.touched);
  }

  get errorMessage() {
    const _formControl = this._formControl;
    return this.validationsService.getValidationErrorMessage(_formControl, this.indexInArray);
  }

  keyUpEvent(value: string): void {
    if (this.disabled) {
      return;
    }

    if (!this.isNumericInput()) {
      return;
    }

    let newValue = value;

    if (!this.hasDecimalPoint(newValue)) {
      newValue = this.removeSpecialCharacters(newValue, /\-|\+|\e/);
    } else {
      newValue = this.removeSpecialCharacters(newValue, /\-|\+|\e\./);
    }

    if (this.controlValue !== newValue) {
      this.setValue(newValue);
    }
  }

  private isNumericInput(): boolean {
    return this.getInputType() === 'number' && !this.isInArray;
  }

  private hasDecimalPoint(value: string): boolean {
    return value.includes('.');
  }

  private removeSpecialCharacters(value: string, regex: RegExp): string {
    return value.replace(regex, '');
  }

  private setValue(value: string): void {
    this._setValue(value);
  }

  keyDownEvent(event: any) {
    if (this.disabled) {
      event.preventDefault();
      return;
    }
  }

  blurEvent(value: string) {
    if (this.disabled) {
      return;
    }
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (this.isInArray && !!formGroup && !!controlName) {
      const control = formGroup.get(controlName);
      control.markAsDirty();
    }
    if (!!value && value.length > 0 && this.type === 'number' && this.decimalPoint) {
      const newValue = parseFloat(value).toFixed(2);
      if (this.controlValue !== newValue) {
        this._setValue(newValue);
      }
    }
    this.inputBlur.emit({
      form: this.formGroup,
      controlName: this.controlName,
    });
  }

  private _setValue(value: string): void {
    setTimeout(() => {
      const formGroup = this.formGroup;
      const controlName = this.controlName;
      if (!!formGroup && !!controlName) {
        formGroup.get(controlName).setValue(value);
      }
      this.changeEvent.emit({
        form: this.formGroup,
        controlName: this.controlName,
      });
    });
  }

  doChange(value): void {
    if (this.disabled) {
      return;
    }
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    const indexInArray = this.indexInArray;
    if (this.isInArray && !!formGroup && !!controlName) {
      const control = formGroup.get(controlName);
      const arrayValue = [...control.value];
      arrayValue[indexInArray] = value;
      control.markAsDirty();
      control.setValue(arrayValue);
    }
    this.changeEvent.emit({
      form: this.formGroup,
      controlName: this.controlName,
    });
  }

  getInputType(): string {
    let result = this.type;
    switch (result) {
      case 'zip': {
        result = 'number';
        break;
      }
      default:
        break;
    }
    return result;
  }

  focusEvent(event): void {
    this.inputFocus.emit(event);
  }

  focusOutEvent(event): void {
    this.inputFocusOut.emit(event);
  }
}
