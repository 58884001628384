import { gql } from 'apollo-angular';
import { limitOrganizationFragment } from '../organization/organization.gql';
import { limitedLocationFragment } from '../location/location.gql';

export const userProfileFragment = gql`
  fragment userProfile on UserType {
    userId
    userFirstNme
    userMiddleNme
    userLastNme
    userEmailAddress
    userAddress
    userWebsite
    accessibleOrganizations {
      ...limitOrganization
    }
    accessibleLocations {
      ...limitedLocation
    }
    location {
      ...limitedLocation
    }
    organization {
      ...limitOrganization
    }
    locationOrganization {
      ...limitOrganization
    }
    userCurView
    userDefaultView
    userUpdatedAt
    userHelpVideoUrl
    avatarUrl
    userActive
    userChannelsConnectWarnInd
    orgLogoUrl
    userAgencyOrgIds
    userAgencyLocIds
    organizationId
    locationId
    rbacRoleId
    rbacAssignmentId
    userReadOnly
    userReadOnlyEditRoleId
    userInactiveLocIds
    userInactiveOrgIds
    userReadOnlyLocIds
    userReadOnlyOrgIds
  }
  ${limitOrganizationFragment}
  ${limitedLocationFragment}
`;

export const userProfileQuery = gql`
  query userProfile {
    user {
      ...userProfile
    }
  }
  ${userProfileFragment}
`;

export const updateUserProfile = gql`
  mutation updateRequestingUser($user: UserUpdate!, $file: Upload) {
    updateRequestingUser(user: $user, file: $file) {
      ...userProfile
    }
  }
  ${userProfileFragment}
`;

export const usersByRequestingUserQuery = gql`
  query usersByRquestingUser {
    usersByRquestingUser {
      rbacRoleId
      organizationId
      locationId
      userId
      userFirstNme
      userLastNme
      userEmailAddress
      userAddress
      userUpdatedAt
      userActive
      userReadOnly
      userReadOnlyEditRoleId
      userAgencyOrgIds
      userAgencyLocIds
      userInactiveLocIds
      userInactiveOrgIds
      userReadOnlyLocIds
      userReadOnlyOrgIds
      location {
        locationId
        locationNme
        locationActive
        locationRadius
        locationStreetAddress
        locationCity
        locationState
        locationZips
      }
      organization {
        organizationId
        organizationNme
      }
      accessibleLocations {
        locationId
        locationNme
        locationActive
        locationRadius
        locationStreetAddress
        locationCity
        locationState
        locationZips
      }
      accessibleOrganizations {
        organizationId
        organizationNme
      }
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($id: ID!, $user: UserUpdate!) {
    updateUser(id: $id, user: $user) {
      ...userProfile
      status
      message
    }
  }
  ${userProfileFragment}
`;

export const checkEmailExisted = gql`
  query checkEmailExisted($userEmail: String!) {
    checkEmailExisted(userEmail: $userEmail)
  }
`;

export const userByEmail = gql`
  query userByEmail($email: String!) {
    userByEmail(email: $email) {
      userId
      userEmailAddress
      userFirstNme
      userLastNme
      userAgencyOrgIds
      userAgencyLocIds
    }
  }
`;

export const getAdminUsers = gql`
  query adminUsers {
    adminUsers {
      userId
      userFirstNme
      userLastNme
      userEmailAddress
      userActive
      rbacRoleId
    }
  }
`;

export const getUserAccounts = gql`
  query userAccounts($input: UserAccountInput) {
    userAccounts(input: $input) {
      count
      users {
        rbacRoleId
        userId
        userFirstNme
        userLastNme
        userEmailAddress
        userReadOnly
        userActive
        userExpireAt
        userLoginLastDte
        userReadOnlyEditRoleId
        location {
          locationId
          locationNme
          locationAddress
        }
        organization {
          organizationId
          organizationNme
          organizationType
        }
        accessibleLocations {
          locationId
          locationNme
          locationAddress
        }
        accessibleOrganizations {
          organizationId
          organizationNme
          organizationType
        }
      }
    }
  }
`;

export const registerUser = gql`
  mutation registerUser($user: UserRegister!) {
    registerUser(user: $user) {
      status
      message
    }
  }
`;

export const userLogin = gql`
  query userLogin($email: String!) {
    userLogin(email: $email) {
      token
    }
  }
`;

export const authenticate = gql`
  query authenticate($email: String!) {
    authenticate(email: $email) {
      token
    }
  }
`;

export const addUsers = gql`
  mutation addUsers($users: [UserInsert!]) {
    addUsers(users: $users) {
      userId
      userEmailAddress
      userFirstNme
      userLastNme
      organizationId
      locationId
      rbacRoleId
    }
  }
`;
