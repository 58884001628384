<form #f [formGroup]="formGroup" *ngIf="formGroup">
  <div [ngStyle]="{width: width, maxWidth: maxWidth}">
    <div class="textarea-input-container">
      <textarea #textEle class="text-m" [class.content-disabled]="disabled"
        [class.content-invalid]="!disabled && !formControl.valid && (formControl.dirty || formControl.touched)"
        [formControl]="formControl" (blur)="loseFocus($event)" (keydown)="captureKeyDownEvent($event, textEle)"
        (cut)="notAllowCutAndCopy($event)" (copy)="notAllowCutAndCopy($event)"
        (mousedown)="captureMouseDown($event)"></textarea>
      <mat-chip-list class="chip-input">
        <ng-template ngFor let-chip [ngForOf]="adChipList">
          <mat-chip *ngIf="chip.addToText" [class.cus-added-chip-item]="true" [class.chip-item-disabled]="disabled">
            <span class="chip-label">{{ chip.chipName }}</span>
            <app-ui-icon fillIcon="Circle_Delete"
                         [class.chip-action-disabled]="disabled"
                         [class.cursor-pointer]="!disabled"
                         (iconClick)="removeChipFromText(chip)">
            </app-ui-icon>
          </mat-chip>
        </ng-template>
      </mat-chip-list>
    </div>

    <div class="prompt-container">
      <span *ngIf="validationError" class="text-s error-message">{{ errorMessage }}</span>
    </div>

    <div class="textarea-input-footer flex flex-justify-between">
      <div class="chips-container">
        <mat-chip-list *ngFor="let chip of adChipList">
          <mat-chip *ngIf="!chip.addToText" [class.cus-chip-item]="true" [class.chip-item-disabled]="disabled">
            <app-ui-icon fillIcon="Circle_Plus"
                         [class.chip-action-disabled]="disabled"
                         [class.cursor-pointer]="!disabled"
                         (iconClick)="addChipToText(chip)">
            </app-ui-icon>
            <span class="chip-label">{{ chip.chipName }}</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <!-- <p *ngIf="!!validationError" class="text-red text-s">{{ errorMessage }}</p> -->
      <p *ngIf="!!sizeLimit && !validationError" class="text-xs text-right">
        {{ sizeLimit - getRealChipTextLength }} / {{ sizeLimit }}
      </p>
    </div>
  </div>
</form>
