import { gql } from 'apollo-angular';

const targetingBrowseResponseFragment = gql`
  fragment targeting on Targeting {
    id
    name
    type
    path
    key
    parent
    audience_size
  }
`;

export const getTargetingSearch = gql`
  query getTargetingSearch($q: String!, $campaignTemplateSpecialAdCategory: String) {
    targetingSearch(q: $q, campaignTemplateSpecialAdCategory: $campaignTemplateSpecialAdCategory) {
      id
      name
      type
      path
      audience_size
    }
  }
`;

export const getTargetingBrowse = gql`
  query getTargetingBrowse($campaignTemplateSpecialAdCategory: String) {
    targetingBrowse(campaignTemplateSpecialAdCategory: $campaignTemplateSpecialAdCategory) {
      ...targeting
    }
  }
  ${targetingBrowseResponseFragment}
`;

export const getTargetingSuggestions = gql`
  query getTargetingSuggestions($targetingList: [TargetingInput]!, $campaignTemplateSpecialAdCategory: String) {
    targetingSuggestions(
      targetingList: $targetingList
      campaignTemplateSpecialAdCategory: $campaignTemplateSpecialAdCategory
    ) {
      ...targeting
    }
  }
  ${targetingBrowseResponseFragment}
`;

export const getTargetingEstimate = gql`
  query getTargetingEstimate($detailedTargetingInput: DetailedTargetingInput!) {
    targetingEstimate(targeting: $detailedTargetingInput) {
      dailyOutcomesCurve {
        spend
        reach
        impressions
        actions
      }
      estimateDau
      estimateMauLowerBound
      estimateMauUpperBound
      estimateReady
    }
  }
`;

export const getRegionSearchDetail = gql`
  query getRegionSearchDetail($state: String!) {
    regionSearchDetail(q: $state) {
      key
      name
      type
      country_code
      country_name
      supports_region
      supports_city
    }
  }
`;
