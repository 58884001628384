import { gql } from 'apollo-angular';

export const FacebookAccountFragment = gql`
  fragment facebookAccount on FacebookAccountType {
    facebookAccountId
    organizationId
    facebookAccountUserId
    facebookAccountUserNme
    facebookAccountInstagramAct
    facebookAccountDefaultPageRealId
    facebookAccountFacebookConnectedInd
    facebookAccountInstagramConnectedInd
    facebookAccountAdActId
    facebookAccountZeeInstagramAct
    facebookAccountDefaultZeePageRealId
    facebookAccountZeeAdActId
  }
`;

const GoogleAccountFragment = gql`
  fragment googleAccount on GoogleAccountType {
    googleAccountConnectedInd
    googleAccountDefaultCustomerId
  }
`;

export const orgSocialAccountInfo = gql`
  query orgSocialAccountInfo {
    organization {
      organizationId
      parentId
      organizationNme
      organizationType
      facebookAccount {
        ...facebookAccount
      }
      googleAccount {
        ...googleAccount
      }
    }
  }
  ${FacebookAccountFragment}
  ${GoogleAccountFragment}
`;

export const limitOrganizationFragment = gql`
  fragment limitOrganization on LimitedOrganizationType {
    parentId
    organizationId
    organizationType
    organizationNme
    organizationContactNme
    organizationContactEmail
    organizationState
    organizationAddress
    orgLogoUrl
    organizationActive
    parentName
    locationTotal
    organizationReadOnly
    organizationGmbEnabled
    organizationCanvaEnabled
    organizationIsSmb
    organizationCreatedAt
    reseller
  }
`;

const organizationFragment = gql`
  fragment organization on OrganizationType {
    organizationId
    parentId
    organizationNme
    organizationContactNme
    organizationContactEmail
    organizationState
    organizationAddress
    organizationType
    organizationGmbEnabled
    organizationCanvaEnabled
    orgLogoUrl
    organizationIsSmb
    parentOrg {
      organizationId
    }
    childrenOrg {
      organizationId
    }
    locations {
      locationId
      locationNme
      organizationId
      locationContactNme
      locationContactEmail
      locationAddress
      locationPhoneNbr
      locationCity
      locationState
      locationZips
      locationRadius
      locationWebsite
    }
  }
`;

export const getAllOrganizations = gql`
  query franchisors {
    franchisors {
      ...organization
    }
  }
  ${organizationFragment}
`;

export const getFranchiseesByZorId = gql`
  query franchiseesByParentId($id: ID!) {
    franchiseesByParentId(id: $id) {
      ...organization
    }
  }
  ${organizationFragment}
`;

export const createOrganization = gql`
  mutation createOrganization($input: OrganizationInput!) {
    createOrganization(input: $input) {
      ...organization
    }
  }
  ${organizationFragment}
`;

export const updateOrganization = gql`
  mutation updateOrganization($id: ID!, $input: OrganizationInput!) {
    updateOrganization(id: $id, input: $input) {
      ...organization
    }
  }
  ${organizationFragment}
`;

export const getNetworkInfo = gql`
  query getCurrentOrg {
    organization {
      organizationId
      locations {
        locationId
        organizationId
      }
    }
  }
`;

// For admin portal
const basicOrgInfo = gql`
  fragment basicOrgInfo on OrganizationType {
    organizationId
    parentId
    organizationNme
    organizationContactNme
    organizationContactEmail
    organizationState
    organizationAddress
    organizationActive
    organizationType
    organizationReadOnly
    organizationGmbEnabled
    organizationCanvaEnabled
    organizationIsSmb
    rootUser {
      userId
      userFirstNme
      userLastNme
      userEmailAddress
    }
  }
`;

export const getOrgsWithBasicInfo = gql`
  query franchisors {
    franchisors {
      ...basicOrgInfo
    }
  }
  ${basicOrgInfo}
`;

export const getFranchiseesWithBasicInfo = gql`
  query franchiseesByParentId($id: ID!) {
    franchiseesByParentId(id: $id) {
      ...basicOrgInfo
    }
  }
  ${basicOrgInfo}
`;

export const toggleActiveStatus = gql`
  mutation toggleActiveStatus($input: ActiveOrDeactiveInput) {
    activeOrDeactiveOrg(input: $input) {
      organizationId
      organizationActive
    }
  }
`;

export const createFranchisorAccount = gql`
  mutation createFranchisorAccount($input: CreateFranchisorInput!, $isSMB: Boolean) {
    createFranchisorAccount(input: $input, isSMB: $isSMB) {
      organizationId
      organizationNme
      organizationActive
    }
  }
`;

export const updateFranchisorAccount = gql`
  mutation updateFranchisorAccount($input: UpdateFranchisorInput!) {
    updateFranchisorAccount(input: $input) {
      organizationId
      organizationNme
      organizationReadOnly
      organizationContactNme
    }
  }
`;

export const toggleReadOnlyStatus = gql`
  mutation toggleReadOnlyStatus($input: ReadOnlyInput!) {
    readOnlyFranchisorAccount(input: $input) {
      organizationId
      organizationReadOnly
    }
  }
`;

// for agency portal

export const getAgencyOrgAccounts = gql`
  query getOrgsByAgency(
    $nbr: Int
    $size: Int
    $orgType: TypeOfOrganization
    $orderBy: String
    $asc: Boolean
    $parentId: ID
  ) {
    getOrgsByAgency(nbr: $nbr, size: $size, orgType: $orgType, orderBy: $orderBy, asc: $asc, parentId: $parentId) {
      organizations {
        organizationId
        parentId
        organizationNme
        organizationAddress
        organizationState
        organizationActive
      }
      count
    }
  }
`;

export const getOrgsByIds = gql`
  query getOrgsByIds($ids: [ID]!) {
    organizations(ids: $ids) {
      organizationId
      organizationNme
      parentId
    }
  }
`;

const orgWithLocationsFragment = gql`
  fragment orgWithLocations on OrganizationType {
    parentId
    organizationId
    organizationNme
    organizationContactNme
    organizationActive
    organizationCanDelete
    organizationCanActivate
    locations {
      organizationId
      locationId
      locationNme
      locationContactNme
      locationPhoneNbr
      locationAddress
      locationCity
      locationState
      locationZips
      locationRadius
      locationActive
      locationCanDelete
      locationExcludedZips
      locationExcludedRadius
      locationWebsite
      myBusinessConnected
      locationZeeGmbLocation
      locationZorGmbLocation
      locationExtensionEnabled
      locationGroups {
        locationGroupId
        locationGroupNme
        locationGroupActive
      }
    }
  }
`;

// for location management page
export const getZorWithLocations = gql`
  query getOrgsWithLocations {
    organization {
      ...orgWithLocations
    }
  }
  ${orgWithLocationsFragment}
`;

export const getZeesWithLocations = gql`
  query getZeesWithLocations($id: ID!) {
    franchiseesByParentId(id: $id) {
      ...orgWithLocations
    }
  }
  ${orgWithLocationsFragment}
`;

export const deleteFranchisee = gql`
  mutation deleteFranchisee($id: ID!) {
    deleteFranchisee(id: $id) {
      organizationId
    }
  }
`;

export const organizationById = gql`
  query organizationInfoById($id: ID!) {
    organizationInfoById(id: $id) {
      organizationId
      organizationType
      organizationNme
      organizationCanvaEnabled
      organizationGmbEnabled
    }
  }
`;
