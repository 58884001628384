<div ngbDropdown *ngIf="!!checkboxOptions" #myDrop="ngbDropdown" [ngStyle]="style" autoClose="outside"
  class="d-inline-block ui-dropdown" [class.error-dropdown]="!!isShowError">
  <div class="dropdown-button-container" [ngClass]="{'dropdown-container-disabled':disabled}">
    <button class="btn" id="dropdown" ngbDropdownToggle [tabindex]="tabindex"
      [disabled]="disabled || !checkboxOptions || (!!checkboxOptions && checkboxOptions.length === 0)">
      {{ buttonText }}
    </button>
    <div class="toggle-button-icon" (click)="toggleDropdown($event)"
      [ngClass]="{'toggle-button-icon-disabled':disabled}"></div>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdown">
    <mat-spinner *ngIf="isOptionsLoading"></mat-spinner>
    <div *ngIf="!isOptionsLoading">
      <app-ui-checkbox
        *ngFor="let option of checkboxOptions"
        class="dropdown-item"
        [disabled]="!!option.disabled || disabled"
        [value]="option.checked"
        (changeEvent)="doCheck($event, option)"
        (clickEvent)="checkPixel($event, option)"
        (disabledClickEvent)="doDisabledClick($event, option)"
        [matTooltip]="option.text"
        matTooltipClass="mat-information-tooltip">
        {{ option.text }}
      </app-ui-checkbox>
    </div>
  </div>
</div>
<div class="selected-container" *ngIf="showSelected">
  <div class="selected-item" *ngFor="let item of displaySelected">
    <p class="text-s">{{ item?.selectedText || item?.text || '' }}</p>
    <app-ui-icon fillIcon="Circle_Delete" [disabled]="disabled" (click)="doCheck({checked: false, source: null}, item)">
    </app-ui-icon>
  </div>
</div>
