import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum DIALOG_BUTTON_TYPE {
  CLICK_BUTTON,
}

export class Url {
  url: string;
}

export class DialogButton {
  text: string;
  buttonType: DIALOG_BUTTON_TYPE;
  url?: Url;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  dialogIcon: string;
  dialogType: string;
  dialogBody: TemplateRef<any>;
  dialogText: string;
  isNoUser: boolean;
  isUserInactive: boolean;
  isSessionTimeOut: boolean;
  dialogBtnText: string;

  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {}

  ngOnInit() {
    const dialogData = this.dialogData;
    if (!!dialogData) {
      this.dialogType = dialogData.dialogType || null;
      this.dialogBody = dialogData.dialogBody || null;
      this.dialogText = dialogData.dialogText || null;
      this.dialogBtnText = dialogData.dialogBtnText || 'Okay';
      this.isNoUser = dialogData.isNoUser;
      this.isUserInactive = dialogData.isUserInactive;
      this.isSessionTimeOut = dialogData.isSessionTimeOut;
      if (dialogData.isSessionTimeOut) {
        this.dialogBtnText = 'Continue Session';
      }
    }
    this.getDialogIcon();
  }

  private getDialogIcon(): void {
    const dialogType = this.dialogType;
    let result = '';
    switch (dialogType) {
      case 'success': {
        result = 'Confirmed_Active';
        break;
      }
      case 'unhappy': {
        result = 'emotion';
        break;
      }
      case 'warning':
      default: {
        result = 'red-warning';
        break;
      }
    }
    this.dialogIcon = result;
  }

  closeDialog(result?: boolean): void {
    this.dialogRef.close(result);
  }
}
