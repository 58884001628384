import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  AdSubscriptionField,
  AdSubscriptionTypeOptions,
  DropdownOption,
  IndicativeCampaign,
  NavigationMenu,
  IAdMetricTypes,
  RadioButtonOption,
  AdsetScheduleNeededType,
  NotificationMessage,
  ITableColumn,
  ChannelName,
  FacebookAdPosition,
  InstagramAdPosition,
  MediaType,
} from '@interfaces';
import * as dataMap from './dictionary-data';
import { camelCase, upperFirst } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  private states: Array<{ abbreviation: string; state: string }> = [
    { abbreviation: 'AL', state: 'Alabama' },
    { abbreviation: 'AK', state: 'Alaska' },
    { abbreviation: 'AZ', state: 'Arizona' },
    { abbreviation: 'AR', state: 'Arkansas' },
    { abbreviation: 'CA', state: 'California' },
    { abbreviation: 'CO', state: 'Colorado' },
    { abbreviation: 'CT', state: 'Connecticut' },
    { abbreviation: 'DE', state: 'Delaware' },
    { abbreviation: 'DC', state: 'Washington D. C.' },
    { abbreviation: 'FL', state: 'Florida' },
    { abbreviation: 'GA', state: 'Georgia' },
    { abbreviation: 'HI', state: 'Hawaii' },
    { abbreviation: 'ID', state: 'Idaho' },
    { abbreviation: 'IL', state: 'Illinois' },
    { abbreviation: 'IN', state: 'Indiana' },
    { abbreviation: 'IA', state: 'Iowa' },
    { abbreviation: 'KY', state: 'Kentucky' },
    { abbreviation: 'ME', state: 'Maine' },
    { abbreviation: 'MD', state: 'Maryland' },
    { abbreviation: 'MA', state: 'Massachusetts' },
    { abbreviation: 'MI', state: 'Michigan' },
    { abbreviation: 'MN', state: 'Minnesota' },
    { abbreviation: 'MS', state: 'Mississippi' },
    { abbreviation: 'MO', state: 'Missouri' },
    { abbreviation: 'MT', state: 'Montana' },
    { abbreviation: 'NE', state: 'Nebraska' },
    { abbreviation: 'NV', state: 'Nevada' },
    { abbreviation: 'NH', state: 'New Hampshire' },
    { abbreviation: 'NJ', state: 'New Jersey' },
    { abbreviation: 'NM', state: 'New Mexico' },
    { abbreviation: 'NY', state: 'New York' },
    { abbreviation: 'NC', state: 'North Carolina' },
    { abbreviation: 'ND', state: 'North Dakota' },
    { abbreviation: 'OH', state: 'Ohio' },
    { abbreviation: 'OK', state: 'Oklahoma' },
    { abbreviation: 'OR', state: 'Oregon' },
    { abbreviation: 'PA', state: 'Pennsylvania' },
    { abbreviation: 'PR', state: 'Puerto Rico' },
    { abbreviation: 'RI', state: 'Rhode Island' },
    { abbreviation: 'SC', state: 'South Carolina' },
    { abbreviation: 'SD', state: 'South Dakota' },
    { abbreviation: 'TN', state: 'Tennessee' },
    { abbreviation: 'TX', state: 'Texas' },
    { abbreviation: 'UT', state: 'Utah' },
    { abbreviation: 'VT', state: 'Vermont' },
    { abbreviation: 'VA', state: 'Virginia' },
    { abbreviation: 'VI', state: 'Virgin Islands' },
    { abbreviation: 'WA', state: 'Washington' },
    { abbreviation: 'WV', state: 'West Virginia' },
    { abbreviation: 'WI', state: 'Wisconsin' },
    { abbreviation: 'WY', state: 'Wyoming' },
  ];
  constructor() {}

  static GetTemplateFieldTipInfo(): any {
    return {
      age: {
        tip: `Select the age of the people who will find your ad relevant`,
        label: `Age`,
      },
      gender: {
        tip: `Choose 'All' unless you only want your ads to be shown to either male or female`,
        label: `Gender`,
      },
      radius: {
        tip: ``,
        label: `Radius`,
      },
      adText: {
        tip:
          `Enter text that clearly tells people what you're promoting and add location ` +
          `specific details within the text by using the list of fields below the text box. `,
        label: `Text`,
      },
      headline: {
        tip: `Add a brief headline to let people know what your ad is about`,
        label: `Headline`,
      },
      linkDesc: {
        tip: `Add additional text to emphasize why people should visit your website`,
        label: `News Feed Link Description`,
      },
      displayLink: {
        tip: `Enter the Display Link you want to promote`,
        label: `Display Link`,
      },
      websiteUrl: {
        tip: `Website URL`,
        label: `Website URL`,
      },
      cta: {
        tip: `Choose the action you want people to take when they see your ad`,
        label: `Call To Action`,
      },
      headlineOne: {
        tip: `Headline 1 appears at the top of your ad and can be up to 30 characters`,
        label: `Headline 1`,
      },
      headlineTwo: {
        tip:
          `Headline 2 appears after Headline 1 at the top of your ad and can be up to 30 characters. ` +
          `It will be separated from Headline 1 by a dash symbol ( - ), ` +
          `and on mobile devices it may wrap to the second line of your ad`,
        label: `Headline 2`,
      },
      headlineThree: {
        tip:
          `Headline 3 appears after Headline 2 at the top of your ad and can be up to 30 characters. ` +
          `It will be separated from Headline 2 by a dash symbol ( - ), ` +
          `and on mobile devices it may wrap to the third line of your ad`,
        label: `Headline 3`,
      },
      adDesc: {
        tip: `Your ad's description appears below the Final URL`,
        label: `Description 1`,
      },
      adDesc2: {
        tip: `Your ad's description appears below the Final URL`,
        label: `Description 2`,
      },
      finalUrl: {
        tip: `The final URL is the URL that people reach after clicking your ad. It should match what your ad promotes`,
        label: `Final URL`,
      },
      pathA: {
        tip: `Fields that are part of your Final URL to tell users they are finding what they are looking for`,
        label: `Display PathA`,
        isOption: true,
      },
      pathB: {
        tip: `Fields that are part of your Final URL to tell users they are finding what they are looking for`,
        label: `Display PathB`,
        isOption: true,
      },
      keywords: {
        tip: `Keyword match types help control which searches on Google can trigger your ad`,
        label: `Keywords`,
        isOption: true,
      },
      negKeywords: {
        tip: `Tell Google Ads not to show your ad for any search containing the terms listed here. Separate all terms by comma.`,
        label: `Negative Keywords`,
        isOption: true,
      },
      duration: {
        tip: `Recommended duration for your campaign.`,
        label: `Duration`,
      },
      advancedScheduling: {
        tip:
          `Advanced scheduling allows you to limit when your ads can run. For example, if you select 8am to 5pm and ` +
          `viewer'stime zone, we'll only show your ads to people from 8am to 5pm in their local time.`,
        label: `Advanced Scheduling`,
      },
      budget: {
        tip: `Your ad set budget is the amount you want to spend on this ad set.`,
        label: `Weekly Budget`,
      },
      maxCPC: {
        tip: `This number represents the highest amount that you're willing to pay for a click on your ad.`,
        label: `Max. CPC`,
      },
      strategy: {
        tip: `Define how you want your keyword bids to be raised and lowered to ensure you're making the most of your AdWords budget.`,
        label: `Bidding`,
      },
    };
  }

  static GetSchedulingOptions(): RadioButtonOption[] {
    return [
      {
        text: 'Run ads all the time',
        value: AdsetScheduleNeededType.NOT_NEED,
      },
      {
        text: 'Run ads on a schedule',
        value: AdsetScheduleNeededType.NEED,
      },
    ];
  }

  private goals(): Array<{ goal: string; objectives: Array<any> }> {
    return [
      {
        goal: 'traffic',
        objectives: [
          { value: 'Promotional', text: 'Promotional' },
          { value: 'Holiday/Seasonal', text: 'Holiday/Seasonal' },
          { value: 'Lead Gen', text: 'Lead Gen' },
          { value: 'Sales', text: 'Sales' },
        ],
      },
      {
        goal: 'awareness',
        objectives: [
          { value: 'Promotional', text: 'Promotional' },
          { value: 'Holiday/Seasonal', text: 'Holiday/Seasonal' },
          { value: 'Reach', text: 'Reach' },
        ],
      },
      {
        goal: 'conversion',
        objectives: [
          { value: 'Promotional', text: 'Promotional' },
          { value: 'Coupon Downloads', text: 'Coupon Downloads' },
          { value: 'Lead Gen', text: 'Lead Gen' },
          { value: 'Sales', text: 'Sales' },
        ],
      },
      {
        goal: 'leads',
        objectives: [
          { value: 'Promotional', text: 'Promotional' },
          { value: 'Holiday/Seasonal', text: 'Holiday/Seasonal' },
          { value: 'Lead Gen', text: 'Lead Gen' },
          { value: 'Sales', text: 'Sales' },
        ],
      },
      {
        goal: 'sales',
        objectives: [
          { value: 'Promotional', text: 'Promotional' },
          { value: 'Holiday/Seasonal', text: 'Holiday/Seasonal' },
          { value: 'Lead Gen', text: 'Lead Gen' },
          { value: 'Sales', text: 'Sales' },
        ],
      },
    ];
  }

  private adSpendOptions(): Array<{
    value: string;
    text: string;
    childOptions?: Array<{ value: string; text: string }>;
  }> {
    return [
      {
        value: 'channel',
        text: 'By Channel',
        childOptions: [
          { value: 'year', text: 'Year to date' },
          { value: 'month', text: 'Month to date' },
          { value: 'lastMonths', text: 'Last 12 months' },
        ],
      },
      {
        value: 'location',
        text: 'By Location',
        childOptions: [
          { value: 'year', text: 'Year to date' },
          { value: 'month', text: 'Month to date' },
          { value: 'lastMonths', text: 'Last 12 months' },
        ],
      },
      {
        value: 'campaign',
        text: 'By Campaign Template',
        childOptions: [
          { value: 'year', text: 'Year to date' },
          { value: 'month', text: 'Month to date' },
          { value: 'lastMonths', text: 'Last 12 months' },
        ],
      },
      { value: 'date', text: 'By Date Range' },
    ];
  }

  getObjectivesByGoal(goal?: string): Observable<Array<{ goal: string; objectives: Array<any> }>> {
    let result = this.goals();
    if (goal) {
      result = result.filter((item) => item.goal === goal);
    }
    return of(result);
  }

  private _optimizationGoals(): any {
    return {
      REACH: { name: 'REACH', text: 'REACH', value: 'REACH', tip: 'Show your ad to the maximum number of people.' },
      LINK_CLICKS: {
        name: 'LINK_CLICKS',
        text: 'LINK CLICKS',
        value: 'LINK_CLICKS',
        tip:
          'Number of clicks on links to select destinations or experiences, on or off Facebook ' +
          '(ex: someone clicks Shop Now on your ad and goes to your website).',
      },
      AD_RECALL_LIFT: {
        name: 'AD_RECALL_LIFT',
        text: 'BRAND AWARENESS',
        value: 'AD_RECALL_LIFT',
        tip: 'Increase awareness for your brand by reaching people who are more likely to be interested in it.',
      },
      POST_ENGAGEMENT: {
        name: 'POST_ENGAGEMENT',
        text: 'ENGAGEMENT',
        value: 'POST_ENGAGEMENT',
        tip: 'Get more post engagements, Page likes, event responses or offer claims.',
      },
    };
  }

  getOptimizationGoals(goal?: string): Array<{ name: string; text: string; value: string; tip: string }> {
    const optimizationGoals = {
      traffic: [this._optimizationGoals().REACH, this._optimizationGoals().LINK_CLICKS],
      awareness: [this._optimizationGoals().AD_RECALL_LIFT],
      conversion: [this._optimizationGoals().REACH, this._optimizationGoals().POST_ENGAGEMENT],
    };
    if (!!goal) {
      goal = goal.toLowerCase();
      return optimizationGoals[goal] || [];
    }
    return [];
  }

  getCallToActionDictionary(channelNme: string): { [key: string]: string } {
    let callToActionDictionary = {
      no_button: 'No Button',
      donate_now: 'Donate Now',
      download: 'Download',
      get_offer: 'Get Offer',
      get_showtimes: 'Get Showtimes',
      learn_more: 'Learn More',
      see_menu: 'See Menu',
      shop_now: 'Shop Now',
    };
    if (!!channelNme && channelNme === 'facebook') {
      const facebookCallToAction = {
        request_time: 'Request Time',
      };
      callToActionDictionary = Object.assign({}, callToActionDictionary, facebookCallToAction);
    }
    return callToActionDictionary;
  }

  getAdSpendOptions(): Array<{ value: string; text: string; childOptions?: Array<{ value: string; text: string }> }> {
    return this.adSpendOptions();
  }

  getStateAbbreviations(): Array<string> {
    return this.states.map((state) => state.abbreviation);
  }

  getStates(): Array<{ abbreviation: string; state: string; key?: string }> {
    return [...this.states];
  }

  getState(abbreviation: string): { abbreviation: string; state: string; key?: string } {
    let result;
    const findState = this.states.find((state) => state.abbreviation === abbreviation);
    if (findState) {
      result = findState;
    }
    return result;
  }

  getAdPositionsOptions(
    channelNme: ChannelName,
    mediaType: MediaType = MediaType.IMAGE,
  ): Array<{
    value: FacebookAdPosition | InstagramAdPosition;
    text: string;
  }> {
    if (channelNme === ChannelName.FACEBOOK) {
      if (mediaType !== MediaType.IMAGES) {
        return [
          {
            value: FacebookAdPosition.FEED,
            text: 'Feed',
          },
          {
            value: FacebookAdPosition.FACEBOOK_REELS,
            text: 'Reels',
          },
          {
            value: FacebookAdPosition.STORY,
            text: 'Stories',
          },
          {
            value: FacebookAdPosition.VIDEO_FEEDS,
            text: 'Video Feeds',
          },
          {
            value: FacebookAdPosition.INSTREAM_VIDEO,
            text: 'In-stream videos',
          },
        ];
      }

      return [
        {
          value: FacebookAdPosition.FEED,
          text: 'Feed',
        },
        {
          value: FacebookAdPosition.FACEBOOK_REELS,
          text: 'Reels',
        },
        {
          value: FacebookAdPosition.VIDEO_FEEDS,
          text: 'Video Feeds',
        },
      ];
    }

    if (channelNme === ChannelName.INSTAGRAM) {
      return [
        {
          value: InstagramAdPosition.STREAM,
          text: 'Feed',
        },
        {
          value: InstagramAdPosition.REELS,
          text: 'Reels',
        },
        {
          value: InstagramAdPosition.STORY,
          text: 'Stories',
        },
      ];
    }

    return [];
  }

  setState(stateInfo: any): void {
    const findStateIndex = this.states.findIndex((state) => state.state.toLowerCase() === stateInfo.name.toLowerCase());
    if (findStateIndex >= 0) {
      const findState = this.states[findStateIndex];
      this.states[findStateIndex] = Object.assign(findState, { key: stateInfo.key });
    }
  }

  getZorUserAccountFields(): Array<{ fieldTitle: string; fieldName: string; fieldType: string }> {
    return [
      { fieldTitle: 'Company Name', fieldName: 'organizationName', fieldType: 'input' },
      { fieldTitle: 'User Name', fieldName: 'name', fieldType: 'input' },
      { fieldTitle: 'Email', fieldName: 'emailAddress', fieldType: 'readonly' },
    ];
  }

  getZeeUserAccountFields(): Array<{ fieldTitle: string; fieldName: string; fieldType: string }> {
    return [
      { fieldTitle: 'First Name', fieldName: 'firstName', fieldType: 'input' },
      { fieldTitle: 'Last Name', fieldName: 'lastName', fieldType: 'input' },
      { fieldTitle: 'Email', fieldName: 'emailAddress', fieldType: 'readonly' },
    ];
  }

  getZeeUserLocationFields(): Array<{ fieldTitle: string; fieldName: string; fieldType: string }> {
    return [
      { fieldTitle: 'Location', fieldName: 'locationId', fieldType: 'dropdown' },
      { fieldTitle: 'Website', fieldName: 'locationWebsite', fieldType: 'input' },
      { fieldTitle: 'Address', fieldName: 'locationAddress', fieldType: 'readonly' },
      { fieldTitle: 'Geo-Targeting', fieldName: 'locationGeo', fieldType: 'readonly' },
    ];
  }

  getFranchiseListFieldWithDisplayName(): Observable<any> {
    return of({
      locationNme: 'LOCATION NAME',
      locationAddress: 'ADDRESS',
      locationState: 'STATE',
      locationZips: 'ZIPS',
      locationRadius: 'RADIUS',
    });
  }

  getNotificationsMessages(): Array<NotificationMessage> {
    return dataMap.notificationsMessages;
  }

  getZeeActivitiesMessages(): Array<{ type: string; msg: string }> {
    return dataMap.zeeActivitiesMessages;
  }

  getAdSubscriptionTypeFields(): Array<AdSubscriptionField> {
    return dataMap.adSubscriptionTypeFields.map((field) => field);
  }

  getAdSubscriptionDetailFields(): Array<AdSubscriptionField> {
    return dataMap.adSubscriptionDetailFields.map((field) => {
      if (field.fieldName === 'adSubscriptionAssignedLocations') {
        field.fieldDefaultValue = [];
      }
      return field;
    });
  }

  getAdSubscriptionTypeOptions(): AdSubscriptionTypeOptions {
    return dataMap.adSubscriptionTypeOptions;
  }

  getFranchiseeLocationFields(): {
    basic: Array<any>;
    selfGeo: Array<any>;
    targetingGeo: Array<any>;
    excludedGeo: Array<any>;
  } {
    return dataMap.franchiseeLocationFields;
  }

  getBulkUploadTemplate(): Array<Array<string>> {
    return dataMap.bulkUploadTemplate;
  }

  getTimePeriodOptions(): Array<DropdownOption> {
    return dataMap.timePeriods;
  }

  getAdPerformanceIndicators(isSMB: boolean = false): Array<IndicativeCampaign> {
    if (isSMB) {
      return dataMap.adPerformanceIndicatorsSMB;
    }
    return dataMap.adPerformanceIndicators;
  }

  // TODO: remove
  getNavigations(role: string, type: string, isAll?: boolean): Array<NavigationMenu> {
    if (!role && !type && isAll) {
      return dataMap.zorNavigations.concat(dataMap.zeeNavigations);
    }
    const navs = role === 'zor' ? dataMap.zorNavigations : dataMap.zeeNavigations;
    return navs.filter((nav) => nav.navType === type);
  }

  getEventLabel(field: string): string {
    const matchedEvent = dataMap.googleAnalyticsEvents.find((event) => event.field === field);
    if (matchedEvent) {
      return matchedEvent.label;
    }
    return '';
  }

  getAdMetricTypes(): Array<IAdMetricTypes> {
    return dataMap.adMetricTypes;
  }

  getConversionEvtOpts(): DropdownOption[] {
    return dataMap.conversionEvents.map((text) => {
      const value = upperFirst(camelCase(text));
      return {
        value,
        text,
      };
    });
  }

  getTemplateMetricsTableColumns(): ITableColumn[] {
    return [
      {
        name: 'CHANNEL',
        prop: 'channelNme',
      },
      {
        name: 'TEMPLATE NAME',
        prop: 'campaignTemplateNme',
        grow: 1.5,
        isLeft: true,
      },
      {
        name: 'RATING',
        prop: 'averageRate',
        grow: 1,
        isLeft: true,
      },
      {
        name: 'RUN',
        prop: 'run',
        type: 'number',
      },
      {
        name: 'DAYS',
        prop: 'days',
        type: 'number',
      },
      {
        name: 'SPEND',
        prop: 'spend',
        type: 'currency',
      },
      {
        name: 'IMPR',
        prop: 'impressions',
        type: 'number',
      },
      {
        name: 'CLICKS',
        prop: 'clicks',
        type: 'number',
      },
      {
        name: 'CTR',
        prop: 'ctr',
        type: 'percent',
      },
      {
        name: 'CVRS',
        prop: 'conversion',
      },
      {
        name: 'CVR',
        prop: 'cvr',
      },
      {
        name: 'CPC',
        prop: 'cpc',
        type: 'currency',
      },
    ];
  }
}
