import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService, GoogleAnalyticsService, LoadingStatusService, UserService } from '@services';
import { SignUpQueryParams } from '@interfaces';

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./simple-layout.component.scss'],
})
export class SimpleLayoutComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  userName: string;
  isScroll: boolean;
  isShowHelpBtn: boolean;
  email: string;

  constructor(
    private userService: UserService,
    public authService: AuthService,
    private googleAnalytics: GoogleAnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingStatusService: LoadingStatusService,
  ) {
    router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingStatusService.isLoading$.next(true);
      }
      if (event instanceof NavigationError || event instanceof NavigationCancel || event instanceof NavigationEnd) {
        this.loadingStatusService.isLoading$.next(false);
        this.isShowHelpBtn = event.url.includes('/register');
      }
    });
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params: SignUpQueryParams) => {
      const { email } = params;
      this.email = email;
    });
  }

  ngOnInit() {
    const user = this.userService.getCachedUserProfile();
    this.userName = user ? user.userFirstNme + ' ' + user.userLastNme : '';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onScrollChange(event): void {
    let el;
    if (!!event.target.documentElement.scrollTop) {
      el = event.target.documentElement;
    }
    if (!!event.target.body.scrollTop) {
      el = event.target.body;
    }
    if (el) {
      if (el.scrollTop < 200 && this.isScroll) {
        this.isScroll = false;
      }
      if (el.scrollTop >= 200 && !this.isScroll) {
        this.isScroll = true;
      }
    }
  }

  logout(): void {
    this.authService.logout();
  }

  eventTrack(label: string) {
    this.googleAnalytics.eventTracking('Navigation', 'Scaffolding Interaction', label);
  }
}
