<div class="search-input-container" [class.disabled]="disabled">
  <div class="input-container">
    <em class="prefix material-icons">search</em>
    <input type="text" [placeholder]="placeholder" [formControl]="formControl"
      (input)="handleInput($event.target.value)" (keydown)="handleKeyDown($event)" [maxlength]="inputLimit">
    <em class="suffix material-icons" *ngIf="formValue && !disabled" (click)="handleClearInput()">cancel</em>
  </div>
  <app-ui-button *ngIf="showSearchBtn" type="button" class="search-input-button" (clickEvent)="handleSearch()"
    [disabled]="disabled">
    {{searchBtnText}}
  </app-ui-button>
</div>
<p *ngIf="!!inputLimit" class="text-xs text-right">
  {{ inputLimit - formValue.length }} / {{ inputLimit }}
</p>
