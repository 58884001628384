import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownOption, ILocation, IObjective } from '@interfaces';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  @ViewChild('dropdown')
  dropDownElement: any;

  filteredInfo: {
    locations: Array<ILocation>;
    objectives: Array<IObjective> | Array<string>;
    channels: Array<string>;
    genders: Array<string>;
  } = {
    locations: [],
    objectives: [],
    channels: [],
    genders: [],
  };
  locations: Array<ILocation> = [];
  objectives: Array<IObjective> | Array<string> = [];
  channels: Array<string> = [];
  genders: Array<string> = [];
  locationOptions: any[] = [];
  selectedLocationIds: string[] = [];
  isNoFilters: boolean;
  constructor(private dialogRef: MatDialogRef<FilterDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit() {
    this.filteredInfo = JSON.parse(JSON.stringify(this.data.filterInfo));
    this.selectedLocationIds = this.filteredInfo.locations.map((location) => location.locationId);
    const { staticLocations, staticObjectives, staticChannels, staticGenders } = this.data;
    this.locations = [...staticLocations];
    this.locationOptions = [...staticLocations].map((location) => ({
      value: location.locationId,
      text: location.locationNme,
    }));
    this.objectives = [...staticObjectives];
    this.channels = [...staticChannels].map((channel) => channel.toLowerCase());
    this.genders = [...staticGenders].map((gender) => gender.toLowerCase());
    this.isNoFilters = [staticLocations, staticObjectives, staticChannels, staticGenders].every(
      (filters) => filters.length === 0,
    );
  }

  handleClick(text: string | IObjective, propName: string) {
    const values = [...this.filteredInfo[propName]];
    let idx = -1;
    const isObjectiveObject = propName === 'objectives' && typeof text !== 'string';
    if (isObjectiveObject) {
      idx = values.findIndex((value) => value.objectiveId === (text as IObjective).objectiveId);
    } else {
      idx = values.indexOf(text);
    }
    if (idx >= 0) {
      this.filteredInfo[propName] = values.filter((value, index) => index !== idx);
    } else {
      if (isObjectiveObject) {
        const matchedObjective = (this.objectives as Array<IObjective>).find(
          (objective: IObjective) => objective.objectiveId === (text as IObjective).objectiveId,
        );
        if (matchedObjective) {
          values.push(matchedObjective);
        }
      } else {
        values.push(text);
      }
      this.filteredInfo[propName] = [...values];
    }
    this.filteredInfo = Object.assign({}, this.filteredInfo);
  }

  isActive(text: string | IObjective, propName: string): boolean {
    const values = this.filteredInfo[propName];
    if (propName === 'objectives' && typeof text !== 'string') {
      return values.some((value) => value.objectiveId === text.objectiveId);
    }
    return values.some((value) => value === text);
  }

  locationSelected(selectedOptions: DropdownOption[]): void {
    this.filteredInfo.locations = this.locations.filter(
      (location) => !!selectedOptions.find((opt) => opt.value === location.locationId),
    );
  }

  handleClear(): void {
    if (this.dropDownElement) {
      this.dropDownElement.clearAllChecked();
    }
    this.filteredInfo = {
      locations: [],
      objectives: [],
      channels: [],
      genders: [],
    };
  }

  closeDialog(isApplyFilters: boolean = false): void {
    if (isApplyFilters) {
      this.dialogRef.close(this.filteredInfo);
    } else {
      this.dialogRef.close();
    }
  }
}
