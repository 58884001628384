import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as gql from './objective.gql';
import { Observable, of } from 'rxjs';
import { IObjective, IObjectiveCreateInput, IObjectiveEditInput, IObjectiveList } from '@interfaces';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ObjectiveService {
  constructor(private apollo: Apollo) {}

  getObjectivesByOrganizationId(variables: {
    id: string;
    orderBy?: string;
    asc?: boolean;
    active?: boolean;
    nbr?: number;
    size?: number;
    filter?: string;
  }): Observable<any> {
    return this.apollo
      .watchQuery<{ objectivesByOrganizationId: IObjectiveList }>({
        query: gql.getObjectivesByOrganizationId,
        variables,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.objectivesByOrganizationId),
        catchError((e) => {
          if (e && e.graphQLErrors && e.graphQLErrors.length) {
            return of({
              isError: true,
              message: e.graphQLErrors[0].message,
            });
          }
          return of(null);
        }),
      );
  }

  createObjective(input: IObjectiveCreateInput): Observable<any> {
    return this.apollo
      .mutate<{ createObjective: IObjective }>({
        mutation: gql.createObjective,
        variables: {
          input,
        },
      })
      .pipe(
        map(({ data }) => data.createObjective),
        catchError((e) => {
          if (e && e.graphQLErrors && e.graphQLErrors.length) {
            return of({
              isError: true,
              message: e.graphQLErrors[0].message,
            });
          }
          return of(null);
        }),
      );
  }

  updateObjective(input: IObjectiveEditInput): Observable<any> {
    return this.apollo
      .mutate<{ updateObjective: IObjective }>({
        mutation: gql.updateObjective,
        variables: {
          input,
        },
      })
      .pipe(
        map(({ data }) => data.updateObjective),
        catchError((e) => {
          if (e && e.graphQLErrors && e.graphQLErrors.length) {
            return of({
              isError: true,
              message: e.graphQLErrors[0].message,
            });
          }
          return of(null);
        }),
      );
  }
}
