import { gql } from 'apollo-angular';
import { limitOrganizationFragment } from '../organization/organization.gql';

export const limitedLocationFragment = gql`
  fragment limitedLocation on LimitedLocationType {
    locationId
    locationNme
    organizationId
    locationContactNme
    locationContactEmail
    locationAddress
    locationAddressLat
    locationAddressLng
    locationPhoneNbr
    locationCity
    locationSelfZip
    locationState
    locationZips
    locationRadius
    locationWebsite
    locationActive
    locationReadOnly
    organization {
      ...limitOrganization
    }
  }
  ${limitOrganizationFragment}
`;

export const locationFragment = gql`
  fragment location on LocationType {
    locationId
    locationNme
    organizationId
    locationContactNme
    locationContactEmail
    locationAddress
    locationStreetAddress
    locationAddressLat
    locationAddressLng
    locationPhoneNbr
    locationCity
    locationSelfZip
    locationState
    locationZips
    locationCountryCode
    locationRadius
    locationExcludedZips
    locationExcludedState
    locationExcludedRadius
    locationWebsite
    locationInstagramAccount
    locationActive
    googleAdwordsClientCustomerId
    adAccountId
    myBusinessConnected
    locationZeeGmbLocation
    locationZorGmbLocation
    locationExtensionEnabled
    campaignTemplateChipAddress
    campaignTemplateChipCity
    campaignTemplateChipPhoneNumber
    organization {
      ...limitOrganization
    }
    locationGroups {
      locationGroupId
      locationGroupNme
      locationGroupActive
    }
  }
  ${limitOrganizationFragment}
`;

export const adsPixelFragment = gql`
  fragment adsPixel on AdsPixelType {
    id
    name
  }
`;

export const getLocationsByOrgId = gql`
  query locationsByOrganizationId($id: ID) {
    locationsByOrganizationId(id: $id) {
      ...location
    }
  }
  ${locationFragment}
`;

export const getLocationsAddressInfoByOrgId = gql`
  query locationsByOrganizationId($id: ID) {
    locationsByOrganizationId(id: $id) {
      locationId
      locationNme
      locationRadius
      locationAddress
      locationStreetAddress
      locationAddressLat
      locationAddressLng
      locationCity
      locationSelfZip
      locationState
      locationZips
      locationActive
    }
  }
`;

export const getLocationsByOrgIdWithPixels = gql`
  query locationsByOrganizationId($id: ID) {
    locationsByOrganizationId(id: $id) {
      ...location
      adsPixels {
        ...adsPixel
      }
    }
  }
  ${locationFragment}
  ${adsPixelFragment}
`;

export const getAllLocationsByViewType = gql`
  query locationsByViewType($nbr: Int!, $size: Int) {
    locationsByViewType(nbr: $nbr, size: $size) {
      locations {
        ...location
      }
    }
  }
  ${locationFragment}
`;

export const getAllLocationsByViewTypeWithPixels = gql`
  query locationsByViewType($nbr: Int!, $size: Int) {
    locationsByViewType(nbr: $nbr, size: $size) {
      locations {
        ...location
        adsPixels {
          ...adsPixel
        }
      }
    }
  }
  ${locationFragment}
  ${adsPixelFragment}
`;

export const updateLocation = gql`
  mutation updateLocation($id: ID!, $input: LocationInput!) {
    updateLocation(id: $id, input: $input) {
      ...location
    }
  }
  ${locationFragment}
`;

export const getLocationById = gql`
  query getLocationById($id: ID) {
    locationById(id: $id) {
      ...location
    }
  }
  ${locationFragment}
`;

export const getLocationByIdWithPixels = gql`
  query getLocationById($id: ID) {
    locationById(id: $id) {
      ...location
      adsPixels {
        ...adsPixel
      }
    }
  }
  ${locationFragment}
  ${adsPixelFragment}
`;

export const createLocation = gql`
  mutation createLocation($input: LocationInput!) {
    createLocation(input: $input) {
      ...location
    }
  }
  ${locationFragment}
`;

export const getMainInfoByViewType = gql`
  query locationsByViewType($nbr: Int!, $size: Int) {
    locationsByViewType(nbr: $nbr, size: $size) {
      locations {
        locationId
        locationNme
        organizationId
        locationContactNme
        locationAddress
        locationStreetAddress
        locationAddressLat
        locationAddressLng
        locationPhoneNbr
        locationCity
        locationSelfZip
        locationState
        locationZips
        locationRadius
        locationWebsite
        organization {
          organizationNme
          organizationType
        }
      }
    }
  }
`;

export const haveFacebookAdPixels = gql`
  query haveFacebookAdPixels {
    haveFacebookAdPixels
  }
`;

export const checkFacebookAdPixels = gql`
  query checkFacebookAdPixels($locationIds: [ID]!) {
    checkFacebookAdPixels(locationIds: $locationIds) {
      noAdPixels
    }
  }
`;

export const locationAddressValidation = gql`
  query locationAddressValidation($address: String!, $selfZip: String) {
    locationAddressValidation(address: $address, selfZip: $selfZip) {
      valid
      errorCode
      message
    }
  }
`;

export const locationAddressSearch = gql`
  query locationAddressSearch($address: String!) {
    locationAddressSearch(address: $address) {
      id
      description
      matched_substrings {
        length
        offset
      }
      place_id
      reference
      structured_formatting {
        main_text
        main_text_matched_substrings {
          length
          offset
        }
        secondary_text
      }
      terms {
        offset
        value
      }
      types
    }
  }
`;

// For admin portal
const basicLocationInfo = gql`
  fragment basicLocationInfo on LocationType {
    locationId
    locationNme
    organizationId
    locationContactNme
    locationAddress
    locationStreetAddress
    locationAddressLat
    locationAddressLng
    locationState
    locationZips
    locationRadius
    locationActive
    organization {
      organizationType
    }
  }
`;

export const getLocationsWithBasicInfo = gql`
  query locationsByOrganizationId($id: ID!) {
    locationsByOrganizationId(id: $id) {
      ...basicLocationInfo
    }
  }
  ${basicLocationInfo}
`;

// for agency portal
export const getAgencyLocationAccounts = gql`
  query getLocationsByAgency($nbr: Int, $size: Int, $orderBy: String, $asc: Boolean, $orgId: ID) {
    getLocationsByAgency(nbr: $nbr, size: $size, orderBy: $orderBy, asc: $asc, orgId: $orgId) {
      locations {
        organizationId
        locationId
        locationNme
        locationAddress
        locationActive
        organization {
          parentId
          organizationNme
        }
      }
      count
    }
  }
`;

export const getLocationsOrgInfoByIds = gql`
  query getLocationsByIds($ids: [ID]!) {
    locationsById(ids: $ids) {
      locationId
      organization {
        organizationId
        organizationNme
        parentId
      }
    }
  }
`;

export const getLocationWithPixelsById = gql`
  query getLocationById($id: ID) {
    locationById(id: $id) {
      locationId
      locationNme
      locationActive
      adsPixels {
        ...adsPixel
      }
    }
  }
  ${adsPixelFragment}
`;

export const getLocationsWithPixelsByOrgId = gql`
  query locationsByOrganizationId($id: ID) {
    locationsByOrganizationId(id: $id) {
      locationId
      locationNme
      locationActive
      adsPixels {
        ...adsPixel
      }
    }
  }
  ${adsPixelFragment}
`;

export const createLocationGroup = gql`
  mutation createLocationGroup($name: String!) {
    createLocationGroup(name: $name)
  }
`;

export const updateLocationGroup = gql`
  mutation updateLocationGroup(
    $locationGroupId: ID!
    $locationGroupNme: String
    $locationGroupActive: Boolean
    $locationGroupLocationIds: [Int]
  ) {
    updateLocationGroup(
      id: $locationGroupId
      name: $locationGroupNme
      active: $locationGroupActive
      locationIds: $locationGroupLocationIds
    )
  }
`;

export const getLocationGroups = gql`
  query locationGroups($nbr: Int!, $size: Int, $order: LocationGroupOrder!, $asc: Boolean) {
    locationGroups(nbr: $nbr, size: $size, order: $order, asc: $asc) {
      locationGroups {
        locationGroupId
        locationGroupNme
        locationGroupActive
        locationGroupLocationIds
        locationGroupOrgId
        locationGroupCreatedAt
        locationGroupUpdatedAt
      }
      count
    }
  }
`;

export const checkLocationGroupExists = gql`
  query checkLocationGroupExists($name: String!) {
    checkLocationGroupExists(name: $name)
  }
`;

export const toggleLocActiveStatus = gql`
  mutation updateLocationActiveStatus($input: LocationActiveInput!) {
    updateLocationActiveStatus(input: $input) {
      locationId
      locationActive
    }
  }
`;

export const deleteLocation = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      locationId
    }
  }
`;

export const availableLocationsByTemplateId = gql`
  query sharedLocationsByTemplateId($templateId: ID!) {
    sharedLocationsByTemplateId(templateId: $templateId) {
      ...location
      adsPixels {
        ...adsPixel
      }
    }
  }
  ${locationFragment}
  ${adsPixelFragment}
`;
