import { Injectable } from '@angular/core';
import {
  GoogleReportQueryParams,
  GoogleReportPage,
  CampaignPerformanceReportPage,
  AdGroupPerformanceReportPage,
  KeywordsPerformanceReportPage,
  SearchQueryPerformanceReportPage,
  CampaignPerformanceReportsResponse,
  AdGroupPerformanceReportsResponse,
  KeywordsPerformanceReportsResponse,
  SearchQueryPerformanceReportsResponse,
} from '@interfaces';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import * as gql from './google-report.gql';

const noData: GoogleReportPage<unknown> = {
  data: [],
  count: 0,
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
  },
};

@Injectable({
  providedIn: 'root',
})
export class GoogleReportService {
  constructor(private apollo: Apollo) {}

  getCampaignReport({ page, filter, orderBy }: GoogleReportQueryParams): Observable<CampaignPerformanceReportPage> {
    return this.apollo
      .watchQuery<CampaignPerformanceReportsResponse>({
        query: gql.campaignPerformanceReport,
        variables: {
          page,
          filter,
          orderBy,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.campaignPerformanceReports),
        catchError(() => of(noData as CampaignPerformanceReportPage)),
      );
  }

  getAdGroupReport({ page, filter, orderBy }: GoogleReportQueryParams): Observable<AdGroupPerformanceReportPage> {
    return this.apollo
      .watchQuery<AdGroupPerformanceReportsResponse>({
        query: gql.adGroupPerformanceReport,
        variables: {
          page,
          filter,
          orderBy,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.adGroupPerformanceReports),
        catchError(() => of(noData as AdGroupPerformanceReportPage)),
      );
  }

  getKeywordsReport({ page, filter, orderBy }: GoogleReportQueryParams): Observable<KeywordsPerformanceReportPage> {
    return this.apollo
      .watchQuery<KeywordsPerformanceReportsResponse>({
        query: gql.keywordsPerformanceReport,
        variables: {
          page,
          filter,
          orderBy,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.keywordsPerformanceReports),
        catchError(() => of(noData as KeywordsPerformanceReportPage)),
      );
  }

  getSearchQueryReport({
    page,
    filter,
    orderBy,
  }: GoogleReportQueryParams): Observable<SearchQueryPerformanceReportPage> {
    return this.apollo
      .watchQuery<SearchQueryPerformanceReportsResponse>({
        query: gql.searchQueryPerformanceReport,
        variables: {
          page,
          filter,
          orderBy,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.searchQueryPerformanceReports),
        catchError(() => of(noData as SearchQueryPerformanceReportPage)),
      );
  }
}
