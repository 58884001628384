import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AuthService,
  UserService,
  GoogleAnalyticsService,
  NotificationService,
  PolicyService,
  BillingApiClientService,
  OrganizationApiClientService,
} from '@services';
import {
  IUser,
  NavigationMenu,
  IconType,
  NotificationType,
  CampaignOperationType,
  PoliciesEnum,
  FormattedNotification,
  Notification,
} from '@interfaces';
import { Observable, Subject, combineLatest, iif, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Utils } from '@utils';

type Notifications = {
  tokenStatuses: Notification[];
  pausedAds: Notification[];
  billingUnset: boolean;
};

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  private ngUnSubscribe$: Subject<any> = new Subject();

  @Input()
  isScroll = false;

  @Output()
  toggleEvent: EventEmitter<void> = new EventEmitter(false);

  @Input()
  ownedLocationNum = 0;

  @Input()
  networkSizeNum = 0;

  @Input()
  dropdownNavs: Array<NavigationMenu> = [];

  @Input()
  userProfile?: IUser | null;

  headerBarMessages: FormattedNotification[] = [];
  nowBarMessageIndex = 0;
  previousIconPath: string;
  nextIconPath: string;

  get isLocationUser(): boolean {
    return this.userService.isLocUser();
  }

  get isZorUser(): boolean {
    return this.userService.isZORView();
  }

  get isZeeUser(): boolean {
    return this.userService.isZEEView();
  }

  get orgName(): string {
    if (this.isLocationUser) {
      return this.userProfile?.location?.locationNme ?? '';
    }
    return this.userProfile?.organization?.organizationNme ?? '';
  }

  get userName(): string {
    const userProfile = this.userProfile;
    if (!userProfile) {
      return '';
    }

    return `${userProfile.userFirstNme} ${userProfile.userLastNme}`.trim().toUpperCase();
  }

  get canNavToAdResults(): boolean {
    return this.userProfile && this.policyService.checkPolicyPermission(PoliciesEnum.navLocalAdResults);
  }

  get canNavToAccount(): boolean {
    if (!this.userProfile) {
      return false;
    }

    return this.isZeeUser
      ? this.policyService.checkPolicyPermission(PoliciesEnum.navZeeAccount)
      : this.policyService.checkPolicyPermission(PoliciesEnum.navZorAccount);
  }

  orgLogoUrl = '';

  get orgLogoClass() {
    return {
      'bg-gray': !this.orgLogoUrl,
      'cursor-pointer': this.isZorUser,
    };
  }

  get organization() {
    if (this.isLocationUser) {
      return this.userProfile?.locationOrganization;
    }

    return this.userProfile?.organization;
  }

  constructor(
    private userService: UserService,
    public authService: AuthService,
    public router: Router,
    private googleAnalytics: GoogleAnalyticsService,
    private notificationService: NotificationService,
    private policyService: PolicyService,
    private readonly billingApiClient: BillingApiClientService,
    private readonly organizationApiClient: OrganizationApiClientService,
  ) {
    this.previousIconPath = Utils.GetIconPath(IconType.Fill, 'Left_Chevron');
    this.nextIconPath = Utils.GetIconPath(IconType.Fill, 'Right_Chevron');

    this._getNotificationsOnNavigation();
  }

  ngOnInit() {
    this._getLogoUrl();
  }

  private _getNotificationsOnNavigation(): void {
    const isAuthenticated$ = this.authService.isAuthenticated().pipe(delay(50));
    const navigationEnded$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    combineLatest([isAuthenticated$, navigationEnded$])
      .pipe(
        takeUntil(this.ngUnSubscribe$),
        switchMap(([isAuthenticated]) => {
          const emptyNotifications: Notifications = {
            pausedAds: [],
            tokenStatuses: [],
            billingUnset: false,
          };

          if (!isAuthenticated) {
            return of(emptyNotifications);
          }

          return this._getNotifications();
        }),
      )
      .subscribe({
        next: ({ tokenStatuses, pausedAds, billingUnset }) => {
          const formattedNotifications: FormattedNotification[] = [];
          if (billingUnset) {
            const billingUnsetNotification = {
              category: 'billingUnset',
            } as FormattedNotification;
            formattedNotifications.push(billingUnsetNotification);
          }
          const notifications = [...tokenStatuses, ...pausedAds];
          this.headerBarMessages = formattedNotifications.concat(
            this.notificationService.formatNotifications(notifications),
          );
        },
        error: () => {
          this.headerBarMessages = [];
          this.nowBarMessageIndex = 0;
        },
      });
  }

  private _getLogoUrl(): void {
    this.userService
      .onOrgLogoChange()
      .pipe(takeUntil(this.ngUnSubscribe$))
      .subscribe((url) => {
        this.orgLogoUrl = url ?? '';
      });
  }

  private _getNotifications(): Observable<Notifications> {
    const billingUnset$ = this._isBillingSet().pipe(
      map((isBillingSet) => {
        return !isBillingSet;
      }),
    );

    const tokenStatuses$ = this.notificationService.getTokenStatusNotifications().pipe(
      map((notifications) => {
        return notifications.filter(
          (notification) =>
            notification.notificationType === NotificationType.account_disconnect ||
            notification.notificationType === NotificationType.funding_source_type_is_unset,
        );
      }),
    );
    const pausedAds$ = this.notificationService.getNotifications().pipe(
      map((notifications) => {
        return notifications.filter(
          (notification) =>
            !!(
              notification.notificationType === NotificationType.campaign_operation &&
              !notification.notificationIsRead &&
              notification.notificationContent.campaignPublishStatus === CampaignOperationType.PAUSE
            ),
        );
      }),
    );

    return combineLatest([tokenStatuses$, pausedAds$, billingUnset$]).pipe(
      map(([tokenStatuses, pausedAds, billingUnset]) => {
        return {
          tokenStatuses,
          pausedAds,
          billingUnset,
        };
      }),
    );
  }

  private _isBillingSet(): Observable<boolean> {
    if (!this.organization) {
      return of(true);
    }
    const locationId = this.userProfile.locationId;
    const organizationId = this.organization.organizationId;

    return this.organizationApiClient.getOrganizationDetail(organizationId, locationId).pipe(
      switchMap((organization) => {
        if (organization.reseller) {
          return of(true);
        }

        return this.billingApiClient.getStatus(organizationId, locationId).pipe(
          map((status) => {
            return status.status && status.paymentMethod;
          }),
        );
      }),
      catchError(() => {
        return of(true);
      }),
    );
  }

  toggleMenu(): void {
    this.toggleEvent.emit();
  }

  navToPage(currentNav: NavigationMenu): void {
    if (currentNav.navName === 'Log Out') {
      this.authService.logout();
    }
  }

  messageChange(indexNum: number): void {
    let index = this.nowBarMessageIndex + indexNum;
    if (index < 0) {
      index = 0;
    } else if (index > this.headerBarMessages.length - 1) {
      index = this.headerBarMessages.length - 1;
    }
    this.nowBarMessageIndex = index;
  }

  gotoResultPage(campaignId: string): void {
    this.router.navigate([`/app/ad-results-details/${campaignId}`]);
  }

  gotoAccountPage(): void {
    this.router.navigateByUrl('/app/account');
  }

  eventTrack(label: string): void {
    this.googleAnalytics.eventTracking('Navigation', 'Scaffolding Interaction', label);
  }

  getBillingPageUrl(): string {
    const organizationId = this.organization?.organizationId;
    return `/ui/management/billing?organizationId=${organizationId}`;
  }

  ngOnDestroy(): void {
    this.ngUnSubscribe$.next();
    this.ngUnSubscribe$.complete();
  }
}
