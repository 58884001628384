import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ui-input-media',
  templateUrl: './ui-input-media.component.html',
  styleUrls: ['./ui-input-media.component.scss'],
})
export class UiInputMediaComponent implements OnChanges {
  @Input()
  formGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  disabled = false;
  @Input()
  width: string;
  @Input()
  placeholder = 'Upload Media';
  @Input()
  buttonPosition = 'center';
  @Input()
  accept = '*';
  @Input()
  isError = false;

  @Output()
  changeEvent: EventEmitter<{ file: File; designId: string }> = new EventEmitter();

  @ViewChild('mediaInput')
  mediaInput: ElementRef;

  designId: string;
  isPublishing: boolean;

  get formControl(): AbstractControl {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName);
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    this._initForm();
  }

  clickInput(): void {
    const mediaInput = this.mediaInput.nativeElement;
    const formControl = this.formControl;
    if (!!mediaInput && formControl) {
      mediaInput.click();
    }
  }

  doFileChange(event: Event): void {
    const mediaInput = event.currentTarget as any;
    if (!!mediaInput.value) {
      const file = mediaInput.files[0] as File;
      this.setFormValue(file);
    }
  }

  clearFile(): void {
    this.mediaInput.nativeElement.value = '';
  }

  private _initForm() {
    let formGroup = this.formGroup;
    let controlName = this.controlName;

    if (!formGroup || !controlName) {
      const defaultControl = {
        defaultControl: new FormControl({ value: '' }, []),
      };
      formGroup = new FormGroup(defaultControl);
      controlName = 'defaultControl';
    }

    this.formGroup = formGroup;
    this.controlName = controlName;
  }

  private setFormValue(file: File, designId?: string): void {
    this.formControl.setValue(file);
    this.changeEvent.emit({ file, designId });
  }
}
