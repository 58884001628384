import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { GMBLocation, GMBLocationBind, GMBLocationDetail } from '@interfaces';
import * as gql from './google.gql';
import { map, catchError } from 'rxjs/operators';
import * as AccountsManagementGql from '../manage-user-social-accounts/manage-user-social-accounts.gql';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  constructor(private apollo: Apollo) {}

  getGoogleMyBusinessLocations(): Observable<Array<GMBLocation>> {
    return this.apollo
      .watchQuery<{ getGMBLocations: Array<GMBLocation> }>({
        fetchPolicy: 'network-only',
        query: gql.getGMBAccountLocationsQuery,
      })
      .valueChanges.pipe(
        map(({ data }) => data.getGMBLocations),
        catchError(() => of([])),
      );
  }

  assignLocationsToGMBLocations(locations: Array<GMBLocationBind>): Observable<{ success: boolean }> {
    return this.apollo
      .mutate<{ bindGMBLocations: { success: boolean } }>({
        mutation: gql.bindMyBusinessLocations,
        variables: {
          locations,
        },
      })
      .pipe(
        map(({ data }) => data.bindGMBLocations),
        catchError(() => of(null)),
      );
  }

  disconnectMyBusiness(): Observable<{ success: boolean; message: string }> {
    return this.apollo
      .mutate<{
        disconnectMyBusiness: {
          success: boolean;
          message: string;
        };
      }>({
        mutation: gql.disconnectMyBusiness,
        refetchQueries: [{ query: AccountsManagementGql.getChannelConnectStatus }],
      })
      .pipe(map(({ data }) => data.disconnectMyBusiness));
  }

  getGMBLocation(gmbLocation: string): Observable<GMBLocationDetail> {
    return this.apollo
      .watchQuery<{ getGMBLocation: GMBLocationDetail }>({
        query: gql.getGMBLocation,
        variables: {
          gmbLocation,
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          let initData = null;
          if (data.getGMBLocation) {
            initData = JSON.parse(
              JSON.stringify(data.getGMBLocation, (key, value) => {
                if (key === '__typename') {
                  return undefined;
                } else {
                  return value;
                }
              }),
            );
          }
          return initData;
        }),
        catchError(() => of(null)),
      );
  }

  editGMBLocation(location: GMBLocationDetail): Observable<{ success: boolean }> {
    return this.apollo
      .mutate<{ editGMBLocation: { success: boolean } }>({
        mutation: gql.editGMBLocation,
        variables: {
          location,
        },
      })
      .pipe(map(({ data }) => data.editGMBLocation));
  }
}
