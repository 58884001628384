import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {
  year: string;

  constructor() {
    this.year = new Date().getFullYear() + '';
  }

  ngOnInit() {}
}
