import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { DictionaryService } from './dictionary/dictionary.service';
import { Router } from '@angular/router';

declare const ga: (action: string, type: string, value?: any) => void;

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
  constructor(private dictionaryService: DictionaryService, private injector: Injector) {}

  public handleError(error: any): void {
    let errorMsg: string;
    if (error instanceof Error) {
      errorMsg = this.getErrorMsg(error);
    }
    if (typeof ga === 'function' && errorMsg) {
      ga('send', 'event', {
        eventCategory: 'Error Reporting',
        eventAction: 'JS Error thrown',
        eventLabel: errorMsg,
        eventValue: null,
      });
    }
    // ignore customized timeout
    const isIgnore = error.message.includes('Timeout exceeded');
    if (!isIgnore) {
      throw error;
    }
  }
  private getErrorMsg(error: Error): string {
    const allNavs = this.dictionaryService.getNavigations(null, null, true);
    if (error.stack) {
      const stack = error.stack.split('\n').map((line) => line.trim());
      const detail = stack[0];
      const location = stack[1];
      const router = this.injector.get(Router);
      const currentURL = router.url;
      const matchedNav = allNavs.find((nav) => currentURL === nav.navPath);
      let page;
      if (matchedNav) {
        const name = matchedNav.navName;
        let role: string;
        if (matchedNav.navId.indexOf('zee') !== -1) {
          role = 'Zee';
        }
        if (matchedNav.navId.indexOf('zor') !== -1) {
          role = 'Zor';
        }
        if (matchedNav.navId.indexOf('admin') !== -1) {
          role = 'Admin';
        }
        page = `${role} - ${name}`;
      }

      // TODO: update route and role
      const subRoutes = ['ad-subscription', 'run-ad-customization', 'ad-results-details', 'create-campaign'];
      subRoutes.forEach((route) => {
        if (currentURL.indexOf(route) !== -1) {
          if (route.includes('subscription')) {
            page = 'Zee - Subscriptions';
          } else if (route.includes('run-ad')) {
            const role = route.includes('org') ? 'Zor' : 'Zee';
            page = role + ' - Run A New Ad / Details';
          } else if (route.includes('results')) {
            page = 'Zee - Ad Results / Details';
          } else {
            page = 'Zor - Create Campaign';
          }
        }
      });
      return `<${page}>\n<${location}>\n${detail}`;
    }
    return error.message || '';
  }
}
