<div class="message-dialog-container">
  <app-ui-icon [icon]="dialogIcon" size="4"></app-ui-icon>
  <span class="close-icon dialog-close-icon" (click)="closeDialog(false)"></span>
  <ng-template [ngIf]="!!dialogBody" [ngTemplateOutlet]="dialogBody"></ng-template>
  <ng-template [ngIf]="!!dialogText">
    <p class="message-dialog-body">{{ dialogText }}</p>
  </ng-template>


  <ng-template [ngIf]="isNoUser">
    <p class="message-dialog-body">
      Sorry, but your user hasn't been set up yet, please sign up first.
    </p>
  </ng-template>

  <ng-template [ngIf]="isUserInactive">
    <p class="message-dialog-body">
      Your Account has been deactivated, please contact
      <a href="mailto:adcorner@a4media.com?subject=Account%20activate">
        adcorner@a4media.com
      </a>
      for assistance.
    </p>
  </ng-template>

  <ng-template [ngIf]="isSessionTimeOut">
    <div class="message-dialog-body">
      <h4>Are you still there?</h4>
      <p class="mt-m">
        You will be logged out soon due to inactivity. If you would like to continue your session, please click below
      </p>
    </div>
  </ng-template>

  <app-ui-button class="message-dialog-button" (clickEvent)="closeDialog(isSessionTimeOut)">
    {{ dialogBtnText }}
  </app-ui-button>
</div>
