<div class="btn-group-toggle checkbox-btn-group" [class.group-checkbox]="!separate"
  *ngIf="options && options.length > 0">
  <label class="btn-primary checkbox-btn" [class.error-border]="isError"
    [class.disabled-btn]="disabled || !!option.disabled" ngbButtonLabel *ngFor="let option of options">
    <div class="flex-hor-center checkbox-btn-content">
      <input type="checkbox" ngbButton [ngModel]="optionStatus(option.value)" (change)="clickOption($event, option)">
      {{option.text}}
    </div>
  </label>
</div>
