import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-labels',
  templateUrl: './text-labels.component.html',
  styleUrls: ['./text-labels.component.scss'],
})
export class TextLabelsComponent implements OnInit {
  @Input()
  labels: Array<string> = [];
  constructor() {}

  ngOnInit() {
    if (!this.labels) {
      this.labels = [];
    }
  }
}
