export enum ObjectiveOrder {
  objectiveNme = 'objectiveNme',
  usedByCampainTemplate = 'usedByCampainTemplate',
  objectiveIsActive = 'objectiveIsActive',
  objectiveTemplateCount = 'objectiveTemplateCount',
}

export interface IObjective {
  objectiveId: string;
  objectiveNme: string;
  objectiveOrganizationId: string;
  objectiveCreatorId: string;
  objectiveCreatedDte?: string;
  objectiveUpdatedDte?: string;
  objectiveIsActive: boolean;
  objectiveIsDeleted: boolean;
  objectiveTemplateCount?: number;
  usedByCampaignTemplate: number;
}

export interface IObjectiveList {
  objectives: Array<IObjective>;
  count: number;
}

export interface ISearchObjectiveInput {
  objectiveNme: string;
  objectiveCreatedDte?: Date;
  objectiveUpdatedDte?: Date;
  objectiveIsActive: boolean;
  objectiveIsDeleted?: boolean;
  usedByCampainTemplate: number;
}

export interface IObjectiveCreateInput {
  objectiveNme: string;
  objectiveIsActive: boolean;
  objectiveIsDeleted?: boolean;
}

export interface IObjectiveEditInput {
  objectiveId: string;
  objectiveNme?: string;
  objectiveIsActive?: boolean;
}
