<span (click)="$event.stopPropagation()">
  <ng-template #averageRating let-fill="fill">
    <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
    </span>
  </ng-template>
  <ngb-rating [(rate)]="stars" [starTemplate]="averageRating" max="5" [readonly]="readonly" (rateChange)="changeRate($event)"></ngb-rating>
  <span *ngIf="isShowReview" id="feedbackRateCount" class="count text-gray"
        [class.cursor-pointer]="reviews > 0" (click)="showTemplateReviews()">
    {{ reviews || 0}}
  </span>
</span>
