import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
})
export class UiButtonComponent implements OnInit {
  @Input()
  link: string;
  @Input()
  disabled: boolean;
  @Input()
  error: boolean;

  @Input()
  type = 'button';
  @Input()
  btnType = 'primary';
  @Input()
  style = 'square';
  @Input()
  position = 'center';

  @Output()
  clickEvent: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit() {}

  doClick(event: MouseEvent) {
    const link = this.link;
    if (!!link) {
      this.router.navigateByUrl(link);
    }
    this.clickEvent.emit(event);
  }
}
