import { gql } from 'apollo-angular';

/**
 * get oauth required info, such as base url, redirect url, scopes
 */
const fbCredentialFragment = gql`
  fragment fbCredential on FacebookCredentialType {
    appId
    scopes
    oauthUri
  }
`;

const googleCredentialFragment = gql`
  fragment googleCredential on GoogleCredentialType {
    appId
    scopes
    oauthUri
  }
`;

const oauthCredentialFragment = gql`
  fragment oauthCredential on CredentialType {
    facebook {
      ...fbCredential
    }
    instagram {
      ...fbCredential
    }
    google {
      ...googleCredential
    }
    mybusiness {
      ...googleCredential
    }
  }
  ${fbCredentialFragment}
  ${googleCredentialFragment}
`;

export const getOAuthRequiredInfo = gql`
  query getOAuthCredential {
    getOAuthCredential {
      ...oauthCredential
    }
  }
  ${oauthCredentialFragment}
`;

/**
 * get channel connect status
 */
const channelConnectStatusFragment = gql`
  fragment connectStatus on ConnectedStateType {
    facebook
    instagram
    google
    mybusiness
  }
`;

export const getChannelConnectStatus = gql`
  query getConnectedState {
    getConnectedState {
      ...connectStatus
    }
  }
  ${channelConnectStatusFragment}
`;

export const getZowiAccountsConnectedStatus = gql`
  query getZOWIChannelAccount {
    getZOWIChannelAccount {
      facebook {
        facebookAccountId
        organizationId
        facebookAccountFacebookConnectedInd
        facebookAccountInstagramConnectedInd
        facebookAccountAdActId
        facebookAccountDefaultPageRealId
        facebookAccountInstagramAct
        facebookAccountZeeAdActId
        facebookAccountDefaultZeePageRealId
        facebookAccountZeeInstagramAct
      }
      google {
        googleAccountConnectedInd
        googleAccountDefaultCustomerId
      }
    }
  }
`;

export const getCheckTokenExpiration = gql`
  query checkTokenExpiration {
    checkTokenExpiration {
      channel
      type
    }
  }
`;

export const getLocationConnectStatus = gql`
  query getLocationBindState {
    getLocationBindState {
      ...connectStatus
    }
  }
  ${channelConnectStatusFragment}
`;

export const getLocationSocialAccountInfo = gql`
  query getUserLocationBindDetail {
    getUserLocationBindDetail {
      locationId
      facebookAccountUserNme
      facebookPageRealId
      facebookPageNme
      locationInstagramAccount
      locationInstagramAccountType
      googleAdwordsClientCustomerId
      googleAdwordsCustomerNme
    }
  }
`;

/**
 * transfer data got after facebook oauth success to server side
 */
const facebookBusinessInfoFragment = gql`
  fragment facebookBusinessInfo on BusinessType {
    id
    name
  }
`;

const facebookPageInfoFragment = gql`
  fragment facebookPageInfo on FacebookPageType {
    id
    name
  }
`;

const facebookAdAccountWithPagesFragment = gql`
  fragment facebookAdAccountWithPages on AdAccountType {
    id
    name
    pages {
      ...facebookPageInfo
    }
    hasNextPages
  }
  ${facebookPageInfoFragment}
`;

const facebookPageInfoListFragment = gql`
  fragment facebookPageInfoList on FacebookUserType {
    id
    name
    adAccounts {
      ...facebookAdAccountWithPages
    }
  }
  ${facebookAdAccountWithPagesFragment}
`;

export const confirmFacebookOAuth = gql`
  mutation confirmFacebookOAuth($result: FacebookOAuthResultInput!) {
    confirmFacebookOAuth(result: $result) {
      ...facebookPageInfoList
    }
  }
  ${facebookPageInfoListFragment}
`;

/**
 * facebook page bind
 */
export const bindPagesForFacebook = gql`
  mutation bindPages(
    $defaultPageId: String!
    $defaultZeePageId: String
    $defaultAdActId: String
    $defaultZeeAdActId: String
    $pages: [PageBind]!
  ) {
    bindPages(
      defaultPageId: $defaultPageId
      defaultZeePageId: $defaultZeePageId
      defaultAdActId: $defaultAdActId
      defaultZeeAdActId: $defaultZeeAdActId
      pages: $pages
    ) {
      success
    }
  }
`;

/**
 * transfer data that got after instagram oauth success to server side
 */
const instagramAccountFragment = gql`
  fragment instagramAccount on InstagramAccountType {
    type
    accountId
    accountName
  }
`;

const instagramLocationPageFragment = gql`
  fragment instagramLocationPage on LocationPageType {
    locationId
    locationName
    pageId
    pageName
    instagramAccount
    accountType
    adActId
    accounts {
      ...instagramAccount
    }
  }
  ${instagramAccountFragment}
`;

const instagramPageInfoListFragment = gql`
  fragment instagramPageInfoList on InstagramUserType {
    id
    name
    locationPages {
      ...instagramLocationPage
    }
  }
  ${instagramLocationPageFragment}
`;

export const confirmInstagramOAuth = gql`
  mutation confirmInstagramOAuth($result: FacebookOAuthResultInput!) {
    confirmInstagramOAuth(result: $result) {
      ...instagramPageInfoList
    }
  }
  ${instagramPageInfoListFragment}
`;

/**
 * bind instagram accounts
 */
export const bindInstagramAccounts = gql`
  mutation bindInstagramAccounts(
    $accounts: [InstagramAccountBind]!
    $zorDefault: String
    $zeeDefault: String
    $zeeDefaultAccountType: String
    $zorDefaultAccountType: String
  ) {
    bindInstagramAccounts(
      accounts: $accounts
      zorDefault: $zorDefault
      zeeDefault: $zeeDefault
      zeeDefaultAccountType: $zeeDefaultAccountType
      zorDefaultAccountType: $zorDefaultAccountType
    ) {
      success
    }
  }
`;

export const disconnectInstagram = gql`
  mutation disconnectInstagram {
    disconnectInstagram {
      success
      message
    }
  }
`;

export const disconnectFacebook = gql`
  mutation disconnectFacebook {
    disconnectFacebook {
      success
      message
    }
  }
`;

/**
 * get Facebook ad accounts and pages
 */
export const getFacebookAdAccountsAndPages = gql`
  query getFacebookAdAccountsAndPages($after: String) {
    facebookPages(after: $after) {
      adAccounts {
        ...facebookAdAccountWithPages
      }
      after
    }
  }
  ${facebookAdAccountWithPagesFragment}
`;

/**
 * get pages by ad account id
 */
export const getPagesByAdAccountId = gql`
  query pagesByAdAccountId($adAccountId: String!) {
    getPagesByAdAccountId(adAccountId: $adAccountId) {
      ...facebookAdAccountWithPages
    }
  }
  ${facebookAdAccountWithPagesFragment}
`;

export const getDefaultInsAccounts = gql`
  query getDefaultInsAccounts {
    getDefaultInsAccounts {
      zeeDefault
      zorDefault
      zeeDefaultAccountType
      zorDefaultAccountType
      zeeDefaultAccounts {
        type
        accountId
        accountName
      }
      zorDefaultAccounts {
        type
        accountId
        accountName
      }
    }
  }
`;

/**
 * get Instagram pages
 */
export const getInstagramPages = gql`
  query locationPages {
    locationPages {
      ...instagramLocationPage
    }
  }
  ${instagramLocationPageFragment}
`;

/**
 * Google Oauth confirm
 */
const GoogleUserInfoFragment = gql`
  fragment googleUserInfo on GoogleUserType {
    id
    name
    email
  }
`;

export const GoogleOauthResultConfirm = gql`
  mutation confirmGoogleOAuth($result: GoogleOAuthInput!) {
    confirmGoogleOAuth(result: $result) {
      ...googleUserInfo
    }
  }
  ${GoogleUserInfoFragment}
`;

export const disconnectGoogle = gql`
  mutation disconnectGoogle {
    disconnectGoogle {
      success
      message
    }
  }
`;

const GoogleAccountFragment = gql`
  fragment googleAccountInfo on GGAWAccountType {
    googleAdwordsCustomerNme
    googleAdwordsClientCustomerId
    googleAdwordsManagerCustomerId
    googleAdwordsCanManageClientsInd
  }
`;

export const getGoogleAccounts = gql`
  query allLinkedGGAWAccount {
    allLinkedGGAWAccount {
      defaultAccountId
      accounts {
        ...googleAccountInfo
      }
    }
  }
  ${GoogleAccountFragment}
`;

export const getSavedGoogleAdsAccounts = gql`
  query googleAdsAccounts {
    googleAdsAccounts {
      ...googleAccountInfo
    }
  }
  ${GoogleAccountFragment}
`;

export const bindGoogleAccounts = gql`
  mutation bindGoogleAccounts($defaultCustomerId: String, $links: [GoogleAccountLocationLinkInput]) {
    bindGoogleAccounts(defaultCustomerId: $defaultCustomerId, links: $links) {
      success
    }
  }
`;
