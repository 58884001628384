import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ui-checkbox',
  templateUrl: './ui-checkbox.component.html',
  styleUrls: ['./ui-checkbox.component.scss'],
})
export class UiCheckboxComponent implements OnInit {
  private ngUnSubscribe$: Subject<any> = new Subject();

  @Input()
  set value(input: boolean) {
    this.defaultValue = input;
  }
  get value() {
    return this.defaultValue;
  }
  @Input()
  disabled = false;
  @Input()
  mainCheckSub;
  @Input()
  childAllCheckSub;
  @Input()
  showError = false;

  @Output()
  changeEvent: EventEmitter<MatCheckboxChange> = new EventEmitter();
  @Output()
  clickEvent: EventEmitter<MouseEvent> = new EventEmitter();
  @Output()
  disabledClickEvent: EventEmitter<MouseEvent> = new EventEmitter();

  defaultValue = false;

  constructor() {}

  ngOnInit() {
    this._addMainCheckSubListener();
    this._addChildCheckSubListener();
  }

  private _addMainCheckSubListener(): void {
    if (this.mainCheckSub) {
      this.mainCheckSub.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((res) => {
        if (res !== undefined) {
          this.defaultValue = res;
          this.selectChange({ checked: res } as MatCheckboxChange);
        }
      });
    }
  }

  private _addChildCheckSubListener(): void {
    if (this.childAllCheckSub) {
      this.childAllCheckSub.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((res) => {
        this.defaultValue = res;
      });
    }
  }

  selectChange(event: MatCheckboxChange) {
    if (!this.disabled) {
      this.changeEvent.emit(event);
    }
  }

  doClick(event: MouseEvent) {
    if (!this.disabled) {
      this.clickEvent.emit(event);
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.disabledClickEvent.emit(event);
    }
  }
}
