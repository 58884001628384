import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbInputDatepicker, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { DatepickerService } from '@services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ui-datepicker-single',
  templateUrl: './ui-datepicker-single.component.html',
  styleUrls: ['./ui-datepicker-single.component.scss'],
})
export class UiDatepickerSingleComponent implements OnInit {
  private ngUnSubscribe$: Subject<any> = new Subject();

  @Input('date')
  set inputDate(input: string | Date | NgbDate) {
    if (!this.date && input) {
      const inputNgbDate = input as NgbDate;
      if (inputNgbDate && !isNaN(inputNgbDate.year) && !isNaN(inputNgbDate.month) && !isNaN(inputNgbDate.day)) {
        this.date = inputNgbDate;
      } else {
        this.date = this.datepickerService.getNgbDateFromDate(input as string | Date);
      }
    }
  }
  @Input('limitDate')
  set inputLimitDate(input: { fromDate: string | Date | NgbDate; toDate: string | Date | NgbDate }) {
    if (input) {
      const limitDate = {
        fromDate: null,
        toDate: null,
      };

      // set limit from date
      const limitNgbFromDate = input.fromDate as NgbDate;
      if (
        limitNgbFromDate &&
        !isNaN(limitNgbFromDate.year) &&
        !isNaN(limitNgbFromDate.month) &&
        !isNaN(limitNgbFromDate.day)
      ) {
        limitDate.fromDate = limitNgbFromDate;
      } else {
        limitDate.fromDate = this.datepickerService.getNgbDateFromDate(input.fromDate as string | Date);
      }

      // set limit to date
      const limitNgbToDate = input.toDate as NgbDate;
      if (limitNgbToDate && !isNaN(limitNgbToDate.year) && !isNaN(limitNgbToDate.month) && !isNaN(limitNgbToDate.day)) {
        limitDate.toDate = limitNgbToDate;
      } else {
        limitDate.toDate = this.datepickerService.getNgbDateFromDate(input.toDate as string | Date);
      }

      // set limit date to this.limitDate
      this.limitDate = limitDate;
    }
  }
  @Input()
  ownFormGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  isRequired = false;
  // TODO: Dick -> check
  @Input()
  disableSameDay = false;
  @Input()
  matIcon: string;
  @Input()
  iconOnly: boolean;
  @Input()
  iconName: string;
  @Input()
  position: 'top' | 'bottom' = 'bottom';

  date: NgbDate;
  limitDate: {
    fromDate: NgbDate;
    toDate: NgbDate;
  } = { fromDate: null, toDate: null };
  nowInputEle = null;
  isCalendarOpen = false;
  canOpenCalendar: boolean;

  @ViewChild('datepicker')
  datepicker: NgbInputDatepicker;

  @Output()
  selectEvent: EventEmitter<string> = new EventEmitter();
  @Output()
  openEvent: EventEmitter<boolean> = new EventEmitter();
  @Output()
  closeEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private datepickerService: DatepickerService,
    public datepickerConfig: NgbInputDatepickerConfig,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    if (!(!!this.ownFormGroup && !!this.controlName)) {
      const validators = this.isRequired ? [Validators.required] : [];
      this.ownFormGroup = this.fb.group({
        date: this.fb.control('', validators),
      });
      this.controlName = 'date';
    } else {
      this.date = this.datepickerService.getNgbDateFromDate(this.control.value);
    }
    this.addFormGroupListener();
  }

  addFormGroupListener() {
    this.control.valueChanges.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((res) => {
      if (res !== this.datepickerService.getDateStrFromNgbDate(this.date)) {
        const date = this.datepickerService.getNgbDateFromDate(res);
        this.date = date;
        this.doAfterSelected(date);
      }
    });
  }

  startInput(event: any) {
    const currentTarget = event.currentTarget;
    this.nowInputEle = currentTarget;
    if (this.canOpenCalendar) {
      // prevent input focus issue
      this.openCalendar(currentTarget);
    }
    this.canOpenCalendar = true;
  }

  endInput(event: any, type: string) {
    this.nowInputEle = null;
    let value = parseInt(event.currentTarget.value, 10);
    if (type === 'year') {
      const autoFullYear = this.datepickerService.getAutoFullYear(`${value}`);
      value = parseInt(autoFullYear, 10);
    }
    if (!isNaN(value) && value !== this.date[type]) {
      const date = Object.assign({}, this.date);
      date[type] = value;
      this.date = date;
    }
  }

  checkInputValue(event: KeyboardEvent) {
    const TAB_KEY = 'Tab';
    const DELETE_KEY = 'Backspace';
    const LEFT_ARROW_KEY = 'ArrowLeft';
    const RIGHT_ARROW_KEY = 'ArrowRight';
    const key = event.key;
    const numberKey = parseInt(key, 10);
    event.returnValue =
      (!isNaN(numberKey) && numberKey >= 48 && numberKey <= 57) ||
      key === TAB_KEY ||
      key === DELETE_KEY ||
      key === LEFT_ARROW_KEY ||
      key === RIGHT_ARROW_KEY;
  }

  setAutoClose() {
    // this.datepickerConfig.autoClose = true;
  }

  toggleCalendar(): void {
    this.nowInputEle = null;
    const datepicker = this.datepicker;
    const isOpen = datepicker.isOpen();
    if (!isOpen) {
      this.doAfterOpen();
    }
    if (datepicker) {
      datepicker.toggle();
    }
  }

  openCalendar(inputEle?: any): void {
    const datepicker = this.datepicker;
    datepicker.open();
    this.doAfterOpen();
    setTimeout(() => {
      if (inputEle && inputEle.focus) {
        inputEle.focus();
      }
    });
  }

  closeCalendar(): void {
    const datepicker = this.datepicker;
    if (datepicker) {
      datepicker.close();
    }
  }

  doAfterOpen() {
    this.isCalendarOpen = true;
    this.openEvent.emit(true);
  }

  doAfterClosed(): void {
    this.isCalendarOpen = false;
    const nowInputEle = this.nowInputEle;
    if (nowInputEle) {
      this.openCalendar(nowInputEle);
    }
    this.closeEvent.emit(true);
  }

  doAfterSelected(date: NgbDate) {
    const dateStr = this.datepickerService.getDateStrFromNgbDate(date);
    this.date = date;
    this.selectEvent.emit(dateStr);
    if (this.control.value !== dateStr) {
      this.control.setValue(dateStr);
    }
  }

  isDisable(date: NgbDate): boolean {
    const fromDate: NgbDate = this.limitDate.fromDate;
    const toDate: NgbDate | null = this.limitDate.toDate;

    if (
      toDate !== null &&
      toDate !== undefined &&
      toDate.year !== null &&
      toDate.month !== null &&
      toDate.day !== null
    ) {
      // Check if the date is before the fromDate or after the toDate
      if (date.before(fromDate) || date.after(toDate)) {
        return true;
      }
    } else {
      // Check if the date is before the fromDate
      if (date.before(fromDate)) {
        return true;
      }
    }

    return false;
  }

  isSelected(date: NgbDate): boolean {
    let result = false;
    if (!!date) {
      const selectedDate = this.datepickerService.newNgbDate(this.date);
      if (selectedDate) {
        result = date.equals(selectedDate);
      }
    } else {
      result = false;
    }
    return result;
  }

  get control() {
    return this.ownFormGroup.get(this.controlName);
  }
}
