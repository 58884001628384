import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PolicyService, UserService } from '@services';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PoliciesEnum } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReadOnlyAccessGuard implements CanActivate, CanActivateChild {
  constructor(private userService: UserService, private router: Router, private policyService: PolicyService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkReadOnlyPermission();
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkReadOnlyPermission();
  }

  private checkReadOnlyPermission(): Observable<boolean> {
    return this.userService.currentUser$.pipe(
      take(1),
      map(() => {
        const isReadOnly = this.userService.isReadOnlyAccess();
        const canRunAdWhenReadOnly = this.policyService.checkPolicyPermission(PoliciesEnum.readOnlyRunAnAd);
        const canAccess = !isReadOnly || (isReadOnly && canRunAdWhenReadOnly);
        const isZEEView = this.userService.isZEEView();
        if (!canAccess) {
          if (isZEEView) {
            this.router.navigateByUrl('/app/home');
          } else {
            this.router.navigateByUrl('/app/performance-overview');
          }
        }
        return canAccess;
      }),
    );
  }
}
