import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignService } from '../../../core/services/campaign/campaign.service';

@Component({
  selector: 'app-campaign-feedback-dialog',
  templateUrl: './campaign-feedback-dialog.component.html',
  styleUrls: ['./campaign-feedback-dialog.component.scss'],
})
export class CampaignFeedbackDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  isLoading = true;
  currentPage = 1;
  pageSize = 10;
  template: {
    name: string;
    channel: string;
    rate: number;
    rateCount: number;
  };
  adReviews: {
    rate: number;
    comment: string;
    locationName: string;
  }[];

  constructor(
    private campaignService: CampaignService,
    private dialogRef: MatDialogRef<CampaignFeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
  ) {}

  ngOnInit() {
    this._initDialogData();
  }

  _initDialogData(): void {
    const dialogData = this.dialogData;
    if (dialogData) {
      const templateId = dialogData.templateId;
      this.getCampaignsByTemplateId(templateId);
    }
  }

  getCampaignsByTemplateId(templateId: number) {
    if (templateId) {
      this.campaignService
        .getCampaignTemplateFeedback(templateId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((campaignTemplate) => {
          this.getCampaignTemplateReviews(campaignTemplate);
          this.isLoading = false;
        });
    }
  }

  private getCampaignTemplateReviews(campaignTemplate): void {
    const templateInfo = {
      name: campaignTemplate.campaignTemplateNme,
      channel: campaignTemplate.campaignChannelNme,
      rate: campaignTemplate.campaignTemplateRateFeedback?.averageRate,
      rateCount: campaignTemplate.campaignTemplateRateFeedback?.rateCount,
    };
    this.template = Object.assign({}, templateInfo);
    this.adReviews = campaignTemplate.campaignTemplateFeedback?.map((i) => {
      return {
        rate: i.rate,
        comment: i.comment,
      };
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
