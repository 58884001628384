import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ValidationsService } from '@services';
import { debounceTime } from 'rxjs/operators';
import { toString, find, filter } from 'lodash';

@Component({
  selector: 'app-ui-dropdown',
  templateUrl: './ui-dropdown.component.html',
  styleUrls: ['./ui-dropdown.component.scss'],
})
export class UiDropdownComponent implements OnInit, OnChanges {
  @Input()
  options = [];
  @Input()
  width: string;
  @Input()
  style = {};
  @Input()
  formGroup: FormGroup;
  @Input()
  uiFormGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  selected: string | number;
  @Input()
  placeholder: string;
  @Input()
  disabled = false;
  @Input()
  emitOptionData = false;
  @Input()
  noSelectIcon = false;
  @Input()
  textAlign = 'left';
  @Input()
  tabindex: number;
  @Input()
  invalid: boolean;
  @Input()
  withDeselect: boolean;

  @Output()
  changeEvent: EventEmitter<string | number> = new EventEmitter();

  @ViewChild('myDrop')
  myDrop: NgbDropdown;
  @ViewChild('optionsContainer')
  optionsContainer: ElementRef;

  isMobile = false;
  dropDownStartY = null;
  dropDownStartTop = 0;
  moveTop = 0;

  constructor(private validationsService: ValidationsService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this._initStyle();
    this._initForm();
    this.getDeviceInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._initStyle();
  }

  private _initForm(): void {
    let formGroup = this.dropDownFormGroup;
    let controlName = this.controlName;
    const defaultValue = this._selected;
    if (!formGroup || !controlName) {
      const defaultControl = {
        defaultControl: new FormControl({ value: defaultValue, disabled: this._disabled }, []),
      };
      formGroup = new FormGroup(defaultControl);
      controlName = 'defaultControl';
    }

    this.formGroup = formGroup;
    this.controlName = controlName;
    this._addValueChangeListener();
  }

  private _initStyle(): void {
    const width = this.width;
    let style = this.style;
    if (!!width) {
      style = Object.assign({}, this.style, { width });
    }
    this.style = style;
  }

  private getDeviceInfo() {
    const deviceInfoStr = localStorage.getItem('deviceInfo');
    if (deviceInfoStr) {
      const deviceInfo = JSON.parse(deviceInfoStr);
      this.isMobile = deviceInfo.mobile;
    }
  }

  get buttonText() {
    const selected = this._selected;
    let result = this.placeholder || 'Please Select...';
    if (!!selected || selected === 0) {
      const selectOption = this.options.find((item) => item.value + '' === selected + '');
      if (!!selectOption) {
        result = selectOption.text;
      }
    }
    return result;
  }

  get buttonTip() {
    const selected = this._selected;

    if (selected) {
      const selectedOption = find(this.options, (option) => {
        return String(option.value) === String(selected);
      });

      if (selectedOption && (selectedOption.tip || selectedOption.text)) {
        return selectedOption.tip || selectedOption.text;
      }
    }

    return '';
  }

  toggleDropdown(event: MouseEvent): void {
    const myDrop = this.myDrop;
    if (!!myDrop && !this._disabled) {
      event.stopPropagation();
      const control = this._formControl;
      if (!!control && !control.touched) {
        control.markAsTouched();
      }
      myDrop.toggle();
    }
  }

  doSelect(value: string): void {
    if (value === this.selected) {
      return this._setValue(null);
    }

    this._setValue(value, this.returnPreView);
  }

  private _setValue(value: string, callback?: (param?: any) => void): void {
    const preValue = this._selected;
    const outputOption = find(this.options, (option) => {
      return toString(option.value) === toString(value);
    });
    const outputValue = outputOption ? outputOption.value : '';
    this.selected = outputValue;
    const control = this._formControl;
    if (!!control) {
      control.setValue(outputValue);
    }
    this.changeEvent.emit(this.emitOptionData ? outputOption : outputValue);
    if (!!callback) {
      callback.call(this, preValue);
    }
    // TODO: this is not a really good thing, needs to be refactored
    // tslint:disable-next-line:max-line-length
    // https://betterprogramming.pub/expressionchangedafterithasbeencheckederror-in-angular-what-why-and-how-to-fix-it-c6bdc0b22787
    this.cdr.detectChanges();
  }

  returnPreView(preValue: string): void {
    const errorMessage = this.errorMessage;
    if (!!errorMessage) {
      this._setValue(preValue);
    }
  }

  private get _formControl(): AbstractControl {
    return this.dropDownFormGroup.get(this.controlName);
  }

  private _addValueChangeListener(): void {
    const control = this._formControl;
    control.valueChanges.pipe(debounceTime(50)).subscribe((value) => {
      this.selected = value;
      this.disabled = control.disabled;
    });
  }

  get _disabled(): boolean {
    const showOptions = this.showOptions;
    return this.disabled || !(!!showOptions && showOptions.length > 0);
  }

  get errorMessage(): string {
    return this.validationsService.getValidationErrorMessage(this._formControl);
  }

  get isShowError(): boolean {
    const formControl = this._formControl;
    if (!!formControl) {
      return !!this.errorMessage && (formControl.dirty || formControl.touched);
    }
    return false;
  }

  get _selected(): string | number {
    const formGroup = this.dropDownFormGroup;
    const controlName = this.controlName;
    if (!!this.selected || this.selected === 0) {
      return this.selected + '';
    } else if (!!formGroup && !!controlName) {
      return formGroup.get(controlName).value;
    }
    return '';
  }

  get showOptions(): Array<any> {
    const selected = this._selected;

    if (this.withDeselect) {
      return this.options;
    }

    return filter(this.options, (option) => {
      return toString(option.value) !== toString(selected);
    });
  }

  isSelected(value: string): boolean {
    return value + '' === this._selected + '';
  }

  get hasSelected() {
    return !!this._selected || this._selected === 0;
  }

  get dropDownFormGroup() {
    return this.formGroup || this.uiFormGroup;
  }

  startMobileTouch(event: any) {
    const touches = event.touches;
    if (touches.length > 0) {
      const touch = touches[0];
      this.dropDownStartY = touch.clientY;
      this.dropDownStartTop = this.moveTop;
    }
  }

  startMobileMove(event: any) {
    const optionsContainerHeight = this.optionsContainer.nativeElement.clientHeight;
    const touches = event.touches;
    if (touches.length > 0) {
      const touch = touches[0];
      const dropDownMoveY = touch.clientY;
      let moveTop = this.dropDownStartTop + this.dropDownStartY - dropDownMoveY;
      if (moveTop < 0 || optionsContainerHeight < 150) {
        moveTop = 0;
      } else if (moveTop + 150 > optionsContainerHeight) {
        moveTop = optionsContainerHeight - 150;
      }
      this.moveTop = moveTop;
    }
  }
}
