export interface Role {
  rbacRoleId: string;
  rbacRoleNme: string;
  rbacRoleDsc: string;
  accessPolicyIds: number[];
  denyPolicyIds: number[];
  naPolicyIds: number[];
}

export interface Policy {
  rbacPolicyId: string;
  rbacPolicyNme: string;
  rbacPolicyDsc: string;
  accessRoles: Role[];
  denyRoles: Role[];
  naRoles: Role[];
}

export interface PolicyList {
  count: number;
  policies: Policy[];
}

export interface QueryPolicyInput {
  pageNum: number;
  pageSize: number;
  text?: string;
  asc?: boolean;
  roleIds?: string[];
}

export interface UpdatePolicyInput {
  policyId: string;
  accessRoleIds?: string[];
  denyRoleIds?: string[];
}

export enum PoliciesEnum {
  createZorAccount = 1,
  createZeeAccountOnAdmin = 2,
  createZeeAccountOnLocationMgmt = 3,
  deactivateZorAccounts = 4,
  deactivateZeeAccountsOnAdmin = 5,
  deactivateZeeAccountsOnLocationMgmt = 6,
  deleteZeeAccounts = 7,
  changeReadOnlyStatus = 8,
  readOnlyCreateAndManageTemplates = 9,
  readOnlyRunAnAd = 10,
  readOnlyViewTemplates = 11,
  readOnlyViewAdResults = 12, // zee ad results list when read only
  readOnlyReviewTemplates = 13,
  readOnlyManageAdAccounts = 14,
  editZorAccountsOnAdmin = 15,
  editZorAccountsOnAccount = 16,
  editZeeAccountsOnAdmin = 17,
  editZeeAccountsOnAccount = 18,
  editZeeInfosOnLocationMgmt = 19,
  manageZeeAccounts = 20,
  manageAdminUsers = 21,
  viewAllAndToggleViewType = 22,
  createAndManageAdTemplates = 23,
  manageAdAccounts = 24,
  // delete ===> createManageLocationTags = 25, duplicated with 44
  runANewAd = 26,
  navLocalAdResults = 27, // ad results detail page
  viewAggregateNetworkAdResults = 28, // zor results -> aggregate results tab
  viewFranchiseWideAdResults = 29, // zor template metric -> click template -> view detail
  createAndManageSubscriptions = 30,
  runAdsOnBehalfOfOthers = 31,
  navBetweenAccounts = 32,
  reviewAdTemplate = 33,
  requestNewAdTemplate = 34,
  viewAdTemplateRequests = 35,
  respondToTemplateRequests = 36,
  addNewAdminUser = 37,
  addNewZorUser = 38, // same new zee user
  addNewZeeUser = 39,
  // delete ===> zeeRequestingTemplate = 40, duplicated with 34
  // delete ===> zorResponseTemplateRequest = 41, duplicated with 36
  manageObjectives = 42,
  manageKeywords = 43,
  manageLocationLabels = 44,
  editPolicyTable = 45,
  viewPolicyTable = 46,
  navAgencyPortal = 47,
  editAZorAdForAZor = 48, // need new field
  editAZorAdForAZee = 49, // need new field
  editAZeeAdForAZee = 50, // need new field
  viewRunButtonOnTemplateCreate = 51,
  navZorPerformanceOverview = 52,
  navZorCreateANewTemplate = 53,
  navZorRunANewAd = 54,
  navManageAdTemplates = 55,
  navLocationManagement = 56,
  navZorAccount = 57,
  navAdResultsAggregatedMetrics = 58, // zor results page
  viewZorNotificationsModal = 59,
  viewZorLatestActivityModal = 60,
  viewZorTopLocationAdSpendModal = 61,
  viewAdResultsTemplateAdMetrics = 62, // zor results -> template metrics tab
  viewAdResultsManageMyAds = 63, // zor results -> manage my ads tab
  navAdSpend = 64,
  navUserManagement = 65,
  navManageTags = 66,
  navZeePerformanceOverview = 67,
  navZeeRunANewAd = 68,
  navZeeAdResults = 69,
  navZeeAccount = 70,
  navManageSubscriptions = 71,
  viewZeeNotificationsModal = 72,
  viewZeeNetworkActivityModal = 73,
  navFaq = 74,
  navSMBPerformanceOverview = 75,
  navSMBCreateANewTemplate = 76,
  navAdminPortal = 77,
}

export enum UserRoleType {
  ZOWI = 1,
  ZOLO,
  ZEWI,
  ZELO,
  AGENCY,
  STAFF,
  ROOT,
  SILO,
}
