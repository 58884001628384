<aside class="side-nav">
  <div *ngIf="canToggleViewType" class="view-dropdown-container">
    <div class="view-dropdown-title">View Mode</div>
    <app-ui-dropdown
      [options]="viewTypes"
      [selected]="currentViewType"
      [width]="'100%'"
      (changeEvent)="changeCurrentZorViewType($event)"
    >
    </app-ui-dropdown>
  </div>
  <nav>
    <ng-container *ngFor="let navItem of navigationList">
      <div class="nav-item">
        <ng-container *ngIf="!navItem?.newApp; else hrefLink">
          <a class="nav-link" [class.active]="navItem.isActive" [routerLink]="navItem.navPath" (click)="navClick(navItem)">
            {{ navItem.navName }}
            <ng-template [ngIf]="navItem.navId === 'home' && !!notificationCount">
              <span class="alert-icon">{{ notificationCount }}</span>
            </ng-template>
          </a>
        </ng-container>
        <ng-template #hrefLink>
          <a class="nav-link" [class.active]="navItem.isActive" [href]="navItem.navPath" (click)="navClick(navItem)">
            {{ navItem.navName }}
            <ng-template [ngIf]="navItem.navId === 'home' && !!notificationCount">
              <span class="alert-icon">{{ notificationCount }}</span>
            </ng-template>
          </a>
        </ng-template>
      </div>
    </ng-container>
  </nav>
</aside>
