<div class="flex" [class.flex-justify-center]="position === 'center'" [class.flex-justify-start]="['start', 'left'].includes(position)"
  [class.flex-justify-end]="['end', 'right'].includes(position)">
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="dialogCloseValue" [ngClass]="{
        'error-btn':error,
        'primary-btn':btnType ==='primary',
        'secondary-btn':btnType==='secondary',
        'round-btn':style==='round'
      }"
      [disabled]="disabled" [type]="type">
      <ng-content class="btn-text"></ng-content>
    </button>
  </mat-dialog-actions>
</div>
