import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { Auth0Service } from '@services';

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    /**
     * Inject re-implemented auth service instead of auth0 built-in service
     * to fix Google Account connection
     */
    {
      provide: AuthService,
      useClass: Auth0Service,
    },
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    AuthModule.forRoot({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_CLIENT_ID,
      audience: `https://${environment.AUTH0_DOMAIN}/api/v2/`,
      redirect_uri: window.location.origin,
      httpInterceptor: {
        allowedList: [
          `${environment.API_SERVER_URL}*`,
          `${environment.USER_API_URL}*`,
          `${environment.BILLING_API_URL}*`,
          `${environment.REPORTING_API_URL}*`,
          `${environment.ORGANIZATION_API_URL}*`,
        ],
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
