<div class="message-dialog-container">
  <span
    *ngIf="dialogType === 'loading'"
    class="close-icon dialog-close-icon"
    (click)="closeDialog()">
  </span>
  <div *ngIf="dialogType === 'error'" class="flex-row flex-hor-start mt-s">
    <div class="img-container">
      <img [src]="dialogImage || 'assets/icons/fill/load-failed.png'" alt="failed">
    </div>
    <div class="ml-s">
      <p class="error-tip">Oops...</p>
      <p class="text-medium">Something went wrong.</p>
      <p class="mb-s">Please try again later.</p>
      <app-ui-button [position]="'left'" (clickEvent)="closeDialog()">
        Go Back
      </app-ui-button>
    </div>
  </div>
  <div *ngIf="dialogType === 'loading'" class="message-container">
    <mat-spinner></mat-spinner>
    <p class="loading-tip">
      Hang on, still trying...
    </p>
  </div>
</div>
