import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { UserService, UserContextService, AppConfigService } from '@services';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { AuthService as Auth0 } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { IUser } from '@interfaces';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly apollo: Apollo,
    private readonly userService: UserService,
    private readonly userContext: UserContextService,
    private readonly googleAnalytics: GoogleAnalyticsService,
    private readonly auth0: Auth0,
    private readonly appConfig: AppConfigService,
  ) {}

  public loginWithRedirect() {
    this.userContext.remove();
    this.apollo.client.clearStore();

    this.auth0.loginWithRedirect({
      redirect_uri: `${window.location.origin}`,
      prompt: 'login',
    });
  }

  public silentLogin(relativeRedirectUrl: string = '/') {
    this.userContext.remove();
    this.apollo.client.clearStore();

    // Re-authenticate user or redirect user to login page
    this.auth0.loginWithRedirect({
      appState: {
        target: relativeRedirectUrl,
      },
    });
  }

  public logout() {
    const user = this.userService.getCachedUserProfile();
    if (user) {
      this.googleAnalytics.eventTracking('User Profile', 'Logged out', user.userId);
    }
    this.userService.userProfile = null;
    this.userService.currentUser$.next(null);
    this.userContext.remove();
    this.apollo.client.clearStore();

    // Logout and redirect to logout endpoint in New App
    // for synchronization
    const redirectUrl = this.appConfig.logoutUrl;
    this.auth0.logout({
      returnTo: redirectUrl,
    });
  }

  public isLoading(): Observable<boolean> {
    return this.auth0.isLoading$;
  }

  public isAuthenticated(): Observable<boolean> {
    return this.auth0.isAuthenticated$;
  }

  public getUserProfile(): Observable<IUser | null> {
    return this.getUser().pipe(
      switchMap(() => {
        return this.userService.getUserProfile();
      }),
    );
  }

  private getUser(): Observable<unknown> {
    return this.auth0.user$;
  }
}
