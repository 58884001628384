<header id="app-header">
  <div class="header-row flex-row" [class.zee-portal]="!isZorUser" [class.header-scroll]="isScroll">
    <div class="header-left flex-row" id="headerLeft">
      <div *ngIf="!!userProfile" class="sidenav-toggle" (click)="toggleMenu()">
        <span>Menu</span>
      </div>
      <div class="header-branding flex-row" routerLink="/">
        <img class="logo" src="assets/icons/logo/adcorner_logo_primary.svg"  alt="AdCorner Logo"/>
      </div>
    </div>

    <div class="header-right flex-row" id="headerRight">
      <ng-template [ngIf]="isZorUser || isZeeUser || isLocationUser">
        <div id="textContent" class="show-gt-md">
          <div class="name-text">{{ orgName }}</div>
          <div *ngIf="!isZeeUser" class="network-info flex-row">
            <div class="info-label">
              <p>Locations Owned</p>
              <p>Network Size</p>
            </div>
            <div class="info-value">
              <p>{{ ownedLocationNum }}</p>
              <p>{{ networkSizeNum }}</p>
            </div>
          </div>
        </div>
      </ng-template>

      <div *ngIf="!!userProfile" class="avatar-container flex-row">
        <div
          class="default-container"
          [ngClass]="orgLogoClass"
          [class.scroll-view]="isScroll"
          [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
        >
          <img
            id="org-avatar"
            class="avatar"
            [appSignedUrl]="orgLogoUrl || 'assets/icons/fill/building_gray.svg'"
            alt="logo"
          />
        </div>
        <ng-template [ngIf]="!isZorUser">
          <app-ui-user-icon [name]="userName" [matMenuTriggerFor]="menu" class="cursor-pointer"></app-ui-user-icon>
        </ng-template>
      </div>

      <mat-menu #menu="matMenu" [overlapTrigger]="false" class="user-menu box-shadow" xPosition="before">
        <div *ngFor="let nav of dropdownNavs">
          <ng-container *ngIf="!nav?.newApp; else hrefLink">
            <a mat-menu-item [routerLink]="nav.navPath" [class.active]="nav.isActive" (click)="navToPage(nav)">
              {{ nav.navName }}
            </a>
          </ng-container>
          <ng-template #hrefLink>
            <a mat-menu-item [href]="nav.navPath" [class.active]="nav.isActive" (click)="navToPage(nav)">
              {{ nav.navName }}
            </a>
          </ng-template>
        </div>
      </mat-menu>
    </div>
  </div>
</header>

<div *ngIf="headerBarMessages.length > 0" class="header-notification-container">
  <ng-template *ngTemplateOutlet="notificationBody; context:{ $implicit: headerBarMessages[nowBarMessageIndex] }">
  </ng-template>
</div>

<ng-template #notificationBody id="token-expired-notification" class="header-notification" let-notification>
  <ng-container [ngSwitch]="notification.category">
    <ng-container *ngSwitchCase="'billingUnset'">
      <p class="normal-notification">
        To finalize your registration, kindly proceed to the
        <a [href]="getBillingPageUrl()">billing page</a>
        to set up your payment method.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="'accountDisconnect'">
      <p class="normal-notification">
        {{ notification.msg }}
        <span *ngIf="canNavToAccount && !isLocationUser" class="notification-action" (click)="gotoAccountPage()">
          <strong>Click here to reconnect.</strong>
        </span>
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="'paymentUnset'">
      <p class="normal-notification">
        {{ notification.msg }} Please follow these
        <a class="notification-action" href="https://www.facebook.com/business/help/132073386867900" target="_blank">
          <strong>instructions</strong>
        </a>
        to get connected.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="'pausedAd'">
      <p class="normal-notification">
        {{ notification.msg }}
        <strong>{{ notification.adName }}&nbsp;-&nbsp;</strong>
        <span class="notification-action" (click)="gotoResultPage(notification.adId)" *ngIf="canNavToAdResults">
          <strong>Click here to resume.</strong>
        </span>
      </p>
    </ng-container>
    <div class="header-notification-nav">
      <button *ngIf="nowBarMessageIndex !== 0" type="button" class="" (click)="messageChange(-1)">
        <img [src]="previousIconPath" alt="previous">
      </button>
      {{ nowBarMessageIndex + 1 }} OF {{ headerBarMessages.length }}
      <button *ngIf="nowBarMessageIndex !== headerBarMessages.length - 1" type="button" class=""
              (click)="messageChange(1)">
        <img [src]="nextIconPath" alt="next">
      </button>
    </div>
  </ng-container>
</ng-template>
