import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export type AdListFilterType = 'channel' | 'locations' | 'timePeriod' | 'datePicker';

@Injectable({
  providedIn: 'root',
})
export class AdListService {
  private changeFilter$: ReplaySubject<{ type: AdListFilterType; value: any }> = new ReplaySubject(4);

  constructor() {}

  updateFilter(type: AdListFilterType, value): void {
    this.changeFilter$.next({ type, value });
  }

  onFilterChange(): ReplaySubject<{ type: AdListFilterType; value: any }> {
    return this.changeFilter$;
  }

  resetFilter(): void {
    this.changeFilter$.next(null);
  }
}
