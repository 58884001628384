import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-list-dialog',
  templateUrl: './notification-list-dialog.component.html',
  styleUrls: ['./notification-list-dialog.component.scss'],
})
export class NotificationListDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<NotificationListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { contentType: string; content: Array<any> },
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.dialogRef.close();
  }
}
