/* tslint:disable */
export interface NetworkError extends Error {}
/* tslint:enable */

export interface NetworkErrorCtor {
  new (msg?: string): NetworkError;
  data: any;
}

function NetworkErrorImpl(this: any, msg: string) {
  Error.call(this);
  this.message = msg || 'network error occurred.';
  this.name = 'NetworkError';
  return this;
}

NetworkErrorImpl.prototype = Object.create(Error.prototype);

export const NetworkError: NetworkErrorCtor = NetworkErrorImpl as any;
