import { Injectable } from '@angular/core';
import { ViewType } from '@interfaces';

export type UserContext = {
  organizationId?: string | null;
  locationId?: string | null;
  userCurView?: ViewType | null;
};

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  private readonly STORAGE_NAME = 'managedUserInfo';

  constructor() {}

  setContext(context: UserContext): void {
    localStorage.setItem(this.STORAGE_NAME, JSON.stringify(context));
  }

  getContext(): UserContext {
    const emptyContext: UserContext = {
      organizationId: null,
      locationId: null,
      userCurView: null,
    };

    const storedContext = localStorage.getItem(this.STORAGE_NAME);
    if (!storedContext) {
      return emptyContext;
    }

    try {
      const context: UserContext = JSON.parse(storedContext);
      return {
        organizationId: context?.organizationId ?? emptyContext.organizationId,
        locationId: context?.locationId ?? emptyContext.locationId,
        userCurView: context?.userCurView ?? emptyContext.userCurView,
      };
    } catch (e) {
      return emptyContext;
    }
  }

  remove(): void {
    localStorage.removeItem(this.STORAGE_NAME);
  }
}
