import { gql } from 'apollo-angular';
import { campaignTemplateMainContentFragment } from '../campaign/campaign.gql';

export const adSubscriptionFragment = gql`
  fragment adSubscription on AdSubscriptionType {
    adSubscriptionId
    adSubscriptionNme
    adSubscriptionChannel
    adSubscriptionGoal
    adSubscriptionPerformMetric
    adSubscriptionWeeklyBudget
    adSubscriptionRunTims
    adSubscriptionStartDte
    adSubscriptionCreatedAt
    adSubscriptionUpdatedAt
    adSubscriptionAssignedLocations
    adSubscriptionActiveInd
    campaignTemplate {
      ...campaignTemplateMainInfo
    }
  }
  ${campaignTemplateMainContentFragment}
`;

export const createAdSubscription = gql`
  mutation createAdSubscription($input: AdSubscriptionInput!) {
    createAdSubscription(adSubscription: $input)
  }
`;

export const updateAdSubscription = gql`
  mutation updateAdSubscription($adSubscriptionId: ID, $updates: AdSubscriptionInput!) {
    updateAdSubscription(adSubscriptionId: $adSubscriptionId, updates: $updates)
  }
`;

export const adSubscriptionById = gql`
  query adSubscription($id: ID!) {
    adSubscriptionById(id: $id) {
      ...adSubscription
    }
  }
  ${adSubscriptionFragment}
`;

export const adSubscriptionsByUser = gql`
  query adSubscriptions {
    adSubscriptionsByUserEmail {
      ...adSubscription
    }
  }
  ${adSubscriptionFragment}
`;

export const findAvailableTemplatesByUser = gql`
  query findAvailableTemplatesByUser($adSubscriptionInput: AdSubscriptionInput!) {
    findAvailableTemplatesByUser(params: $adSubscriptionInput) {
      ...campaignTemplateMainInfo
    }
  }
  ${campaignTemplateMainContentFragment}
`;
