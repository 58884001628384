import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule, BREAKPOINT } from '@angular/flex-layout';
import { NguCarouselModule } from '@ngu/carousel';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

// material modules
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

const MatModules = [
  MatDialogModule,
  MatIconModule,
  MatCheckboxModule,
  MatCardModule,
  MatSliderModule,
  MatNativeDateModule,
  MatSelectModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatRadioModule,
  MatTooltipModule,
  MatButtonToggleModule,
  MatProgressBarModule,
  MatRippleModule,
  MatButtonModule,
  MatChipsModule,
  MatMenuModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatMomentDateModule,
];

// video player modules
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

const VideoPlayerModules = [VgCoreModule, VgOverlayPlayModule, VgControlsModule, VgBufferingModule];

import { dialogsList } from './dialogs';
import { componentsList } from './components';
import { directivesList } from './directives';
import { pipesList } from './pipes';
import { widgetsList, TextareaWithChipDataHandleService } from './widgets';

const CUSTOM_BREAK_POINTS = [
  {
    alias: 'sm.screen',
    suffix: 'SmScreen',
    mediaQuery: 'screen and (max-width: 768px)',
    priority: 1000,
  },
  {
    alias: 'md.screen',
    suffix: 'MdScreen',
    mediaQuery: 'screen and (min-width: 769px)',
    priority: 1000,
  },
];
const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAK_POINTS,
  multi: true,
};

@NgModule({
  declarations: [...dialogsList, ...componentsList, ...directivesList, ...pipesList, ...widgetsList],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ...MatModules,
    ...VideoPlayerModules,
    NguCarouselModule,
    NgxChartsModule,
    NgxDatatableModule,
    NgxPaginationModule,
    NgbModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxChartsModule,
    NgxDatatableModule,
    NgxPaginationModule,
    NgbModule,
    ...MatModules,
    ...VideoPlayerModules,
    ...componentsList,
    ...directivesList,
    ...pipesList,
    ...widgetsList,
  ],
  providers: [CustomBreakPointsProvider, NgbDropdownConfig, TextareaWithChipDataHandleService],
})
export class SharedModule {}
