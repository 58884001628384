import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { GraphQLModule } from './graphql.module';
import { CustomErrorHandlerService } from './services';

@NgModule({
  declarations: [],
  imports: [CommonModule, LayoutModule, GraphQLModule],
  providers: [{ provide: ErrorHandler, useClass: CustomErrorHandlerService }],
})
export class CoreModule {}
