import { Injectable } from '@angular/core';
import * as gql from './channel.gql';
import { Apollo } from 'apollo-angular';
import { IChannelConnectivityStatus } from '@interfaces';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  constructor(private apollo: Apollo) {}

  getChannelConnectivityStatus(): Observable<IChannelConnectivityStatus> {
    return this.apollo
      .watchQuery<{ channelConnectivity: IChannelConnectivityStatus }>({
        fetchPolicy: 'network-only',
        query: gql.getChannelConnectivityStatus,
      })
      .valueChanges.pipe(
        map(({ data }) => data.channelConnectivity),
        catchError(() => of(null)),
      );
  }
}
