import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '@utils';
import { DetailedContentInterface, MessagePromptDialogDataInterface } from '@interfaces';
import { MessagePromptDialogComponent as PromptDialog } from 'src/app/shared/dialogs/message-prompt-dialog/message-prompt-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MessagePromptService {
  private dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog) {}

  static BuildNormalContentData(input: string | number): DetailedContentInterface {
    if (typeof input === 'string' || typeof input === 'number') {
      return { content: String(input), isNormal: true };
    }
  }

  static BuildLinkContentData(input: string | number, href?: string): DetailedContentInterface {
    if (typeof input === 'string' || typeof input === 'number') {
      return { content: String(input), isLink: true, href: href ? href : String(input) };
    }
  }

  static BuildRouterContentData(input: string | number, router: string[]): DetailedContentInterface {
    if (typeof input === 'string' || typeof input === 'number') {
      return { content: String(input), isRouter: true, routerString: router };
    }
  }

  showDialog(component: any, payload: any = {}, closeCb = Utils.Noop) {
    const defaultParams = {
      width: '90%',
      maxWidth: '30em',
      disableClose: true,
    };
    if (!payload) {
      payload = {};
    }
    if (!payload.data) {
      payload.data = { withClose: true, withIcon: true };
    } else {
      const tmp = Object.assign({}, { withClose: true, withIcon: true }, payload.data);
      Object.assign(payload.data, tmp);
    }
    const dialogParams = { ...defaultParams, ...payload };

    this.dialogRef = this.dialog.open(component, dialogParams);
    this.dialogRef.afterClosed().subscribe((response) => {
      this.dialogRef = null;
      if (closeCb) {
        closeCb(response);
      }
    });
  }

  displayInternalError(err, payload: MessagePromptDialogDataInterface, cb = Utils.Noop) {
    if (err && Array.isArray(err) && err.length > 0) {
      const msg = MessagePromptService.BuildNormalContentData(err[0].message);
      this.displayError({ data: { content: [[msg]] } }, cb);
      // } else if (err instanceof InternalError) {
    } else {
      if (payload && Array.isArray(payload.content) && payload.content.length) {
        this.showDialog(PromptDialog, { data: payload }, cb);
      }
    }
  }

  displayError(payload: any = {}, closeCb = Utils.Noop) {
    this.showDialog(PromptDialog, payload, closeCb);
  }

  displaySuccess(payload: any = {}, closeCb = Utils.Noop) {
    if (!payload.data) {
      payload.data = {};
    }
    payload.data.isSucceed = true;
    payload.data.btnString = 'Okay';
    this.showDialog(PromptDialog, payload, closeCb);
  }

  buildErrorMessage(errors: { message: string }[]): { content: string; isNormal: boolean }[][] {
    const tmp = [];
    errors.forEach(({ message }) => {
      tmp.push([{ content: message, isNormal: true }]);
    });
    return tmp;
  }

  hasGraphqlError(error: any): boolean {
    return !!(error && error.graphQLErrors && error.graphQLErrors.length);
  }

  hasNetworkError(error: any): boolean {
    return !!(
      error &&
      error.networkError &&
      error.networkError.result &&
      error.networkError.result.errors &&
      error.networkError.result.errors.length
    );
  }

  httpErrorHandle(error: any, payload: MessagePromptDialogDataInterface) {
    if (this.hasGraphqlError(error)) {
      const messages = this.buildErrorMessage(error.graphQLErrors);
      this.displayError({ data: { content: messages } });
    } else if (this.hasNetworkError(error)) {
      const messages = this.buildErrorMessage(error.networkError.result.errors);
      this.displayError({ data: { content: messages } });
    } else {
      this.displayInternalError(error, payload);
    }
  }
}
