<div class="app-container" [class.sidebar-active]="isOpen" [class.scroll]="isScroll" [class.zee-portal]="!isZor">
  <app-page-sidebar
    class="sidenav"
    [userProfile]="userProfile"
    [navigationList]="pageNavigationList"
    (navigationClick)="onNavigationClick($event)">
  </app-page-sidebar>

  <app-page-header
    class="header"
    [userProfile]="userProfile"
    [dropdownNavs]="dropdownNavs"
    [ownedLocationNum]="ownedLocationNum"
    [networkSizeNum]="networkSizeNum"
    (toggleEvent)="isOpen = !isOpen"
    [isScroll]="isScroll"
  >
  </app-page-header>

  <main class="main" appPageScroll (scrollChange)="onScrollChange($event)">
    <router-outlet></router-outlet>
  </main>

  <app-page-footer class="footer"></app-page-footer>
  <app-page-loading></app-page-loading>
</div>
