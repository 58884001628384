import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgbInputDatepicker, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { DatepickerService } from '@services';
import * as moment from 'moment';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidationsService } from '@services';

enum SelectDateType {
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  NONE = '',
}

@Component({
  selector: 'app-ui-datepicker',
  templateUrl: './ui-datepicker.component.html',
  styleUrls: ['./ui-datepicker.component.scss'],
})
export class UiDatepickerComponent implements OnInit {
  private ngUnSubscribe$: Subject<any> = new Subject();

  @Input('fromDate')
  set inputFromDate(input: string | Date | NgbDate) {
    if (!this.fromDate && !this.ownFormGroup && input) {
      const inputNgbDate = input as NgbDate;
      if (inputNgbDate && !isNaN(inputNgbDate.year) && !isNaN(inputNgbDate.month) && !isNaN(inputNgbDate.day)) {
        this.fromDate = inputNgbDate;
      } else {
        this.fromDate = this.datepickerService.getNgbDateFromDate(input as string | Date);
      }
      this.emitChange();
    }
  }
  @Input('toDate')
  set inputToDate(input: string | Date | NgbDate) {
    if (!this.toDate && !this.ownFormGroup && input) {
      const inputNgbDate = input as NgbDate;
      if (inputNgbDate && !isNaN(inputNgbDate.year) && !isNaN(inputNgbDate.month) && !isNaN(inputNgbDate.day)) {
        this.toDate = inputNgbDate;
      } else if (inputNgbDate) {
        this.toDate = this.datepickerService.getNgbDateFromDate(input as string | Date);
      }
      this.emitChange();
    }
  }
  @Input('limitDate')
  set inputLimitDate(input: { fromDate: string | Date | NgbDate; toDate: string | Date | NgbDate }) {
    if (input) {
      const limitDate = {
        fromDate: null,
        toDate: null,
      };

      // set limit from date
      const limitNgbFromDate = input.fromDate as NgbDate;
      if (
        limitNgbFromDate &&
        !isNaN(limitNgbFromDate.year) &&
        !isNaN(limitNgbFromDate.month) &&
        !isNaN(limitNgbFromDate.day)
      ) {
        limitDate.fromDate = limitNgbFromDate;
      } else if (limitNgbFromDate) {
        limitDate.fromDate = this.datepickerService.getNgbDateFromDate(input.fromDate as string | Date);
      }

      // set limit to date
      const limitNgbToDate = input.toDate as NgbDate;
      if (limitNgbToDate && !isNaN(limitNgbToDate.year) && !isNaN(limitNgbToDate.month) && !isNaN(limitNgbToDate.day)) {
        limitDate.toDate = limitNgbToDate;
      } else if (limitNgbToDate) {
        limitDate.toDate = this.datepickerService.getNgbDateFromDate(input.toDate as string | Date);
      }

      // set limit date to this.limitDate
      this.limitDate = limitDate;
    }
  }
  @Input()
  disableSameDay = false;
  @Input()
  ownFormGroup: FormGroup;
  @Input()
  fromDateControlName;
  @Input()
  toDateControlName;
  @Input()
  width: string;
  @Input()
  maxWidth: string;
  @Input()
  disabled = false;
  @Input()
  position: 'top' | 'bottom' = 'bottom';

  fromDate: NgbDate;
  toDate: NgbDate;
  limitDate: {
    fromDate: NgbDate;
    toDate: NgbDate;
  } = { fromDate: null, toDate: null };
  hoveredDate: NgbDate = null;
  selectDateType: SelectDateType = SelectDateType.NONE;
  isCalendarOpen = false;
  nowInputEle = null;
  activeValidation = true;

  @ViewChild('datepicker', { static: true })
  datepicker: NgbInputDatepicker;

  @Output()
  selectEvent: EventEmitter<{
    fromDate: string;
    toDate: string;
  }> = new EventEmitter();
  @Output()
  openEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private datepickerService: DatepickerService,
    public datepickerConfig: NgbInputDatepickerConfig,
    private validationsService: ValidationsService,
  ) {}

  ngOnInit() {
    this.initFormGroup();
  }

  startInput(event: any, selectDateType: string) {
    const currentTarget = event.currentTarget;
    this.selectDateType = selectDateType as SelectDateType;
    if (this.nowInputEle !== currentTarget) {
      this.nowInputEle = currentTarget;
      this.openCalendar(currentTarget);
    }
    this.setDatePickerTouched(selectDateType);
  }

  endInput(event: any, type: string) {
    let value = parseInt(event.currentTarget.value, 10);
    if (type === 'year') {
      const autoFullYear = this.datepickerService.getAutoFullYear(`${value}`);
      value = parseInt(autoFullYear, 10);
    }
    const selectDateType = this.selectDateType;
    let changeDate;

    this.nowInputEle = null;
    // this.selectDateType = SelectDateType.NONE;

    // get date which need be changed.
    if (selectDateType === SelectDateType.FROM_DATE) {
      changeDate = this.fromDate;
    } else if (selectDateType === SelectDateType.TO_DATE) {
      changeDate = this.toDate;
    }

    // do change.
    if (!isNaN(value) && changeDate && value !== changeDate[type]) {
      const date = this.datepickerService.newNgbDate(changeDate);
      date[type] = value;
      if (
        !moment(this.datepickerService.getDateStrFromNgbDate(date)).isValid() ||
        this.isDisable(date, selectDateType)
      ) {
        this.setInvalidDate(selectDateType, this.datepickerService.newNgbDate(changeDate));
        return;
      }
      if (selectDateType === SelectDateType.FROM_DATE) {
        this.fromDate = date;
      } else if (selectDateType === SelectDateType.TO_DATE) {
        this.toDate = date;
      }
      if (selectDateType === SelectDateType.FROM_DATE || selectDateType === SelectDateType.TO_DATE) {
        this.emitChange();
      }
    }
  }

  setInvalidDate(selectDateType: SelectDateType, preDate: NgbDate) {
    // TODO: invalid or disabled date set by input.
    if (selectDateType === SelectDateType.FROM_DATE) {
      this.fromDate = null;
    } else if (selectDateType === SelectDateType.TO_DATE) {
      this.toDate = null;
    }
    setTimeout(() => {
      if (selectDateType === SelectDateType.FROM_DATE) {
        this.fromDate = preDate;
      } else if (selectDateType === SelectDateType.TO_DATE) {
        this.toDate = preDate;
      }
    });
  }
  // TODO refactor event.keyCode;
  checkInputValue(event: KeyboardEvent) {
    const TAB_KEY = 'Tab';
    const DELETE_KEY = 'Backspace';
    const LEFT_ARROW_KEY = 'ArrowLeft';
    const RIGHT_ARROW_KEY = 'ArrowRight';
    const key = event.key;
    const numberKey = parseInt(key, 10);
    event.returnValue =
      (!isNaN(numberKey) && numberKey >= 48 && numberKey <= 57) ||
      key === TAB_KEY ||
      key === DELETE_KEY ||
      key === LEFT_ARROW_KEY ||
      key === RIGHT_ARROW_KEY;
  }

  setAutoClose() {
    // this.datepickerConfig.autoClose = true;
  }

  toggleCalendar(selectDateType: string): void {
    this.nowInputEle = null;
    this.selectDateType = selectDateType as SelectDateType;
    const datepicker = this.datepicker;
    const isOpen = datepicker.isOpen();
    if (!isOpen) {
      this.doAfterOpen();
    }
    if (datepicker) {
      datepicker.toggle();
    }
  }

  openCalendar(inputEle?: any): void {
    const datepicker = this.datepicker;
    datepicker.open();
    this.doAfterOpen();
    this.openEvent.emit(true);
    setTimeout(() => {
      if (inputEle && inputEle.focus) {
        inputEle.focus();
      }
    });
  }

  closeCalendar(): void {
    const datepicker = this.datepicker;
    if (datepicker) {
      datepicker.close();
    }
  }

  doAfterOpen(): void {
    this.isCalendarOpen = true;
  }

  doAfterClosed(): void {
    const nowInputEle = this.nowInputEle;
    this.isCalendarOpen = false;
    if (nowInputEle) {
      this.openCalendar(nowInputEle);
    }
  }

  onDateSelection(date: NgbDate) {
    if (this.isDisable(date)) {
      return;
    }
    if (this.selectDateType === SelectDateType.FROM_DATE) {
      this.fromDate = date;
      if (!this.toDate) {
        this.selectDateType = SelectDateType.TO_DATE;
        // this.openCalendar();
      }
    } else if (this.selectDateType === SelectDateType.TO_DATE) {
      this.toDate = date;
      if (!this.fromDate) {
        this.selectDateType = SelectDateType.FROM_DATE;
      } else {
        this.closeCalendar();
      }
    }

    // emit selected date string
    this.emitChange();
  }

  emitChange() {
    let fromDateStr = '';
    let toDateStr = '';
    // init from and to date;
    if (this.fromDate) {
      fromDateStr = this.datepickerService.getDateStrFromNgbDate(this.fromDate);
    }
    if (this.toDate) {
      toDateStr = this.datepickerService.getDateStrFromNgbDate(this.toDate);
    }
    this.setFormGroupDateByOwnDate();
    this.selectEvent.emit({
      fromDate: fromDateStr,
      toDate: toDateStr,
    });
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isDisable(date: NgbDate, selectDateType?: SelectDateType): boolean {
    selectDateType = selectDateType || this.selectDateType;
    let result = false;
    if (!!date) {
      const limitFromDate = this.limitDate.fromDate;
      const limitToDate = this.limitDate.toDate;
      let fromDate = this.fromDate;
      const toDate = this.toDate;
      if (!!date) {
        switch (selectDateType) {
          case SelectDateType.FROM_DATE: {
            if (!!limitFromDate) {
              result = date.before(limitFromDate);
            }
            if (!result) {
              if (!!toDate && !!limitToDate) {
                if (toDate.before(limitToDate)) {
                  result = date.after(toDate);
                } else {
                  result = date.after(limitToDate);
                }
              } else if (!!toDate) {
                result = date.after(toDate);
              } else if (!!limitToDate) {
                result = date.after(limitToDate);
              }
            }
            break;
          }
          case SelectDateType.TO_DATE: {
            fromDate = this.disableSameDay
              ? this.datepickerService.getNgbDateFromDate(
                  moment(this.datepickerService.getDateStrFromNgbDate(fromDate)).add(1, 'day').toDate(),
                )
              : fromDate;
            if (!!limitToDate) {
              result = date.after(limitToDate);
            }
            if (!result) {
              if (!!fromDate && !!limitFromDate) {
                if (fromDate.after(limitFromDate)) {
                  result = date.before(fromDate);
                } else {
                  result = date.before(limitFromDate);
                }
              } else if (!!fromDate) {
                result = date.before(fromDate);
              } else if (!!limitFromDate) {
                result = date.before(limitFromDate);
              }
            }
            break;
          }
          default:
            break;
        }
      }
    } else {
      result = true;
    }
    return result;
  }

  private hasBothDateControl() {
    const formGroup = this.ownFormGroup;
    const fromDateControlName = this.fromDateControlName;
    const toDateControlName = this.toDateControlName;
    if (formGroup && fromDateControlName && toDateControlName) {
      const fromDateControl = formGroup.get(fromDateControlName);
      const toDateControl = formGroup.get(toDateControlName);
      if (fromDateControl && toDateControl) {
        return true;
      }
    }
    return false;
  }

  private get fromDateControl(): AbstractControl {
    const formGroup = this.ownFormGroup;
    const fromDateControlName = this.fromDateControlName;
    if (formGroup && fromDateControlName) {
      const fromDateControl = formGroup.get(fromDateControlName);
      if (fromDateControl) {
        return fromDateControl;
      }
    }
    return null;
  }

  private get toDateControl(): AbstractControl {
    const formGroup = this.ownFormGroup;
    const toDateControlName = this.toDateControlName;
    if (formGroup && toDateControlName) {
      const toDateControl = formGroup.get(toDateControlName);
      if (toDateControl) {
        return toDateControl;
      }
    }
    return null;
  }

  // start about formGroup ////////////////////////////////////

  initFormGroup() {
    if (this.hasBothDateControl()) {
      this.setBothDateByFormGroup();
      this.addFormGroupListener();
    }
  }

  setBothDateByFormGroup() {
    const fromDateControlValue = this.fromDateControl.value;
    const toDateControlValue = this.toDateControl.value;
    if (fromDateControlValue) {
      this.fromDate = this.datepickerService.getNgbDateFromDate(new Date(fromDateControlValue));
    }
    if (toDateControlValue) {
      this.toDate = this.datepickerService.getNgbDateFromDate(new Date(toDateControlValue));
    }
  }

  setFromDateByFormGroup() {
    this.fromDate = this.datepickerService.getNgbDateFromDate(new Date(this.fromDateControl.value));
  }

  setToDateByFormGroup() {
    this.toDate = this.datepickerService.getNgbDateFromDate(new Date(this.toDateControl.value));
  }

  addFormGroupListener(): void {
    this.fromDateControl.valueChanges.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((value) => {
      if (!value) {
        this.fromDate = null;
      } else {
        const newDate = this.datepickerService.getNgbDateFromDate(new Date(value));
        if (!this.fromDate.equals(newDate)) {
          this.setFromDateByFormGroup();
        }
      }
    });
    this.toDateControl.valueChanges.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((value) => {
      if (!value) {
        this.toDate = null;
      } else {
        const newDate = this.datepickerService.getNgbDateFromDate(new Date(value));
        if (!this.fromDate.equals(newDate)) {
          this.setToDateByFormGroup();
        }
      }
    });
  }

  setFormGroupDateByOwnDate() {
    if (this.hasBothDateControl()) {
      const fromDateControl = this.fromDateControl;
      const toDateControl = this.toDateControl;
      const newFromDate = this.datepickerService.getDateStrFromNgbDate(this.fromDate);
      const newToDate = this.datepickerService.getDateStrFromNgbDate(this.toDate);
      if (fromDateControl.value !== newFromDate) {
        fromDateControl.setValue(newFromDate);
      }
      if (toDateControl.value !== newToDate) {
        toDateControl.setValue(newToDate);
      }
    }
  }

  setDatePickerTouched(dateControlType: string) {
    if ('fromDate' === dateControlType && this.fromDateControl) {
      this.fromDateControl.markAsTouched();
    }
    if ('toDate' === dateControlType && this.toDateControl) {
      this.toDateControl.markAsTouched();
    }
  }

  get validationError() {
    const fromDateControl = this.fromDateControl;
    const toDateControl = this.toDateControl;
    if (!!fromDateControl && !!toDateControl) {
      const formControlInvalid = fromDateControl.invalid || toDateControl.invalid;
      const formControlDirty = fromDateControl.dirty || toDateControl.dirty;
      const formControlTouched = fromDateControl.touched || toDateControl.touched;
      return !!this.errorMessage && formControlInvalid && (formControlDirty || formControlTouched);
    }
    return false;
  }

  get errorMessage() {
    const fromDateControl = this.fromDateControl;
    const toDateControl = this.toDateControl;
    let dateControl = fromDateControl;
    if (toDateControl.invalid) {
      dateControl = toDateControl;
    }
    return this.validationsService.getValidationErrorMessage(dateControl);
  }
  // end formGroup ////////////////////////////////////
}
