import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isArray, includes, each, concat } from 'lodash';

const validateSimpleValueOrArray = (value: any | any[], validatorFn: (value: any) => any) => {
  if (Array.isArray(value)) {
    let validationError = null;
    value.forEach((itemValue) => {
      const itemValidationError = validatorFn(itemValue);
      if (itemValidationError) {
        validationError = itemValidationError;
      }
    });

    return validationError;
  }

  return validatorFn(value);
};

export const UrlValidator = (control: AbstractControl): ValidationErrors | null => {
  const URLPattern = /^https?:\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&\/~+#]*[\w\-@?^=%&\/~+#])?$/;
  const matchArray = String(control.value).match(URLPattern);

  return !matchArray || matchArray.length === 0 ? { patternError: true } : null;
};

export const AgeRangeValidator = (formGroup: FormGroup, type: string): ValidatorFn => {
  if ('min' === type) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (Number(formGroup.get('maxAge').value) === 0) {
        return null;
      }
      return Number(control.value) > Number(formGroup.get('maxAge').value) ? { minAgeError: true } : null;
    };
  }
  if ('max' === type) {
    return (control: AbstractControl): ValidationErrors | null => {
      return Number(control.value) < Number(formGroup.get('minAge').value) ? { maxAgeError: true } : null;
    };
  }
  return (control: AbstractControl): ValidationErrors | null => null;
};

export const maxChipLength = (limit: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if ('number' === typeof limit && !isNaN(limit)) {
      return control.value.length > limit ? { max: { max: limit } } : null;
    }
    return null;
  };
};

export const googleUrlPathValidator = (formGroup: FormGroup, pathA: string, pathB: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!formGroup || !pathA || !pathB) {
      return null;
    }
    if (control === formGroup.get(pathA)) {
      if (formGroup.get(pathB).hasError('googlePathError')) {
        const errors = formGroup.get(pathB).errors;
        delete errors['googlePathError'];
        formGroup.get(pathB).setErrors(Object.keys(errors).length ? errors : null);
      }
      if (!formGroup.get(pathA).value && formGroup.get(pathB).value) {
        return { googlePathError: true };
      }
    }
    if (control === formGroup.get(pathB)) {
      if (formGroup.get(pathA).hasError('googlePathError')) {
        const errors = formGroup.get(pathA).errors;
        delete errors['googlePathError'];
        formGroup.get(pathA).setErrors(Object.keys(errors).length ? errors : null);
      }
      if (!formGroup.get(pathA).value && formGroup.get(pathB).value) {
        return { googlePathError: true };
      }
    }
    return null;
  };
};

export const keywordDuplicatedValidator = (formGroup: FormGroup, controlNames: string[]): ValidatorFn => {
  return (control: AbstractControl): { keywordDuplicateError: Array<string> } => {
    if (!formGroup || !controlNames?.length) {
      return null;
    }

    const keywordControls = controlNames.map((i) => formGroup.get(i));
    let inputedKeywords = [];
    each(keywordControls, (keywordControl) => {
      if (keywordControl !== control) {
        inputedKeywords = concat(inputedKeywords, keywordControl.value);
      }
    });

    return duplicatedValidator(inputedKeywords)(control);
  };
};

const duplicatedValidator = (inputs = [] as Array<string>) => {
  return (control: AbstractControl): { keywordDuplicateError: Array<string> } => {
    if (control && isArray(control.value)) {
      const dupArray = [];
      each(control.value, (word) => {
        if (includes(inputs, word)) {
          dupArray.push(word);
        }
      });
      return dupArray.length ? { keywordDuplicateError: dupArray } : null;
    }

    return null;
  };
};

export const maxWordsCountValidator = (maxWordsCount: number): ValidatorFn => {
  return (c: AbstractControl): { [key: string]: any } => {
    const validateMaxWordsCount = (wordsCount: string) => {
      if (wordsCount.split(' ').length > maxWordsCount) {
        return { maxWordsCount: { maxCount: maxWordsCount } };
      }
    };

    const value = c.value;
    return validateSimpleValueOrArray(value, validateMaxWordsCount);
  };
};

export const patternValidator = (pattern: RegExp, fieldText?: string): ValidatorFn => {
  const validatePattern = (value: string) => {
    if (!!value) {
      if (fieldText === 'dsc') {
        const matchArray = value.match(pattern);
        if (matchArray) {
          return { dscError: { fieldText } };
        }
      } else {
        const matchArray = value.match(pattern);
        if (!matchArray || matchArray.length === 0) {
          if (fieldText === 'path') {
            return { pathPatternError: { fieldText } };
          }
          if (fieldText === 'keyword') {
            return { keywordPatternError: { fieldText } };
          }
          return { patternError: { fieldText } };
        }
      }
    }

    return null;
  };

  return (c: AbstractControl): { [key: string]: any } => {
    const value = c.value;
    return validateSimpleValueOrArray(value, validatePattern);
  };
};

export const valueDuplicatedValidator = (formGroup: FormGroup, name: string, ...controlsToCheckAgainst: string[]) => {
  return (c: AbstractControl): { [key: string]: any } => {
    if (!formGroup || !controlsToCheckAgainst?.length) {
      return null;
    }

    let validationError = null;
    controlsToCheckAgainst.forEach((i) => {
      const controlToCheckAgainst = formGroup.get(i);
      if (c?.value?.trim() === controlToCheckAgainst?.value?.trim()) {
        if (name === 'headline') {
          validationError = { headlineDuplicated: true };
          return;
        }

        if (name === 'description') {
          validationError = { descriptionDuplicated: true };
          return;
        }

        validationError = { valueDuplicated: true };
        return;
      }
    });

    return validationError;
  };
};
