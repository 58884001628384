export enum Assignment {
  PERMISSION_CREATE = 'permission.object.create',
  PERMISSION_READ = 'permission.object.read',
  PERMISSION_UPDATE = 'permission.object.update',
  PERMISSION_DELETE = 'permission.object.delete',
  ORGANIZATION_CREATE = 'organization.object.create',
  ORGANIZATION_READ = 'organization.object.read',
  ORGANIZATION_UPDATE = 'organization.object.update',
  ORGANIZATION_DELETE = 'organization.object.delete',
  LOCATION_CREATE = 'location.object.create',
  LOCATION_READ = 'location.object.read',
  LOCATION_UPDATE = 'location.object.update',
  LOCATION_DELETE = 'location.object.delete',
  TEMPLATE_CREATE = 'template.object.create',
  TEMPLATE_READ = 'template.object.read',
  TEMPLATE_UPDATE = 'template.object.update',
  TEMPLATE_DELETE = 'template.object.delete',
  CAMPAIGN_CREATE = 'campaign.object.create',
  CAMPAIGN_READ = 'campaign.object.read',
  CAMPAIGN_UPDATE = 'campaign.object.update',
  CAMPAIGN_DELETE = 'campaign.object.delete',
  CAMPAIGN_EVENT_PAUSE = 'campaign.event.pause',
  CAMPAIGN_EVENT_STOP = 'campaign.event.stop',
  GROUP_CREATE = 'group.object.create',
  GROUP_READ = 'group.object.read',
  GROUP_UPDATE = 'group.object.update',
  GROUP_DELETE = 'group.object.delete',
  REPORTS_CREATE = 'report.object.create',
  REPORTS_READ = 'report.object.read',
  REPORTS_UPDATE = 'report.object.update',
  REPORTS_DELETE = 'report.object.delete',
}
