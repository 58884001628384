import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-publish-ad-dialog',
  templateUrl: './publish-ad-dialog.component.html',
  styleUrls: ['./publish-ad-dialog.component.scss'],
})
export class PublishAdDialogComponent implements OnInit {
  currentChannel: 'facebook';

  constructor(
    public dialogRef: MatDialogRef<PublishAdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {}

  ngOnInit() {
    const { channel } = this.dialogData;

    this.currentChannel = channel;
  }
}
