import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as gql from './notification.gql';
import { DictionaryService } from '../dictionary/dictionary.service';
import { orderBy } from 'lodash';
import { NotificationListDialogComponent } from 'src/app/shared/dialogs/notification-list-dialog/notification-list-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  FormattedNotification,
  NetworkActivity,
  Notification,
  NotificationType,
  TokenStatusNotification,
  TokenStatusType,
} from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationCount: Subject<number> = new Subject();
  dialogRef: MatDialogRef<NotificationListDialogComponent, any>;
  openPreviewDialog$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private apollo: Apollo, private dictionaryService: DictionaryService, private dialog: MatDialog) {}

  getTemplateRequestNotifications(): Observable<Notification[]> {
    return this.apollo
      .watchQuery<{ campaignTemplateRequestNotification: Notification[] }>({
        query: gql.campaignTemplateRequestNotification,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.campaignTemplateRequestNotification || []),
        catchError(() => of([])),
      );
  }

  getNotifications(): Observable<Notification[]> {
    return this.apollo
      .watchQuery<{ notificationForCurrentUser: Notification[] }>({
        query: gql.notificationForCurrentUser,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.notificationForCurrentUser || []),
        catchError(() => of([])),
      );
  }

  getTokenStatusNotifications(): Observable<Notification[]> {
    return this.apollo
      .watchQuery<{ checkTokenNotification: TokenStatusNotification[] }>({
        query: gql.checkTokenNotification,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => this.formatTokenStatusNotifications(data.checkTokenNotification)),
        catchError(() => of([])),
      );
  }

  updateNotificationStatus(notificationId: string): Observable<boolean> {
    return this.apollo
      .watchQuery<{ readNotification: boolean }>({
        query: gql.readNotification,
        fetchPolicy: 'network-only',
        variables: { notificationId },
      })
      .valueChanges.pipe(
        map(({ data }) => data.readNotification),
        catchError(() => of(false)),
      );
  }

  getNetworkActivities(): Observable<NetworkActivity> {
    const noData = {
      newAds: [],
      firstAdPlaced: [],
      hit2kImpressions: [],
      hit5kImpressions: [],
      hit10kImpressions: [],
      highestCtrAd: [],
      adsConversion: [],
      newUsers: [],
    };
    return this.apollo
      .watchQuery<{ networkActivities: NetworkActivity }>({
        query: gql.networkActivities,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.networkActivities || noData),
        catchError(() => of(noData)),
      );
  }

  showAllDialog(contentType: string, content: Array<any>): void {
    this.dialog.open(NotificationListDialogComponent, {
      width: '90%',
      maxWidth: '50em',
      maxHeight: '80vh',
      data: { contentType, content },
      panelClass: 'dialog-card',
    });
  }

  closeAllDialog(): void {
    this.dialog.closeAll();
  }

  getAdSubscriptionNotifications(): Observable<Array<Notification>> {
    return this.apollo
      .watchQuery<{ adSubscriptionNotifications: Array<Notification> }>({
        query: gql.adSubscriptionNotifications,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.adSubscriptionNotifications || []),
        catchError(() => of([])),
      );
  }

  private formatTokenStatusNotifications(tokenStatusNotifications: TokenStatusNotification[]): Notification[] {
    if (!tokenStatusNotifications) {
      return [];
    }
    let notifications: Notification[] = [];
    tokenStatusNotifications.forEach((tokenStatusNotification) => {
      const { type, content, channel, failedAccountStatus } = tokenStatusNotification;
      switch (type) {
        case TokenStatusType.ZEE_TOKEN_EXPIRED: {
          const notificationContent = {
            disconnectFranchisees: content,
          };
          const notificationType = NotificationType.ad_inactive_by_account_disconnect;
          notifications.push({ notificationType, notificationContent });
          break;
        }
        case TokenStatusType.CAN_NOT_PULL_AD_RESULT: {
          const notificationContent = {
            inActiveAds: content,
            showDetailStatus: false,
          };
          const notificationType = NotificationType.ad_inactive_by_account_disconnect;
          notifications.push({ notificationType, notificationContent });
          break;
        }
        case TokenStatusType.TOKEN_EXPIRED: {
          const notificationContent = {
            channelNme: channel,
          };
          const notificationType = NotificationType.account_disconnect;
          notifications.push({ notificationType, notificationContent });
          break;
        }
        case TokenStatusType.NO_FOUNDING_SOURCE: {
          if (failedAccountStatus && failedAccountStatus.length) {
            const notificationContent = {
              channelNme: channel,
              failedAccountStatus,
              showDetailStatus: false,
            };
            const notificationType = NotificationType.funding_source_type_is_unset;
            notifications.push({ notificationType, notificationContent });
          }
          break;
        }
        default:
          break;
      }
    });
    if (!!notifications.length) {
      notifications = notifications.map((notification) => {
        return { ...notification, notificationIsRead: true };
      });
    }
    return notifications;
  }

  formatNotifications(
    notifications: Notification[],
    campaignTypes?: { id: string; type: string }[],
  ): FormattedNotification[] {
    const notificationMessages = this.dictionaryService.getNotificationsMessages();
    return orderBy(notifications, [(item) => Number(item.notificationId)], ['desc']).map((notification) => {
      const matchedNotification = notificationMessages.find(
        (notificationMessage) => notificationMessage.type === notification.notificationType,
      );
      const id = notification.notificationId;
      const isRead = notification.notificationIsRead;
      const notificationContent = notification.notificationContent || {};
      const notificationType = matchedNotification.type;
      const conversionAdName = 'Conversion Ad For Your Subscription';
      const isConversionAd = notificationType === NotificationType.conversion_campaign_template_for_ad_sub;
      const adId = notificationContent['campaignId'];
      const category = matchedNotification.category;
      const adName = isConversionAd
        ? conversionAdName
        : notificationContent['campaignNme'] || notificationContent['campaignTemplateNme'];
      const channelName = isConversionAd
        ? null
        : notificationContent['channelNme'] || notificationContent['campaignChannelNme'];
      const notificationMsg = this.formatMessage(notification, matchedNotification.msg);
      let adType;
      if (adId && !!campaignTypes && campaignTypes.length > 0) {
        const matchedAd = campaignTypes.find((campaign) => campaign.id === adId.toString());
        adType = matchedAd ? matchedAd.type : null;
      }
      return {
        id,
        isRead,
        adId,
        adName,
        adType,
        channelName,
        category,
        msg: notificationMsg,
        content: { ...notificationContent },
      };
    });
  }

  private formatMessage(notification: Notification, message: string): string {
    const content = notification.notificationContent;
    const type = notification.notificationType;
    const messageMap = {
      [NotificationType.highest_ctr]: () => message.replace('[value]', content.ctr + '%'),
      [NotificationType.highest_clicks]: () => {
        const formattedClicks = content.clicks.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, (value) => value + ',');
        return message.replace('[value]', formattedClicks);
      },
      [NotificationType.ad_failed]: () => {
        const startDate = content.campaignDurationStartDte;
        const year = startDate.substring(0, 5);
        const date = startDate.replace(year, '') + '-' + year.substring(0, 4);
        return message
          .replace('[adName]', content.campaignNme)
          .replace('[channelName]', content.channelNme)
          .replace('[startDate]', date);
      },
      [NotificationType.campaign_channel_connected_state]: () => {
        return message
          .replace('[adSubscriptionName]', content.adSubscriptionNme)
          .replace('[disconnectedChannelName]', content.disconnectedChannelNme)
          .replace('[templateName]', content.campaignTemplateNme)
          .replace('[channelName]', content.campaignChannelNme);
      },
      [NotificationType.campaign_template_request]: () => message.replace('[zeeNme]', content.userName),
      [NotificationType.funding_source_type_is_unset]: () => message.replace('[channelName]', content.channelNme),
      [NotificationType.account_disconnect]: () =>
        message.replace('[channelName]', () => (content.channelNme === 'google' ? 'Google' : 'Facebook / Instagram')),
    };
    return messageMap[type] ? messageMap[type]() : message;
  }
}
