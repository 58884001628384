import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/shared/dialogs/message-dialog/message-dialog.component';
// tslint:disable-next-line:max-line-length
import { NetworkErrorDialogComponent } from 'src/app/shared/dialogs/network-error-dialog/network-error-dialog.component';
import { ProgressBarDialogComponent } from 'src/app/shared/dialogs/progress-bar-dialog/progress-bar-dialog.component';
import { ConfirmDialogComponent } from '@dialogs';
import { omit } from 'lodash';

const defaultDialogConfig: MatDialogConfig = {
  width: '90%',
  maxWidth: '30em',
  maxHeight: '80vh',
  disableClose: false,
};

@Injectable({
  providedIn: 'root',
})
export class CommonDialogService {
  private dialogKey = -1;
  private dialogMap = {};
  public dialogConfig: MatDialogConfig;

  private static getDialogComponent(dialogType: string): any {
    const dialogMap = {
      message: MessageDialogComponent,
      networkError: NetworkErrorDialogComponent,
      progress: ProgressBarDialogComponent,
      confirm: ConfirmDialogComponent,
    };
    return dialogMap[dialogType] || MessageDialogComponent;
  }

  constructor(private dialog: MatDialog) {}

  // not for specific feature dialogs
  openDialog(dialogType: string, config?: MatDialogConfig, isCloseAll: boolean = false): MatDialogRef<any> {
    if (isCloseAll) {
      this.closeAll();
    }
    const dialogComp = CommonDialogService.getDialogComponent(dialogType);
    this.getDialogConfig(dialogType, config);
    this.dialogKey += 1;
    if (!this.dialogMap[this.dialogKey]) {
      this.dialogMap[this.dialogKey] = this.dialog.open(dialogComp, this.dialogConfig);
    }
    return this.dialogMap[this.dialogKey];
  }

  closeDialog(result?: any): void {
    const dialogRef = this.dialogMap[this.dialogKey];
    if (dialogRef) {
      dialogRef.close(result);
      this.dialogMap = omit(this.dialogMap, [this.dialogKey]);
    }
  }

  closeAll(): void {
    this.dialog.closeAll();
    this.dialogMap = {};
    this.dialogKey = -1;
  }

  private getDialogConfig(dialogType: string, config: MatDialogConfig): void {
    this.dialogConfig = { ...defaultDialogConfig };
    if (dialogType === 'progress') {
      this.dialogConfig.maxWidth = '50em';
      this.dialogConfig.disableClose = true;
      this.dialogConfig.data = 'Please wait...';
    }
    if (config) {
      this.dialogConfig = { ...this.dialogConfig, ...config };
    }
  }
}
