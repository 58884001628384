<mat-chip-list #chipList *ngIf="!!formGroup" class="chip-container" (focus)="doChipListFocus(input)"
  [class.textarea-chip-container]="type === 'textarea'">
  <div class="prefix" *ngIf="prefix">
    <span>{{prefix}}</span>
    <app-ui-tip *ngIf="prefixTip" [tip]="prefixTip">
    </app-ui-tip>
  </div>
  <div class="content">
    <mat-chip *ngFor="let chip of chips" [selectable]="!disabled && selectable" [removable]="!disabled && removable"
      [disabled]="disabled" (removed)="remove(chip)">
      {{chip}}
      <mat-icon [class.icon-disabled]="disabled" matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input #input class="chip-input text-s" [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)" [disabled]="disabled">
  </div>
  <div class="suffix" *ngIf="suffix">
    <i class="material-icons" (click)="clickSuffix()">
      list_alt
    </i>
  </div>
</mat-chip-list>
<div class="grid notation-container" *ngIf="!!validationError || !!inputDescription || !!inputLimit">
  <p *ngIf="!!validationError" class="text-red text-s mb-s">{{ errorMessage }}</p>
  <p *ngIf="!!inputDescription && !validationError" class="text-xs text-italic">
    {{ inputDescription }}
  </p>
  <p *ngIf="!!inputLimit && !validationError" class="text-xs text-right">
    {{ inputLimit - controlValue.length }} / {{ inputLimit }}
  </p>
</div>
