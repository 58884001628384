import { Injectable } from '@angular/core';
import { ICaretInfo, IChipObject, ITextareaStyle } from '@interfaces';
import * as sanitizeHtml from 'sanitize-html';

@Injectable()
export class TextareaWithChipDataHandleService {
  constructor() {}

  static GetStyle(oElm, strCssRule): any {
    let strValue = '';
    if (document.defaultView && document.defaultView.getComputedStyle) {
      strValue = document.defaultView.getComputedStyle(oElm, '').getPropertyValue(strCssRule);
    } else if (oElm.currentStyle) {
      strCssRule = strCssRule.replace(/\-(\w)/g, (strMatch, p1) => {
        return p1.toUpperCase();
      });
      strValue = oElm.currentStyle[strCssRule];
    }
    return strValue;
  }

  static CalcContentHeightAndWidth(text: string, style: any) {
    const div = document.createElement('pre');
    div.setAttribute(
      'style',
      `position: absolute; visibility: hidden; height: auto; white-space: pre-wrap;` +
        `font-family: ${style.fontFamily}; max-width: ${style.availableWidth}px;` +
        `font-size: ${style.fontSize}; line-height: ${style.lineHeight}`,
    );
    div.innerHTML = TextareaWithChipDataHandleService.SanitizeHtml(text);
    document.body.appendChild(div);

    const contentHeight = div.clientHeight;
    const contentWidth = div.clientWidth;

    div.parentNode.removeChild(div);

    return [contentHeight, contentWidth];
  }

  static CalculateWordDimensions(text: string, style: any) {
    const div = document.createElement('div');
    div.setAttribute(
      'style',
      `position: absolute; visibility: hidden; height: auto; white-space: pre-wrap;` +
        `font-family: ${style.fontFamily}; width: auto;` +
        `font-size: ${style.fontSize}; line-height: ${style.lineHeight}`,
    );
    div.innerHTML = TextareaWithChipDataHandleService.SanitizeHtml(text);
    document.body.appendChild(div);
    const dimensions = div.clientWidth;
    div.parentNode.removeChild(div);
    return dimensions;
  }

  static GetChipPosition(item: IChipObject, content: string, style: any) {
    const str = item.placeholderString;
    const index = content.indexOf(str);
    let height: any = 0;
    let deltaWidth = 0;
    if (index !== -1) {
      const baseStr = content.slice(0, index + item.placeholderString.length);
      [height] = TextareaWithChipDataHandleService.CalcContentHeightAndWidth(baseStr, style);
      let pad = `'`;
      while (true) {
        let newHeight = 0;
        [newHeight] = TextareaWithChipDataHandleService.CalcContentHeightAndWidth(baseStr + pad, style);
        if (newHeight > height) {
          break;
        }
        pad += `'`;
      }
      [, deltaWidth] = TextareaWithChipDataHandleService.CalcContentHeightAndWidth(pad, style);
    }
    return [height, deltaWidth];
  }

  static GetTextareaStyles(textareaElement: any) {
    const newStyle: ITextareaStyle = Object.assign({});
    if (!textareaElement) {
      return newStyle;
    }
    newStyle.fontSize = TextareaWithChipDataHandleService.GetStyle(textareaElement, 'font-size');
    newStyle.fontFamily = TextareaWithChipDataHandleService.GetStyle(textareaElement, 'font-family');
    newStyle.lineHeight = TextareaWithChipDataHandleService.GetStyle(textareaElement, 'line-height');
    // belows will use for calculate, so transform to int
    newStyle.totalWidth = parseInt(TextareaWithChipDataHandleService.GetStyle(textareaElement, 'width'), 10);
    newStyle.totalHeight = parseInt(TextareaWithChipDataHandleService.GetStyle(textareaElement, 'height'), 10);
    newStyle.borderRightWidth = parseInt(
      TextareaWithChipDataHandleService.GetStyle(textareaElement, 'border-right-width'),
      10,
    );
    newStyle.borderLeftWidth = parseInt(
      TextareaWithChipDataHandleService.GetStyle(textareaElement, 'border-left-width'),
      10,
    );
    newStyle.borderTopWidth = parseInt(
      TextareaWithChipDataHandleService.GetStyle(textareaElement, 'border-top-width'),
      10,
    );
    newStyle.borderBottomWidth = parseInt(
      TextareaWithChipDataHandleService.GetStyle(textareaElement, 'border-bottom-width'),
      10,
    );
    newStyle.paddingRightWidth = parseInt(
      TextareaWithChipDataHandleService.GetStyle(textareaElement, 'padding-right'),
      10,
    );
    newStyle.paddingLeftWidth = parseInt(
      TextareaWithChipDataHandleService.GetStyle(textareaElement, 'padding-left'),
      10,
    );
    newStyle.paddingTopWidth = parseInt(TextareaWithChipDataHandleService.GetStyle(textareaElement, 'padding-top'), 10);
    newStyle.paddingBottomWidth = parseInt(
      TextareaWithChipDataHandleService.GetStyle(textareaElement, 'padding-bottom'),
      10,
    );
    newStyle.availableWidth =
      newStyle.totalWidth -
      newStyle.borderRightWidth -
      newStyle.borderLeftWidth -
      newStyle.paddingRightWidth -
      newStyle.paddingLeftWidth;
    newStyle.availableHeight =
      newStyle.totalHeight -
      newStyle.borderTopWidth -
      newStyle.borderBottomWidth -
      newStyle.paddingBottomWidth -
      newStyle.paddingTopWidth;
    return newStyle;
  }

  static CalculateDeleteRange(caretInfo: ICaretInfo, adChipList: IChipObject[] = []) {
    for (const item of adChipList) {
      const itemEndIndex = item.startIndex + item.placeholderString.length;
      const start = caretInfo.selectionStart;
      const end = caretInfo.selectionEnd;
      if (item.addToText && item.startIndex !== null && !(item.startIndex >= end || itemEndIndex <= start)) {
        item.needDelete = true;
        caretInfo.selectionStart = item.startIndex < start ? item.startIndex : start;
        caretInfo.selectionEnd = itemEndIndex > end ? itemEndIndex : end;
      }
    }
  }

  static UpdateChipIndex(source: string, adChipList: IChipObject[]) {
    for (const chip of adChipList) {
      const index = source.indexOf(chip.placeholderString);
      chip.startIndex = index === -1 ? null : index;
    }
  }

  static BuildChipStatus(adText: string, adChipList: IChipObject[]) {
    if (Array.isArray(adChipList)) {
      for (const chip of adChipList) {
        const index = adText.indexOf(chip.placeholderString);
        chip.addToText = index !== -1;
        chip.startIndex = index !== -1 ? index : null;
      }
    }
  }

  static CalculateContentLengthWithChip(content: string, chipList: IChipObject[]): number {
    if (typeof content !== 'string') {
      return 0;
    }
    if (!Array.isArray(chipList)) {
      return content.length;
    }
    let needAddedLen = 0;
    let minusLen = 0;
    chipList.forEach((chip) => {
      if (content.indexOf(chip.placeholderString) !== -1) {
        minusLen += chip.placeholderString.length;
        needAddedLen += chip.charDropCount;
      }
    });
    return content.length - minusLen + needAddedLen;
  }

  static SanitizeHtml(html) {
    return sanitizeHtml(html, {
      allowedTags: ['b', 'i', 'em', 'strong'],
    });
  }
}
