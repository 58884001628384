import { gql } from 'apollo-angular';
import { limitOrganizationFragment } from '../organization/organization.gql';
import { channelDataFragment } from '../channel/channel.gql';
import { locationFragment } from '../location/location.gql';
import { ObjectiveType } from '../objective/objective.gql';

const campaignTemplateFeedbackFragment = gql`
  fragment campaignTemplateFeedback on CampaignTemplateFeedbackType {
    id
    campaignId
    campaignTemplateId
    rate
    comment
  }
`;

const campaignTemplateFragment = gql`
  fragment campaignTemplate on CampaignTemplateType {
    campaignTemplateId
    campaignTemplateNme
    organizationId
    campaignTemplateSharedLocationIds
    organization {
      ...limitOrganization
    }
    campaignChannelId
    campaignChannelNme
    channel {
      ...channel
    }
    campaignTemplateEditableFields
    campaignTemplateNewCampaignInd
    campaignTemplateAudienceGender
    campaignTemplateMaxAge
    campaignTemplateMinAge
    campaignTemplateTakeRate
    campaignTemplateCreatedAt
    campaignTemplateActiveInd
    campaignTemplateRunTims
    campaignTemplateRecommendedDuration
    campaignTemplateAudienceSuggestedSpend
    campaignTemplateAvailableStartDte
    campaignTemplateAvailableEndDte
    campaignTemplateCampaignCreator
    campaignTemplateOptimizationGoal
    campaignTemplateImageUrls
    campaignTemplateAdDsc
    campaignTemplateObjectives
    campaignTemplateHeadlines
    campaignTemplateNewsFeedlinkDscs
    campaignTemplateGoal
    campaignTemplateDefaultWebsiteUrl
    campaignTemplateWebsiteUrls
    campaignTemplateInProgressQty
    campaignTemplateSpecialAdCategory
    campaignTemplateRadius
    campaignTemplateObjectiveObjects {
      ...objective
    }
    campaignTemplateRateFeedback {
      averageRate
      rateCount
    }
    campaignTemplateUserFeedback {
      ...campaignTemplateFeedback
    }
    campaignTemplateMetrics {
      impressions
      ctr
      cvr
      cpc
    }
    campaignOverview {
      averageDuration
      averageSpend
    }
    campaignTemplateUpdatedAt
    campaignTemplateOptOutInd
    campaignTemplateSharedGroupIds
    campaignTemplateFilteredLocationIds
    campaignTemplateScheduleNeeded
    campaignTemplateScheduleTimezone
    campaignTemplateScheduleList {
      startMinute
      endMinute
      days
      dayOfWeek
      startHour
      endHour
    }
  }
  ${limitOrganizationFragment}
  ${channelDataFragment}
  ${ObjectiveType}
  ${campaignTemplateFeedbackFragment}
`;

const campaignFragment = gql`
  fragment campaign on CampaignType {
    campaignId
    campaignNme
    campaignTemplate {
      ...campaignTemplate
    }
    campaignChannelId
    campaignChannelNme
    campaignType
    campaignGoal
    campaignAudienceGender
    campaignOptimizationGoal
    campaignMaxAge
    campaignMinAge
    campaignDurationStartDte
    campaignDurationEndDte
    campaignPublishStatus
    campaignPaused
    campaignBudget
    campaignMetrics {
      spend
    }
    campaignImageUrls
    location {
      ...location
    }
    campaignHeadlines
    campaignNewsFeedlinkDscs
    campaignObjectives
    campaignDefaultWebsiteUrl
    campaignWebsiteUrls {
      url
      locationId
    }
    channel {
      ...channel
      ... on FacebookChannel {
        facebookAdsetRecommendations {
          title
          message
        }
      }
      ... on InstagramChannel {
        instagramAdsetRecommendations {
          title
          message
        }
      }
    }
    campaignRanByZowi
    campaignChannelStatus
    campaignArchiveDate
    campaignScheduleNeeded
    campaignScheduleTimezone
    campaignScheduleList {
      startMinute
      endMinute
      days
      dayOfWeek
      startHour
      endHour
    }
    campaignPotentialReach
  }
  ${locationFragment}
  ${campaignTemplateFragment}
  ${channelDataFragment}
  ${campaignTemplateFeedbackFragment}
`;

const processingStatusTypeFragment = gql`
  fragment eventStatusType on EventStatusType {
    campaignId
    status
    message
  }
`;

export const addCampaignTemplate = gql`
  mutation addCampaignTemplate($templateInput: CreateCampaignTemplateInput!, $filenames: [String]) {
    addCampaignTemplate(campaignTemplate: $templateInput, filenames: $filenames) {
      ...campaignTemplate
    }
  }
  ${campaignTemplateFragment}
`;

export const upload = gql`
  mutation upload($files: [Upload!]!) {
    upload(files: $files)
  }
`;

export const getCampaignTemplateByID = gql`
  query findCampaignTemplateById($id: ID!) {
    campaignTemplate(id: $id) {
      ...campaignTemplate
    }
  }
  ${campaignTemplateFragment}
`;

export const addCampaign = gql`
  mutation addCampaign($input: CampaignInput!) {
    addCampaign(campaign: $input) {
      campaignId
    }
  }
`;

export const archiveCampaign = gql`
  mutation archiveCampaign($id: ID!) {
    archiveCampaign(id: $id)
  }
`;

export const getCampaignByID = gql`
  query campaign($id: ID!) {
    campaign(id: $id) {
      ...campaign
    }
  }
  ${campaignFragment}
`;

/***ZOR***/

export const getSharedTemplates = gql`
  query findCampaignTemplates {
    getSharedTemplates {
      ...campaignTemplate
    }
  }
  ${campaignTemplateFragment}
`;

export const getCampaignsByRequestingUserForAdSpend = gql`
  query campaignsByRequestingUserForAdSpend {
    campaignsByRequestingUserForAdSpend {
      campaignId
      campaignNme
      campaignChannelId
      campaignChannelNme
      campaignDurationStartDte
      campaignDurationEndDte
      campaignType
      campaignTemplate {
        campaignTemplateId
        campaignTemplateNme
      }
      campaignMetrics {
        spend
        days
        dailyMetrics {
          spend
          date
        }
      }
      location {
        locationId
        locationNme
      }
    }
  }
`;

export const getCampaignsByRequestingUserViewType = gql`
  query campaignsByViewType {
    campaignsByViewType {
      ...campaign
    }
  }
  ${campaignFragment}
`;

export const getCampaignsByViewTypeForAdSpend = gql`
  query campaignsByViewTypeForAdSpend {
    campaignsByViewTypeForAdSpend {
      campaignId
      campaignNme
      campaignChannelId
      campaignChannelNme
      campaignDurationStartDte
      campaignDurationEndDte
      campaignType
      campaignTemplate {
        campaignTemplateId
        campaignTemplateNme
      }
      campaignMetrics {
        spend
        days
        dailyMetrics {
          spend
          date
        }
      }
      location {
        locationId
        locationNme
      }
    }
  }
`;

export const findCampaignsByViewType = gql`
  query campaignsByViewType {
    campaignsByViewType {
      ...campaign
    }
  }
  ${campaignFragment}
`;

export const getAllTemplatesByPage = gql`
  query getAllTemplatesByPage($pageNum: Int!, $pageSize: Int) {
    getAllTemplatesByPage(pageNum: $pageNum, pageSize: $pageSize) {
      templates {
        ...campaignTemplate
      }
      count
    }
  }
  ${campaignTemplateFragment}
`;

export const getBasicCampaignTemplatesViewType = gql`
  query getBasicCampaignTemplatesViewType {
    basicCampaignTemplatesViewType {
      campaignTemplateId
      campaignTemplateNme
      campaignChannelNme
      startDte
      endDte
      actualLocationCount
      sharedLocationCount
      rating
      run
      updateDte
      campaignTemplateNewCampaignInd
      active
      rateCount
    }
  }
`;

export const updateCampaignTemplateById = gql`
  mutation updateCampaignTemplateById(
    $id: ID!
    $campaignTemplate: UpdateCampaignTemplateInput!
    $filenames: [String]!
  ) {
    updateCampaignTemplateById(id: $id, campaignTemplate: $campaignTemplate, filenames: $filenames) {
      ...campaignTemplate
    }
  }
  ${campaignTemplateFragment}
`;

export const cloneCampaignTemplateById = gql`
  mutation cloneCampaignTemplateById($id: ID!, $campaignTemplate: UpdateCampaignTemplateInput!, $filenames: [String]!) {
    cloneCampaignTemplateById(id: $id, campaignTemplate: $campaignTemplate, filenames: $filenames) {
      ...campaignTemplate
    }
  }
  ${campaignTemplateFragment}
`;

export const activeCampaignTemplate = gql`
  mutation activeCampaignTemplate($id: ID!, $active: Boolean!) {
    activeCampaignTemplate(id: $id, active: $active)
  }
`;

export const getWSCampaignTemplate = gql`
  subscription {
    campaignTemplate {
      shouldUpdate
      data {
        ...campaignTemplate
      }
    }
  }
  ${campaignTemplateFragment}
`;

export const getCampaignResultsByLocationId = gql`
  query campaignResultsByLocationId($id: ID!) {
    campaignResultsByLocationId(id: $id) {
      externalLink
      duration
      spend
      impressions
      clicks
      conversion
      cpc
      ctr
      cvr
    }
  }
`;

export const createCampaignTemplateFeedback = gql`
  mutation createCampaignTemplateFeedback(
    $campaignId: Int!
    $campaignTemplateFeedback: CampaignTemplateFeedbackInput!
  ) {
    createCampaignTemplateFeedback(campaignId: $campaignId, campaignTemplateFeedback: $campaignTemplateFeedback) {
      ...campaignTemplateFeedback
    }
  }
  ${campaignTemplateFeedbackFragment}
`;

export const updateCampaignTemplateFeedback = gql`
  mutation updateCampaignTemplateFeedback($id: Int!, $campaignTemplateFeedback: CampaignTemplateFeedbackInput!) {
    updateCampaignTemplateFeedback(id: $id, campaignTemplateFeedback: $campaignTemplateFeedback) {
      ...campaignTemplateFeedback
    }
  }
  ${campaignTemplateFeedbackFragment}
`;

export const campaignsByTemplateId = gql`
  query campaignsByTemplateId($id: ID!) {
    campaignsByTemplateId(id: $id) {
      ...campaign
    }
  }
  ${campaignFragment}
`;

export const getCampaignTemplateFeedback = gql`
  query campaignTemplateFeedback($id: ID!) {
    campaignTemplate(id: $id) {
      campaignTemplateId
      campaignTemplateNme
      campaignChannelNme
      campaignTemplateRateFeedback {
        averageRate
        rateCount
      }
      campaignTemplateFeedback {
        ...campaignTemplateFeedback
        userName
        rateCount
      }
    }
  }
  ${campaignTemplateFeedbackFragment}
`;

// New for restyling

/*
 * Only get the necessary information
 * Used for Zee: home page, run ad list page
 * Used for Zor: template management page, run ad list page
 */

export const campaignTemplateMainContentFragment = gql`
  fragment campaignTemplateMainInfo on CampaignTemplateType {
    campaignTemplateId
    campaignTemplateNme
    campaignChannelNme
    campaignTemplateNewCampaignInd
    campaignTemplateImageUrls
    campaignTemplateGoal
    campaignTemplateObjectives
    campaignTemplateAudienceGender
    campaignTemplateRunTims
    campaignTemplateInProgressQty
    campaignTemplateAdDsc
    campaignTemplateAssignedDte
    campaignTemplateAvailableEndDte
    campaignTemplateActiveInd
    campaignTemplateSharedLocationIds
    campaignTemplateSharedGroupIds
    campaignTemplateFilteredLocationIds
    campaignTemplateNewCampaignInd
    channel {
      ... on FacebookChannel {
        campaignChannelNme
        facebookChannelMediaType
      }
      ... on InstagramChannel {
        campaignChannelNme
        instagramChannelMediaType
      }
      ... on GoogleChannel {
        campaignChannelNme
      }
    }
    campaignTemplateRateFeedback {
      averageRate
      rateCount
    }
    campaignTemplateMetrics {
      impressions
      ctr
      cvr
      cpc
    }
    campaignTemplateObjectiveObjects {
      ...objective
    }
  }
  ${ObjectiveType}
`;

export const campaignMainContentFragment = gql`
  fragment campaignMainInfo on CampaignType {
    locationId
    location {
      locationNme
    }
    campaignId
    campaignBudget
    campaignNme
    campaignType
    campaignChannelNme
    campaignImageUrls
    campaignAdDsc
    campaignAudienceGender
    campaignObjectives
    campaignDurationStartDte
    campaignDurationEndDte
    campaignPublishStatus
    campaignPaused
    campaignMetrics {
      spend
      impressions
      clicks
    }
    channel {
      ... on FacebookChannel {
        campaignChannelNme
        facebookChannelMediaType
        facebookAdsetRecommendations {
          title
          message
        }
      }
      ... on InstagramChannel {
        campaignChannelNme
        instagramChannelMediaType
        instagramAdsetRecommendations {
          title
          message
        }
      }
      ... on GoogleChannel {
        campaignChannelNme
      }
    }
    campaignTemplate {
      campaignTemplateId
      campaignTemplateRateFeedback {
        averageRate
        rateCount
      }
    }
    campaignChannelStatus
    campaignRanByZowi
    campaignArchiveDate
  }
`;

export const getZeeCampaignTemplateMainInfo = gql`
  query getZeeCampaignTemplateMainInfo {
    getSharedTemplates {
      ...campaignTemplateMainInfo
    }
  }
  ${campaignTemplateMainContentFragment}
`;

export const getSharedTemplatesByViewType = gql`
  query getSharedTemplatesByViewType {
    getSharedTemplatesByViewType {
      ...campaignTemplateMainInfo
    }
  }
  ${campaignTemplateMainContentFragment}
`;

export const getCampaignsByRequestingUser = gql`
  query campaignsByRequestingUser {
    campaignsByRequestingUser {
      ...campaignMainInfo
    }
  }
  ${campaignMainContentFragment}
`;

export const operateCampaign = gql`
  mutation operateCampaign($campaignOperation: OperateCampaignInput!) {
    operateCampaign(campaignOperation: $campaignOperation) {
      campaignId
      campaignType
      campaignDurationStartDte
      campaignDurationEndDte
      campaignPublishStatus
      campaignPaused
      campaignBudget
    }
  }
`;

export const editCampaign = gql`
  mutation editCampaign($campaignInfoArgs: UpdateCampaignInput!) {
    editCampaign(campaignInfoArgs: $campaignInfoArgs) {
      campaignId
      campaignType
      campaignDurationStartDte
      campaignDurationEndDte
      campaignPublishStatus
      campaignPaused
      campaignBudget
      campaignScheduleNeeded
      campaignScheduleTimezone
      campaignScheduleList {
        startMinute
        endMinute
        days
        dayOfWeek
        startHour
        endHour
      }
    }
  }
`;

export const getCampaignProcessingStatus = gql`
  query trackCampaignEvents($ids: [ID]!) {
    trackCampaignEvents(ids: $ids) {
      ...eventStatusType
    }
  }
  ${processingStatusTypeFragment}
`;

export const cancelCampaignEvents = gql`
  mutation cancelCampaigns($ids: [ID]!) {
    cancelCampaigns(ids: $ids)
  }
`;

export const trackCampaignCancellation = gql`
  query trackCampaignCancellation($ids: [ID]!) {
    trackCampaignCancellation(ids: $ids) {
      ...eventStatusType
    }
  }
  ${processingStatusTypeFragment}
`;

const campaignTemplateRequestFragment = gql`
  fragment campaignTemplateRequest on CampaignTemplateRequestResponse {
    campaignTemplateRequestId
    campaignTemplateRequestChannel
    campaignTemplateRequestDetails
    campaignTemplateRequestMediaType
    campaignTemplateRequestImageUrls
    campaignTemplateRequestAcceptStatus
    campaignTemplateRequestNeedSendEmail
    campaignTemplateRequestCreatedAt
    campaignTemplateRequestCreatorNme
    locationLists {
      locationId
    }
  }
`;

export const getZeeTemplateRequests = gql`
  query getCampaignTemplateRequests {
    getCampaignTemplateRequests {
      ...campaignTemplateRequest
    }
  }
  ${campaignTemplateRequestFragment}
`;

export const getCampaignTemplateRequestById = gql`
  query getCampaignTemplateRequestById($id: ID!) {
    getCampaignTemplateRequestById(campaignTemplateRequestId: $id) {
      ...campaignTemplateRequest
    }
  }
  ${campaignTemplateRequestFragment}
`;

export const denyOrAccessCampaignTemplateRequest = gql`
  mutation denyOrAccessCampaignTemplateRequest($input: AccessOrDenyInput!) {
    denyOrAccessCampaignTemplateRequest(input: $input) {
      campaignTemplateRequestId
      campaignTemplateRequestAcceptStatus
      campaignTemplateRequestNeedSendEmail
    }
  }
`;

export const createZeeCampaignTemplate = gql`
  mutation requestNewCampaignTemplate($templateInput: CampaignTemplateRequestInput!, $filenames: [String]) {
    requestNewCampaignTemplate(campaignTemplateRequest: $templateInput, filenames: $filenames) {
      campaignTemplateRequestId
    }
  }
`;
