import { Injectable, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services';
import { MessageDialogComponent } from '@dialogs';
import { IUser, PoliciesEnum } from '@interfaces';
import { CommonDialogService, PolicyService, UserService } from '@services';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserRouteGuard implements CanActivate, CanActivateChild, OnDestroy {
  private sub = new Subscription();

  private messageDialogRef: MatDialogRef<MessageDialogComponent>;

  constructor(
    private userService: UserService,
    private policyService: PolicyService,
    private authService: AuthService,
    private dialogService: CommonDialogService,
  ) {}

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._canActivate(childRoute, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._canActivate(childRoute, state);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private _canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Policy Logic (To be deprecated)
    const { defaultAccess, policyIds } = route.data;

    return this.getUserProfile().pipe(
      switchMap((userProfile: IUser | null) => {
        if (!userProfile) {
          this.openFailedLoginDialog('isNoUser');
          return of(false);
        }

        return this.canActivateRoute(userProfile, defaultAccess, policyIds);
      }),
    );
  }

  private getUserProfile(): Observable<IUser | null> {
    return this.authService.getUserProfile();
  }

  private getPolicyAccess(policyIds: number[]): Observable<boolean[]> {
    const accessRequests = policyIds.map((policyId) => this.policyService.getPolicyAccess(policyId));
    return combineLatest(accessRequests);
  }

  private canActivateRoute(user: IUser, defaultAccess?: unknown, policyIds?: number[]): Observable<boolean> {
    if (defaultAccess) {
      return of(true);
    }

    if (!policyIds) {
      return of(true);
    }

    return this.getPolicyAccess(policyIds).pipe(
      map((accessResults) => {
        let canActivateRoute = accessResults.some((hasAccess) => hasAccess);

        const isAdminRole = this.userService.isAdminRole(user);
        const isAgencyRole = this.userService.isAgencyRole(user);

        if (policyIds.includes(PoliciesEnum.navAdminPortal)) {
          canActivateRoute = canActivateRoute && isAdminRole;
        }
        if (policyIds.includes(PoliciesEnum.navAgencyPortal)) {
          canActivateRoute = canActivateRoute && isAgencyRole;
        }

        if (canActivateRoute) {
          this.policyService.getNavMenus(user);
        }

        return true;
      }),
    );
  }

  private openFailedLoginDialog(type: 'isUserInactive' | 'isNoUser'): void {
    const messageType = {};
    messageType[type] = true;
    this.openMessageDialog(messageType);
    this.sub.add(
      this.messageDialogRef.afterClosed().subscribe(() => {
        this.authService.logout();
      }),
    );
  }

  private openMessageDialog(messageType: { isUserInactive?: boolean; isNoUser?: boolean }): void {
    const { isUserInactive, isNoUser } = messageType;
    this.messageDialogRef = this.dialogService.openDialog('message', {
      disableClose: true,
      data: {
        dialogType: 'warning',
        isUserInactive,
        isNoUser,
      },
    });
  }
}
