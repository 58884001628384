import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IGSheetsSync } from '@interfaces';

export const runGsheetsSync = gql`
  mutation runGsheetsSync($syncType: String!) {
    runGsheetsSync(syncType: $syncType) {
      success
      message
    }
  }
`;

interface RunGsheetsSyncResponse {
  runGsheetsSync: IGSheetsSync;
}

@Injectable({
  providedIn: 'root',
})
export class SyncSheetService {
  constructor(private apollo: Apollo) {}

  runGSheetsSync(syncType: string): Observable<IGSheetsSync> {
    return this.apollo
      .mutate<RunGsheetsSyncResponse>({
        mutation: runGsheetsSync,
        variables: { syncType },
      })
      .pipe(
        map(({ data }) => data.runGsheetsSync),
        catchError((error) => {
          return of({
            success: false,
            message: [error],
          });
        }),
      );
  }
}
