import { gql } from 'apollo-angular';

const pageInfoFragment = gql`
  fragment pageInfo on PageInfoSimplified {
    hasPreviousPage
    hasNextPage
  }
`;

const campaignReportFragment = gql`
  fragment campaignReport on CampaignPerformanceReport {
    campaignPerformanceReportCampaignId
    campaignPerformanceReportCampaignNme
    campaignPerformanceReportClicks
    campaignPerformanceReportImpressions
    campaignPerformanceReportCtr
    campaignPerformanceReportAverageCpc
    campaignPerformanceReportCost
    campaignPerformanceReportConversions
    campaignPerformanceReportViewThroughConversions
    campaignPerformanceReportCostPerConversion
    campaignPerformanceReportConversionRate
    campaignPerformanceReportDevice
    campaignPerformanceReportAdvertisingChannelType
  }
`;

export const campaignPerformanceReport = gql`
  query campaignPerformanceReports(
    $page: PageInput
    $filter: GoogleAdsPerformanceReportFilter
    $orderBy: GoogleAdsPerformanceReportOrder
  ) {
    campaignPerformanceReports(page: $page, filter: $filter, orderBy: $orderBy) {
      data {
        ...campaignReport
      }
      count
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${campaignReportFragment}
  ${pageInfoFragment}
`;

const adGroupReportFragment = gql`
  fragment adGroupReport on AdGroupPerformanceReport {
    adgroupPerformanceReportAdgroupId
    adgroupPerformanceReportAdgroupNme
    adgroupPerformanceReportCampaignId
    adgroupPerformanceReportCampaignNme
    adgroupPerformanceReportClicks
    adgroupPerformanceReportImpressions
    adgroupPerformanceReportCtr
    adgroupPerformanceReportAverageCpc
    adgroupPerformanceReportCost
    adgroupPerformanceReportConversions
    adgroupPerformanceReportDevice
    adgroupPerformanceAdvertisingChannelType
    adgroupPerformanceReportTopImpressionsPercentage
    adgroupPerformanceReportAbsoluteTopImpressionsPercentage
  }
`;

export const adGroupPerformanceReport = gql`
  query adGroupPerformanceReports(
    $page: PageInput
    $filter: GoogleAdsPerformanceReportFilter
    $orderBy: GoogleAdsPerformanceReportOrder
  ) {
    adGroupPerformanceReports(page: $page, filter: $filter, orderBy: $orderBy) {
      data {
        ...adGroupReport
      }
      count
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${adGroupReportFragment}
  ${pageInfoFragment}
`;

const keywordsReportFragment = gql`
  fragment keywordsReport on KeywordsPerformanceReport {
    keywordsPerformanceReportKeywordId
    keywordsPerformanceReportAdgroupId
    keywordsPerformanceReportAdgroupNme
    keywordsPerformanceReportCampaignId
    keywordsPerformanceReportCampaignNme
    keywordsPerformanceReportCriteria
    keywordsPerformanceReportEffectiveCpcBid
    keywordsPerformanceReportTopOfPageCpc
    keywordsPerformanceReportFirstPageOfCpc
    keywordsPerformanceReportClicks
    keywordsPerformanceReportImpressions
    keywordsPerformanceReportCtr
    keywordsPerformanceReportCost
    keywordsPerformanceReportConversions
    keywordsPerformanceReportAverageCpc
    keywordsPerformanceReportKeywordMatchType
    keywordsPerformanceReportTopImpressionsPercentage
    keywordsPerformanceReportAbsoluteTopImpressionsPercentage
  }
`;

export const keywordsPerformanceReport = gql`
  query keywordsPerformanceReports(
    $page: PageInput
    $filter: GoogleAdsPerformanceReportFilter
    $orderBy: GoogleAdsPerformanceReportOrder
  ) {
    keywordsPerformanceReports(page: $page, filter: $filter, orderBy: $orderBy) {
      data {
        ...keywordsReport
      }
      count
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${keywordsReportFragment}
  ${pageInfoFragment}
`;

const searchQueryReportFragment = gql`
  fragment searchQueryReport on SearchQueryPerformanceReport {
    searchQueryPerformanceReportAdgroupNme
    searchQueryPerformanceReportCampaignId
    searchQueryPerformanceReportCampaignNme
    searchQueryPerformanceReportQuery
    searchQueryPerformanceReportQueryMatchTypeWithVariant
    searchQueryPerformanceReportQueryTargetingStatus
    searchQueryPerformanceReportClicks
    searchQueryPerformanceReportImpressions
    searchQueryPerformanceReportCtr
    searchQueryPerformanceReportAverageCpc
    searchQueryPerformanceReportCost
  }
`;

export const searchQueryPerformanceReport = gql`
  query searchQueryPerformanceReports(
    $page: PageInput
    $filter: GoogleAdsPerformanceReportFilter
    $orderBy: GoogleAdsPerformanceReportOrder
  ) {
    searchQueryPerformanceReports(page: $page, filter: $filter, orderBy: $orderBy) {
      data {
        ...searchQueryReport
      }
      count
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${searchQueryReportFragment}
  ${pageInfoFragment}
`;
