import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { WatchQueryOptions, OperationVariables } from '@apollo/client/core';
import * as gql from './location.gql';
import {
  ILocation,
  ILocationInput,
  ILocationList,
  ILocationGroupUpdate,
  ILocationGroupListType,
  LocationGroupOrder,
  IGetLocationGroupsVariable,
  IGoogleLocationAddress,
  IGoogleAddressValidationType,
  ILocationUpdateInput,
} from '@interfaces';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { catchError, map, take, debounceTime, switchMap } from 'rxjs/operators';
import { AsyncValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { sortBy, cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private updateLocationStub: BehaviorSubject<string> = new BehaviorSubject(null);
  constructor(private apollo: Apollo) {}

  getLocationsByOrgId(id: string, needPixels = false): Observable<Array<ILocation>> {
    let query = gql.getLocationsByOrgId;
    if (needPixels) {
      query = gql.getLocationsByOrgIdWithPixels;
    }
    return this.apollo
      .watchQuery<{ locationsByOrganizationId: Array<ILocation> }>({
        query,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationsByOrganizationId || []),
        catchError(() => of([])),
      );
  }

  getLocationsAddressInfoByOrgId(id: string): Observable<Array<ILocation>> {
    return this.apollo
      .watchQuery<{ locationsByOrganizationId: Array<ILocation> }>({
        query: gql.getLocationsAddressInfoByOrgId,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) =>
          sortBy(data.locationsByOrganizationId || [], (location) => location.locationNme.toLowerCase()),
        ),
        catchError(() => of([])),
      );
  }

  getAllLocationsByViewType(needPixels = false): Observable<ILocationList> {
    const pageNum = 1;
    const pageSize = 999999;
    let query = gql.getAllLocationsByViewType;
    if (needPixels) {
      query = gql.getAllLocationsByViewTypeWithPixels;
    }
    const noData = {
      locations: [],
      totalCount: 0,
      pageCount: 0,
      pageNbr: pageNum,
      pageSize,
    };
    return this.apollo
      .watchQuery<{ locationsByViewType: ILocationList }>({
        fetchPolicy: 'network-only',
        query,
        variables: {
          nbr: pageNum,
          size: pageSize,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationsByViewType || noData),
        catchError(() => of(noData)),
      );
  }

  updateLocation(id: string, input: ILocationUpdateInput): Observable<ILocation> {
    return this.apollo
      .mutate<{ updateLocation: ILocation }>({
        mutation: gql.updateLocation,
        variables: {
          id,
          input,
        },
      })
      .pipe(
        map(({ data }) => {
          this.updateLocationStub.next('location updated');
          return data.updateLocation;
        }),
        catchError(() => of(null)),
      );
  }

  getLocationById(id: string, needPixels = false): Observable<ILocation> {
    const normalQuery = {
      fetchPolicy: 'network-only',
      query: gql.getLocationById,
      variables: { id },
    } as WatchQueryOptions<OperationVariables>;
    if (needPixels) {
      normalQuery.query = gql.getLocationByIdWithPixels;
    }
    return this.apollo
      .watchQuery<{ locationById: ILocation }>(normalQuery)
      .valueChanges.pipe(map(({ data }) => data.locationById));
  }

  createLocation(input: ILocationInput): Observable<ILocation> {
    return this.apollo
      .mutate<{ createLocation: ILocation }>({
        mutation: gql.createLocation,
        variables: { input },
      })
      .pipe(
        map(({ data }) => data.createLocation),
        catchError((err) => of(null)),
      );
  }

  locationAddressValidation(address: string, selfZip = ''): Observable<IGoogleAddressValidationType> {
    return this.apollo
      .watchQuery<{ locationAddressValidation: IGoogleAddressValidationType }>({
        query: gql.locationAddressValidation,
        variables: {
          address,
          selfZip,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationAddressValidation),
        catchError(() => of({ valid: false })),
      );
  }

  searchAddress(address: string): Observable<Array<IGoogleLocationAddress>> {
    return this.apollo
      .watchQuery<{ locationAddressSearch: Array<IGoogleLocationAddress> }>({
        query: gql.locationAddressSearch,
        variables: {
          address,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationAddressSearch),
        catchError(() => of([])),
      );
  }

  getLocationsWithBasicInfo(id: string): Observable<Array<ILocation>> {
    return this.apollo
      .watchQuery<{ locationsByOrganizationId: Array<ILocation> }>({
        query: gql.getLocationsWithBasicInfo,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) =>
          sortBy(data.locationsByOrganizationId || [], (location) => location.locationNme.toLowerCase()),
        ),
        catchError(() => of([])),
      );
  }

  // for agency view page
  getAgencyLocationAccounts(args: {
    nbr: number;
    size: number;
    orderBy: string;
    asc: boolean;
    orgId?: string;
  }): Observable<{ locations: Array<ILocation>; count: number }> {
    const noData = {
      locations: [],
      count: 0,
    };
    return this.apollo
      .watchQuery<{ getLocationsByAgency: { locations: Array<ILocation>; count: number } }>({
        query: gql.getAgencyLocationAccounts,
        fetchPolicy: 'network-only',
        variables: args,
      })
      .valueChanges.pipe(
        map(({ data }) => data.getLocationsByAgency || noData),
        catchError(() => of(noData)),
      );
  }

  getLocationsOrgInfoByIds(ids: Array<number>): Observable<Array<ILocation>> {
    return this.apollo
      .watchQuery<{ locationsById: Array<ILocation> }>({
        fetchPolicy: 'network-only',
        query: gql.getLocationsOrgInfoByIds,
        variables: { ids },
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationsById || []),
        catchError(() => of([])),
      );
  }

  getLocationWithPixelsById(id: string): Observable<ILocation> {
    return this.apollo
      .watchQuery<{ locationById: ILocation }>({
        query: gql.getLocationWithPixelsById,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationById),
        catchError(() => of(null)),
      );
  }

  getLocationsWithPixelsByOrgId(id: string): Observable<Array<ILocation>> {
    return this.apollo
      .watchQuery<{ locationsByOrganizationId: Array<ILocation> }>({
        query: gql.getLocationsWithPixelsByOrgId,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationsByOrganizationId || []),
        catchError(() => of([])),
      );
  }

  createLocationGroup(groupName: string): Observable<boolean> {
    return this.apollo
      .mutate<{ createLocationGroup: boolean }>({
        mutation: gql.createLocationGroup,
        variables: {
          name: groupName,
        },
        refetchQueries: [
          { query: gql.getLocationGroups, variables: { nbr: 0, size: 10, order: 'locationGroupNme', asc: true } },
        ],
      })
      .pipe(
        map(({ data }) => data.createLocationGroup),
        catchError(() => of(false)),
      );
  }

  updateLocationGroup(locationGroupInput: ILocationGroupUpdate): Observable<boolean> {
    return this.apollo
      .mutate<{ updateLocationGroup: boolean }>({
        mutation: gql.updateLocationGroup,
        variables: {
          ...locationGroupInput,
        },
      })
      .pipe(
        map(({ data }) => data.updateLocationGroup),
        catchError(() => of(false)),
      );
  }

  getLocationGroups(variables: IGetLocationGroupsVariable): Observable<ILocationGroupListType> {
    const noData = {
      count: 0,
      locationGroups: [],
    };
    const defaultVariables = {
      nbr: 0,
      size: 10,
      order: LocationGroupOrder.locationGroupNme,
      asc: true,
    };
    return this.apollo
      .watchQuery<{ locationGroups: ILocationGroupListType }>({
        query: gql.getLocationGroups,
        variables: { ...defaultVariables, ...variables },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const locationGroups = cloneDeep(data.locationGroups || noData);
          locationGroups.locationGroups = locationGroups.locationGroups.map((locationGroup) => {
            if (!locationGroup.locationGroupLocationIds) {
              locationGroup.locationGroupLocationIds = [];
            }
            return locationGroup;
          });
          return locationGroups;
        }),
        catchError(() => of(noData)),
      );
  }

  getActiveLocationGroups(variables: IGetLocationGroupsVariable): Observable<ILocationGroupListType> {
    return this.getLocationGroups(variables).pipe(
      map((locationGroups: ILocationGroupListType) => {
        locationGroups.locationGroups = locationGroups.locationGroups.filter(
          (locationGroup) => !!locationGroup.locationGroupActive,
        );
        return locationGroups;
      }),
    );
  }

  checkLocationGroupExists(groupName: string): Observable<boolean> {
    return this.apollo
      .watchQuery<{ checkLocationGroupExists: boolean }>({
        query: gql.checkLocationGroupExists,
        fetchPolicy: 'network-only',
        variables: {
          name: groupName,
        },
      })
      .valueChanges.pipe(
        map(({ data }) => data.checkLocationGroupExists),
        catchError(() => of(true)),
      );
  }

  checkLocationGroupExistsValidator(options?: { excludes?: Array<string> }): AsyncValidatorFn {
    return (c: AbstractControl): Observable<ValidationErrors | null> => {
      let resSub = of(null);
      if (!(!!options && options.excludes && options.excludes.includes(c.value))) {
        resSub = this.checkLocationGroupExists(c.value).pipe(
          map((res) => {
            return res ? { checkLocationGroupExists: true } : null;
          }),
          take(1),
        );
      }
      if (!!c.valueChanges) {
        return c.valueChanges.pipe(
          debounceTime(1000),
          switchMap(() => resSub),
          take(1),
        );
      }
      return of(null);
    };
  }

  toggleLocActiveStatus(input: {
    locationId: string;
    locationActive: boolean;
  }): Observable<{ locationId: string; locationActive: boolean }> {
    return this.apollo
      .mutate<{ updateLocationActiveStatus: { locationId: string; locationActive: boolean } }>({
        mutation: gql.toggleLocActiveStatus,
        variables: { input },
      })
      .pipe(
        map(({ data }) => data.updateLocationActiveStatus),
        catchError(() => of(null)),
      );
  }

  deleteLocation(locationId: string): Observable<{ locationId: string }> {
    return this.apollo
      .mutate<{ deleteLocation: { locationId: string } }>({
        mutation: gql.deleteLocation,
        variables: {
          id: locationId,
        },
      })
      .pipe(
        map(({ data }) => data.deleteLocation),
        catchError(() => of(null)),
      );
  }

  getAvailableLocationsByTemplateId(templateId: string): Observable<Array<ILocation>> {
    return this.apollo
      .watchQuery<{ sharedLocationsByTemplateId: Array<ILocation> }>({
        query: gql.availableLocationsByTemplateId,
        fetchPolicy: 'network-only',
        variables: { templateId },
      })
      .valueChanges.pipe(
        map(({ data }) => data.sharedLocationsByTemplateId || []),
        catchError(() => of([])),
      );
  }
}
