import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { union } from 'lodash';
import { Option } from '@interfaces';

interface CheckboxButtonOption {
  value: string;
  text: string;
  status: boolean;
  icon?: string;
  fillIcon?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-ui-checkbox-button',
  templateUrl: './ui-checkbox-button.component.html',
  styleUrls: ['./ui-checkbox-button.component.scss'],
})
export class UiCheckboxButtonComponent implements OnInit {
  @Input()
  ownFormGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  options: Array<CheckboxButtonOption> = [];
  @Input()
  separate = false;
  @Input()
  disabled = false;

  @Output()
  changeEvent: EventEmitter<Array<string>> = new EventEmitter();
  @Output()
  disabledClickEvent: EventEmitter<string | number> = new EventEmitter();

  constructor() {}
  ngOnInit() {
    this.initForm();
    this._listenValueChange();
  }

  initForm(): void {
    let formGroup = this.ownFormGroup;
    let controlName = this.controlName;
    if (!(!!formGroup && !!controlName)) {
      controlName = 'default';
      formGroup = new FormGroup({
        default: new FormControl('', [Validators.required]),
      });
    }
    this.ownFormGroup = formGroup;
    this.controlName = controlName;
  }

  private _listenValueChange() {
    const formGroup = this.ownFormGroup;
    const controlName = this.controlName;
    formGroup.get(controlName).valueChanges.subscribe((value) => {
      this.changeEvent.emit(value);
    });
  }

  optionStatus(value: string) {
    const formValue = this.ownFormGroup.get(this.controlName).value as Array<string>;
    const result = !!formValue.find((item) => item === value);
    return result;
  }

  clickOption(event: any, option: Option) {
    const checked = event.target.checked;
    const value = option.value;
    if (option.disabled || this.disabled) {
      this._doDisabledClick(value);
    } else {
      if (checked) {
        this.checkOption(value);
      } else {
        this.unCheckOption(value);
      }
    }
  }

  private checkOption(value: string | number) {
    const options = this.options;
    const formGroup = this.ownFormGroup;
    const controlName = this.controlName;
    const control = formGroup.get(controlName);
    let formValue = control.value as Array<string | number>;
    formValue.push(value);
    formValue = union(formValue);
    formValue = formValue.filter((item) => !!options.find((option) => option.value === item));
    control.setValue(formValue);
  }

  private unCheckOption(value: string | number) {
    const formGroup = this.ownFormGroup;
    const controlName = this.controlName;
    const control = formGroup.get(controlName);
    const formValue = control.value as Array<string>;
    control.setValue(formValue.filter((item) => item !== value));
  }

  private _doDisabledClick(value: string | number) {
    this.disabledClickEvent.emit(value);
  }

  get isError(): boolean {
    const formGroup = this.ownFormGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      const errors = formGroup.get(controlName).errors;
      const dirty = formGroup.get(controlName).dirty;
      const touched = formGroup.get(controlName).touched;
      return !!errors && (dirty || touched);
    }
    return false;
  }
}
