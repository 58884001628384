import { gql } from 'apollo-angular';

export const gmbLocation = gql`
  fragment GMBLocation on GMBLocationType {
    name
    locationName
    languageCode
    storeCode
    primaryPhone
    additionalPhones
    address {
      revision
      regionCode
      languageCode
      postalCode
      sortingCode
      administrativeArea
      locality
      sublocality
      addressLines
      recipients
      organization
    }
    websiteUrl
    regularHours {
      periods {
        openDay
        openTime
        closeDay
        closeTime
      }
    }
    specialHours {
      specialHourPeriods {
        startDate {
          year
          month
          day
        }
        openTime
        endDate {
          year
          month
          day
        }
        closeTime
        isClosed
      }
    }
  }
`;

export const getGMBAccountLocationsQuery = gql`
  query getGMBLocations {
    getGMBLocations {
      name
      locationName
    }
  }
`;

export const bindMyBusinessLocations = gql`
  mutation bindGMBLocations($locations: [GMBLocationBind]!) {
    bindGMBLocations(locations: $locations) {
      success
    }
  }
`;

export const disconnectMyBusiness = gql`
  mutation disconnectMyBusiness {
    disconnectMyBusiness {
      success
      message
    }
  }
`;

export const getGMBLocation = gql`
  query getGMBLocation($gmbLocation: String) {
    getGMBLocation(gmbLocation: $gmbLocation) {
      ...GMBLocation
    }
  }
  ${gmbLocation}
`;

export const editGMBLocation = gql`
  mutation editGMBLocation($location: GMBLocationInput) {
    editGMBLocation(location: $location) {
      success
    }
  }
`;
