import { Injectable, SecurityContext } from '@angular/core';
import { Utils } from '@utils';
import { DomSanitizer } from '@angular/platform-browser';
import { ChannelName, ILocation } from '@interfaces';

interface Chip {
  chipName: string;
  charDropCount: number;
  stringDisplayInZor: string;
  placeholder: string;
  isLocation?: boolean;
  isCity?: true;
  isPhoneNumber?: true;
}

@Injectable({
  providedIn: 'root',
})
export class AdTextChipParseService {
  private chipList: Array<Chip> = [];
  private googleChipList: Array<Chip> = [];
  private preString = '  **';
  private postString = '**  ';
  private parseReg: RegExp;
  private specialChars: string[];

  constructor(private domSanitizer: DomSanitizer) {
    this.specialChars = ['/', '*', '$', '^', '|', '\\', '+', '{', '}', '(', ')'];
    this.googleChipList = [
      {
        chipName: 'Location',
        charDropCount: 20,
        stringDisplayInZor: 'Location',
        placeholder: `${this.preString}Locate${this.postString}`,
        isLocation: true,
      },
      {
        chipName: 'City',
        charDropCount: 15,
        stringDisplayInZor: 'City',
        placeholder: `${this.preString}Cty${this.postString}`,
        isCity: true,
      },
    ];
    this.chipList = [
      ...this.googleChipList,
      {
        chipName: 'Phone Number',
        charDropCount: 12,
        stringDisplayInZor: 'Phone Number',
        placeholder: `${this.preString}phoneNumb${this.postString}`,
        isPhoneNumber: true,
      },
    ];
    this.parseReg = new RegExp(
      `${this.transformSpecialCharInRegExp(this.preString)}` +
        `(.)*?${this.transformSpecialCharInRegExp(this.postString)}`,
      'g',
    );
  }

  private transformSpecialCharInRegExp(input: string): string {
    let output = '';
    const len = input.length;
    for (let i = 0; i < len; i++) {
      if (this.specialChars.indexOf(input[i]) !== -1) {
        output += `\\` + input[i];
      } else {
        output += input[i];
      }
    }
    return output;
  }

  getAdTextChipList(channelName?: ChannelName): Array<Chip> {
    if (channelName === ChannelName.GOOGLE) {
      return this.googleChipList;
    }

    return this.chipList;
  }

  buildChipObject(chips: object[]): any[] {
    const list = [];
    const len = chips.length;
    for (let index = 0; index < len; index++) {
      list.push({
        chipName: chips[index]['chipName'],
        addToText: false,
        index,
        charDropCount: chips[index]['charDropCount'],
        placeholderString: chips[index]['placeholder'],
        needDelete: false,
      });
    }
    return list;
  }

  parseAdTextInZorPage(input: string, chipList: object[] = []): any {
    if (input == null) {
      return [];
    }
    let result = null;
    let start = 0;
    const output = [];
    // TODO refactor
    result = this.parseReg.exec(input);
    while (result) {
      output.push({
        content: input.slice(start, result.index),
        isBoldDisplay: false,
      });
      for (const chip of chipList) {
        if (chip['placeholder'] === result[0]) {
          output.push({
            content: chip['stringDisplayInZor'],
            isBoldDisplay: true,
          });
        }
      }
      start = result.index + result[0].length;
      result = this.parseReg.exec(input);
    }
    output.push({
      content: input.slice(start),
      isBoldDisplay: false,
    });
    return output;
  }

  parseAdTextInZeePageWithMultiLocation(input: string, chipList: object[] = []): any {
    return this.parseAdTextInZorPage(input, chipList);
  }

  parseAdTextInZeePageWithSingleLocation(input: string, location: ILocation): any {
    if (input == null) {
      return [];
    }
    let result = null;
    let start = 0;
    const output = [];
    // TODO refactor
    result = this.parseReg.exec(input);
    while (result) {
      output.push({
        content: input.slice(start, result.index),
        isBoldDisplay: false,
      });
      for (const chip of this.chipList) {
        if (chip.placeholder === result[0]) {
          if (chip.isLocation) {
            output.push({
              content: (location && location.campaignTemplateChipAddress) || '',
              isBoldDisplay: false,
            });
          } else if (chip.isPhoneNumber) {
            output.push({
              content: (location && location.campaignTemplateChipPhoneNumber) || '',
              isBoldDisplay: false,
            });
          } else if (chip.isCity) {
            output.push({
              content: (location && location.campaignTemplateChipCity) || '',
              isBoldDisplay: false,
            });
          }
          break;
        }
      }
      start = result.index + result[0].length;
      result = this.parseReg.exec(input);
    }
    output.push({
      content: input.slice(start),
      isBoldDisplay: false,
    });
    return output;
  }

  buildDisplayedAdTextInfo(
    value: any[],
    textEle: any,
    lineLimit: number = 8,
    widthRate: number = 0.4,
  ): { triggerLineLimit: boolean; adTextContent: string; filteredAdTextContent: string } {
    const tmp = this.transferAdText(value);
    const wholeAdText = tmp.join(' ');
    if (!textEle) {
      return {
        triggerLineLimit: false,
        adTextContent: wholeAdText,
        filteredAdTextContent: wholeAdText,
      };
    }
    let triggerLineLimit;
    let filteredAdTextContent;
    if (this.isDivHeightOverflow(wholeAdText, textEle, lineLimit)) {
      triggerLineLimit = true;
      filteredAdTextContent = this.filterAdTextByLine(tmp, textEle, lineLimit, widthRate);
    } else {
      triggerLineLimit = false;
      filteredAdTextContent = wholeAdText;
    }
    return {
      triggerLineLimit,
      adTextContent: wholeAdText,
      filteredAdTextContent,
    };
  }

  private transferAdText(input: { content: string; isBoldDisplay: boolean }[]): string[] {
    if (!Array.isArray(input) || !input.length) {
      return [''];
    }
    const output = [];
    input.forEach((item) => {
      let tmp = [];
      if (!!item.content) {
        tmp = item.content.split(' ');
      }
      tmp.forEach((str) => {
        if (str.trim() && item.isBoldDisplay) {
          output.push(`<strong>${str.trim()}</strong>`);
        } else if (str.trim() && !item.isBoldDisplay) {
          output.push(str);
        }
      });
    });
    return output;
  }

  private filterAdTextByLine(source: any[], targetEle: any, line: number, widthRate: number): string {
    const index = this._getListIndex(source, targetEle, line - 1);
    if (index === -1) {
      return '';
    }
    if (index === source.length) {
      return source.join(' ');
    }
    for (let pos = index + 1; pos <= source.length; pos++) {
      if (this.isDivWidthOverflow(source.slice(index, pos).join(' '), targetEle, widthRate)) {
        return source.slice(0, pos - 1).join(' ') + '...';
      }
    }
  }

  private _getListIndex(list: string[], targetEle: any, lineLimit: number, preStringList: any[] = []): number {
    const preIndex = preStringList.length;
    let preStr = '';
    if (preIndex) {
      preStr = preStringList.join(' ');
    }
    if (!this.isDivHeightOverflow(preStr + list.join(' '), targetEle, lineLimit)) {
      return list.length + preIndex;
    } else {
      const left = list.slice(0, Math.ceil(list.length / 2));
      const right = list.slice(Math.ceil(list.length / 2));
      if (this.isDivHeightOverflow(preStr + left.join(' '), targetEle, lineLimit)) {
        if (1 === left.length) {
          return preIndex;
        }
        return this._getListIndex(left, targetEle, lineLimit, [...preStringList]);
      }
      return this._getListIndex(right, targetEle, lineLimit, [...preStringList, ...left]);
    }
  }

  public isDivHeightOverflow(content: string, targetEle: any, lineLimit: number) {
    const div = document.createElement('div');
    div.setAttribute(
      'style',
      `position: absolute; visibility: hidden; height: auto; white-space: ${Utils.GetElementStyle(
        targetEle,
        'white-space',
      )};` +
        `font-family: ${Utils.GetElementStyle(targetEle, 'font-family')};` +
        `width: ${Utils.GetElementStyle(targetEle, 'width')};` +
        `font-size: ${Utils.GetElementStyle(targetEle, 'font-size')};` +
        `line-height: ${Utils.GetElementStyle(targetEle, 'line-height')}`,
    );
    div.innerHTML = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.domSanitizer.bypassSecurityTrustHtml(content),
    );
    document.body.appendChild(div);
    const line =
      parseFloat(Utils.GetElementStyle(div, 'height')) / parseFloat(Utils.GetElementStyle(targetEle, 'line-height'));
    div.parentNode.removeChild(div);
    return line > lineLimit;
  }

  private isDivWidthOverflow(content: string, targetEle: any, widthLimit: number) {
    const div = document.createElement('div');
    div.setAttribute(
      'style',
      `position: absolute; visibility: hidden; height: auto; white-space: ${Utils.GetElementStyle(
        targetEle,
        'white-space',
      )};` +
        `font-family: ${Utils.GetElementStyle(targetEle, 'font-family')};` +
        `width: auto` +
        `font-size: ${Utils.GetElementStyle(targetEle, 'font-size')};` +
        `line-height: ${Utils.GetElementStyle(targetEle, 'line-height')}`,
    );
    div.innerHTML = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.domSanitizer.bypassSecurityTrustHtml(content),
    );
    document.body.appendChild(div);
    const width =
      parseFloat(Utils.GetElementStyle(div, 'width')) / parseFloat(Utils.GetElementStyle(targetEle, 'width'));
    div.parentNode.removeChild(div);
    return width > widthLimit;
  }

  getRealChipTextLength(text: string): number {
    const chipList = this.getAdTextChipList();
    let chipTextsLength = 0;
    chipList.forEach((chip) => {
      if (text.includes(chip.placeholder)) {
        text = text.replace(chip.placeholder, '');
        chipTextsLength += chip.charDropCount + 1;
      }
    });
    return text.length + chipTextsLength;
  }
}
