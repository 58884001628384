import { gql } from 'apollo-angular';

export const ObjectiveType = gql`
  fragment objective on ObjectiveType {
    objectiveId
    objectiveNme
    objectiveOrganizationId
    objectiveCreatorId
    objectiveCreatedDte
    objectiveUpdatedDte
    objectiveIsActive
    objectiveIsDeleted
    objectiveTemplateCount
    usedByCampainTemplate
  }
`;

export const getObjectiveById = gql`
  query objectiveById($id: ID!) {
    objectiveById(id: $id) {
      ...objective
    }
  }
  ${ObjectiveType}
`;

export const getObjectivesByOrganizationId = gql`
  query objectivesByOrganizationId(
    $id: ID!
    $orderBy: String
    $asc: Boolean
    $active: Boolean
    $nbr: Int
    $size: Int
    $filter: String
  ) {
    objectivesByOrganizationId(
      id: $id
      orderBy: $orderBy
      asc: $asc
      active: $active
      nbr: $nbr
      size: $size
      filter: $filter
    ) {
      count
      objectives {
        ...objective
      }
    }
  }
  ${ObjectiveType}
`;

export const getObjectivesByCampaignTemplateId = gql`
  query objectivesByCampaignTemplateId($id: ID!) {
    objectivesByCampaignTemplateId(id: $id) {
      ...objective
    }
  }
  ${ObjectiveType}
`;

export const createObjective = gql`
  mutation createObjective($input: ObjectiveInput!) {
    createObjective(objective: $input) {
      ...objective
    }
  }
  ${ObjectiveType}
`;

export const updateObjective = gql`
  mutation updateObjective($input: ObjectiveUpdateInput!) {
    updateObjective(objective: $input) {
      ...objective
    }
  }
  ${ObjectiveType}
`;
