/* tslint:disable */
export interface InternalError extends Error {}
/* tslint:enable */

export interface InternalErrorCtor {
  new (msg?: string): InternalError;
  data: any;
}

function InternalErrorImpl(this: any, msg: string) {
  Error.call(this);
  this.message = msg || 'internal error occurred.';
  this.name = 'InternalError';
  return this;
}

InternalErrorImpl.prototype = Object.create(Error.prototype);

export const InternalError: InternalErrorCtor = InternalErrorImpl as any;
