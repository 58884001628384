<mat-card class="card-container" [class.no-shadow]="noShadow"
          [ngClass]="{'green-top':borderColor==='green','blue-top':borderColor==='blue','pink-top':borderColor==='pink',
          'orange-top':borderColor==='orange','purple-top':borderColor==='purple','red-top':borderColor==='red'}">
  <div class="card-title" *ngIf="!!title">{{ title | uppercase }}</div>
  <ng-template [ngIf]="!!bigTitle">
    <h1 class="card-big-title">{{bigTitle}}</h1>
    <div class="card-side-icon"
         [ngClass]="{'bg-green':color==='green','bg-red':color==='red','bg-orange':color==='orange'}">
      <img src="assets/icons/fill/{{imageUrl}}.svg" alt="">
    </div>
  </ng-template>
  <ng-content></ng-content>
</mat-card>


