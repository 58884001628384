import { Injectable } from '@angular/core';
import * as AccountsManagementGql from './manage-user-social-accounts.gql';
import { MessagePromptService } from '../message-prompt/message-prompt.service';
import { LocationService } from '../location/location.service';
import { OrganizationService } from '../organization/organization.service';
import { UserService } from '../user/user.service';
import {
  AccountConnectStatus,
  ChannelName,
  ChannelOauthParamsInterface,
  FacebookAccountBindInfoInterface,
  FacebookOAuthConfirmInterface,
  FacebookPageBindInputInterface,
  FacebookPageInfoInterface,
  FacebookUserInfoInterface,
  GoogleAccountBindInfoInterface,
  GoogleAccountInfoInterface,
  GoogleAccountsBindInterface,
  GoogleOAuthConfirmInterface,
  ILocationBindDetail,
  InstagramAccountBindInterface,
  InstagramLocationPageInterface,
  InstagramPageListInterface,
  IOrganization,
  IUser,
  OrgGoogleAccountsInterface,
  TokenExpirationStatusInterface,
  GetDefaultInsAccountsInterface,
} from '@interfaces';
import { GraphqlError, InternalError, NetworkError, Utils } from '@utils';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PromptDialog, ReminderDialog } from '@dialogs';

// TODO: Double check!!!

@Injectable({
  providedIn: 'root',
})
export class ManageUserSocialAccountsService {
  private dialogRef: MatDialogRef<PromptDialog | ReminderDialog>;
  private oauthRequiredInfos: ChannelOauthParamsInterface;
  private sub: Subscription;

  constructor(
    private apollo: Apollo,
    private dialog: MatDialog,
    private org: OrganizationService,
    private location: LocationService,
    private user: UserService,
    private messageDisplay: MessagePromptService,
  ) {
    this.getOauthRequiredInfo().then();
  }

  static CheckAccountConnected(input: AccountConnectStatus) {
    for (const key of Object.keys(input)) {
      if (input[key] === true) {
        return true;
      }
    }
    return false;
  }

  static ShowPromptModal(input: AccountConnectStatus, profile: IUser): boolean {
    return !!(
      profile &&
      profile.userChannelsConnectWarnInd === true &&
      !ManageUserSocialAccountsService.CheckAccountConnected(input)
    );
  }

  static BuildPreString(channel: string): string {
    return (
      `You have not yet linked your ` +
      String(channel[0].toUpperCase()) +
      channel.slice(1) +
      ` account, please connect your advertising channels on your `
    );
  }

  static IsFacebookNotBindPages(org: IOrganization): boolean {
    return !(org && org.facebookAccount && org.facebookAccount.facebookAccountDefaultPageRealId);
  }

  static NotBindFacebookPagesForZeeLocations(org: IOrganization): boolean {
    return !(org && org.facebookAccount && org.facebookAccount.facebookAccountDefaultZeePageRealId);
  }

  static NotBindInstagramAccountForZeeLocations(org: IOrganization): boolean {
    return !(org && org.facebookAccount && org.facebookAccount.facebookAccountZeeInstagramAct);
  }

  static WhetherCanRunCampaign(
    orgInfo: IOrganization,
    channel: string,
    locations?: InstagramLocationPageInterface[],
  ): boolean {
    let result = false;
    switch (channel) {
      case ChannelName.FACEBOOK:
        result = !ManageUserSocialAccountsService.IsFacebookNotBindPages(orgInfo);
        break;
      case ChannelName.INSTAGRAM:
        result =
          Array.isArray(locations) && locations.some((item: InstagramLocationPageInterface) => !!item.instagramAccount);
        break;
      case ChannelName.GOOGLE:
        result = !!(orgInfo && orgInfo.googleAccount && orgInfo.googleAccount.googleAccountDefaultCustomerId);
        break;
    }
    return result;
  }

  private static BuildOptions<T>(payload: T): T {
    return Object.assign({ fetchPolicy: 'network-only' }, payload);
  }

  private httpErrorHandle: any = (error) => {
    if (this.messageDisplay.hasGraphqlError(error)) {
      const messages = this.messageDisplay.buildErrorMessage(error.graphQLErrors);
      this.messageDisplay.displayError({ data: { content: messages } });
      throw new GraphqlError();
    } else if (this.messageDisplay.hasNetworkError(error)) {
      const messages = this.messageDisplay.buildErrorMessage(error.networkError.result.errors);
      this.messageDisplay.displayError({ data: { content: messages } });
      throw new NetworkError();
    } else {
      throw new InternalError();
    }
    // tslint:disable-next-line:semicolon
  };

  getOauthRequiredInfo(): Promise<ChannelOauthParamsInterface> {
    if (!this.user.canManageSocialAccounts()) {
      return new Promise<ChannelOauthParamsInterface>((resolve) => {
        const tmp = { appId: '', scopes: '', oauthUri: '' };
        resolve({
          google: { ...tmp },
          facebook: { ...tmp },
          instagram: { ...tmp },
        });
      });
    }
    return new Promise<ChannelOauthParamsInterface>((resolve) => {
      if (this.oauthRequiredInfos) {
        resolve(this.oauthRequiredInfos);
      } else {
        this.apollo
          .watchQuery<{ getOAuthCredential: ChannelOauthParamsInterface }>({
            query: AccountsManagementGql.getOAuthRequiredInfo,
            fetchPolicy: 'network-only',
          })
          .valueChanges.pipe(
            map(({ data }) => data.getOAuthCredential),
            catchError(this.httpErrorHandle),
          )
          .subscribe((oauthRequiredInfos) => {
            this.oauthRequiredInfos = oauthRequiredInfos;
            resolve(oauthRequiredInfos);
          });
      }
    });
  }

  getCheckTokenExpiration(): Observable<Array<TokenExpirationStatusInterface>> {
    return this.apollo
      .watchQuery<{ checkTokenExpiration: Array<TokenExpirationStatusInterface> }>({
        query: AccountsManagementGql.getCheckTokenExpiration,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.checkTokenExpiration),
        catchError((err) => {
          this.httpErrorHandle(err);
          return of([]);
        }),
      );
  }

  getAccountsConnectedStatus(): Observable<AccountConnectStatus> {
    return this.apollo
      .watchQuery<{ getConnectedState: AccountConnectStatus }>({
        query: AccountsManagementGql.getChannelConnectStatus,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.getConnectedState),
        catchError((err) => {
          this.httpErrorHandle(err);
          return of({
            facebook: null,
            google: null,
            instagram: null,
          });
        }),
      );
  }

  getZowiAccountsConnectedStatus(): Observable<{
    facebook: FacebookAccountBindInfoInterface;
    google: GoogleAccountBindInfoInterface;
  }> {
    return this.apollo
      .watchQuery<{
        getZOWIChannelAccount: { facebook: FacebookAccountBindInfoInterface; google: GoogleAccountBindInfoInterface };
      }>({
        query: AccountsManagementGql.getZowiAccountsConnectedStatus,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.getZOWIChannelAccount),
        catchError((err) => {
          this.httpErrorHandle(err);
          return of({
            facebook: null,
            google: null,
          });
        }),
      );
  }

  getLocationConnectedState(): Observable<{ facebook: boolean; google: boolean; instagram: boolean }> {
    return this.apollo
      .watchQuery<{ getLocationBindState: { facebook: boolean; google: boolean; instagram: boolean } }>({
        query: AccountsManagementGql.getLocationConnectStatus,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.getLocationBindState),
        catchError((err) => {
          this.httpErrorHandle(err);
          return of({
            facebook: false,
            google: false,
            instagram: false,
          });
        }),
      );
  }

  getLocationSocialAccountInfo(): Observable<ILocationBindDetail> {
    return this.apollo
      .watchQuery<{ getUserLocationBindDetail: ILocationBindDetail }>({
        query: AccountsManagementGql.getLocationSocialAccountInfo,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.getUserLocationBindDetail),
        catchError((err) => {
          this.httpErrorHandle(err);
          return of(null);
        }),
      );
  }

  transferFBOauthDataToServer(payload: FacebookOAuthConfirmInterface): Observable<FacebookUserInfoInterface> {
    return this.apollo
      .mutate<{ confirmFacebookOAuth: FacebookUserInfoInterface }>({
        mutation: AccountsManagementGql.confirmFacebookOAuth,
        variables: { result: payload },
      })
      .pipe(
        map(({ data }) => data.confirmFacebookOAuth),
        catchError(this.httpErrorHandle),
      );
  }

  bindFacebookPages(payload: FacebookPageBindInputInterface): Observable<{ success: boolean }> {
    return this.apollo
      .mutate<{ bindPages: { success: boolean } }>({
        mutation: AccountsManagementGql.bindPagesForFacebook,
        variables: {
          pages: payload.pages,
          defaultZeeAdActId: payload.defaultZeeAdActId,
          defaultZeePageId: payload.defaultZeePageId,
          defaultAdActId: payload.defaultAdActId,
          defaultPageId: payload.defaultPageId,
        },
      })
      .pipe(
        map(({ data }) => data.bindPages),
        catchError(this.httpErrorHandle),
      );
  }

  getFacebookPagesFromServer(after = ''): Observable<FacebookUserInfoInterface> {
    const isFinishGetAccountSub = new ReplaySubject(1);
    this.apollo
      .watchQuery<{ facebookPages: FacebookUserInfoInterface }>({
        query: AccountsManagementGql.getFacebookAdAccountsAndPages,
        variables: { after },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          if (data && !!data.facebookPages) {
            return data.facebookPages;
          }
          return null;
        }),
        catchError(this.httpErrorHandle),
      )
      .subscribe(
        (facebookPages) => {
          if (facebookPages && facebookPages.after) {
            this.getFacebookPagesFromServer(facebookPages.after).subscribe((res) => {
              const adAccounts = [...facebookPages.adAccounts, ...res.adAccounts];
              isFinishGetAccountSub.next({
                adAccounts,
              });
            });
          } else {
            isFinishGetAccountSub.next(facebookPages);
          }
        },
        (err) => {
          isFinishGetAccountSub.error(err);
        },
      );
    return isFinishGetAccountSub.asObservable() as Observable<FacebookUserInfoInterface>;
  }

  getPagesByAdAccountId(adAccountId: string): Observable<Array<FacebookPageInfoInterface>> {
    return this.apollo
      .watchQuery<{ getPagesByAdAccountId: Array<FacebookPageInfoInterface> }>({
        query: AccountsManagementGql.getPagesByAdAccountId,
        variables: {
          adAccountId,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.getPagesByAdAccountId),
        catchError(this.httpErrorHandle),
      );
  }

  disconnectFacebookAccount(): Observable<{ success: boolean; message: string }> {
    return this.apollo
      .mutate<{ disconnectFacebook: { success: boolean; message: string } }>({
        mutation: AccountsManagementGql.disconnectFacebook,
      })
      .pipe(
        map(({ data }) => data.disconnectFacebook),
        catchError(this.httpErrorHandle),
      );
  }

  transferIGOauthDataToServer(payload: FacebookOAuthConfirmInterface): Observable<InstagramPageListInterface> {
    return this.apollo
      .mutate<{ confirmInstagramOAuth: InstagramPageListInterface }>({
        mutation: AccountsManagementGql.confirmInstagramOAuth,
        variables: { result: payload },
      })
      .pipe(
        map(({ data }) => data.confirmInstagramOAuth),
        catchError(this.httpErrorHandle),
      );
  }

  getDefaultInsAccounts(): Observable<GetDefaultInsAccountsInterface> {
    return this.apollo
      .watchQuery<{ getDefaultInsAccounts: GetDefaultInsAccountsInterface }>({
        query: AccountsManagementGql.getDefaultInsAccounts,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.getDefaultInsAccounts),
        catchError(this.httpErrorHandle),
      );
  }

  getInstagramPagesFromServer(): Observable<Array<InstagramLocationPageInterface>> {
    return this.apollo
      .watchQuery<{ locationPages: Array<InstagramLocationPageInterface> }>({
        query: AccountsManagementGql.getInstagramPages,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => data.locationPages),
        catchError(this.httpErrorHandle),
      );
  }

  bindInstagramAccount(
    payload: InstagramAccountBindInterface[],
    zorDefault: string,
    zeeDefault: string,
    zeeDefaultAccountType: string,
    zorDefaultAccountType: string,
  ): Observable<{ success: boolean }> {
    return this.apollo
      .mutate<{ bindInstagramAccounts: { success: boolean } }>({
        mutation: AccountsManagementGql.bindInstagramAccounts,
        variables: {
          accounts: payload,
          zorDefault: zorDefault || '',
          zeeDefault: zeeDefault || '',
          zorDefaultAccountType: zorDefaultAccountType || '',
          zeeDefaultAccountType: zeeDefaultAccountType || '',
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => data.bindInstagramAccounts),
        catchError(this.httpErrorHandle),
      );
  }

  disconnectInstagramAccount(): Observable<{ success: boolean; message: string }> {
    return this.apollo
      .mutate<{ disconnectInstagram: { success: boolean; message: string } }>({
        mutation: AccountsManagementGql.disconnectInstagram,
      })
      .pipe(
        map(({ data }) => data.disconnectInstagram),
        catchError(this.httpErrorHandle),
      );
  }

  transferGoogleOauthDataToServer(
    payload: GoogleOAuthConfirmInterface,
  ): Observable<{ id: string; name: string; email: string }> {
    return this.apollo
      .mutate<{ confirmGoogleOAuth: { id: string; name: string; email: string } }>({
        mutation: AccountsManagementGql.GoogleOauthResultConfirm,
        variables: { result: payload },
      })
      .pipe(
        map(({ data }) => data.confirmGoogleOAuth),
        catchError(this.httpErrorHandle),
      );
  }

  getGoogleAccounts(): Observable<{
    defaultId?: string;
    accountList: Array<any>;
  }> {
    return this.apollo
      .watchQuery<{ allLinkedGGAWAccount: OrgGoogleAccountsInterface }>({
        query: AccountsManagementGql.getGoogleAccounts,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const accountsInfo: OrgGoogleAccountsInterface = data.allLinkedGGAWAccount;
          if (!accountsInfo) {
            return { accountList: [] };
          }
          if (!Array.isArray(accountsInfo.accounts) || !accountsInfo.accounts.length) {
            this.promptForNoGoogleAdsAccount();
            return { accountList: [] };
          }
          return {
            defaultId: accountsInfo.defaultAccountId,
            accountList: accountsInfo.accounts.filter(
              (account: GoogleAccountInfoInterface) => !account.googleAdwordsCanManageClientsInd,
            ),
          };
        }),
        catchError((err) => {
          this.httpErrorHandle(err);
          return of({
            defaultId: null,
            accountList: [],
          });
        }),
      );
  }

  getSavedGoogleAdsAccounts(): Observable<{
    accountList: GoogleAccountInfoInterface[];
  }> {
    return this.apollo
      .watchQuery<{ googleAdsAccounts: GoogleAccountInfoInterface[] }>({
        query: AccountsManagementGql.getSavedGoogleAdsAccounts,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const accountList: GoogleAccountInfoInterface[] = data.googleAdsAccounts;
          if (!accountList) {
            return { accountList: [] };
          }
          if (!Array.isArray(accountList) || !accountList.length) {
            this.promptForNoGoogleAdsAccount();
            return { accountList: [] };
          }
          return {
            accountList: accountList.filter(
              (account: GoogleAccountInfoInterface) => !account.googleAdwordsCanManageClientsInd,
            ),
          };
        }),
        catchError((err) => {
          this.httpErrorHandle(err);
          return of({
            accountList: [],
          });
        }),
      );
  }

  getGoogleLocationsAndAccounts(): Observable<any> {
    const user: IUser = this.user.getCachedUserProfile();
    const locations = this.location.getLocationsByOrgId(user.organization.organizationId);
    const accounts = this.getGoogleAccounts();
    return combineLatest([locations, accounts]).pipe(
      map((res) => ({ locations: res[0], ...res[1] })),
      catchError((err) => {
        throw err;
      }),
    );
  }

  bindGoogleAccounts(payload: GoogleAccountsBindInterface): Observable<{ success: boolean }> {
    return this.apollo
      .mutate<{ bindGoogleAccounts: { success: boolean } }>({
        mutation: AccountsManagementGql.bindGoogleAccounts,
        variables: { defaultCustomerId: payload.defaultId, links: payload.locationAccountList },
      })
      .pipe(
        map(({ data }) => data.bindGoogleAccounts),
        catchError(this.httpErrorHandle),
      );
  }

  disconnectGoogleAccount(): Observable<{ success: boolean; message: string }> {
    return this.apollo
      .mutate<{ disconnectGoogle: { success: boolean; message: string } }>({
        mutation: AccountsManagementGql.disconnectGoogle,
      })
      .pipe(
        map(({ data }) => data.disconnectGoogle),
        catchError(this.httpErrorHandle),
      );
  }

  validationForCampaignActions(currentChannel: string, router: string, callback: () => void, failCb: () => void) {
    if (this.user.isOrgUser()) {
      this._validationCampaignActionsForNotLocationUser(currentChannel, router, callback, failCb); // TODO:
    } else if (this.user.isLocUser()) {
      this._validationCampaignActionsForLocationUser(currentChannel, router, callback, failCb); // TODO:
    }
  }

  private _validationCampaignActionsForLocationUser(
    currentChannel: string,
    router: string,
    callback: () => void,
    failCb: () => void,
  ) {
    Utils.CancelSubscribe(this.sub);
    this.sub = this.getLocationConnectedState().subscribe(
      (res) => {
        if (res && res[currentChannel.toLowerCase()]) {
          if (callback) {
            callback();
          }
        } else {
          if (failCb) {
            failCb();
          }
          this.showErrorMsgForLocationUser(currentChannel);
        }
        Utils.CancelSubscribe(this.sub);
      },
      (err) => {
        if (failCb) {
          failCb();
        }
        this.showErrorMsgIfNeeded(err, 'Error Occurred in getting location connect status.');
        Utils.CancelSubscribe(this.sub);
      },
    );
  }

  private _validationCampaignActionsForNotLocationUser(
    currentChannel: string,
    router: string,
    callback: () => void,
    failCb: () => void,
  ) {
    Utils.CancelSubscribe(this.sub);
    const statusSub = this.getAccountsConnectedStatus().subscribe(
      (status) => {
        Utils.CancelSubscribe(statusSub);
        if (!status[currentChannel]) {
          if (failCb) {
            failCb();
          }
          this.promptForSpecificChannelConnect(router, currentChannel);
          return;
        } else {
          const orgInfo = this.org.getOrgSocialAccountInfoByUser();
          let pageOrAccounts;
          switch (currentChannel.toLowerCase()) {
            case ChannelName.FACEBOOK:
              pageOrAccounts = this.getFacebookPagesFromServer();
              break;
            case ChannelName.INSTAGRAM:
              pageOrAccounts = this.getInstagramPagesFromServer();
              break;
            case ChannelName.GOOGLE:
              pageOrAccounts = this.getSavedGoogleAdsAccounts().pipe(
                map((res: { accountList: GoogleAccountInfoInterface[] }) => res.accountList || []),
              );
              break;
          }

          this.sub = combineLatest([pageOrAccounts, orgInfo]).subscribe(
            (response) => {
              Utils.CancelSubscribe(this.sub);

              let hasFbAdAccounts;
              let hasFbPages;
              let havePagesOrAccounts;
              let canRunCampaign;
              switch (currentChannel.toLowerCase()) {
                case ChannelName.FACEBOOK:
                  const fbPages = response[0] as FacebookUserInfoInterface;
                  hasFbAdAccounts = fbPages && Array.isArray(fbPages.adAccounts) && fbPages.adAccounts.length;
                  hasFbPages = hasFbAdAccounts
                    ? fbPages.adAccounts.some((item) => item.pages && item.pages.length)
                    : false;
                  havePagesOrAccounts = hasFbAdAccounts && hasFbPages;
                  break;
                case ChannelName.INSTAGRAM:
                  const insPages = response[0] as InstagramLocationPageInterface[];
                  havePagesOrAccounts = !!Utils.FindItemInArray(
                    insPages,
                    null,
                    (v, item) => !!item['accounts'].length,
                  )[0];
                  if (this.user.isZOWIView()) {
                    havePagesOrAccounts =
                      havePagesOrAccounts ||
                      !ManageUserSocialAccountsService.NotBindInstagramAccountForZeeLocations(response[1]);
                  }
                  break;
                case ChannelName.GOOGLE:
                  havePagesOrAccounts = Array.isArray(response[0]) && response[0].length;
                  break;
              }
              if (!havePagesOrAccounts) {
                if (failCb) {
                  failCb();
                }
                this.noPageOrAccountFound(currentChannel, hasFbAdAccounts, hasFbPages);
                return;
              }

              switch (currentChannel.toLowerCase()) {
                case ChannelName.FACEBOOK:
                  canRunCampaign = ManageUserSocialAccountsService.WhetherCanRunCampaign(response[1], currentChannel);
                  break;
                case ChannelName.INSTAGRAM:
                  canRunCampaign = ManageUserSocialAccountsService.WhetherCanRunCampaign(
                    response[1],
                    currentChannel,
                    response[0] as InstagramLocationPageInterface[],
                  );
                  if (this.user.isZOWIView()) {
                    canRunCampaign =
                      canRunCampaign ||
                      !ManageUserSocialAccountsService.NotBindInstagramAccountForZeeLocations(response[1]);
                  }
                  break;
                case ChannelName.GOOGLE:
                  canRunCampaign = ManageUserSocialAccountsService.WhetherCanRunCampaign(response[1], currentChannel);
                  break;
              }
              if (canRunCampaign) {
                if (callback) {
                  callback();
                }
              } else {
                if (failCb) {
                  failCb();
                }
                this.noBoundPageOrAccountFound(currentChannel, router);
              }
            },
            () => {
              if (failCb) {
                failCb();
              }
              Utils.CancelSubscribe(this.sub);
            },
          );
        }
      },
      () => {
        if (failCb) {
          failCb();
        }
        Utils.CancelSubscribe(statusSub);
      },
      undefined,
    );
  }

  noPageOrAccountFound(channel: string, hasFbAccounts?: boolean, hasFbPages?: boolean): void {
    switch (channel.toLowerCase()) {
      case ChannelName.FACEBOOK:
        if (!hasFbAccounts) {
          this.promptForNoFacebookAdAccounts();
          return;
        }
        if (!hasFbPages) {
          this.promptForNoFacebookPages();
        }
        break;
      case ChannelName.INSTAGRAM:
        this.promptForNoInstagramAccountsFound();
        break;
      case ChannelName.GOOGLE:
        this.promptForNoGoogleAccountsFound();
        break;
    }
  }

  noBoundPageOrAccountFound(channel: string, router: string) {
    switch (channel.toLowerCase()) {
      case ChannelName.FACEBOOK:
        this.promptForNoFacebookPageBound(router);
        break;
      case ChannelName.INSTAGRAM:
        this.promptForNoInstagramAccountBound(router);
        break;
      case ChannelName.GOOGLE:
        this.promptForNoGoogleAccountBound(router);
        break;
    }
  }

  promptForNoFacebookPages() {
    const msg = MessagePromptService.BuildNormalContentData(`To learn how to create a facebook page go here: `);
    const hyperLink = MessagePromptService.BuildLinkContentData('https://www.facebook.com/pages/creation/');
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[msg, hyperLink]] } });
  }

  promptForNoFacebookAdAccounts() {
    const preMsg = MessagePromptService.BuildNormalContentData(
      `Please go to your account info page to link your Facebook Ads Manager account or go to this link ( `,
    );
    const postMsg = MessagePromptService.BuildNormalContentData(` ) to learn how to create one`);
    const hyperLink = MessagePromptService.BuildLinkContentData('https://www.facebook.com/ads/manager');
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[preMsg, hyperLink, postMsg]] } });
  }

  promptForNoFacebookPageBound(routerString: string) {
    const preMsg = MessagePromptService.BuildNormalContentData(`Please bind your Facebook page first on your `);
    const postMsg = MessagePromptService.BuildNormalContentData(` page.`);
    const routerLink = MessagePromptService.BuildRouterContentData(`Account Info`, ['/', routerString]);
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[preMsg, routerLink, postMsg]] } });
  }

  promptForNoInstagramAccountsFound() {
    const preMsg = MessagePromptService.BuildNormalContentData(
      `to learn how to connect an instagram account to Facebook page, please go here: `,
    );
    const link = MessagePromptService.BuildLinkContentData(`https://www.facebook.com/business/help/898752960195806`);
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[preMsg, link]] } });
  }

  promptForNoInstagramAccountBound(routerString: string) {
    const preMsg = MessagePromptService.BuildNormalContentData(`Please bind your Instagram account first on your `);
    const postMsg = MessagePromptService.BuildNormalContentData(` page.`);
    const routerLink = MessagePromptService.BuildRouterContentData(`Account Info`, ['/', routerString]);
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[preMsg, routerLink, postMsg]] } });
  }

  promptForNoGoogleAccountBound(routerString: string) {
    const preMsg = MessagePromptService.BuildNormalContentData(
      `Please bind your Google customer account first on your `,
    );
    const postMsg = MessagePromptService.BuildNormalContentData(` page.`);
    const routerLink = MessagePromptService.BuildRouterContentData(`Account Info`, ['/', routerString]);
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[preMsg, routerLink, postMsg]] } });
  }

  promptForNoGoogleAccountsFound() {
    const msg = MessagePromptService.BuildNormalContentData(
      `to learn how to create new Google Ads accounts from your manager accounts go here: `,
    );
    const link = MessagePromptService.BuildLinkContentData(
      `https://support.google.com/google-ads/answer/7456139?co=ADWORDS.IsAWNCustomer%3Dfalse&hl=en`,
    );
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[msg, link]] } });
  }

  promptForNoGoogleAdsAccount() {
    const msg = MessagePromptService.BuildNormalContentData(`You do not have a Google Ads account, please go here: `);
    const link = MessagePromptService.BuildLinkContentData(`https://ads.google.com/home/`);
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[msg, link]] } });
  }

  showNormalErrorMessage(message: string, cb = Utils.Noop) {
    const msg = MessagePromptService.BuildNormalContentData(message);
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[msg]] } }, cb);
  }

  showErrorMsgIfNeeded(err: any, msg: string) {
    const errMsg = MessagePromptService.BuildNormalContentData(msg);
    this.messageDisplay.httpErrorHandle(err, { content: [[errMsg]] });
  }

  showErrorMsgForLocationUser(channel?: string) {
    let pre = 'Your related social medial account';
    switch (channel && channel.toLowerCase()) {
      case ChannelName.FACEBOOK:
        pre = `Your Facebook account`;
        break;
      case ChannelName.INSTAGRAM:
        pre = `Your Instagram account`;
        break;
      case ChannelName.GOOGLE:
        pre = `Your Google account`;
        break;
    }
    const msg = MessagePromptService.BuildNormalContentData(
      pre +
        ` is not connected yet. Please contact your franchise organization regarding this issue.
    `,
    );
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[msg]] } });
  }

  promptForSpecificChannelConnect(router: string, currentChannel: string) {
    this.promptForConnect(router, ManageUserSocialAccountsService.BuildPreString(currentChannel));
  }

  promptForConnect(router = 'app/account', preString = 'Please connect your advertising channels on your '): any {
    if (!this.user.canManageSocialAccounts()) {
      this.showErrorMsgForLocationUser();
      return;
    }
    if (this.dialogRef) {
      return;
    }

    const preMsg = MessagePromptService.BuildNormalContentData(preString);
    const postMsg = MessagePromptService.BuildNormalContentData(` page`);
    const routerStr = MessagePromptService.BuildRouterContentData('Account Info', [router]);
    this.messageDisplay.showDialog(PromptDialog, {
      data: {
        content: [[preMsg, routerStr, postMsg]],
        isRouterBtn: true,
        btnString: 'Go To Account Info',
        router: [router],
      },
    });
  }

  promptForNoLocationsInWideUser() {
    if (this.dialogRef) {
      return;
    }
    const promptMsg = MessagePromptService.BuildNormalContentData(
      `You can't create an ad campaign template until you have ` +
        `added your franchises in the "Location Management" section first.`,
    );
    this.messageDisplay.showDialog(PromptDialog, {
      data: {
        content: [[promptMsg]],
        isRouterBtn: true,
        btnString: 'Add My Franchise Locations Now',
        router: ['/app/location-management'],
        isEmotion: true,
      },
    });
  }

  showErrorMsgOnZowiAccountConnected(channel: string) {
    let pre;
    switch (channel && channel.toLowerCase()) {
      case ChannelName.FACEBOOK:
        pre = `Facebook account`;
        break;
      case ChannelName.INSTAGRAM:
        pre = `Instagram account`;
        break;
      case ChannelName.GOOGLE:
        pre = `Google account`;
        break;
    }
    const msg = MessagePromptService.BuildNormalContentData(
      pre +
        ` is not connected yet. Please contact your franchisor organization regarding this issue.
    `,
    );
    this.messageDisplay.showDialog(PromptDialog, { data: { content: [[msg]] } });
  }

  showReminderModal(router: string = 'app/account', updateUser = true): any {
    if (this.dialogRef) {
      return;
    }
    this.dialogRef = this.dialog.open(ReminderDialog, {
      width: '85%',
      maxWidth: '500px',
      disableClose: true,
      data: { router, updateUser },
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  showErrorByEditAd(channel: string, router: string) {
    let msg;
    const postMsg = `please connect or contact your franchise organization to connect first.`;
    switch (channel) {
      case ChannelName.FACEBOOK:
        msg = `This location is not connect to Facebook ad account, ` + postMsg;
        break;
      case ChannelName.GOOGLE:
        msg = `This location is not connect to Google client account, ` + postMsg;
        break;
      case ChannelName.INSTAGRAM:
        msg = `This location is not connect to Instagram account, ` + postMsg;
        break;
    }
    let payload = {};
    if (this.user.isOrgUser()) {
      payload = {
        data: {
          content: [[MessagePromptService.BuildNormalContentData(msg)]],
          isRouterBtn: true,
          btnString: 'Go To Account Info',
          router: [router],
        },
      };
    } else if (this.user.isLocUser()) {
      payload = {
        data: { content: [[MessagePromptService.BuildNormalContentData(msg)]] },
      };
    }
    this.messageDisplay.showDialog(PromptDialog, payload);
  }
}
