import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ManageUserSocialAccountsService as OauthConnectService, UserService } from '@services';
import { AccountConnectStatus as ConnectStatus, IUser } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class AccountConnectionGuard implements CanActivate {
  constructor(private userService: UserService, private oauthConnectService: OauthConnectService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url.includes('performance-overview') || state.url.includes('home')) {
      return this.showConnectionReminder();
    } else {
      return this.checkConnectionStatus();
    }
  }

  private checkConnectionStatus(): Observable<boolean> {
    return this.oauthConnectService.getAccountsConnectedStatus().pipe(
      map((connectStatus: ConnectStatus) => {
        if (!OauthConnectService.CheckAccountConnected(connectStatus)) {
          this.oauthConnectService.promptForConnect('app/account');
          return false;
        } else {
          return true;
        }
      }),
    );
  }

  private showConnectionReminder(): Observable<boolean> {
    const userProfile: IUser = this.userService.getCachedUserProfile();
    if ((userProfile && !userProfile.userChannelsConnectWarnInd) || !this.userService.canManageSocialAccounts()) {
      return of(true);
    } else {
      return this.oauthConnectService.getAccountsConnectedStatus().pipe(
        map((connectStatus: ConnectStatus) => {
          if (
            this.userService.canManageSocialAccounts() &&
            OauthConnectService.ShowPromptModal(connectStatus, userProfile)
          ) {
            this.oauthConnectService.showReminderModal('app/account', true);
          }
          return true;
        }),
      );
    }
  }
}
