import { IUser } from './user.interface';
import { CampaignTemplate } from './campaign.interface';
import { ILimitedOrg } from './organization.interface';

export enum LocationGroupOrder {
  locationGroupNme = 'locationGroupNme',
  locationGroupActive = 'locationGroupActive',
  locationGroupCreatedAt = 'locationGroupCreatedAt',
  locationCount = 'locationCount',
}

// TODO remove locationSelfState
export interface ILocation {
  locationId: string;
  organizationId?: string;
  organization?: ILimitedOrg;
  locationNme?: string;
  locationContactNme?: string;
  locationContactEmail?: string;
  locationAddress?: string;
  locationStreetAddress?: string;
  locationPhoneNbr?: string;
  locationState?: Array<string>;
  locationCity?: string;
  locationSelfZip?: string;
  locationSelfState?: string;
  locationZips?: Array<string>;
  locationRadius?: string;
  users?: Array<IUser>;
  campaignTemplates?: Array<CampaignTemplate>;
  requestingUser?: IUser;
  locationWebsite?: string;
  locationInstagramAccount?: string;
  googleAdwordsClientCustomerId?: string;
  locationActive?: boolean;
  adAccountId?: string;
  adsPixels?: Array<{
    id: string;
    name: string;
  }>;
  locationReadOnly?: boolean;
  locationCanDelete?: boolean;
  locationGroups?: Array<LocationGroup>;
  locationCountryCode?: string;
  locationExcludedZips?: Array<string>;
  locationExcludedRadius?: Array<string>;
  myBusinessConnected?: boolean;
  locationZeeGmbLocation?: string;
  locationZorGmbLocation?: string;
  locationExtensionEnabled?: boolean;
  campaignTemplateChipAddress?: string | null;
  campaignTemplateChipCity?: string | null;
  campaignTemplateChipPhoneNumber?: string | null;
}

export interface ILocationBindDetail {
  locationId: string;
  facebookAccountUserNme: string;
  facebookPageRealId: string;
  facebookPageNme: string;
  locationInstagramAccount: string;
  locationInstagramAccountType: string;
  googleAdwordsClientCustomerId: string;
  googleAdwordsCustomerNme: string;
}

// TODO remove locationSelfState

export interface ILimitedLocation {
  locationId: string;
  organizationId: string;
  locationNme: string;
  locationContactNme?: string;
  locationContactEmail?: string;
  locationAddress: string;
  locationStreetAddress?: string;
  locationPhoneNbr?: string;
  locationState?: Array<string>;
  locationCity?: string;
  locationSelfZip?: string;
  locationSelfState?: string;
  locationZips?: Array<string>;
  locationRadius?: string;
  locationWebsite?: string;
  googleAdwordsClientCustomerId?: string;
  locationActive?: boolean;
  locationReadOnly?: boolean;
  organization?: ILimitedOrg;
  locationExcludedZips?: Array<string>;
  locationExcludedRadius?: Array<string>;
}

export interface ILocationList {
  locations: Array<ILocation>;
  totalCount: number;
  pageCount: number;
  pageNbr: number;
  pageSize: number;
}

export interface ILocationInput {
  organizationId: string;
  locationNme: string;
  locationContactNme?: string;
  locationContactEmail?: string;
  locationAddress?: string;
  locationPhoneNbr?: string;
  locationCity?: string;
  locationSelfZip?: string;
  locationWebsite?: string;
  locationRadius?: string;
  locationZips?: Array<string>;
  locationState?: Array<string>;

  locationExcludedZips?: Array<string>;
  locationExcludedRadius?: Array<string>;
}

export interface ILocationUpdateInput {
  organizationId?: string;
  locationId?: string;
  locationNme?: string;
  locationContactNme?: string;
  locationContactEmail?: string;
  locationAddress?: string;
  locationPhoneNbr?: string;
  locationCity?: string;
  locationSelfZip?: string;
  locationWebsite?: string;
  locationRadius?: string;
  locationZips?: Array<string>;
  locationState?: Array<string>;
  locationExcludedZips?: Array<string>;
  locationExcludedRadius?: Array<string>;
}

export interface ILocationGroupListType {
  locationGroups: Array<LocationGroup>;
  count: number;
}

export interface LocationGroup {
  locationGroupId: string;
  locationGroupNme: string;
  locationGroupActive: boolean;
  locationGroupLocationIds: Array<string>;
  locationGroupOrgId: string;
  locationGroupCreatedAt: string;
  locationGroupUpdatedAt: string;
}

export interface ILocationGroupUpdate {
  locationGroupId: string;
  locationGroupNme?: string;
  locationGroupActive?: boolean;
  locationGroupLocationIds?: Array<number>;
}

export interface IGetLocationGroupsVariable {
  nbr?: number;
  size?: number;
  order?: string;
  asc?: boolean;
}

export interface IGoogleLocationAddress {
  id: string;
  description: string;
  matched_substrings: Array<{
    length: string;
    offset: string;
  }>;
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: Array<{
      length: string;
      offset: string;
    }>;
    secondary_text: string;
  };
  terms: Array<{
    offset: string;
    value: string;
  }>;
  types: Array<string>;
}

export interface IGoogleAddressValidationType {
  valid: boolean;
  errorCode?: number;
  message?: string;
}
