import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { orderBy } from 'lodash';
import { DropdownOption } from '@interfaces';

@Component({
  selector: 'app-result-list-dialog',
  templateUrl: './result-list-dialog.component.html',
  styleUrls: ['./result-list-dialog.component.scss'],
})
export class ResultListDialogComponent implements OnInit {
  // TODO: refactor
  constructor(
    private dialogRef: MatDialogRef<ResultListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public listData: { data?: any[]; value?: string; title?: string; subject?: string },
  ) {}

  list = [];
  sortOptions: DropdownOption[];
  isLatestActivity = false;
  isAdReviews = false;
  isNormal = true;
  private valueType: string;

  ngOnInit() {
    this.updateSortOptions();
    this.initListData();
  }

  private updateSortOptions() {
    this.valueType = this.listData.value;
    let valueText: string;
    switch (this.valueType) {
      case 'ctr':
        valueText = 'CTR';
        break;
      case 'run':
        valueText = 'Run Times';
        break;
      case 'spend':
      default:
        valueText = 'Spending';
        break;
    }
    this.sortOptions = [
      { text: 'Top ' + valueText, value: 'desc' },
      { text: 'Bottom ' + valueText, value: 'asc' },
    ];
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  sortList(order: string): void {
    this.list = orderBy(this.list, this.valueType, order);
  }

  private initListData(): void {
    if (this.listData.data.length !== 0) {
      this.isLatestActivity = this.listData.title === 'All Franchisee By Latest Activities';
      this.isAdReviews = this.listData.title === 'Ad Reviews';
      this.isNormal = !this.isLatestActivity && !this.isAdReviews;
      this.list = this.listData.data;
    }
  }
}
