<div class="message-dialog-container">
  <app-ui-icon [icon]="'red-warning'" size="4"></app-ui-icon>
  <span class="close-icon dialog-close-icon" (click)="dialogRef.close(false)"></span>

  <p class="message-dialog-body">{{ confirmMessage || 'You have unsaved changes, are you sure to leave this page?'}}</p>
  <div class="message-dialog-button-group">
    <app-ui-button class="message-dialog-button" (clickEvent)="dialogRef.close(false)">
      Cancel
    </app-ui-button>
    <app-ui-button class="message-dialog-button" btnType="secondary" (clickEvent)="dialogRef.close(true)">
      Continue
    </app-ui-button>
  </div>
</div>
`
