import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ui-card',
  templateUrl: './ui-card.component.html',
  styleUrls: ['./ui-card.component.scss'],
})
export class UiCardComponent implements OnInit, OnChanges {
  @Input()
  title: string;
  @Input()
  color: string;
  @Input()
  bigTitle: string;
  @Input()
  noShadow: boolean;

  imageUrl: string;
  borderColor: string;

  constructor() {}

  ngOnInit() {
    if (this.color) {
      this.borderColor = this.color.toLowerCase();
    }
    this.updateImageUrl(this.bigTitle);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.borderColor = changes.color.currentValue ? this.color.toLowerCase() : null;
    }
  }

  private updateImageUrl(title): void {
    if (title) {
      switch (title.toLowerCase()) {
        case 'highest ctr': {
          this.imageUrl = 'clicks-white';
          break;
        }
        case 'most run': {
          this.imageUrl = 'audience-primary-white';
          break;
        }
        case 'top rated': {
          this.imageUrl = 'audience-primary-white';
          break;
        }
        case 'highest spend': {
          this.imageUrl = 'dollar-white';
          break;
        }
        case 'most impressions': {
          this.imageUrl = 'impressions-white';
          break;
        }
        case 'most clicks': {
          this.imageUrl = 'clicks-white';
          break;
        }
        default: {
          this.imageUrl = 'audience-primary-white';
          break;
        }
      }
    }
  }
}
