export interface IKeywordGroup {
  keywordGroupId: string;
  keywordGroupNme: string;
  keywordGroupContent: Array<string>;
  keywordGroupCreatedAt: string;
  organizationId?: string;
}
export interface KeywordItem {
  value: string;
  disabled: boolean;
  highlight: boolean;
}

export enum KeywordGroupOrder {
  keywordGroupNme = 'keywordGroupNme',
  keywordGroupCreatedAt = 'keywordGroupCreatedAt',
  keywordGroupContent = 'keywordGroupContent',
  keywordCount = 'keywordCount',
}

export interface IKeywordGroupListType {
  keywordGroups: Array<IKeywordGroup>;
  count: number;
}

export interface IKeywordGroupPageInput {
  pageNum: number;
  pageSize: number;
}

export interface ISearchKeywordGroupInput {
  keywordGroupNme: string;
  pageInfo: IKeywordGroupPageInput;
}

export interface ISearchKeywordInput {
  keyword?: string;
  sortBy?: IKeywordGroupOrderInfo;
  pageInfo: IKeywordGroupPageInput;
}

export interface IKeywordGroupOrderInfo {
  orderBy: string;
  asc: boolean;
}

export interface IKeywordGroupInput {
  keywordGroupNme: string;
  keywordGroupContent: Array<string>;
}
