<div>
  <div class="dialog-card-title flex-row">
    <h2 class="page-title">Franchisee Reviews</h2>
    <span class="close-icon" (click)="closeDialog()"></span>
  </div>

  <mat-spinner *ngIf="isLoading"></mat-spinner>

  <div *ngIf="!isLoading && template" class="dialog-card-content section-row">
    <div class="flex-hor-center">
      <app-ui-icon [logo]="template.channel"></app-ui-icon>
      <span class="mr-s ml-s">{{ template.name }}</span>
      <app-ui-star-rating [stars]="template.rate" [reviews]="template.rateCount"></app-ui-star-rating>
    </div>

    <ul class="section-row">
      <ng-container *ngFor="let adReview of adReviews | slice: (currentPage - 1) * pageSize : (currentPage - 1) * pageSize + pageSize">
        <li class="list-item">
          <div>
            <app-ui-star-rating [stars]="adReview.rate" [isShowReview]="false"></app-ui-star-rating>
            <span class="ml-s">{{ adReview.locationName }}</span>
            <p class="mt-xs text-gray">{{ adReview.comment }}</p>
          </div>
        </li>
      </ng-container>
      <li class="mt-s">
        <ngb-pagination [(page)]="currentPage" [pageSize]="pageSize" [collectionSize]="adReviews.length" [maxSize]="3"></ngb-pagination>
      </li>
    </ul>
  </div>
</div>
