import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportingFormat } from '@interfaces';
import { AppConfigService } from '@services';
import { NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type DownloadOptions = {
  organizationId: string;
  locationId?: string | null;
  format: ReportingFormat;
  startDate?: string | null;
  endDate?: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class ReportingApiClientService {
  constructor(private readonly appConfig: AppConfigService, private readonly httpClient: HttpClient) {}

  public downloadCampaignMetrics(options: DownloadOptions): Observable<{
    blob: Blob;
    fileName: string;
  }> {
    const downloadUrl = this.getCampaignMetricsUrl(options);
    const fileExtension = options.format;
    const contentType = `application/${options.format}`;

    return this.httpClient
      .get(downloadUrl, {
        observe: 'response',
        responseType: 'blob',
        headers: {
          Accept: contentType,
        },
      })
      .pipe(
        map((response) => {
          let fileName = this.getFileNameFromHeader(response);
          if (!fileName) {
            fileName = `campaing-metrics-report.${fileExtension}`;
          }

          return {
            blob: response.body,
            fileName,
          };
        }),
      );
  }

  private getCampaignMetricsUrl({ organizationId, locationId, format, startDate, endDate }: DownloadOptions): string {
    const endpoint = new URL(`${this.appConfig.reportingApiUrl}/${organizationId}/campaign-metrics-report.${format}`);

    if (locationId) {
      endpoint.searchParams.append('locationId', locationId);
    }
    if (startDate) {
      endpoint.searchParams.append('startDate', startDate);
    }
    if (endDate) {
      endpoint.searchParams.append('endDate', endDate);
    }

    return endpoint.toString();
  }

  private getFileNameFromHeader(response: HttpResponse<unknown>): string | null {
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
    return '';
  }
}
