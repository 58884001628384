import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { GoogleAnalyticsService, ValidationsService } from '@services';

@Component({
  selector: 'app-ui-radio',
  templateUrl: './ui-radio.component.html',
  styleUrls: ['./ui-radio.component.scss'],
})
export class UiRadioComponent implements OnInit {
  @Input()
  ownFormGroup: FormGroup;
  @Input()
  controlName: string;
  @Input()
  options;
  @Input()
  selected = '';
  @Input()
  disabled = false;
  @Input()
  isTrackEvent = false;

  @Output()
  changeEvent: EventEmitter<MatRadioChange> = new EventEmitter();

  constructor(private validationsService: ValidationsService, private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit() {
    this._initForm();
  }

  private _initForm() {
    let formGroup = this.ownFormGroup;
    let controlName = this.controlName;

    if (!formGroup || !controlName) {
      const defaultControl = {
        defaultControl: new FormControl({ value: '', disabled: this.disabled }, []),
      };
      formGroup = new FormGroup(defaultControl);
      controlName = 'defaultControl';
    }

    this.ownFormGroup = formGroup;
    this.controlName = controlName;
    this.setSelected();
  }

  getValue(option: any) {
    return !!option.value ? option.value : option;
  }

  getText(option: any) {
    return !!option.text ? option.text : !!option.value ? option.value : option;
  }

  doChangeEvent(event: MatRadioChange) {
    const control = this.formControl;
    if (!!event.value) {
      if (!!control) {
        control.setValue(event.value);
      }
      this.setSelected();
    }
    this.changeEvent.emit(event);
  }

  get formControl(): AbstractControl {
    return this.ownFormGroup.get(this.controlName);
  }

  get validationError() {
    const formControl = this.formControl;
    return !!this.errorMessage && formControl.invalid && (formControl.dirty || formControl.touched);
  }

  get errorMessage() {
    return this.validationsService.getValidationErrorMessage(this.formControl);
  }

  private setSelected(): void {
    const formGroup = this.ownFormGroup;
    const controlName = this.controlName;
    let result = '';
    if (!!this.selected) {
      result = this.selected;
    }
    if (!!formGroup && !!controlName) {
      result = this.formControl.value;
    }
    this.selected = result;
  }

  eventTrack(label: string): void {
    if (this.isTrackEvent) {
      // Only for 'Create Template' page
      this.googleAnalytics.eventTracking('Create Campaign', 'Tooltip Interaction', label);
    }
  }
}
