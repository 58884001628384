import { Injectable } from '@angular/core';
import { AdsetScheduleTimesType } from '@interfaces';
import { union } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DaypartingService {
  googleAdsetScheduleFromFacebook(adsetSchedule: Array<AdsetScheduleTimesType>): Array<AdsetScheduleTimesType> {
    const result: Array<AdsetScheduleTimesType> = [];
    adsetSchedule.forEach((item) => {
      const facebookItem = item as AdsetScheduleTimesType;
      const days = facebookItem.days || [];
      if (days.length > 0) {
        days.forEach((day) => {
          const newAdsetScheduleItem: AdsetScheduleTimesType = {
            startHour: Math.floor(facebookItem.startMinute / 60),
            startMinute: facebookItem.startMinute % 60,
            endHour: Math.floor(facebookItem.endMinute / 60),
            endMinute: facebookItem.endMinute % 60,
            dayOfWeek: day,
          };
          result.push(newAdsetScheduleItem);
        });
      } else {
        result.push({
          startHour: Math.floor(facebookItem.startMinute / 60),
          startMinute: facebookItem.startMinute % 60,
          endHour: Math.floor(facebookItem.endMinute / 60),
          endMinute: facebookItem.endMinute % 60,
          dayOfWeek: undefined,
        });
      }
    });
    return result;
  }

  facebookAdsetScheduleFromGoogle(adsetSchedule: Array<AdsetScheduleTimesType>): Array<AdsetScheduleTimesType> {
    const result: Array<AdsetScheduleTimesType> = [];
    adsetSchedule.forEach((item) => {
      const googleItem = item;
      const dayOfWeek = googleItem.dayOfWeek;
      const newAdsetScheduleItem: AdsetScheduleTimesType = {
        startMinute: googleItem.startMinute + googleItem.startHour * 60,
        endMinute: googleItem.endMinute + googleItem.endHour * 60,
        days: !!dayOfWeek || dayOfWeek === 0 ? [dayOfWeek] : [],
      };

      const sameMinuteItemIndex = result.findIndex((adsetScheduleItem) => {
        return (
          adsetScheduleItem.startMinute === newAdsetScheduleItem.startMinute &&
          adsetScheduleItem.endMinute === newAdsetScheduleItem.endMinute
        );
      });
      if ((!!dayOfWeek || dayOfWeek === 0) && sameMinuteItemIndex >= 0) {
        result[sameMinuteItemIndex].days.push(...newAdsetScheduleItem.days);
        result[sameMinuteItemIndex].days = union(result[sameMinuteItemIndex].days);
      } else {
        result.push(newAdsetScheduleItem);
      }
    });
    return result;
  }

  overlappingTimeValid(minuteWithDays: Array<{ startMinute: number; endMinute: number }>): boolean {
    let valid = true;
    minuteWithDays = minuteWithDays.sort((preItem, nextItem) => (preItem.startMinute < nextItem.startMinute ? -1 : 1));
    minuteWithDays.forEach((minute, index, array) => {
      if (index > 0) {
        const preMinute = array[index - 1];
        if (preMinute.endMinute > minute.startMinute) {
          valid = false;
        }
      }
    });
    return valid;
  }
}
