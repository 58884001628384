import { Injectable, Inject, OnDestroy } from '@angular/core';

import {
  Auth0Client,
  RedirectLoginOptions,
  PopupLoginOptions,
  PopupConfigOptions,
  LogoutOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js';

import { of, from, BehaviorSubject, Subject, Observable, iif, defer, ReplaySubject } from 'rxjs';

import { concatMap, tap, map, filter, takeUntil, distinctUntilChanged, catchError, switchMap } from 'rxjs/operators';

import { ɵc as AbstractNavigator, ɵb as Auth0ClientService } from '@auth0/auth0-angular';
import { Location } from '@angular/common';

/**
 * DO NOT USE EXPLICITLY, use `AuthService` from `auth0/auth0-angular` library.
 *
 * This is a COPY-PASTE from `auth0/auth0-angular@1.2.0` with change in detecting the callback
 * See the original library service
 * https://github.com/auth0/auth0-angular/blob/v1.2.0/projects/auth0-angular/src/lib/auth.service.ts
 */

@Injectable({
  providedIn: 'root',
})
export class Auth0Service implements OnDestroy {
  private isLoadingSubject$ = new BehaviorSubject(true);
  private isAuthenticatedSubject$ = new BehaviorSubject(false);
  private errorSubject$ = new ReplaySubject<Error>(1);

  private ngUnsubscribe$ = new Subject();

  readonly isLoading$ = this.isLoadingSubject$.asObservable();

  readonly isAuthenticated$ = this.isLoading$.pipe(
    filter((loading) => !loading),
    distinctUntilChanged(),
    concatMap(() => this.isAuthenticatedSubject$),
  );

  readonly user$ = this.isAuthenticated$.pipe(
    filter((authenticated) => authenticated),
    distinctUntilChanged(),
    concatMap(() => this.auth0Client.getUser()),
  );

  readonly idTokenClaims$ = this.isAuthenticated$.pipe(
    filter((authenticated) => authenticated),
    distinctUntilChanged(),
    concatMap(() => this.auth0Client.getIdTokenClaims()),
  );

  readonly error$ = this.errorSubject$.asObservable();

  constructor(
    @Inject(Auth0ClientService) private auth0Client: Auth0Client,
    private location: Location,
    private navigator: AbstractNavigator,
  ) {
    const checkSessionOrCallback$ = (isCallback: boolean) =>
      iif(
        () => isCallback,
        this.handleRedirectCallback(),
        defer(() => this.checkSession()),
      );

    this.shouldHandleCallback()
      .pipe(
        switchMap((isCallback) =>
          checkSessionOrCallback$(isCallback).pipe(
            catchError((error) => {
              this.errorSubject$.next(error);
              this.navigator.navigateByUrl('/');
              return of(undefined);
            }),
          ),
        ),
        concatMap(() => this.auth0Client.isAuthenticated()),
        tap((authenticated) => {
          this.isAuthenticatedSubject$.next(authenticated);
          this.isLoadingSubject$.next(false);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    // https://stackoverflow.com/a/41177163
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  loginWithRedirect(options?: RedirectLoginOptions): Observable<void> {
    return from(this.auth0Client.loginWithRedirect(options));
  }

  loginWithPopup(options?: PopupLoginOptions, config?: PopupConfigOptions): Observable<void> {
    return from(
      this.auth0Client.loginWithPopup(options, config).then(async () => {
        this.isAuthenticatedSubject$.next(await this.auth0Client.isAuthenticated());
      }),
    );
  }

  logout(options?: LogoutOptions): void {
    this.auth0Client.logout(options);

    if (options?.localOnly) {
      this.isAuthenticatedSubject$.next(false);
    }
  }

  getAccessTokenSilently(options?: GetTokenSilentlyOptions): Observable<string> {
    return of(this.auth0Client).pipe(concatMap((client) => client.getTokenSilently(options)));
  }

  getAccessTokenWithPopup(options?: GetTokenWithPopupOptions): Observable<string> {
    return of(this.auth0Client).pipe(concatMap((client) => client.getTokenWithPopup(options)));
  }

  private async checkSession(): Promise<void> {
    return this.auth0Client.checkSession();
  }

  private shouldHandleCallback(): Observable<boolean> {
    return of(this.location.path()).pipe(
      map((search) => {
        /**
         * Originally, Auth0 client considered page as a callback after login
         * when URL contained 'code'/'error' and 'state' URL parameters
         * and tried to restore previous app state and login user
         *
         * But that logic did not allow to connect Google account - there was a redirection
         * to default page instead of /app/account since Auth0 considered redirect page
         * from Google as a redirect page after login and Auth0 tried to authenticate
         * user
         *
         * Re-implement auth0 service and callback page determination to prevent such behavior
         * by ensuring URL does not have URL params for account connection
         */
        return (
          (search.includes('code=') || search.includes('error=')) &&
          search.includes('state=') &&
          !search.includes('channel')
        );
      }),
    );
  }

  private handleRedirectCallback(): Observable<RedirectLoginResult> {
    return defer(() => this.auth0Client.handleRedirectCallback()).pipe(
      tap((result) => {
        const target = result?.appState?.target ?? '/';
        this.navigator.navigateByUrl(target);
      }),
    );
  }
}
