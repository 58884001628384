<div class="dialog-container" [attr.id]="idPrefix">
  <div [attr.id]="idPrefix + 'Close'" class="dialog-close-container interaction" (click)="closeModal()">
    <img [src]=" closeIconPath " alt="close icon">
  </div>

  <div [attr.id]="idPrefix + 'Icon'" class="dialog-icon-container" >
    <img [src]=" warningIconPath " alt="warning icon">
  </div>

  <div [attr.id]="idPrefix + 'Content'" class="dialog-content-container">
    <div>
      <span class="dialog-content">Please connect your advertising channels on your&nbsp;</span>
      <button class="dialog-content interaction" type="button" (click)="gotoRouter()">Account Info</button>
      <span class="dialog-content">&nbsp;page</span>
    </div>
  </div>

  <div [attr.id]="idPrefix + 'BtnContainer'" class="dialog-actions">
    <button class="app-primary-btn" type="button" (click)="gotoRouter()">
      <span class="btn-label">Go To Account Info</span>
    </button>
  </div>
</div>
